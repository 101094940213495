/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.simplicity-field {
    margin-top: 8px;
    margin-bottom: 8px;
    /*margin-top: 4px;*/
    /*margin-bottom: 4px;*/
}
.simplicity-field .fields-style-body {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: #000000;
}
.simplicity-field .fields-style-h1, .simplicity-field .h1  {
    font-size: 2.5rem;
    line-height: 1.5;
    font-weight: 700;
    color: #2d2d2d;
    margin-bottom: 0.6em;
    text-transform: uppercase
}
.simplicity-field .fields-style-h2, .simplicity-field .h2  {
    font-size: 1.5rem;
    line-height: 1.1;
    font-weight: 600;
    color: #2d2d2d;
    margin-top: 2em;
    margin-bottom: 0.4em;
}
.simplicity-field .fields-style-h3, .simplicity-field .h3  {
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 500;
    color: #2d2d2d;
    margin-top: 2em;
    margin-bottom: 0.4em
}
