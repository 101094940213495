/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.two-column-side-nav.neighbourhood{
    padding-left: 0px
}

.two-column-side-nav.neighbourhood .two-column-side-nav-left{
    width: 385px;
    max-width: unset;
    padding: 48px 0px 0px 24px;
}

.two-column-side-nav.neighbourhood .two-column-side-nav-right{
    padding: 56px 80px;
}

.two-column-side-nav.neighbourhood .feature-webpage-container.fixed_3{
    flex-direction: row;
}

.two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content{
    width: 100%;
    padding: 0;
    display: flex;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}

.two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-banner-image{
    width: 50%;
    height: 100%;
    min-height: 376px;
}

.two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-banner-image .model-image{
    height: 100% !important;
    min-height: 376px;
}

.two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-content-text{
    width: 50%;
    padding: 32px;
}

/* .neighbourhood-introduction {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.neighbourhood-introduction div {
    flex-basis: 100%;
} */
/* .neightbourhood-side-block {
    min-height: 500px;
} */

.neightbourhood-side-block .feed-small-container{
    margin-bottom: 0px;
}

.neightbourhood-side-block .simplicity-cover-image-thumb{
    margin-top: 0px;
    height: 180px !important;
}

.neightbourhood-side-block h2{
    margin-bottom: 16px;
}

.neightbourhood-side-block .view-header{
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    padding-top: 16px;
    border-top: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.neightbourhood-side-block .view-header a:hover{
    color: var(--color-dnv-hover-green);
}

.neightbourhood-side-block .feed-component-container-small{
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}

.neightbourhood-side-block .feed-component-panel,
.neightbourhood-side-block .feed-component-header{
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}

.neightbourhood-side-block .feed-component-header{
    margin-right: 24px;
}

.neightbourhood-side-block .feed-component-header-title:after{
    display: none;
}

/* .neightbourhood-side-block .view-header h2:after{
    content: "";
    background: var(--color-dnv-brand-navy);
    width: 110px;
    height: 8px;
    margin-top: 8px;
    display: block;
} */
.neightbourhood-side-block .view-header a{
    margin-top: 6px;
    font-weight: 700;
}
.neightbourhood-side-block .view-content h3.title {
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--color-dnv-brand-blue);
    padding: 12px 0px;
}

.neightbourhood-side-block .view-content h3.title a:hover{
    color: var(--color-dnv-hover-green);
}

.neightbourhood-side-block .view-content .views-row{
    background-color: var(--color-white);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
    margin-bottom: 24px;
}

.neightbourhood-side-block .view-content .views-row:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.neightbourhood-side-block .view-content .field-content.banner-image-style{
    height: 180px;
    overflow: hidden;
}

.neightbourhood-side-block .view-content .views-row .field-name-title,
.neightbourhood-side-block .view-content .views-row .field-name-post-date {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    margin: 0;
    padding:  0px 16px;
}
.neightbourhood-side-block .view-content .views-row .field-name-post-date {
    padding-bottom: 12px;
}
/* ------------------------------------------- */
.label-above {
    text-transform: none !important;
    color: #262626;
    font-family: var(--font-family-heading);
    font-size: 25px;
    margin: 0 0 16px 0;
}
.field-name-field-services {
    margin-top: 64px;
}
.services-container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    flex-wrap: wrap;
}
.field-name-field-services .service {
    width: calc((100% - 64px) / 3);
    height: 76px;
    font-family: var(--font-family-heading);
    /* display: table-cell; */
    margin: 0;
    font-size: 17px;
    border: 1px solid #cdd5c3;
    display: flex;
    padding: 16px;
}
.field-name-field-services .service:first-child {
    border-left: 1px solid #cdd5c3;
}

.field-name-field-services .service:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}
.field-name-field-services .service-link {
    /* padding: 16px 12px; */
    margin-left: 7px;
    font-size: 17px;
    display: flex;
    align-items: center;
}
.field-name-field-services .service svg path {
    fill: var(--color-dnv-interactive-blue);
    /*fill: #819fa6;*/
}
.field-name-field-services .service a {
    font-family: var(--font-family-links-labels);
    /*font-size: 17px;*/
    /*line-height: 22px;*/
    font-size: 20px;
    line-height: 25px;
    display: flex;

}
.field-name-field-services .service:hover svg path,
.field-name-field-services .service:hover a {
    fill: var(--color-dnv-hover-green);
    color: var(--color-dnv-hover-green);
}
.field-name-field-services .service:active svg path,
.field-name-field-services .service:active a {
    fill: var(--color-dnv-active-black);
    color: var(--color-dnv-active-black);
}
.field-name-field-services .service .service-icon {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.field-name-field-services .service .service-icon svg{
    height: 24px;
    width: auto;
}

@media (max-width: 1201px){
    .two-column-side-nav.neighbourhood .two-column-side-nav-right{
        padding: 56px 20px;
    }
}

@media (max-width: 1124px){
    .neightbourhood-side-block .feed-component-panel,
    .neightbourhood-side-block .feed-component-header,
    .neightbourhood-side-block .feed-component-container-small{
        background-color: transparent;
    }

    .two-column-side-nav.neighbourhood .two-column-side-nav-left{
        width: 100%;
        padding: 0px 20px;
    }

    .neightbourhood-side-block .view-content {
        display: flex;
        gap: 32px;
        justify-content: space-between;
        overflow-x: auto;
        margin-bottom: 16px;
    }

    .neightbourhood-side-block .view-content::-webkit-scrollbar {
      height: 8px;
    }

    .neightbourhood-side-block .view-content::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--color-dnv-bg-and-border-pale-tan);
    }

    .neightbourhood-side-block .view-content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--color-dnv-brand-blue);
    }

    .neightbourhood-side-block .view-content .views-row{
        width: calc((100% - 64px)/3);
        min-width: 338px;
    }

    .neightbourhood-side-block .feed-component-header{
        margin-right: 0px;
    }

    .neightbourhood-side-block .view-header{
        padding-right: 0px;
    }
}

/*@media (max-width: 640px) {*/
@media (max-width: 1000px) {
    .services-container {
        display: flex;
        /* flex-direction: column;
        position: relative;
        width: 100%; */
    }
    .field-name-field-services .service {
        border-left: 1px solid #c5c5c1;
    }
}

@media (max-width: 730px){
    .field-name-field-services .service{
        width: calc((100% - 32px) / 2);
    }
}

@media (max-width: 480px){
    .field-name-field-services .service{
        width: 100%;
    }
}

@media (max-width: 360px){
    .two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content{
        display: block;
    }

    .two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-content-text{
        width: 100%;
    }
    .two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-banner-image{
        width: 100%;
        height: 200px;
        min-height: unset;
    }

    .two-column-side-nav.neighbourhood .feature-webpage-container .feature-webpage-content .webpage-banner-image .model-image{
        height: 200px !important;
        min-height: unset;
    }

    .neightbourhood-side-block .view-content{
        display: block;
    }

    .neightbourhood-side-block .view-content .views-row{
        width: 100%;
        min-width: unset;
    }
}

/*.field-name-field-services .service a:hover {*/
/*    color: #f0f0eb;*/
/*    text-decoration: none*/
/*}*/

/*.field-name-field-services .service a:hover:before {*/
/*    color: #c6d0d0*/
/*}*/

/*.theme-programs_services .field-name-field-services .service a:hover:before {*/
/*    color: #b9c7c9*/
/*}*/

/*.theme-recreation_leasure .field-name-field-services .service a:hover:before {*/
/*    color: #c6d0bc*/
/*}*/

/*.theme-permits_licenses .field-name-field-services .service a:hover:before {*/
/*    color: #e4d3ba*/
/*}*/

/*.theme-property_development .field-name-field-services .service a:hover:before {*/
/*    color: #ddc9b6*/
/*}*/

/*.theme-government .field-name-field-services .service a:hover:before {*/
/*    color: #c8c2bb*/
/*}*/

/*.theme-contact .field-name-field-services .service a:hover:before {*/
/*    color: #d7d6bc*/
/*}*/

/*.theme-alert .field-name-field-services .service a:hover:before {*/
/*    color: #d2c2bc*/
/*}*/

/*.field-name-field-services .service.house a:before {*/
/*    content: "\e610"*/
/*}*/

/*.field-name-field-services .service.clipboard a:before {*/
/*    content: "\e609"*/
/*}*/

/*.field-name-field-services .service.info a:before {*/
/*    content: "\e628"*/
/*}*/

/*.field-name-field-services .service.animal a:before {*/
/*    content: "\e607"*/
/*}*/

/*.field-name-field-services .service.book a:before {*/
/*    content: "\e608"*/
/*}*/

/*.field-name-field-services .service.construction a:before {*/
/*    content: "\e612"*/
/*}*/

/*.field-name-field-services .service.contact a:before {*/
/*    content: "\e613"*/
/*}*/

/*.field-name-field-services .service.council a:before {*/
/*    content: "\e614"*/
/*}*/

/*.field-name-field-services .service.garbage a:before {*/
/*    content: "\e616"*/
/*}*/

/*.field-name-field-services .service.hand a:before {*/
/*    content: "\e619"*/
/*}*/

/*.field-name-field-services .service.noise a:before {*/
/*    content: "\e61b"*/
/*}*/

/*.field-name-field-services .service.notice a:before {*/
/*    content: "\e61c"*/
/*}*/

/*.field-name-field-services .service.parking a:before {*/
/*    content: "\e61d"*/
/*}*/

/*.field-name-field-services .service.permit a:before {*/
/*    content: "\e61e"*/
/*}*/

/*.field-name-field-services .service.person a:before {*/
/*    content: "\e61f"*/
/*}*/

/*.field-name-field-services .service.recreation a:before {*/
/*    content: "\e620"*/
/*}*/

/*.field-name-field-services .service.residential_parking a:before {*/
/*    content: "\e621"*/
/*}*/

/*.field-name-field-services .service.snow a:before {*/
/*    content: "\e623"*/
/*}*/

/*.field-name-field-services .service.tax a:before {*/
/*    content: "\e624"*/
/*}*/

/*.field-name-field-services .service.tree a:before {*/
/*    content: "\e625"*/
/*}*/

/*.field-name-field-services .service.sprinkler a:before {*/
/*    content: "\e900"*/
/*}*/

/*.field-name-field-services .service.pencil2 a:before {*/
/*    content: "\e901"*/
/*}*/

/*.field-name-field-services .service.pencil a:before {*/
/*    content: "\e905"*/
/*}*/
