/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-cover-image {
    position: relative;
    transform: translate3d(0, 0, 0);
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
    margin-bottom: 36px;
}

.simplicity-cover-image.cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.simplicity-cover-image.top {
    background-position: top center;
    background-size: cover;
}
