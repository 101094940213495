/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
.nav-container {
    display: flex;
    justify-content: space-between;
}
.modified-time-nav, .share-btn-nav {
    flex: 1;
    padding: 10px;
}
.share-link-primary, .share-link-primary svg, .share-link-primary .social-icon-label {
    text-decoration: none;
    border-radius: 3px;
    background: var(--color-dnv-interactive-blue);
    color: var(--color-white);
    fill: var(--color-white);
}
.share-link-primary:hover, .share-link-primary:hover svg, .share-link-primary:hover .social-icon-label {
    text-decoration: underline solid var(--color-dnv-hover-green);
    background: var(--color-dnv-hover-green);
    border-radius: 3px;
    color: var(--color-white);
    fill: var(--color-white);
}
.share-link, .share-link svg, .share-link .social-icon-label {
    text-decoration: underline solid var(--color-dnv-interactive-blue);
    color: var(--color-dnv-interactive-blue);
    fill: var(--color-dnv-interactive-blue);
}
.share-link:hover, .share-link:hover svg, .share-link:hover .social-icon-label {
    text-decoration: underline solid var(--color-dnv-hover-green);
    color: var(--color-dnv-hover-green);
    fill: var(--color-dnv-hover-green);
}

.share-popover {
    background-color: #FAFAF9;
    border: 1px solid #919191;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    padding: 10px;
    width: 187px;
    z-index: 5;
}

.popover-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.popover-title {
    font-size: 17px;
    padding: 12px 0 0 11px;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding-right: 8px;
    padding-bottom: 10px;
}

.popover-triangle {
    left: 50%;
    position: absolute;
    top: -20px;
    transform: translateX(-50%);
}

.popover-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.popover-content a {
    align-items: center;
    font-weight: 400;
    color: #000000;
    display: flex;
    gap: 16px;
    padding: 8px 0;
    padding-left: 12px;
    text-decoration: none;
}

.share-popover .popover-content a:hover,
.share-popover .popover-content a:focus {
    color: #0873A1;
    text-decoration: underline;
}

.popover-content span {
    white-space: nowrap;
}

.popover-content hr {
    border: none;
    border-top: 1px solid #919191;
    margin: 12px 0;
    margin-left: 12px;
    width: 86%;
}

.share-popover .social-icon {
    height: 27px;
    width: 27px;
}

.share-button {
    align-items: center;
    background-color: #FAFAF9;
    border: 2px solid #E5E5E5;
    border-radius: 3px;
    color: #004F59;
    cursor: pointer;
    display: flex;
    font-weight: 800;
    gap: 6px;
    line-height: 22px;
    margin-bottom: 16px;
    margin-top: -16px;
    padding: 4px 10px;
    width: 95px;
    height: 40px;
}

.share-button-icon {
    padding-left: 6px;
    padding-top: 1px;
    /*color: #0873A1;*/
    /*fill: #0873A1;*/
    color: #004F59;
    fill: #004F59;
    width: 14px;
}

.share-button-active {
    border: 2px dashed #004F59;
    border-radius: 3px;
}

.toast {
    background-color: #F4F4EC;
    border-radius: 4px;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.15);
    color: #2D2D2D;
    margin-top: -4px;
    padding: 10px;
    position: absolute;
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    transform: translateX(-23%);
}

.toast.fade-out {
    opacity: 0;
}
