/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.panel-group {
    margin: 32px 0;
    width: 100%;
    position: relative;
}

.panel-group .item-content {
    display: block;
    background: #fff;
    margin: 0;
    padding: 10px 32px 10px 32px;
}

.panel-group .tab-nav-wrapper .tab-nav {
    display: flex;
    gap: 6px;
    padding: 0;
    margin-bottom: -2px;
    flex-wrap: wrap;
}

.panel-group .tab-nav-wrapper .tab-nav li {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0;
    line-height: 1.3em;
    height: 48px;
    border-right: 2px solid #f0f0eb;
    background: #d4d4c5;
    vertical-align: middle;
    padding: 16px 20px;
    line-height: 1.2;
    margin-right: 8px;
}

@media (max-width: 960px) {
    .panel-group .tab-nav-wrapper .tab-nav {
        gap: 0;
    }
    .panel-group .tab-nav-wrapper .tab-nav li {
        height: 72px;
        width: 100%;
        justify-content: center;
        border-left: 2px solid var(--color-dnv-brand-grey);
        border-right: 2px solid var(--color-dnv-brand-grey);
    }
}

.panel-group .tab-nav-wrapper .tab-nav li:hover {
    background: #e5e5dc;
    color: var(--color-dnv-brand-green);
    text-decoration: underline;
}

.panel-group .tab-nav-wrapper .tab-nav li {
    font-weight: 400;
    line-height: 22px;
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium);
    background: var(--color-dnv-bg-and-border-pale-tan);
    color: var(--color-dnv-brand-blue);
    border-top: 2px solid var(--color-dnv-bg-and-border-pale-tan);
    /* border-bottom: 2px solid var(--color-dnv-brand-grey); */
    border-left: 2px solid var(--color-dnv-bg-and-border-pale-tan);
    border-right: 2px solid var(--color-dnv-bg-and-border-pale-tan);
}
.panel-group .tab-nav-wrapper .tab-nav li.active {
    color: var(--color-dnv-brand-black);
    background: #fff;
    border-top: 2px solid var(--color-dnv-brand-grey);
    border-bottom: 2px solid #fff;
    border-left: 2px solid var(--color-dnv-brand-grey);
    border-right: 2px solid var(--color-dnv-brand-grey);
    text-decoration: underline;
    padding-top: 13px;
}
.panel-group .tab-nav-wrapper .tab-nav li:focus {
    outline: 2px dashed var(--color-dnv-brand-green);
    outline-offset: -4px;
}

.panel-group.tabs  {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.panel-group.accordion .item {
    display: block;
    margin: 0 0 5px;
}

.panel-group.accordion .item .item-header {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-size: 1.6rem;
}

.panel-group.accordion .item .item-header div {
    display: block;
    padding: 16px 32px 16px 16px;
    background: #d4d4c5;
}

.panel-group.accordion .item .item-header div:after {
    position: absolute;
    top: 50%;
    right: 12px;
    margin: -12px 0 0 0;
    content: "+";
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1;
    text-align: center;
}

.panel-group.accordion .item .item-header div:hover {
    background: #e5e5dc;
    color: #000;
    text-decoration: none;
}

.panel-group.accordion .item.active .item-header div {
    background: #fff;
}

.panel-group.accordion .item.active .item-header div:after {
    margin: -14px 0 0 0;
    right: 13px;
    content: "–";
}

.panel-group .tab-nav-wrapper .tab-nav li {
    margin: 0 0.375rem 0 0;
}

/* .panel-group .tab-nav-wrapper .tab-nav li span{
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
} */

.panel-group .tab-nav-wrapper .tab-nav li:hover {
    cursor: pointer;
}

.panel-group .tab-nav-wrapper .tab-nav li div {
    border: 2px solid #D3D3C8;
    color: #575757;
    background-color: #D3D3C8;
}

.panel-group .tab-nav-wrapper .tab-nav li div.active {
    border-bottom-color: #fff;
    background-color: #fff;
}

.panel-group .tab-nav-wrapper .tab-nav li div.active:hover, .panel-group .tab-nav-wrapper .tab-nav li div.active:active {
    background-color: #fff;
}

.panel-group .tab-nav-wrapper .tab-nav li div:hover, .panel-group .tab-nav-wrapper .tab-nav li div {
    background-color: #D3D3C8;
    color: #000;
    text-decoration: underline;
}

.panel-group .tab-nav-wrapper .tab-nav li div:focus {
    outline: none;
    text-decoration: underline;
}

.panel-group.tabs .tab-items {
    display: block;
    border: 2px solid var(--color-dnv-brand-grey);
    min-width: 900px;
    width: 100%;
}

@media (max-width: 900px){
    .panel-group.tabs .tab-items{
        min-width: fit-content;
    }
}

@media (max-width: 768px) {
    .panel-group.tabs .tab-items {
        width: 100%;
        min-width: fit-content;
    }
}



.panel-group.accordion {
    border-top: 1px solid #D3D3C8;
}

.panel-group.accordion .item {
    border-bottom: 1px solid #D3D3C8;
}

.panel-group.accordion .item .item-header {
    display: block;
}

.panel-group.accordion .item .item-header div {
    background: transparent;
    width: 100%;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    padding: 0.5625rem 3rem 0.5625rem 1.6875rem;
    margin: 0;
    background-color: transparent;
    color: #0980B5;
    font-size: 1.25rem;
    position: relative;
    /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-heading);
    cursor: pointer;
}

.panel-group.accordion .item .item-header div:after {
    content: '\f067';
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    color: #575757;
    font-size: 1.25rem;
    position: absolute;
    right: 2rem;
    top: 50%;
    margin: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

.panel-group.accordion .item .item-header div:hover, .panel-group.accordion .item .item-header div:active {
    background: transparent;
    color: #44883E;
    text-decoration: underline;
}

.panel-group.accordion .item .item-header div:hover:after, .panel-group.accordion .item .item-header a:active:after {
    color: #44883E;
}

.panel-group.accordion .item .item-header div:focus {
    background: transparent;
    text-decoration: underline;
    outline: 2px dashed #44883E;
}

.panel-group.accordion .item.active .item-header div {
    color: var(--color-dnv-active-dark-yellow);
}

.panel-group.accordion .item.active .item-header div:after {
    content: '\f068';
    color: var(--color-dnv-active-dark-yellow);
    right: 2rem;
    margin: 0;
}

@media only screen and (min-width: 1125px) and (max-width: 1400px) {
    .panel-group.tabs .tab-items {
        min-width: 650px;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1270px) {
.panel-group .tab-nav-wrapper .tab-nav{
    flex-wrap: nowrap;
}
}

@media only screen and (max-width: 1060px) {
    .panel-group.accordion .item .item-header div {
        font-size: 1.0625rem;
    }

    .panel-group.accordion .item .item-header div:after {
        font-size: 1.25rem;
        line-height: 0;
    }

    .panel-group.accordion .item.active .item-header div:after {
        margin: 0;
    }
}

@media print {
    .panel-group .tab-nav-wrapper {
        display: none;
    }

    .panel-group .tab-items > .item, .panel-group .item-content {
        display: block !important;
    }

    .panel-group .item-content {
        padding: 0;
    }

    .panel-group .item-header {
        position: relative;
        left: auto;
        width: auto;
        height: auto;
        overflow: visible;
        font-size: 28px !important;
        /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
        font-family: var(--font-family-heading);
        display: block !important;
        margin: 2em 0 .4em !important;
    }

    .panel-group .item-header div {
        padding: 0 !important;
        font-size: inherit;
    }

    .panel-group .item-header div:after {
        display: none;
    }
}
