/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.news-notices h1{
    margin-bottom: 16px;
}

.news-notices .share-button{
    margin-top: -3px;
}

.news-notices .field.field-date.event-date{
    margin: 6px 0px 0px 0px;
    font-weight: 800;
    padding-bottom: 16px;
}

@media only screen and (max-width: 400px){
    .news-notices .field.field-date.event-date{
        padding-right: 10px;
        margin-top: -4px;
    }
}
