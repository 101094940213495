/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.table-of-contents {
    padding: 0 0 16px 0;
}

.table-of-contents > .rich-text-container > p {
    margin-bottom: 8px;
}

.table-of-contents li {
    line-height: 1.4em;
    font-size: 17px;
    display: list-item;
    padding: 0.15em 0;
}
.table-of-contents li a {
    text-decoration: underline;
    color: var(--color-dnv-interactive-blue)
}



.back-to-top-group {
    border-bottom: 1px solid var(--color-border-grey);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}
.no-bottom-border-line {
    border-bottom: unset;
}
.back-to-top-group p {
    /*border: 1px solid var(--color-border-grey);*/
    /*padding: 12px;*/
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
    width: 100%;
}
.anchor.right {
    justify-content: flex-end;
}
.anchor.left {
    justify-content: flex-start;
}
.anchor.center {
    justify-content: center;
}
