/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-accordion-container {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.simplicity-accordion-container .acc-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    height: 28px;
}

.simplicity-accordion-container .acc-btn-wrapper .acc-option {
    color: var(--color-dnv-brand-blue);
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    font-weight: 800;
    padding: 1px 6px;
    cursor: pointer;
    text-transform: none;
}
.simplicity-accordion-container .acc-btn-wrapper .acc-option:hover {
    color: var(--color-dnv-brand-green);
    text-decoration: underline;
}

.simplicity-accordion .accordion-container {
    border-bottom: 1px solid var(--color-border-grey);
    width: 100%;
    min-height: 45px;
    margin: 0;
    padding: 8px 0;
}
/* The first accordion contains the second child */
.simplicity-accordion-container .simplicity-accordion.first .accordion-container {
    border-top: 1px solid var(--color-border-grey);
}

.simplicity-accordion-container .simplicity-accordion-root{
    --step-width: 46px;
    position: relative;
}
.simplicity-accordion-container .simplicity-accordion-root .line {
    --line-width: 2px;
    --line-offset: calc(var(--line-width) / 2);
    height: 100%;
    border-right: var(--line-width) solid #919191;
    position: absolute;
    left: calc(var(--step-width) / 2 - var(--line-offset));
    top: 8px;
}

.simplicity-accordion-container .simplicity-accordion-root .line span {
    --line-dot-width: 4px;
    position: absolute;
    bottom: calc(var(--line-dot-width) * -1);
    border: var(--line-dot-width) solid var(--color-dnv-brand-grey);
    left: calc(var(--line-dot-width) * -1 + var(--line-offset));
    z-index: 3;
    border-radius: 50%;
}

/* ------ Step Container ------ */
.simplicity-accordion-container .step-container {
    margin-top: 8px;
}

.simplicity-accordion-container .step-container h6 {
    width: var(--step-width);
    height: calc(var(--step-width) - 2px);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 42px;
    border: 2px solid var(--color-dnv-brand-grey);
    border-radius: 50%;
    vertical-align: middle;
    background-color: white;
    z-index: 2;
    position: relative;
}

/* ------ Accordion Style ------ */
.simplicity-accordion-container .simplicity-accordion .accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px 10px 20px;
    /* height: 45px; */
    color: var(--color-dnv-interactive-blue);
}

.simplicity-accordion-container .simplicity-accordion.open .accordion-header{
    color: var(--color-dnv-brand-green);
}

.simplicity-accordion-container .simplicity-accordion .accordion-header p {
    color: var(--color-dnv-interactive-blue);
    font-size: 20px;
    line-height: 25px;
    font-weight: 800;
    margin: 0;
}

.simplicity-accordion-container .simplicity-accordion.open .accordion-header p{
    color: var(--color-dnv-brand-green);
}

.simplicity-accordion.open>.simplicity-accordion-container .accordion-header,
.simplicity-accordion-container .accordion-header:hover p {
    color: var(--color-dnv-brand-green);
}

.simplicity-accordion-container .simplicity-accordion {
    display: flex;
    width: 100%;
    gap: 24px;
    cursor: pointer;
}

.simplicity-accordion-container .simplicity-accordion.open .accordion-header {
    border-bottom: 0;
}

.simplicity-accordion-container .simplicity-accordion .accordion-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.simplicity-accordion-container .simplicity-accordion .accordion-section-content {
    transition: max-height 0.3s ease-in-out;
}
.simplicity-accordion-container .simplicity-accordion .accordion-section-content div {
    padding: 1em 1.6em;
}
.simplicity-accordion-container .simplicity-accordion.open .accordion-section-content {
    display: block;
}
.simplicity-accordion-container .simplicity-accordion.closed .accordion-section-content {
    display: none;
}
/*-------------------------------------------------------------*/

.simplicity-layout-accordion-container {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
.simplicity-layout-accordion-container .acc-option {
    width:auto;
    padding: 1px 8px;
    font-size: var(--font-size-medium);
    white-space: nowrap;
    font-family: var(--font-family-links-labels);
}
.simplicity-layout-accordion-container .acc-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 1em;
}
.simplicity-layout-accordion-container .acc-btn-wrapper button:hover {
    cursor: pointer;
}
.simplicity-layout-accordion-container .accordion-header h2  {
    margin:0;
    font-size: 20px;
}
.simplicity-layout-accordion-container .accordion-header h2 a {
    color: var(--color-dnv-interactive-blue);
}
.simplicity-layout-accordion-root .simplicity-layout-accordion .accordion-header:hover,
.simplicity-layout-accordion-container .accordion-header h2:hover {
    cursor: pointer;
}

.simplicity-layout-accordion-root .simplicity-layout-accordion .accordion-header{
    display: flex;
    justify-content: space-between;
    border-bottom:  1px solid var(--color-border-grey);
    padding: 12px;
    margin-bottom: 0px;
}
.simplicity-layout-accordion-root .simplicity-layout-accordion.open .accordion-header{
    border-bottom: none;
}
.simplicity-layout-accordion-root .simplicity-layout-accordion:nth-child(1) .accordion-header {
    border-top: 1px solid var(--color-border-grey);

}

.simplicity-layout-accordion-root .simplicity-layout-accordion .accordion-header .h2{
    line-height: 25px;
    font-size: 20px;
    color: var(--color-dnv-brand-blue);
}

.simplicity-layout-accordion-root .simplicity-layout-accordion .accordion-header svg{
    color: var(--color-dnv-brand-blue);
}

.simplicity-layout-accordion .accordion-content {
    display: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.simplicity-layout-accordion.open .accordion-header,
.simplicity-layout-accordion.open .accordion-header a {
    color: var(--color-dnv-focus-green);
}
.simplicity-layout-accordion .accordion-section-content {
    border-bottom: 1px solid var(--color-border-grey);
    transition: max-height 0.3s ease-in-out;
    padding: 1em 1.6em;
}
.simplicity-layout-accordion .accordion-section-content div {
    /*padding: 1em 1.6em;*/
}
.simplicity-layout-accordion.open .accordion-section-content {
    display: block;
}
.simplicity-layout-accordion.open .accordion-section-content{
    padding-top: 0px;
}
.simplicity-layout-accordion.closed .accordion-section-content {
    display: none;
}
