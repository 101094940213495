/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.btn:not(.icon), .form-submit {
    font-family: var(--font-family-links-labels);
    font-size: 16px;
    border: none;
    background: var(--color-dnv-interactive-blue);
    margin: 0 0 .5em 0;
    padding: 16px 34px;
    color: var(--color-dnv-text-white);
    cursor: pointer;
    -webkit-transition: background .25s;
    -o-transition: background .25s;
    transition: background .25s;
}

.btn:hover, .form-submit:hover {
    background: var(--color-dnv-hover-green);
    color: #fff;
    text-decoration: none;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.51);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.51);
}

button.text {
    background: transparent;
    color: var(--color-link-blue);
    font-size: var(--font-size-medium);
    -webkit-transition: color .25s;
    -o-transition: color .25s;
    transition: color .25s;
    border: none;
    line-height: normal;
}



.btn,
.form-submit,
.wysiwyg .btn {
    background-color: var(--color-dnv-interactive-blue);
    color: var(--color-dnv-interactive-white);
    padding: 1rem 2.125rem;
    font-size: 1.0625rem;
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    font-family: var(--font-family-links-labels);
    -webkit-transition: background-color 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
}

.btn:hover,
.form-submit:hover,
.wysiwyg .btn:hover {
    background-color: var(--color-dnv-hover-green);
    color: var(--color-dnv-text-white);
}

.btn:active,
.form-submit:active,
.wysiwyg .btn:active {
    background-color: var(--color-dnv-active-black);
    color: var(--color-dnv-text-white);
}

/*.btn:focus,*/
/*.form-submit:focus,*/
/*.wysiwyg .btn:focus {*/
/*    background-color: var(--color-dnv-interactive-blue);*/
/*    border: 2px dashed #3D7737;*/
/*}*/
