/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/*.simplicity-field-image {}*/

/*.simplicity-field-image-card {*/
/*    background-color: #fff;*/
/*    border-radius: 0.5rem;*/
/*    box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);*/
/*    padding-bottom: 1rem;*/
/*}*/

/*.simplicity-field-image-card > :last-child {*/
/*    margin-bottom: 0;*/
/*}*/

.simplicity-field-image-card > img {
    /*border-radius: 0.5rem 0.5rem 0 0;*/
    width: 100%;
}

/*.simplicity-field-image-card > img ~ * {*/
/*    margin-left: 1rem;*/
/*    margin-right: 1rem;*/
/*}*/
