/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.form-question-group {
    min-height: 36px;
}

.form-question-group h3 {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium-large);
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    margin: 0 0 36px 0;
}

.simplicity-forms .question-group-container {
    border-left: 1px solid #8c8c8c;
    margin-left: 16px;
}

.simplicity-forms .question-group-container .simplicity-forms-field-group {
    margin-bottom: 0;
    padding: 0 0 16px 36px;
}

.simplicity-forms .question-group-container table {
    margin-bottom: 0;
}
