/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.page-feedback__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.page-feedback__block {
    border: 2px solid var(--color-border-grey);
    padding: 1.5rem 1.875rem;
    margin-top: 3.0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.page-feedback__block hr {
    width: 100%;
    border-color: var(--color-border-grey);
}

.page-feedback__options {
    display: flex;
    gap: 2em;
    font-size: var(--font-size-medium);
}
.feedback-titlebar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
}
.feedback-titlebar p {
    margin: 0;
}

.feedback-response {
    width: 100%;
    min-height: 200px;
}
.feedback-response textarea {
    width: 100%;
    min-height: 160px;
}
.feedback-instruction-title {
    font-family: var(--font-family-links-labels);
    margin-bottom: 5px;
    margin-left: 2px;
    font-weight: normal;
    font-size: 1.6rem;
}
.feedback-instruction {
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: var(--font-family-links-labels);
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    margin-left: 2px;
    font-weight: normal;
    font-size: 16px;
}
.feedback-textarea textarea.feedback-form-textarea {
    font-size: 14px;
    width: 100%;
}
.feedback-textarea {
    margin-top: 12px;
    margin-bottom: 12px;
}
.feedback-actions {
    width: 100%;
    display: block;
}
.feedback-close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px dashed transparent;
    padding: 0;
    margin: 0;
    background-color: transparent;
    font-size: 1.625rem;
    color: var(--color-dnv-bg-and-border-dark-navy);
    font-weight: 400;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.feedback-close:hover {
    background-color: var(--color-dnv-bg-and-border-dark-navy);
    color: var(--color-dnv-text-white);
}

.feedback-actions .btn {
    background-color: var(--color-dnv-brand-green);
    display: flex;
    align-items: center;
    gap: 8px;
    height: 48px;
}

.page-feedback__options .selected {
    background-color: var(--color-black);
    color: var(--color-dnv-text-white);
}
