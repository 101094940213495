/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.field {
    margin: 16px 0 16px 0;
}
.field.permit-cta_sidebar{
    margin: 16px 0px;
}

.field.additional_info{
    margin-top: 64px;
}

.field.additional_info p {
    margin-bottom: 24px;
}

.field.additional_info h2{
    margin-bottom: 16px;
}

.field.additional_info h2:not(:first-child){
    margin-top: 32px;
}

.field.additional_info h3{
    margin-top: 16px;
    margin-bottom: 12px;
}

.component {
    width: 100%;
    display: flex;
    flex-direction: column;
    /*border: 1px solid red;*/
}

.component.accordion,
.component.embed,
.component.feature-webpage,
.component.feedback,
.component.form,
.component.heading,
.component.icon-links-list,
.component.info-box,
.component.image-gallery,
.component.image-list,
.component.link-button,
.component.quote,
.component.rich-text-with-image,
.component.tab-panel,
.component.navigation-list,
.component.table-of-contents,
.component.youtube,
.component.feed-model {
    margin-bottom: 24px;
}
.component.document-list,
.component.links-list {
    margin-bottom: 16px;
}
.component.image,
.component.call-to-action {
    margin-top: 24px;
    margin-bottom: 24px;
}

.homepage-wrapper .homepage-highlights .component.call-to-action{
    margin: 0px;
}

.component.rich-text,
.component.rich-text-editor {
    /* none: adds its own bottom margin */
}

.field.field-component.story{
    padding: 0px 160px;
}


@media only screen and (max-width: 1060px) {
    .field.field-component.story{
        padding: 0px;
    }
}
