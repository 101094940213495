/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.side-nav h2.story-category-title {
    font-family: var(--font-family-heading);
    font-size: 25px;
    font-weight: 750;
}
.story-category {
    padding: 6px 0px 16px 10px;
}

.story-categories .form-item{
    margin-left: 15px;
}
/*  DNV UI Styles prevents these */
/*.story-category-list-link {*/
/*    color: var(--color-dnv-brand-blue);*/
/*    !*color: #8e8e8e;*!*/
/*}*/

/*.story-category-list-link:hover {*/
/*    color: var(--color-dnv-brand-green);*/
/*    text-decoration: underline;*/
/*}*/

/*.story-category-list-link:active {*/
/*    color: var(--color-dnv-brand-black);*/
/*    text-decoration: underline;*/
/*}*/
.active-category {
    text-decoration: underline;
}
/*#edit-field-blog-category-tid-wrapper > div.story-categories > div > div > div > div.story-category.active_category.active*/
.story-category a {
    color: var(--color-dnv-interactive-blue);
    font-family: var(--font-family-links-labels);
}
.story-category a{
    display: flex;
    justify-content: space-between;
    text-decoration: underline;
}
.story-category a svg{
    width: 14px;
    height: 16px;
    margin-right: 22px;
    margin-top: 3px;
}
.story-category.active_category.active a {
    color: var(--color-dnv-interactive-blue);
    font-family: var(--font-family-links-labels);
    text-decoration: underline;
}
.story-category-list {
    margin: 30px 0 12px 0;
    padding: 0px 160px;
}
.story-feed-title {
    margin-top: 34px;
    margin-bottom: 12px;
    color: var(--color-black);
    font-family: var(--font-family-links-labels);
    padding: 0px 160px;
}
.simplicity-story-hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(66, 66, 66, 0), rgba(66, 66, 66, 0.75), rgba(66, 66, 66, 0));
    margin-bottom: 24px;
    margin-top: 24px;
}
.story-feed-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    flex-direction: column;
    padding: 0px 160px;
}
.story-feed-content {
    border: 1px solid #DFDFDF;
    /* flex: 0 0 32%; */
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-width: 230px;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.story-feed-content-text {
    width: 100%;
    min-height: 190px;
    padding: 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

}
.story-feed-content-description {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
}
.story-feed-content .story-feed-content-image {
    height: 200px;
    width: 100%;
    max-width:100%;
    display:flex;
    overflow:hidden;
    position:relative;
    object-fit: cover;
    background-color: #ababab;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /*border: 1px solid red !important;*/
}
.story-feed-content .model-image img {
    position: absolute;
    top:-100%;
    left: 0;
    right: 0;
    bottom: -100%;
    margin: auto;
    margin-left: 50%;
    transform: translateX(-50%);
    /* height: 100%; */
    width: 100%;
}
.story-feed-content h4 {
    font-weight: 700;
    line-height: 1.5;
    color: var(--color-dnv-interactive-blue);
    margin: 12px 0;
}


@media only screen and (max-width: 1125px) {
    .story-feed-content{
        display: flex;
    }
    .story-feed-content .story-feed-content-image {
        height: 376px;
    }
    .story-feed-content .story-feed-content-image .model-image{
        height: 100% !important;
    }
    .story-feed-content-text{
        height: 100% !important;
    }
}


@media only screen and (max-width: 1060px) {
    .story-feed-container,
    .story-feed-title {
        padding: 0;
    }

    .story-category-list{
        padding: 0;
    }
}

@media screen and (max-width: 481px) {
    .story-feed-content{
        display: block;
    }
    .story-feed-content .story-feed-content-image {
        height: 200px;
    }
}

@media screen and (max-width: 340px) {
    .story-feed-content{
        min-width: 100%;
        width: 100%;
    }
}


/** {*/
/*    box-sizing: border-box*/
/*}*/

/*html.mobile.touch .scroll-btn {*/
/*    display: none*/
/*}*/

/*html.mobile.no-touch .scroll-btn {*/
/*    opacity: 1!important*/
/*}*/

/*body {*/
/*    background: 0 0;*/
/*    color: #2a2e2e;*/
/*    font-family: "Helvetica Neue",arial,sans-serif;*/
/*    font-size: 15px;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale*/
/*}*/

/*body.dark {*/
/*    color: #fff*/
/*}*/

/*a {*/
/*    text-decoration: none;*/
/*    color: #288ce4;*/
/*    transition: color linear .1s*/
/*}*/

/*a:hover {*/
/*    color: #1977ca*/
/*}*/

/*a:active,a:hover {*/
/*    text-decoration: none*/
/*}*/

/*button::-moz-focus-inner {*/
/*    border: 0*/
/*}*/

/*p {*/
/*    margin: 0 0 1.5em*/
/*}*/

/*h2,h3,strong,strong a {*/
/*    font-weight: 700*/
/*}*/

/*.sans-serif,.sans-serif input,.sans-serif select,.sans-serif textarea {*/
/*    font-family: "Helvetica Neue",arial,sans-serif*/
/*}*/

/*.serif,.serif input,.serif select,.serif textarea {*/
/*    font-family: Georgia,Times,serif*/
/*}*/

/*[dir=rtl],[dir=rtl] * {*/
/*    font-family: Tahoma,Geneva,"Helvetica Neue",Helvetica,Arial,Verdana,sans-serif*/
/*}*/



/*
.recommendations-layout .post-list {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.recommendations-layout .alert--error {
    line-height: 1.4;
    border-radius: 4px;
    margin: 16px;
    padding: 16px
}

.recommendations-wrapper {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100vw
}

.recommendations-wrapper .recommend-main {
    display: inline-block;
    position: relative;
    color: #2a2e2e;
    font-size: 14px;
    clear: both;
    width: 100%
}

.recommendations-wrapper .recommend-main:hover .scroll-btn {
    opacity: 1
}

.recommendations-wrapper .recommend-main.no-meta .recommend-content,.recommendations-wrapper .recommend-main.no-meta .recommend-top-comment-wrapper {
    max-height: calc(5.2em);
    padding-top: 2px
}

.recommendations-wrapper .recommend-main.no-meta.has-preview-title .recommend-content,.recommendations-wrapper .recommend-main.no-meta.has-preview-title .recommend-content .recommend-post-title {
    max-height: calc(5em)
}

.recommendations-wrapper .recommend-main .scroll-btn {
    position: absolute;
    background: rgba(255,255,255,.7);
    color: rgba(0,0,0,.55);
    font-size: 36px;
    line-height: .9;
    height: 50px;
    top: calc(50% - 25px);
    border: 1px solid #e7e9ee;
    border-radius: 3px;
    padding: 8px;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    outline: 0;
    z-index: 99999
}

.recommendations-wrapper .recommend-main .scroll-btn:hover {
    background: rgba(255,255,255,.8);
    color: rgba(0,0,0,.65);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    opacity: 1
}

.recommendations-wrapper .recommend-main .scroll-btn.scroll-left {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 4px
}

.recommendations-wrapper .recommend-main .scroll-btn.scroll-right {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 4px
}

.recommend-col-header {
    margin-bottom: 12px
}

.recommend-col-header .recommendations-unit-title {
    font-size: 12.5px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(101,108,122,.65)
}

.recommend-col-header .recommendations-unit-title .recommendations-forum {
    color: #3d424a
}

@media screen and (max-width: 480px) {
    .recommend-col-header {
        margin-bottom:.4em
    }
}

.recommend-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;
    margin: 4px auto 10px;
    padding-top: 2px;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.recommend-wrapper::-webkit-scrollbar {
    display: none
}

.recommend-post {
    position: relative;
    display: inline-block;
    background: #fff;
    width: 200px;
    height: 200px;
    border: 1px solid #e7e9ee;
    border-radius: 6px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    vertical-align: middle;
    transform: translate3d(0,0,0);
    transition: all .2s ease-in-out;
    margin: 0 4px;
    cursor: pointer;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000
}

.recommend-post:first-child {
    margin-left: 0
}

.recommend-post:last-child {
    margin-right: 0
}

.recommend-post:focus,.recommend-post:hover {
    transform: translate3d(0,-2px,0)
}

.recommend-post:focus {
    outline-offset: -3px
}

.recommend-post:focus:not(:focus-visible) {
    border: 1px solid #e7e9ee
}

.recommend-post:focus-visible {
    border: 2px solid #2e9fff;
    border-radius: calc(4px)
}

.recommend-post:focus-visible .overlay,.recommend-post:focus-visible .recommend-image-wrapper {
    border-radius: calc(4px)
}

.recommend-post .recommend-post-header {
    position: relative;
    width: 100%;
    height: 50%;
    overflow: hidden;
    transition: all .2s linear
}

.recommend-post .recommend-post-header .recommend-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    -webkit-mask-image: -webkit-radial-gradient(white,black)
}

.recommend-post .recommend-post-header .recommend-image-wrapper img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: calc(100px);
    object-fit: cover;
    transform: translate(-50%,-50%);
    transition: all .2s linear
}

.recommend-post .recommend-post-header .recommend-image-wrapper .overlay {
    position: absolute;
    background: linear-gradient(to top,rgba(0,0,0,.35) 10%,rgba(0,0,0,.1) 60%,transparent 90%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.recommend-post .recommend-top-comment-wrapper {
    display: block
}

.recommend-post .recommend-post-title {
    position: absolute;
    color: rgba(255,255,255,.95);
    font-size: 16px;
    text-shadow: 0 .5px 2px rgba(0,0,0,.35);
    width: 90%;
    left: 5%;
    bottom: 0;
    white-space: normal;
    margin: 8px auto;
    display: block;
    line-height: 1.25;
    max-height: calc(2.5em);
    overflow: hidden;
    transition: all 150ms ease-in-out,font-size 0s ease-in-out
}

.recommend-post a.title {
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 1.25
}

.recommend-post .meta {
    color: #717888;
    font-size: 12.5px;
    width: 90%;
    margin: 8px 5% 0;
    padding: 0;
    padding-inline-start:0;text-overflow: ellipsis;
    overflow: hidden;
    transition: all 150ms linear 50ms
}

.recommend-post .meta li {
    display: inline;
    font-weight: 500;
    line-height: 1.2
}

.recommend-post .meta li:not(:first-child)::before {
    content: '\2022';
    position: relative;
    font-size: 10px;
    top: -1px;
    padding-right: 4px;
    opacity: .9
}

.recommend-post .content-preview-wrapper,.recommend-post .top-comment {
    color: #2a2e2e;
    display: inline-block;
    width: 90%;
    margin: 6px 5%;
    font-size: 14px
}

.recommend-post .content-preview-wrapper .top-comment-header,.recommend-post .top-comment .top-comment-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 30px;
    margin-bottom: 8px
}

.recommend-post .content-preview-wrapper .top-comment-header .top-comment-avatar-wrapper,.recommend-post .top-comment .top-comment-header .top-comment-avatar-wrapper {
    position: relative;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    overflow: hidden
}

.recommend-post .content-preview-wrapper .top-comment-header .top-comment-avatar-wrapper img,.recommend-post .top-comment .top-comment-header .top-comment-avatar-wrapper img {
    position: absolute;
    width: 30px;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: translate(-50%,-50%)
}

.recommend-post .content-preview-wrapper .top-comment-header .user,.recommend-post .top-comment .top-comment-header .user {
    font-size: 12.5px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: all .2s ease-in-out
}

.recommend-post .content-preview-wrapper .recommend-content,.recommend-post .content-preview-wrapper .recommend-top-comment-wrapper,.recommend-post .top-comment .recommend-content,.recommend-post .top-comment .recommend-top-comment-wrapper {
    line-height: 1.3;
    max-height: calc(3.9em);
    padding-top: 0;
    margin-top: 2px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.recommend-post .content-preview-wrapper .recommend-content .recommend-post-title,.recommend-post .content-preview-wrapper .recommend-top-comment-wrapper .recommend-post-title,.recommend-post .top-comment .recommend-content .recommend-post-title,.recommend-post .top-comment .recommend-top-comment-wrapper .recommend-post-title {
    position: relative;
    color: rgba(0,0,0,.65);
    color: #2a2e2e;
    font-weight: 500;
    text-shadow: none;
    width: 100%;
    left: 0;
    margin-top: 0
}

.recommend-post .content-preview-wrapper p,.recommend-post .top-comment p {
    display: block;
    color: #494e58;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 0;
    white-space: pre-wrap
}

.recommendations-unit {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center
}

.recommendations-unit .recommendation-container {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    width: 100%;
    min-width: 0
}

.recommend-main.no-preview .recommend-post {
    height: 200px
}

.recommend-main.no-preview .recommend-post .recommend-post-header {
    height: 100%
}

.recommend-main.no-preview .recommend-post .recommend-post-header .recommend-image-wrapper {
    height: 100%;
    border-radius: calc(5px)
}

.recommend-main.no-preview .recommend-post .recommend-post-header .recommend-image-wrapper img {
    width: auto;
    min-width: 100%;
    min-height: 200px
}

.recommend-main.no-preview .recommend-post .recommend-post-header .recommend-image-wrapper .overlay {
    background: linear-gradient(to top,rgba(0,0,0,.5) 20%,transparent);
    border-radius: calc(5px)
}

.recommend-main.no-preview .recommend-post .recommend-post-header .recommend-post-title {
    font-size: 22px;
    max-height: calc(3.75em);
    margin-bottom: 16px
}

.recommend-main.no-preview .recommend-post .meta {
    position: absolute;
    font-size: 13px;
    color: rgba(255,255,255,.95);
    bottom: 45%
}

.recommend-main.no-preview .recommend-post .content-preview-wrapper {
    display: none
}

@media (max-width: 480px) {
    .recommend-main .recommend-post {
        height:200px
    }

    .recommend-main .recommend-post .recommend-post-header {
        height: 100%
    }

    .recommend-main .recommend-post .recommend-post-header .recommend-image-wrapper {
        height: 100%;
        border-radius: calc(5px)
    }

    .recommend-main .recommend-post .recommend-post-header .recommend-image-wrapper img {
        width: auto;
        min-width: 100%;
        min-height: 200px
    }

    .recommend-main .recommend-post .recommend-post-header .recommend-image-wrapper .overlay {
        background: linear-gradient(to top,rgba(0,0,0,.5) 20%,transparent);
        border-radius: calc(5px)
    }

    .recommend-main .recommend-post .recommend-post-header .recommend-post-title {
        font-size: 22px;
        max-height: calc(3.75em);
        margin-bottom: 16px
    }

    .recommend-main .recommend-post .meta {
        position: absolute;
        font-size: 13px;
        color: rgba(255,255,255,.95);
        bottom: 45%
    }

    .recommend-main .recommend-post .content-preview-wrapper {
        display: none
    }
}



.dark .recommend-col-header .recommendations-unit-title {
    color: rgba(255,255,255,.75)
}

.dark .recommend-col-header .recommendations-unit-title .recommendations-forum {
    color: #fff
}

.dark .recommendations-wrapper .recommend-main .scroll-btn {
    background: rgba(31,31,31,.85);
    color: rgba(255,255,255,.75);
    border-color: rgba(31,31,31,.85)
}

.dark .recommendations-wrapper .recommend-main .scroll-btn:hover {
    background: rgba(31,31,31,.95);
    color: rgba(255,255,255,.9);
    border-color: rgba(31,31,31,.9)
}

.dark .recommend-post {
    background: rgba(255,255,255,.08);
    border: 1px solid rgba(255,255,255,.05)
}

.dark .recommend-post:focus:not(:focus-visible) {
    border: 1px solid rgba(255,255,255,.05)
}

.dark .recommend-post:focus-visible {
    border: 2px solid #2e9fff;
    border-radius: calc(4px)
}

.dark .recommend-post .meta {
    color: rgba(255,255,255,.5)
}

@media (max-width: 480px) {
    .dark .recommend-post .meta {
        color:rgba(255,255,255,.75)
    }
}

.dark .recommend-post .content-preview-wrapper .recommend-content,.dark .recommend-post .content-preview-wrapper .recommend-content .recommend-post-title,.dark .recommend-post .content-preview-wrapper .top-comment {
    color: rgba(255,255,255,.9)
}

.dark .recommend-post spoiler {
    background: rgba(255,255,255,.2)
}


.media-container img {
    border-radius: 3px;
    display: block;
    max-width: 100%;
    max-height: 480px;
    z-index: -1
}

.media-button {
    text-align: left;
    font-size: 10px;
    padding: 3px 5px 4px 0;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px
}

.media-content-placeholder {
    display: none;
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    max-width: 100%
}

.media-content-placeholder.media-Twitter {
    width: auto
}

.media-content-placeholder.media-video {
    width: 100%
}

.media-button-contract,.media-button-expand {
    display: none
}

.media-container {
    display: none
}

.media-container.media-activated,.media-container.media-mode-deferred,.media-container.media-show-buttons {
    display: block
}

.media-show-buttons .media-button-expand {
    display: inline-block;
    cursor: zoom-in
}

.media-show-buttons .media-button-contract {
    display: none;
    margin-bottom: 15px;
    cursor: zoom-out
}

.media-show-buttons .media-button-contract i,.media-show-buttons .media-button-expand i {
    font-size: 8px;
    color: #fff;
    padding: 4px 5px 4px 4px;
    margin-right: 2px
}

.media-activated .media-content-placeholder {
    display: block;
    clear: both
}

.media-show-buttons.media-activated .media-button-contract {
    display: inline-block
}

.media-show-buttons.media-activated .media-button-expand {
    display: none
}

.media-mode-deferred .media-content-placeholder {
    display: block
}

.media-mode-deferred.media-show-buttons .media-button-contract,.media-mode-deferred.media-show-buttons .media-button-expand {
    display: none
}

.media-content-loader {
    display: none;
    height: 300px;
    text-align: center;
    background: url(https://c.disquscdn.com/next/recommendations/assets/img/loader.ba7c86e8b4b6135bb668d05223f8f127.gif) no-repeat center center
}

.media-loading.media-activated .media-content-placeholder {
    display: none
}

.media-loading.media-activated .media-content-loader {
    display: block
}

.media-loading.media-activated .media-Imgur {
    position: absolute
}

.media-content-placeholder {
    min-height: 100px
}

.media-content-placeholder .media-force-load {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    border-radius: 3px;
    border: 2px solid rgba(0,39,59,.2);
    color: rgba(0,39,59,.2)!important
}

.media-content-placeholder .media-force-load i {
    width: 100%;
    position: absolute;
    top: 50%;
    font-size: 32px;
    margin-top: -16px;
    text-align: center
}

.media-Imgur {
    display: block!important;
    position: relative
}

.media-webpage {
    height: 36px;
    border-left: 5px solid;
    border-color: #7f919e;
    padding: 0 0 0 15px;
    width: 100%;
    display: inline-block
}

.media-webpage img {
    float: left;
    max-height: 36px;
    max-width: 36px
}

.media-webpage .media-webpage-title {
    padding-top: 2px
}

.media-webpage .media-webpage-domain {
    padding-top: 3px;
    font-size: 80%;
    color: #656c7a
}

.post-media-link i {
    padding-right: 4px
}

.post-media-link .post-media-link-domain {
    font-size: 11px;
    color: #656c7a!important
}

spoiler {
    display: inline;
    background: #7f919e;
    color: transparent;
    padding: 0 .5em
}

spoiler a {
    visibility: hidden;
    transition: none
}

spoiler .media-content-placeholder {
    background: #7f919e
}

spoiler:focus,spoiler:hover {
    background: #e7e9ee;
    color: inherit
}

spoiler:focus a,spoiler:hover a {
    visibility: visible
}

spoiler:focus .media-content-placeholder,spoiler:hover .media-content-placeholder {
    background: 0 0
}

*/
