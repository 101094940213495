/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.report-header-image {
    min-height: 340px;
    background-color: #8a8a8a;
}
.report-header-image .simplicity-cover-image-thumb {
    margin-top: 0;
    margin-bottom: 0;
}
.report .main-content {
    margin-top: 95px;
}

.report .report_header {
    font-size: 18px;
}
.report .report_header .l-container{
    max-width: 1440px;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
}
.report .report_header .fixed-header-wrapper #header {
    height: 95px;
    padding: 10px 0;
}
.report .report_header .fixed-header-wrapper {
    background: #162128;
    position: relative;
    z-index: 900;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@media (max-width: 1060px) {
    .report_header .fixed-header-wrapper, #header {
        height: 70px;
    }
}
@media print {
    .report_header .fixed-header-wrapper, #header {
        height: auto !important;
    }
}
.report_header #header .logo {
    -webkit-transition: height .25s;
    -o-transition: height .25s;
    transition: height .25s;
    position: absolute;
    width: 183px;
    height: 95px;
    margin: -10px 0 0;
}

@media print {
    .report .report_header #header .logo {
        display: none;
    }
}

@media (max-width: 1060px) {
    .report .report_header #header .logo {
        width: 153px;
        height: 65px;
        margin: -10px 0 0;
    }
}

.report .report_header #header .header-content {
    float: right;
    position: relative;
    width: 922px;
    border-left: 1px solid #fff;
    color: #fff;
    font-weight: 900;
    width: calc(100% - 210px);
    margin-left: 210px;
    padding: 15px 20px;
}

.report .report_header #header .header-content span{
    color: #fff;
    font-weight: 900;
    font-size: 18px;
}

.report .report_header #header .header-content .block {
    z-index: 900;
}

.report .report_header #header .header-content h2, #header .header-content .header-toggle {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 17px 15px;
    color: #000;
    font-size: 15px;
    font-size: 1.5rem;
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-links-labels);

    cursor: pointer;
    -webkit-transition: background .25s;
    -o-transition: background .25s;
    transition: background .25s;
}

.report .report_header #header .header-content h2:after, #header .header-content .header-toggle:after {
    float: right;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: .75;
    color: #162129;
    -webkit-transition: color .25s;
    -o-transition: color .25s;
    transition: color .25s;
}

.report .report_header #header .header-content h2:hover, #header .header-content .header-toggle:hover {
    color: #162129;
    text-decoration: none;
}

.report .report_header #header .header-content h2:hover:after, #header .header-content .header-toggle:hover:after {
    color: #124d61;
}

.report .report_header #header .header-content .header-toggle {
    display: none;
    position: relative;
    float: right;
    width: 108px;
    height: 47px;
    margin-right: 0;
    background: #fff;
    outline: none;
}

.report .report_header #header .header-content .header-toggle.menu-toggle {
    background: #fff;
}

.report .report_header #header .header-content .header-toggle.menu-toggle:after {
    content: "";
}

.report .report_header #header .header-content .header-toggle.search-toggle {
    background: transparent;
    display: block;
    float: left;
    height: auto;
    margin-top: 15px;
    padding: 0;
    width: auto;
}

.report #block-views-online-report-back-link-block-1{
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    font-style: italic;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: -3px;
}

#block-views-online-report-back-link-block-1 a{
    color: #EAAF6F;
    text-decoration: none;
    font-weight: 900;
    font-size: 1rem;
}

.report {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
}

.report .layout {
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    zoom: 1;
    padding-left: 52px;
    padding-right: 52px;
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    background: #fff;
}
@media (max-width: 1150px) {
    .report .layout {
        padding-right:32px;
        padding-left: 32px
    }
}
.report .simplicity-cover-image .cover{
    background: #fff;
    max-height: 240px;
}
.report .region-online-report-menu{
    position: relative;
    z-index: 10;
    text-transform: none!important;
    background: #fff;
    height: 0;
}

.report .simplicity-image-caption{
    display: none;
}
.report .online-report__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    /*-webkit-transform: translateY(-100%);*/
    /*-ms-transform: translateY(-100%);*/
    /*transform: translateY(-100%);*/
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 400;
    text-transform: uppercase;
    text-align: right;
    font-size: 1rem;
}
.report .online-report__menu .button {
    background-color: #fff;
    color: #C65540;
    padding: 2rem 0;
    width: 95px;
    position: relative;
    border-right: 1px solid #CBCBCB;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.333em;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
}
.report .online-report__menu a {
    color: #000;
}
.report .online-report__menu .block-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.334rem 1.667rem;
    position: relative;
    font-weight: 700;
    clear: both;
    margin: 0;
    text-align: center;
    color: #C65540;
    font-size: 1rem;
    line-height: 1.333em;
    background-color: #fff;
    border-radius: 0;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
}
@media (min-width: 1151px){
    .report .online-report__menu .block-title {
        padding: 2rem 2.5rem;
        font-size: 1.5rem;
    }
}
.report .online-report__menu .block-title svg {
    font-size: 1.333em;
    vertical-align: bottom;
    margin-left: 16px;
    width: 1em;
    margin-left: 8px;
    text-align: center;
}
.report .online-report__menu .block-title:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.online-report__menu .menu-block-wrapper {
    display: none;
    position: absolute;
    z-index: 50;
    top: 100%;
    right: 4px;
    width: 100%;
    clear: both;
    text-align: left;
    padding: 32px 48px;
    background-color: #fff;
}
@media (min-width: 1151px){
    .online-report__menu .menu-block-wrapper {
        right: 0;
    }
}
#region-online-report-menu .MuiPaper-root {
    position: absolute;
    right: 0;
    display: block;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.38);
    z-index: 50;
    width: 100%;
    clear: both;
    text-align: left;
    margin-top: 0;
    padding: 32px 48px;
    border-radius: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
}
@media (min-width: 481px){
    #region-online-report-menu .MuiPaper-root {
        width: 375px;
    }
}

#region-online-report-menu .MuiList-root li {
    padding: 18px 0;
    text-transform: uppercase!important;
}

#region-online-report-menu .MuiList-root li:hover {
    background: none;color: #C65540;
    text-decoration: underline;
}
#region-online-report-menu .active{
    color: #C65540;
    text-decoration: none;
}
@media (max-width: 1151px){
    #region-online-report-menu .MuiList-root .MuiMenu-paper-item__overview {
        font-size: 1rem!important;
    }
}
#region-online-report-menu .MuiList-root .MuiMenu-paper-item__overview {
    display: block;
    font-size: 1.125rem!important;
    text-transform: uppercase!important;
}
#region-online-report-menu .MuiMenu-paper li:not(:last-of-type) {
    border-bottom: 1px solid #F0E9E9;
}
#region-online-report-menu .online-report__pdf-link {
    display: block;
    margin-top: 26px;
    font-weight: 700;
    text-transform: none;
}
#region-online-report-menu .online-report__pdf-link a{
    color: #000;
    text-decoration: none;

}
#region-online-report-menu .online-report__pdf-link a:hover {
    color: #C65540;
}

.report h1.page-title{
    font-size: 2.5rem!important;
    position: relative;
    padding-bottom: 16px;
    font-weight: 700;
    text-transform: uppercase!important;
    z-index: 0;
}
.report .field-name-field-online-report-logo {
    max-width: 25%;
}

@media (min-width: 769px){
    .report .field-name-field-online-report-logo {
        display: inherit;
        float: right;
        padding-left: 4.63%;
        margin-bottom: 32px;
    }
    .report .field-name-field-online-report-logo img {
        max-width: 100%;
        height: inherit;
    }
}

@media (min-width: 1151px){
    .report .field-name-field-online-report-logo {
        max-width: 33.33%;
    }
}
@media (max-width: 1151px) {
    .report h1.page-title {
        font-size:1.5rem!important
    }
}
.report .page-title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 120px;
    height: 5px;
    background-color: #EAAF6F;
}
.report .main-content {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    background-color: #fff;
    padding-bottom: 64px;
}
@media only screen and (max-width: 1060px){
    .report .main-content {
        width: 100%;
        max-width: 100%;
        padding: 1.25rem;
        float: none;
    }
}
.report .main-content p {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
}
@media (min-width: 1151px){
    .report .main-content p {
        font-size: 1.5rem;
    }
}
@media (max-width: 768px){
    .report .main-content p {
        font-size: 1rem;
    }
}
.report .main-content .divider-arrow {
    /*margin: 16px 0 72px;*/
    clear: both;
    margin: 16px 0 24px;
    color: #C65540;
    font-size: 32px;
    /*font-size: 3.2rem;*/
    text-align: center;
}
.report .field-name-field-subhead {
    text-transform: uppercase!important;
    font-size: 2rem;
    font-weight: 900!important;
    position: relative;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25em;
    line-height: 1.45;
}
@media (max-width: 1151px) {
    .report .field-name-field-subhead {
        font-size:1.5rem!important
    }
}
.report .field-name-field-subhead:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 120px;
    height: 5px;
    background-color: #C65540;
}
.report .field-name-field-description{
    margin-bottom: 1.25em;
}
.report .field-name-field-description span{
    font-size: 1rem;
}
@media (min-width: 1151px){
    .report .field-name-field-description span{
        font-size: 1.5rem;
    }
}

.report .online-report__tiles{
    margin-right: -1.2%;
    margin-top: 80px;
    margin-bottom: 60px;
    margin-left: -1.2%;
}
.report .view-row{
    width: 33.3333%
}
.report .online-report__tiles .tile{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-shadow: 0px 0px 0px 0px transparent;
    box-shadow: 0px 0px 0px 0px transparent;
    -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out,-webkit-box-shadow 0.2s ease-in-out;
    padding: 0;
    margin: 0 1.25rem 0 0;
    width: calc(33.333% - 1.25rem);
}

@media (min-width: 769px){
    .report .online-report__tiles .tile {
        padding: 0 1.2% 20px;
    }
}
.report .online-report__tiles .tile .tile__image img{
    position: absolute;
    top:-100%;
    left: 0;
    right: 0;
    bottom: -100%;
    margin:auto;
    margin-left:50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%;
}
.report .online-report__tiles .tile .tile__image:before{
    content: '';
    padding-top:75%;
    display:block;
}
.report .tile__image--mobile {
    height: 200px;
}
.report .online-report__tiles .tile .tile__image--mobile {
    display: none;
    overflow: hidden;
}

@media (min-width: 481px){
    .report .online-report__tiles .tile .tile__image--mobile {
        height: 200px;
    }
    .report .online-report__tiles .tile .tile__image--mobile {
        display: none;
        overflow: hidden;
    }
}

.report .online-report__tiles .tile__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 7.8% 12px;
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    background-color: #C65540;
}
@media (min-width: 961px){
    .report .online-report__tiles .tile__content {
        font-size: 1.125rem;
    }
}
@media (max-width: 768px){
    .report .online-report__tiles .tile__content.is-open {
        background-color: #1287A8;
    }
}
.report .online-report__tiles .tile__summary {
    line-height: 1.778em;
    font-size: 18px;
}

.report .online-report__banner {
    position: relative;
    overflow: hidden;
    text-align: center
}

@media (min-width: 641px) {
    .report .online-report__banner img {
        margin:0 auto;
        max-width: 100%;
        height: auto
    }
}
/*ImageCards*/
.report .image-cards {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 -32px 0
}

@media (min-width: 1151px) {
    .report .image-cards {
        margin-top:80px
    }
}

@media (min-width: 769px) {
    .report .image-cards {
        margin-right:-1.2%;
        margin-bottom: 60px;
        margin-left: -1.2%
    }
}

.report .image-cards .views-row{
    width: 33.333%;
}
@media (max-width: 768px) {
    .report .image-cards .views-row {
        width:100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.report .image-cards .tile-wrap {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.report .image-cards .tile__image {
    height: 220px;
}
.report .image-cards .tile__image img {
    display: block;
    position: relative;
    top: 0;
    left: 50%;
    width: auto;
    height: 220px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.report .image-cards .tile__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 7.8% 12px;
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem;
    background-color: #C65540;
}

.report .image-cards .tile__image--mobile {
    display: none;
    overflow: hidden;
}

.report .image-cards .tile__image--mobile img {
    top: 50%;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
}

.report .image-cards .tile__mobile-content {
    width: 100%;
    padding: 32px;
    font-size: 1rem;
    line-height: 1.625em;
    display: none;
    opacity: 0;
    -webkit-box-shadow: 0px 0px 0px 0px transparent;
    box-shadow: 0px 0px 0px 0px transparent;
    transition: opacity 200ms, display 200ms;
}
@media (max-width: 768px) {
    .image-cards .tile__mobile-content.is-open {
        display: block;
        opacity: 1;
    }
}
.image-cards .tile__mobile-content>div:not(:last-of-type) {
    margin-bottom: 1.625em;
}

.report .image-cards .tile__mobile-content .tile__report-link {
    font-size: 0.875rem;
    text-align: right;
    font-weight: 700;
}
.report .image-cards .tile__mobile-content .tile__report-link a {
    color: #0980B5;
}

.report .image-cards .tile__title {
    padding-right: 24px;
    font-size: 18px;
    font-weight: 700;
    text-transform: none!important;
}
@media (max-width: 1151px) {
    .report .image-cards .tile__title {
        font-size: 1rem;
    }
}
.report .image-cards .tile__content svg{
    position: absolute;
    bottom: 12px;
    right: 7.8%;
}

.report .image-cards .is-hidden {
    display: none;
}

.report .image-cards .tile {
    counter-increment: tile;
    padding: 0;
    margin: 0;
}

@media (min-width: 769px) {
    .report .image-cards .tile {
        padding:0 1.2% 20px !important
    }
    .report .image-cards .tile:hover {
        cursor: pointer;
    }

    .report .image-cards .tile:hover .tile__content {
        padding-top: 21px;
        top: 0;
        background-color: rgba(198,85,64,0.8);
        -webkit-box-shadow: 0px 3px 12px 0px #B7B7A9;
        box-shadow: 0px 3px 12px 0px #B7B7A9
    }

    .report .image-cards .tile:hover .tile__content .tile__summary {
        display: block;
    }
}

@media only screen and (max-width: 760px) {
    .report .image-cards .tile {
        width:calc(100%);
        margin: 0 0 1.25rem 0;

    }
}

@media (max-width: 768px) {
    .report .image-cards .tile .tile__image--mobile {
        display:block
    }
    .report .image-cards .tile .tile__image {
        display:none
    }
}

@media (min-width: 481px) {
    .report .image-cards .tile .tile__image--mobile {
        height:200px
    }

    .report .image-cards .tile .tile__image--mobile img {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

/*LightBox*/


.report #lightbox-img {
    height: 80vh;
    max-width: 90vw;
    object-fit: cover;
}

.report #lightbox{
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.report .online-report__tile-lightbox {
    position: relative;
    z-index: 100;
    overflow: hidden;
    opacity: 1;
    height: inherit;
    font-size: 21px;
    font-size: 2.1rem;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex: 0 0 100%;
}
.online-report__tile-lightbox .overlay__content {
    padding: 60px 9.3%;
    background-color: #fff;
}

/*Completely optional styling that has nothing to do with the lightbox*/
.report .online-report__tile-lightbox .lightbox-item {
    position: relative;
    visibility: visible;
    opacity: 1;
    width: 86%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.report .online-report__tile-lightbox .overlay__controls {
    float: right;
    margin-right: -6.2%;
}
.report .online-report__tile-lightbox .overlay__controls>div {
    display: inline-block;
    vertical-align: middle;
}
.report .online-report__tile-lightbox .overlay__pager>div {
    font-size: 1rem;
    display: inline-block;
    padding: 0.95em 1.2em;
    color: #C65540;
    line-height: 1em;
    font-weight: 700;
    border: 1px solid #CBCBCB;
}
.report .online-report__tile-lightbox .overlay__pager>div i, .online-report__tile-lightbox .overlay__pager>div svg {
    font-size: 1.5rem;
}
.report .online-report__tile-lightbox .overlay__close {
    font-size: 1.75rem;
    display: inline-block;
    padding-left: 0.95em;
    color: #C65540;
    line-height: 1em;
    font-weight: 700;
}
.report .online-report__tile-lightbox .overlay__pager .tooltip .tooltiptext {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 1;
    padding: 8px 16px;
    color: #fff;
    font-size: 0.75em;
    line-height: 1em;
    text-align: center;
    white-space: nowrap;
    background-color: rgba(0,0,0,0.75);
    border-radius: 6px;
    -webkit-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
    bottom: 125%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}


.report .online-report__tile-lightbox .overlay__title {
    margin-bottom: 48px;
    position: relative;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 21px;
    text-transform: uppercase!important;
    max-width: 86%;
}
.report .online-report__tile-lightbox .overlay__title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 120px;
    height: 5px;
    background-color: #C65540;
}

.report .online-report__tile-lightbox .overlay__intro {
    margin-bottom: 32px;
    line-height: 1.5em;
    font-size: 21px;
}

.report .online-report__tile-lightbox .overlay__body {
    margin-bottom: 32px;
    font-size: 1rem;
    line-height: 1.8em;
}

.report .online-report__tile-lightbox .overlay__report-link {
    font-size: 1rem;
    text-align: right;
    font-weight: 700;
}

.report .online-report__tile-lightbox .overlay__report-link a{
    color: #0980B5;
}
.report .online-report__tile-lightbox .overlay__report-link svg {
    padding-left: 2px;
    color: #C65540;
    font-size: 1.11em;
}
.report .online-report__tile-lightbox .overlay__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    background-color: #C65540;
}
.report .online-report__tile-lightbox .overlay__footer .overlay__fact {
    padding: 3.5% 7%;
}
.report .online-report__tile-lightbox .overlay__footer .overlay__fact, .online-report__tile-lightbox .overlay__footer .overlay__fact+.overlay__footer-image {
    width: 50%;
    float: left;
}
.report .online-report__tile-lightbox .field-name-field-fact-summary {
    font-size: 1.25rem;
    line-height: 1.3em;
}

.report .online-report__tile-lightbox .field-name-field-fact-title {
    margin-bottom: 0.2667em;
    font-size: 54px;
    font-size: 5.4rem;
    font-weight: 700;
}

@media (min-width: 961px) {
    .report .online-report__tile-lightbox .field-name-field-fact-title {
        font-size: 60px;
        font-size: 3.75rem;
    }
}

.report .online-report__tile-lightbox .field-name-field-fact-summary {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.3em;
}

@media (min-width: 961px) {
    .report .online-report__tile-lightbox .field-name-field-fact-summary {
        font-size: 1.5rem;
        line-height: 1.5em;
    }
}
.report .online-report__tile-lightbox .overlay__footer{
    max-height: 250px;
}
.report .online-report__tile-lightbox .overlay__footer .overlay__fact+.overlay__footer-image {
    width: 50%;
    float: left;
    height: inherit;
    position: relative;
    overflow: hidden;
}
.report .online-report__tile-lightbox .overlay__footer .overlay__fact+.overlay__footer-image img {
    max-width: 100%;
    height: inherit;
}
.report .online-report__tile-lightbox .overlay__footer .overlay__footer-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: 100%;
    display: block;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -o-object-fit: cover;
    object-fit: cover;
}

.report .online-report__jump-nav {
    margin-top: 96px;
    /*margin-bottom: 64px;*/
}
.report .online-report__jump-nav .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #CBCBCB;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}
.report .online-report__jump-nav .buttons>a {
    position: relative;
    width: 50%;
    padding: 1.334em 1.667em;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.333em;
    color: #0980B5;
    text-decoration: none;
}
.report .online-report__jump-nav .buttons>a:last-of-type:not(:first-of-type){
    text-align: right;
}
@media (min-width: 1151px){
    .report .online-report__jump-nav .buttons>a {
        font-size: 1.5rem;
    }
}
.report .online-report__jump-nav .buttons>a span{
    text-decoration: none;
}
.online-report__jump-nav .buttons.one-item {
    width: 50%;
}
.online-report__jump-nav .buttons.one-item a{
    width: 100%;
}
.report .online-report__jump-nav .buttons>a:hover {
    color: #C65540;
    text-decoration: none;
}
.report .online-report__jump-nav .buttons>a:first-of-type:not(:last-of-type) {
    border-right: 1px solid #CBCBCB;
}

.report .online-report__jump-nav .buttons>a svg {
    position: absolute;
    top: 50%;
    left: 0.75em;
    color: #C65540;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.report .online-report__jump-nav .buttons>a:last-of-type:not(:first-of-type) svg {
    left: auto;
    right: 0.75em;
}

.report .clearfix:after{
    width: 0;
}

/* --------------------------------------------------------------------- */
/* Header */
.report .report_header {
    background-color: #162128;
}
.report .report_header_container {
    display: flex;
    flex-direction: row;
    color: #FFFFFF;
}
.report .report_header_container a {
    color: #FFFFFF;
    float: right;
}
.report #header, #header {
    font-size: 16px;
    font-weight: 900;
    color: #fff;
    line-height: 1.33em
}

@media (min-width: 1151px) {
    .report #header, #header {
        font-size:18px
    }
}

.report #header a, #header a {
    color: #EAAF6F;
    text-decoration: none
}

.report #header a:hover, #header a:hover {
    color: #e49642
}

.report #header>div, #header>div {
    position: relative
}

.report #header .header-content {
    position: inherit;
    float: left;
    margin: 0 0 0 126px;
    width: calc(100% - 126px);
    padding: 5px 0 5px 6px;
    color: #fff;
    text-transform: capitalize;
    text-align: left;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s
}

@media (min-width: 769px) {
    .report #header .header-content {
        width:calc(100% - 165px);
        margin-left: 165px
    }
}

@media (min-width: 641px) {
    .report #header .header-content {
        padding: 5px 20px;
        border-left: 1px solid #fff
    }
}

@media (min-width: 1061px) {
    .report #header .header-content {
        width:calc(100% - 210px);
        margin-left: 210px;
        padding: 15px 20px
    }
}

@media (max-width: 769px) {
    .report #header .logo {
        width:112px
    }
}

.report #header .online-report__header-title {
    display: inline-block;
    font-size: 14px;
    width: 100%
}

.report #header .online-report__header-title, #header .online-report__header-title .views-field, #header .online-report__header-title .field-content, #header .online-report__header-title .views-field {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size:14px;
    font-weight: 900;
}

@media (min-width: 769px) {
    .report #header .views-field-field-online-report-tile-title {
        font-size:16px
    }

    .report #header .views-field-field-online-report-tile-title .header__page-title {
        font-size: 0.778em
    }
}

.report #header .online-report__back, #header .online-report__back {
    display: none
}

@media (min-width: 769px) {
    .report #header .online-report__back, #header .online-report__back {
        display:block;
        position: absolute;
        top: 50%;
        right: 0;
        font-style: italic;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-top: -3px
    }
}

.report #header,.fixed-header-wrapper,.fixed-header-wrapper.fixed>#header, #header, .fixed-header-wrapper, .fixed-header-wrapper.fixed>#header {
    background-color: #162128
}

@media (min-width: 1061px) {
    .report #header,.fixed-header-wrapper, #header, .fixed-header-wrapper {
        min-height:96px
    }
}

.report .fixed-header-wrapper.fixed>#header, .fixed-header-wrapper.fixed>#header {
    min-height: 70px
}

@media (max-width: 768px) {
    .report .fixed #header .logo, .fixed #header .logo {
        width:112px
    }
}

.report .fixed #header .header-content, .fixed #header .header-content {
    margin: 0 0 0 126px
}

@media (min-width: 641px) {
    .report .fixed #header .header-content, .fixed #header .header-content {
        padding:5px 20px
    }
}

@media (max-width: 1060px) {
    .report .fixed #header .header-content, .fixed #header .header-content {
        width:calc(100% - 126px)
    }
}

@media (min-width: 769px) {
    .report .fixed #header .header-content, .fixed #header .header-content {
        width:calc(100% - 165px);
        margin-left: 165px
    }
}

.online-report__tile-lightbox .overlay__footer .overlay__footer-image {
    position: relative;
    overflow: hidden;
    height: 250px;
}
.online-report__tile-lightbox .overlay__footer>div {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.report .field span{
    font-size: 22px;
    margin-bottom: 1.25em;
    line-height: 1.45;
}
