/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.simplicity-info-box {
    border: 2px solid var(--color-dnv-brand-navy);
    display: flex;
    flex-direction: row;
    gap: 12px;
    min-height: 72px;
}
.simplicity-info-box-icon {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    align-items: flex-start;
    width: 80px;
    padding-left: 1.2em;
    padding-right: 1.2em;
}
.font-awesome-icon-layer path {
    fill: #ffffff
}
.simplicity-info-box-icon svg {
    width: 25px;
    height: 25px;
}
.simplicity-info-box-icon svg path {
    width: 25px;
    height: 25px;
    color: #000000;
}
.simplicity-info-box-icon .fa-info {
    font-size: 16px;
    margin-left: -4px;
    margin-top: -5px;

}
.simplicity-info-box-icon .fa-message path {
    color: var(--color-dnv-brand-navy);
}
.simplicity-info-box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
}
.simplicity-info-box-content p,
.simplicity-info-box-content .rich-text-container p,
.simplicity-info-box-content .rich-text-container > :not(h2,h3) {
    margin: 1.0em 1.0em 1.0em 0;
}

@media (max-width: 767px) {
    /* .simplicity-info-box-icon{
        align-items: start;
        padding-top: 1.2em;
    } */
}
