/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.homepage-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    width: 90%;
    margin: 0 auto;
}

.home-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin: 5.5em auto 2em;
}

.home-blocks a {
    color: var(--color-dnv-interactive-blue);
    font-size: var(--font-size-medium);
}

.home-blocks .news-items, .home-blocks .council-items{
    padding-bottom: 0.5em;
}

.home-block:nth-child(1),.home-block:nth-child(2) {
    border-right: 2px solid var(--color-border-grey);
    padding-right: 2.5em;
}

.home-blocks .news-item{
    max-width: 250px;
    padding-bottom: 8px;
}

.home-blocks .council-item {
    max-width: 250px;
    padding-bottom: 8px;
}

.home-blocks .news-item a,.home-blocks .council-item a{
    font-family: var(--font-family-heading);
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium-large);
    display: block;
    line-height: 20px;
}
/* Homepage Layout */
.home-top-section {
    align-items: stretch;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    float: none;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--desktop-width);
    height: 550px;
}

.home-top-section .home-banner {
    align-items: stretch;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    float: none;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--desktop-width);
}
.component.hero-carousel {
    width: 100%;
    height: 100%;
}

.home-banner-links a{
    font-family: var(--font-family-links-labels);
    font-size: 20px;
}
@media only screen and (max-width: 1060px) {
    .home-top-section {
        flex-direction: column;
        display: block;
        clear: both;
    }
    .home-top-section .home-banner {
        flex-direction: column-reverse;
        display: block;
        clear: both;
    }
}

/* Homepage banner */
.home-top-section > .home-banner {
    flex-basis: calc(1440px* 0.73);
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    width: calc(1440px* 0.73);
}
.home-top-section .home-banner {
    flex-basis: calc(1440px* 0.73);
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    width: calc(1440px* 0.73);
    display: flex;
}
.home-top-section .component.hero-carousel {
    flex-direction: row;
}

@media only screen and (max-width: 1440px) {
    .home-top-section .home-banner {
        flex-basis: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 1060px) {
    .home-top-section .home-banner {
        flex: 1 0 100%;
        width: 100%;
    }
    .component.hero-carousel .home-banner {
        flex: 1 0 100%;
        width: 100%;
    }
}

.home-top-section .home-banner-image {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: calc(100% - 480px);
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}

@media only screen and (max-width: 1060px) {
    .home-top-section{
        height: auto;
    }
    .home-top-section .home-banner-image {
        position: relative;
        height: 376px;
        width: 100%;
        min-height: 200px;
    }

    .home-banner-links{
        position:static;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
}

.home-top-section .home-banner .home-banner-content {
    background-color: var(--color-dnv-brand-navy);
    color: var(--color-white);
    padding: 48px 32px;
    width: 480px;
    max-width: calc((1440px* 0.73) - 6.5rem);
    transition: opacity 0.5s ease-in-out 1s;
    opacity: 1;
    position: relative;
}
.hero-container {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    width: 100%;
}
.hero-description-wrapper {
    margin-bottom: 24px;
}
.home-top-section .home-banner .home-banner-content a{
    color: var(--color-white);
    line-height: 44px;;
}

.home-top-section .home-banner .home-banner-content h1 {
    margin-bottom: 24px;
}

.home-top-section .home-banner .home-banner-content p {
    margin-bottom: 0px;
}

.home-top-section .home-banner .home-banner-content .controls{
    position: absolute;
    bottom: 48px;
    /*display: flex;*/
    /*justify-content: space-between;*/
}

.home-top-section .home-banner .home-banner-content .controls a {
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium-large);
    color: var(--color-link-blue);
    display: block;
}

.home-top-section .home-banner .home-banner-content .controls a:hover {
    color: var(--color-dnv-hover-green);
}

.home-top-section .home-banner .home-banner-content .controls .forward-back {
    background-color: var(--color-white);
    color: var(--color-black);
    display: flex;
    justify-content: space-between;
    width: 140px;
    height: 44px;
    align-items: center;
    font-size: 17px;
    border-radius: 3px;
    padding: 0px 7px;
}

.home-top-section .home-banner .home-banner-content .controls .forward-back button {
    appearance: none;
    border: 2px dashed transparent;
    padding: 0;
    margin: 0;
    background-color: transparent;
    font-size: 1.1875rem;
    color: var(--color-dnv-interactive-blue);
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;

}

/*
.home-top-section .home-banner .home-banner-content .controls .forward-back button:hover {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-active-dark-yellow);
    text-decoration: none;
}
*/

.home-top-section .home-banner .home-banner-content .content {
    background-color:var(--color-white);
}

.home-top-section .home-services {
    background-color: #A4C7CF;
    padding: 2.75rem 3.125rem;
    min-height: 500px;
    width: 100%;
}

.home-top-section .home-services .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-top-section .home-services .links svg{
    font-size: var(--font-size-extra-large);
}

.home-top-section .home-services .links a {
    font-family: var(--font-family-links-labels);
    color: var(--color-black);
    font-size: var(--font-size-medium);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;
    height: 50px;
}

.home-top-section .home-services .links a span:nth-child(1){
    color: #004f59;
    font-size: 1.625rem;
}

.home-top-section .home-services .links a span:nth-child(2){
    font-family: var(--font-family-links-labels);
}

@media screen and (min-width: 1200px) {
    .home-top-section .home-services {
        width: 371px;
    }
}

@media only screen and (max-width: 1440px) {
    .home-top-section .home-banner .home-banner-content {
        /* max-width: calc(100% - 6.5rem); */
        width: 480px;
    }
}

@media only screen and (max-width: 1060px) {
    .home-top-section .home-banner .home-banner-content {
        max-width: 100%;
        width: 100%;
        position: relative;
        right: auto;
        bottom: 0;
        opacity: 1;
        padding: 1.875rem 32px;
    }

    .home-top-section .home-banner .home-banner-content .controls{
        position: static;
        margin-right: 24px;
    }
}

@media only screen and (max-width: 538px) {
    .home-top-section .home-banner .home-banner-content a{
        line-height: normal;
    }
}
@media only screen and (max-width: 480px) {
    .home-top-section .home-banner .home-banner-content {
        padding: 1.875rem 16px;
    }
}


/* @media only screen and (max-width: 700px){
    .homepage-wrapper{
    margin-top: 32px;
    }
} */



/* Others */

.homepage-wrapper {
    min-height: 350px;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
}

.featured-services__wrapper {
    background-color: #A4C7CF;
    flex-basis: calc(1440px* 0.27);
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 500px;
    padding: 2.75rem 3.125rem;
    width: calc(1440px* 0.27);
}

.featured-services-list__wrapper {
    /*background-color: #A4C7CF;*/
    /*flex-basis: calc(1440px* 0.27);*/
    flex-grow: 1;
    flex-shrink: 0;
    /*min-height: 500px;*/
    padding: 2.75rem 3.125rem;
    /*width: calc(1440px* 0.27);*/
    width: 100%;
}

@media only screen and (max-width: 1440px) {
    .featured-services__wrapper {
        flex-basis: 27%;
        width: 27%;
    }
}

@media only screen and (max-width: 1060px) {
    .featured-services__wrapper {
        flex: 1 0 100%;
        min-height: 0;
        padding: 1.875rem 1.25rem;
        width: 100%;
    }
}
