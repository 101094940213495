/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
@import "../_variables.css";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

html {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

q, blockquote {
    quotes: none;
}

q:before, q:after, blockquote::before, blockquote::after {
    content: "";
    content: none;
}

a img {
    border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, .ui-datepicker .ui-datepicker-title, .entity-embed-engagesurvey-survey-wrapper .engagesurvey-survey-status, .view-dnv-engagesurvey-surveys .engagesurvey-survey-status, .feature .field-name-field-feature-type, .filter__submit-button, .home-notice .view-header .view-title, .notices .view-header .view-title, .section-links .block-title, #engagesurvey-survey-view-form .engagesurvey-survey-status, #engagesurvey-survey-view-form .engagesurvey-survey-stats .engagesurvey-survey-stats-item-label, .events-calendar .date-nav-wrapper .date-heading, #header .secondary-nav h2, #header .secondary-nav .block-title, .online-report__menu, .field-name-field-tiles > .label-above, .tiles > .label-above, .online-report__tiles .tile__title, .field-name-field-services .label-above {
    text-transform: none !important;
}

h1, .h1, .feature .field-name-field-title, .wysiwyg table .table-title, .table-search-results table .table-title {
    /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-heading);
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0.6em;
}

h2, .h2 {
    /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-heading);
    font-weight: 900;
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 0.4em;
}
h3, .h3 {
    /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-heading);
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0.2em;
}

h4, .h4 {
    /*font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-heading);
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: .6em;
}

h5, .h5 {
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 3px;
}

p {
    font-size: 17px;
    line-height: 1.5em;
    margin: 0 0 1.5em;
}

@media (max-width: 767px) {
    p {
        line-height: 1.4;
    }
}

html {
    color: #000;
    font-size: 17px;
    line-height: 1.5;
}

body {
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    background-color: var(--color-white);
    color: #000;
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
    min-width: 320px;
}

.model-description {
    font-size: 22px;
    margin-bottom: 1.25em;
    line-height: 1.45;
}

.no-scroll {
    overflow: hidden;
}

a {
    color: #0980B5;
    font-family: var(--font-family-links-labels);
}
.side-nav a {
    color: #000;
    text-decoration: none;
    -webkit-transition: color .25s;
    -o-transition: color .25s;
    transition: color .25s;
}
a:hover {
    color: #1b8898;
    text-decoration: underline;
}

a:hover i {
    color: #44a6b5;
}

@media only screen and (max-width: 1060px) {
    body {
        background-color: #fff;
    }
}

@media (max-width: 575.98px) {
    body {
        position: relative;
        overflow-x: hidden;
    }
}

blockquote {
    border-left: 2px solid #D3D3C8;
    padding-bottom: 1rem;
    padding-left: 1rem;
    position: relative;
    font-size: 1.25rem;
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-links-labels);
}

blockquote::before {
    font-weight: 400;
    font-size: 1.625rem;
    color: var(--color-black);
}

blockquote > *:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 768px) {
    blockquote {
        padding-bottom: 1.125rem;
        padding-left: 1.25rem;
    }
}

strong, b {
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-links-labels);
}

a.is-arrow .fa-arrow-right{
    margin-top: 5px;
}

/* a.is-arrow:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f061";
    margin-left: 8px;
    display: inline-block;
    padding: 0 0 0 10px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */
/* a.is-external:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f08e";
    margin-left: 8px;
    display: inline-block;
    padding: 0 0 0 10px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
} */

.simplicity-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
}
.simplicity-icon-inline {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
}
