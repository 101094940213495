/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.services-list {
    max-width: 100%;
    width: 100%;
    border: 1px solid red;
    /*border: 1px solid #dfdfdf;*/
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap
}

.service-list-items {
    max-width: 100%;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    /*width: 100%;*/
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*flex-direction: row;*/
}

.service-list-item {
    /*border: 1px solid red;*/
    flex-basis: auto;
    flex-grow: 1;
    width: 300px;
    max-width: 300px;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
}

.common-tasks {
    width: 100%;
    display: block;
    flex-wrap: wrap;
}

.common-tasks .feed-component-header-items{
    margin-top: -31px;
}

.homepage-title {
    margin-bottom: 2rem;
}

.homepage-title:after {
    content: "";
    background: var(--color-dnv-brand-navy);
    position: absolute;
    width: 110px;
    height: 8px;
    margin-top: 8px;
}

.common-tasks-items {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 32px;
    margin-bottom: 40px;
}

.common-tasks-items-item {
    /* margin: 1rem; */
    /* flex-grow: 1;
    flex-basis: 0; */
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
    padding: 1rem;
    /* flex: 22%; */
    max-height: 76px;
    width: calc((100% - 96px) / 4);
}

.common-tasks-items-item:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.common-tasks-items-item a {
    width: fit-content;
}

/* setting icons dimensions*/
/* rap */
.common-tasks-icon .fa-triangle-exclamation{
    width: 24px;
    height: 24px;
}
/* apply for permit */
.common-tasks-icon .fa-clipboard-check{
    height: 24px;
}
/* look up collection */
.common-tasks-icon .fa-trash-can{
    height: 24px;
}
/* apply for a job */
.common-tasks-icon .fa-suitcase{
    width: 24px;
    height: 22.25px;
}
/* access council materials */
.common-tasks-icon .fa-building-columns{
    width: 24px;
    height: 24px;
}
/* check road works map */
.common-tasks-icon .fa-road-barrier{
    width: 30px;
    height: 24px;
}
/* volunteer for committee */
.common-tasks-icon .fa-comments{
    width: 24px;
    height: 27px;
}
/* look up a bylaw  */
.common-tasks-icon .fa-magnifying-glass-arrow-right{
    width: 24px;
}


@media screen and (min-width: 1060px) {
    .common-tasks {
        width: 100%;
        /* min-width: 335px; */
        display: block;
        /* flex-direction: row; */
        margin-bottom: 40px;
    }

    .common-tasks-title {
        width: 100%;
    }
}

/* @media only screen and (max-width: 700px) {
    .common-tasks-items {
        flex-direction: column;
    }

    .common-tasks-items-item {
        max-width: 100%;
    }
} */

.common-tasks-link:hover,
.common-tasks-link:hover .common-tasks-text,
.common-tasks-link:hover svg path {
    color: var(--color-dnv-brand-green) !important;
    fill: var(--color-dnv-brand-green);
    text-decoration-color: var(--color-dnv-brand-green);
}

.common-tasks-link {
    font-family: var(--font-family-links-labels);
    color: var(--color-dnv-text-black);
    font-size: 17px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
}
.common-tasks-link, .common-tasks-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.common-tasks-link-all {
    padding-left: 0;
}

.common-tasks-icon{
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
}
.common-tasks-icon svg{
    margin: auto;
}
.common-tasks-icon svg path {
    fill: var(--color-dnv-brand-navy);
}
.common-tasks-text {
    display: flex;
    align-items: center;
    margin-left: 12px;
    line-height: normal;
}

@media screen and (max-width: 1060px) {
    .common-tasks-items {
        flex-direction: column;
    }
    .common-tasks-items-item{
        width: 100%;
    }
}
