
.layout .dnv-widget .input-hint {
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
}
.layout .dnv-widget .property-tax-calculator ul {
  list-style: disc;
  padding-left: calc(2ch + 10px);
}
.layout .dnv-widget .property-tax-calculator .graph {
  width: 750px;
  height: auto;
  min-height: 375px;
}
.layout .dnv-widget .property-tax-calculator .drop-shadow {
  display: none;
}
.layout .dnv-widget .property-tax-calculator .graph-wrapper + p:not(.caption) {
  margin-top: 24px;
}
.layout .dnv-widget .tup-widget .table_wrapper {
  max-width: 725px;
}
.layout .dnv-widget .tup-widget .table_wrapper table {
  min-width: 1280px;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.caseNum {
  width: 12.5%;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.address {
  width: 9.7%;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.contact {
  width: 11.25%;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.appDate {
  width: 11.8%;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.issueDate, .layout .dnv-widget .tup-widget .table_wrapper table th.extendDate {
  width: 9.7%;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.expDate {
  width: 11%;
  min-width: 140px;
}
.layout .dnv-widget .tup-widget .table_wrapper table th.planDesc {
  width: 24%;
}
.layout .dnv-widget .planning-search .table_wrapper {
  max-width: 725px;
}
.layout .dnv-widget .planning-search .table_wrapper table {
  min-width: 1280px;
}
.layout .dnv-widget .planning-search .table_wrapper table th.caseNumber {
  width: 12.5%;
}
.layout .dnv-widget .planning-search .table_wrapper table th.appType {
  width: 9.7%;
}
.layout .dnv-widget .planning-search .table_wrapper table th.address, .layout .dnv-widget .planning-search .table_wrapper table th.contact {
  width: 15.6%;
}
.layout .dnv-widget .planning-search .table_wrapper table th.appDate {
  width: 11.9%;
}
.layout .dnv-widget .planning-search .table_wrapper table th.planDesc {
  width: 34.7%;
}
.layout .dnv-widget .council-search-tables .table_wrapper_outer .drop-shadow {
  display: none;
}
/* .layout .dnv-widget .council-search-tables .table_wrapper table {
  min-width: 895px;
} */
.layout .dnv-widget .council-search-tables .table_wrapper table th.meetingDate {
  width: 16%;
}
.layout .dnv-widget .council-search-tables .table_wrapper table th.type {
  width: 28%;
}
.layout .dnv-widget .council-search-tables .table_wrapper table th.documents {
  width: 56%;
}
.layout .dnv-widget .council-search-tables .table_wrapper table td.meetingDate {
  white-space: nowrap;
}
.layout .dnv-widget .council-search-tables .table_wrapper table td.documents .btn.btn-video {
  padding: 0px;
  margin: 0px;
}
.layout .dnv-widget .council-search-tables .table_wrapper table td.documents .btn.btn-video:hover {
  box-shadow: none;
}
.layout .dnv-widget .bl-search .table_wrapper_outer .drop-shadow {
  display: none;
}
.layout .dnv-widget .bl-search .table_wrapper {
  overflow-x: hidden;
}
.layout .dnv-widget .bl-search .table_wrapper table {
  min-width: 900px;
}
.layout .dnv-widget td.td-date,
.layout .dnv-widget td.caseNum {
  text-wrap: nowrap;
}
.layout .dnv-widget .panel {
  width: 50%;
  background-color: var(--color-panel-background);
  border: 1px solid var(--color-panel-border);
  padding: 30px 25px;
  min-width: 256px;
}
.layout .dnv-widget .panel .form-item--flex.date {
  gap: 24px 10px;
}
.layout .dnv-widget .panel .form-item--flex {
  gap: 0px 10px;
}
.layout .dnv-widget .panel .date-wrapper {
  margin-bottom: 24px;
}
.layout .dnv-widget .panel .form-text {
  background-color: transparent;
  border: none;
  font-size: 1.0625rem;
}
.layout .dnv-widget .panel .form-item.date {
  width: 185px;
}
.layout .dnv-widget .panel .form-item.date.auto-width {
  width: auto;
}
.layout .dnv-widget .panel .MuiButtonBase-root.MuiPickersDay-root {
  border: none;
}
.layout .dnv-widget .hasEvent {
  position: relative;
  width: 11px;
  height: 11px;
  overflow: hidden;
  display: inline-block;
  margin-right: 6px;
}
.layout .dnv-widget .hasEvent:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 11px 0;
  border-color: transparent var(--color-link-blue) transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}
.layout .dnv-widget .council-search .form-item {
  margin-bottom: 24px;
  width: 100%;
}
.layout .dnv-widget .council-search .MuiDateCalendar-root {
  width: auto;
}
.layout .dnv-widget .council-search .MuiPickersDay-root.Mui-selected.selected {
  color: var(--color-link-blue);
  background-color: var(--color-panel-background) !important;
}
.layout .dnv-widget .council-search .MuiPickersCalendarHeader-root {
  width: 252px;
  padding: 0px;
  margin: auto;
}
.layout .dnv-widget .council-search .MuiPickersCalendarHeader-label {
  font-family: var(--font-family-paragraph);
}
.layout .dnv-widget .row-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.layout .dnv-widget .row-wrapper .items-wrapper {
  min-width: 30%;
}
.layout .dnv-widget .row-wrapper .pager {
  display: flex;
  justify-content: flex-end;
}
.layout .dnv-widget .itemspp {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.layout .dnv-widget .itemspp span {
  line-height: 42px;
}
.layout .dnv-widget .itemspp select {
  min-width: 80px;
  width: auto;
  margin: 0px 5px;
  padding-top: 8px;
}
.layout .dnv-widget .itemspp select:hover {
  cursor: pointer;
}
.layout .dnv-widget .meetings {
  margin-top: 15px;
}
.layout .dnv-widget .no-wrap {
  text-wrap: nowrap;
}
.layout .dnv-widget .dataTables_info {
  margin: 20px 0px;
}
.layout .dnv-widget .content p,
.layout .dnv-widget .content ol,
.layout .dnv-widget .content ul {
  max-width: none;
}
.layout .dnv-widget .search-flex {
  display: flex;
  gap: 16px 30px;
}
.layout .dnv-widget .input-hint {
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
}
.layout .dnv-widget td.meetingDate {
  width: 25%;
}
.layout .dnv-widget td.type {
  width: 35%;
}
.layout .dnv-widget td.documents {
  width: 50%;
}
.layout .dnv-widget .asc .fa-sort-down {
  display: none;
}
.layout .dnv-widget .asc .fa-sort-up {
  display: block;
}
.layout .dnv-widget .desc .fa-sort-down {
  display: block;
}
.layout .dnv-widget .desc .fa-sort-up {
  display: none;
}
.layout .dnv-widget .expand-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-family: var(--font-family-links-labels);
}
.layout .dnv-widget .expand-btn-wrapper button {
  background-color: transparent;
  border: none;
  color: var(--color-dnv-brand-blue);
}
.layout .dnv-widget .expand-btn-wrapper button:hover {
  cursor: pointer;
}
.layout .dnv-widget button.link--inline {
  background-color: transparent;
  border: none;
  position: relative;
  color: var(--color-dnv-brand-blue);
  text-decoration: underline;
}
.layout .dnv-widget .dnv-election table {
  min-width: 590px;
}
.layout .dnv-widget .dnv-election table th.status {
  width: 15%;
}
.layout .dnv-widget .dnv-election table th.candidate {
  width: 35%;
}
.layout .dnv-widget .dnv-election table th.votes, .layout .dnv-widget .dnv-election table th.percentage {
  width: 25%;
}
.layout .dnv-widget .dnv-election .drop-shadow {
  display: none !important;
}
.layout .dnv-widget .permit-search table {
  min-width: 1100px;
}
.layout .dnv-widget .permit-search table th.permitNum {
  width: 15%;
}
.layout .dnv-widget .permit-search table th.address {
  width: 15%;
}
.layout .dnv-widget .permit-search table th.value {
  width: 15%;
}
.layout .dnv-widget .permit-search table th.contact {
  width: 20%;
}
.layout .dnv-widget .permit-search table th.status {
  width: 9%;
}
.layout .dnv-widget .permit-search table th.issuedDate {
  width: 12%;
}
.layout .dnv-widget .permit-search table th.workClass {
  width: 19%;
}
.layout.integration .campaigner-form {
  max-width: 788px;
  width: auto;
}
.layout.integration .campaigner-form .checkbox {
  display: flex;
}
.layout.integration .campaigner-form .form-item {
  width: 100%;
}

.toast-error .Toastify__toast {
  border-radius: 0;
  border-left: 5px solid var(--color-dnv-messaging-red);
}

.toast-warning .Toastify__toast {
  border-radius: 0;
  border-left: 5px solid var(--color-dnv-messaging-yellow);
}

.toast-success .Toastify__toast {
  border-radius: 0;
  border-left: 5px solid var(--color-dnv-messaging-green);
}

.toast-info .Toastify__toast {
  border-radius: 0;
  border-left: 5px solid var(--color-link-bright-blue);
}

.spinner-container {
  display: flex;
  justify-content: center;
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

.pager ul li a:hover {
  cursor: pointer;
}

/* overlay for video panel*/
.slide-pane__overlay {
  z-index: 9999; /* same as 'search' in upper right*/
}
.slide-pane__overlay .slide-pane {
  padding: 52px 24px 48px 24px;
}
.slide-pane__overlay .slide-pane .slide-pane__header {
  background-color: var(--color-white);
  flex-direction: row-reverse;
  border: none;
  height: auto;
  flex: 0 0 70px;
  margin-bottom: 32px;
}
.slide-pane__overlay .slide-pane .slide-pane__header .slide-pane__close {
  margin-left: 0;
  color: var(--color-dnv-interactive-blue);
  opacity: 1;
}
.slide-pane__overlay .slide-pane .slide-pane__header .slide-pane__close:hover {
  color: var(--color-dnv-brand-green);
}
.slide-pane__overlay .slide-pane .slide-pane__header .slide-pane__title-wrapper {
  margin: 0;
}
.slide-pane__overlay .slide-pane .slide-pane__header .slide-pane__title-wrapper .slide-pane__title {
  font-size: 32px;
  white-space: normal;
  overflow: unset;
  text-overflow: unset;
  max-width: unset;
  line-height: normal;
}
.slide-pane__overlay .slide-pane .slide-pane__content {
  padding: 0px;
}
.slide-pane__overlay .slide-pane .slide-pane__content iframe {
  aspect-ratio: 16/9;
}
.slide-pane__overlay .slide-pane .slide-pane__content .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
}
.slide-pane__overlay .slide-pane .slide-pane__content .information-block {
  border: 2px solid var(--color-dnv-brand-navy);
  padding: 1rem;
  max-width: 800px;
}
.slide-pane__overlay .slide-pane .slide-pane__content .information-block p.information-block__message {
  max-width: 67ch;
  font-weight: 700;
  position: relative;
  margin: 0;
  line-height: 1.3;
}
.slide-pane__overlay .slide-pane .slide-pane__content .information-block p.information-block__message .fa-message {
  color: var(--color-dnv-brand-navy);
}
.slide-pane__overlay .slide-pane .slide-pane__content .information-block p.information-block__message .fa-info {
  font-size: 16px;
  margin-left: -4px;
  margin-top: -5px;
}

.btn.btn-video {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--color-dnv-interactive-blue);
}
.btn.btn-video:hover {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--color-dnv-hover-green);
}
.btn.btn-video svg {
  margin-right: 5px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (min-width: 1440px) {
  .layout .dnv-widget .table_wrapper_outer .drop-shadow {
    display: none;
  }
  .layout .dnv-widget .table_wrapper {
    overflow-x: hidden;
  }
  .layout .permit-search .table_wrapper_outer .drop-shadow,
  .layout .council-decisions .table_wrapper_outer .drop-shadow {
    display: block;
  }
  .layout .permit-search .table_wrapper,
  .layout .council-decisions .table_wrapper {
    overflow-x: auto;
  }
}
@media only screen and (max-width: 968px) {
  .layout .dnv-widget .council-search-tables .table_wrapper_outer .drop-shadow {
    display: block;
  }
  .layout .dnv-widget .bl-search .table_wrapper {
    overflow-x: auto;
  }
  .layout .dnv-widget .bl-search .table_wrapper_outer .drop-shadow {
    display: block;
  }
  .layout .dnv-widget .council-search-tables .table_wrapper table {
    min-width: 895px;
  }
}
/* mobile */
@media only screen and (max-width: 767px) {
  .layout .dnv-widget .panel .date .form-text {
    line-height: normal;
  }
  .layout .dnv-widget .panel .form-item--flex.date {
    gap: 10px;
  }
  .layout .dnv-widget .search-flex {
    display: block;
  }
  .layout .dnv-widget .search-flex .button {
    margin-top: 20px;
  }
  .layout .dnv-widget .council-search,
  .layout .dnv-widget .search-wrapper {
    display: block;
  }
  .layout .dnv-widget .council-search .panel,
  .layout .dnv-widget .search-wrapper .panel {
    width: 100%;
    margin-bottom: 20px;
  }
  .layout .dnv-widget .council-search .panel.quickView .button-wrapper,
  .layout .dnv-widget .search-wrapper .panel.quickView .button-wrapper {
    margin-top: 10px;
    padding-bottom: 10px;
    justify-content: start;
  }
  .layout .dnv-widget .council-search .panel.quickView .button-wrapper > *,
  .layout .dnv-widget .search-wrapper .panel.quickView .button-wrapper > * {
    width: auto !important;
  }
  .layout .dnv-widget .council-search .panel.search-field label.form-item__label,
  .layout .dnv-widget .search-wrapper .panel.search-field label.form-item__label {
    font-weight: normal;
    margin-bottom: 1.5em;
  }
  .layout .dnv-widget .property-tax-calculator .graph-wrapper {
    max-width: 100%;
    overflow-x: scroll;
  }
  .layout .dnv-widget .property-tax-calculator .graph {
    width: 650px;
    height: auto;
  }
  .layout .dnv-widget .property-tax-calculator .drop-shadow {
    display: none !important;
  }
}
@media only screen and (max-width: 654px) {
  .layout .dnv-widget .dnv-election .drop-shadow {
    display: block !important;
  }
}
@media only screen and (max-width: 481px) {
  .layout .dnv-widget .panel {
    padding: 1rem;
  }
  .layout .dnv-widget select {
    min-width: 0;
    max-width: 100%;
  }
}/*# sourceMappingURL=dnv-search-lib.css.map */