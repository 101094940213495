/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.placeholder {
    color: var(--color-black);
}

.menu {
    width: 100%;
}

/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .control {
        width: 181px;
    }

    .menu {
        width: 277px;
    }

    .menu.lg {
        width: 350px;
    }

    .menu.right {
        right: 0;
    }
}
