/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.layout.integration {
    background-color: var(--color-white);
    padding-right:80px;
    padding-left: 80px;
    padding-top: 48px;
    padding-bottom: 48px;
    /*margin-left: 24px;*/
}
.layout.integration .main-content .content p {
    font-size: 1.0625rem;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 1em;
    max-width: none;
}

.layout.integration.w-side-nav {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
}

.layout.integration .main-content .content .simplicity-info-box-content p,
.layout.integration .main-content .content .simplicity-info-box-content .rich-text-container p,
.layout.integration .main-content .content .simplicity-info-box-content .rich-text-container > :not(h2,h3) {
    margin: 1.0em 1.0em 1.0em 0;
}


@media only screen and (max-width: 767px) {
    .layout.integration {
        flex-direction: column;
        padding: 48px 32px 0px 32px;
        max-width: 100%;
    }
    .layout.integration.sidebar-first .main-content {
        width: 100%;
        max-width: 100%;
    }
    .layout.integration.sidebar-first .main-content .page-content {
        flex: 1;
    }
    .layout.integration .main-content{
        max-width: 100%;
    }
}

@media (max-width: 1440px) {
    .layout.integration .main-content{
        max-width: unset;
    }
}

@media (max-width: 480px) {
    .layout.integration{
        padding: 48px 16px 0px 16px;
    }
}
