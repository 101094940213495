/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.field .notification h3 {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium-large);
    line-height: 25px;
}

@media only screen and (max-width: 600px) {
    .field .notification h3 {
        font-size: var(--font-size-medium);
        line-height: 22px;
    }

    .field .notification .notification__message p {
        font-family: var(--font-family-paragraph);
        font-size: var(--font-size-medium);
        line-height: 26px;
    }
}



