/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.simplicity-embedded-app {
    margin-bottom: 40px;
    /*margin-top: 40px;*/
    /*border: 1px solid #0d0d0d;*/
    padding-top: 24px;
    padding-bottom: 24px;
    /*box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);*/
}
