/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.feed-home-container,
.feed-small-container {
    display: block;
    margin-bottom: 3.125rem;
}

.feed-small-container{
    margin-top: 32px;
}

.feed-home-container{
    margin: 40px 0px 0px 0px;
    display: block;
    max-width: 100%;
}

.feed-home-container>.feed-home-row {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;
    margin-bottom: 80px;
}

/*.feed-home-container>.feed-home-row:not(:first-child){*/
/*    margin-top: 32px;*/
/*}*/

@media only screen and (max-width: 700px) {
    .feed-home-container>.feed-home-row {
        flex-direction: column;
    }
}

/* .feed-home-container>.feed-home-row>* {
    border-right: 2px solid #D3D3C8;
    display: flex;
    flex-basis: calc(25% - 1rem);
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1rem;
    margin-top: 0.875rem;
    padding: 0 1.25rem 1.5rem 1.875rem;
    max-width: 480px;
    min-width: 360px;
    width: 33.33%;
} */

.feed-home-container>.feed-home-row>.one-third{
    display: flex;
    max-width: 100%;
    /* min-width: 360px; */
    width: calc(100%/3);
    padding-bottom: 0px;
}

.feed-home-container>.feed-home-row.news>.one-third .feed-component-title{
    padding-top: 12px;
}

/* .feed-home-container>.feed-home-row>.one-third:not(.home){
    padding-bottom: 16px;
} */

.feed-home-container>.feed-home-row>.two-third{
    display: flex;
    max-width: 100%;
    min-width: 360px;
    width: calc(100%/3*2);
    padding-right: 32px;
}

/* .feed-home-container>.feed-home-row>.two-third.feed-latest-news{
    overflow-x: auto;
    padding-bottom: 16px;
} */

.feed-home-row.stories .feed-component-links-wrapper,
.feed-latest-news .feed-component-links-wrapper{
    margin-top: 1px;
}

@media only screen and (max-width: 1440px){
/* .feed-home-container>.feed-home-row>.one-third:not(.home){
    padding-bottom: 24px;
} */
.feed-home-container>.feed-home-row>.two-third.feed-latest-news::-webkit-scrollbar {
    height: 8px;
}
.feed-home-container>.feed-home-row>.two-third.feed-latest-news::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--color-dnv-bg-and-border-pale-tan);
}
.feed-home-container>.feed-home-row>.two-third.feed-latest-news::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-dnv-brand-blue);
}
}

.feed-component-links-wrapper{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 4px;
}


.feed-home-container>.feed-home-row>:nth-child(3) {
    border-right: 0;
    margin-right: 0;
    padding-right: 2.5rem
}


.feed-component-container {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
}

.feed-component-container-notices {
    display: flex;
    flex-basis: calc(100%/3);
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    max-width: calc(100%/3);
    padding: 0;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;

}

.feed-component-container-notices>.feed-component-panel{
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
    flex: 1;
    position: relative;
}

.feed-component-container-notices>.feed-component-panel:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.feed-component-container-notices>.feed-component-panel .feed-component-content{
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    height: 104px;
}
.feed-component-container-notices .feed-component-title{
    padding-left: 16px;
    padding-bottom: 12px;
}

.feed-component-container-small {
    border: 0;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    transition: -webkit-box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out;
    width: 100%;
}

.feed-component-container-small > .feed-component-panel .feed-component-item{
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
    margin-bottom: 24px;
}

.feed-component-container-small > .feed-component-panel .feed-component-item:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.feed-component-container-small > .feed-component-panel .feed-component-item >.feed-component-content{
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.feed-component-container-small > .feed-component-panel .feed-component-item >.feed-component-content >.feed-component-title{
    padding: 16px 16px 0px 16px;
}

.feed-component-container-small > .feed-component-panel .feed-component-item >.feed-component-content >.feed-component-title a{
    padding-bottom: 12px;
    display: inline-block;
}

.feed-component-container-small > .feed-component-panel .feed-component-item >.feed-component-content >.feed-component-title a:hover{
    color: var(--color-dnv-hover-green);
}

.feed-component-container.council>.feed-component-panel-clear>.feed-component-panel-inner{
    column-count: 2;
}

.empty-message{
    padding: 30px 16px;
}

/* .feed-component-container-notices:hover {
    -webkit-box-shadow: 0 3px 12px 0 #B7B7A9;
    box-shadow: 0 3px 12px 0 #B7B7A9
} */


.feed-component-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-right: 24px;
}
.feed-component-container-notices .feed-component-header {
    margin-right: 0;
}
.feed-component-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-right: 24px;
    align-items: center;
}

.feed-component-header > .feed-component-title-link-all {
    margin-top: 6px;
    margin-left: 10px;
}

.feed-component-header > .feed-component-title-link-all > a {
    font-weight: 700;
}

.feed-component-header-title:after{
    content: "";
    background: var(--color-dnv-brand-navy);
    width: 110px;
    height: 8px;
    margin-top: 8px;
    display: block;
}

.feed-component-header-title>h2 {
    margin-bottom: 0;
}

.feed-component-header-clear {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 16px 0;
}

.feed-component-header-clear > .feed-component-title-wrapper {
    margin-right: 20px;
}

.feed-component-header-clear h2 {
    margin-bottom: 0;
}

.feed-component-panel {
    height: auto;
    min-height: 220px;
}

.feed-component-panel-clear {
    height: 100%;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.feed-component-panel-clear:hover {
    border-color: var(--color-dnv-top-bar-border-light-blue);
}


@media only screen and (max-width: 700px) {
    .feed-component-panel-clear,
    .feed-component-panel {
       min-height: auto;
    }
}

.feed-component-date {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
}

.feed-component-date-notice {
    padding: 0px 16px 16px 16px;
}

.homepage .feed-component-date.feed-component-date-notice {
    position: absolute;
    bottom: 16px;
    padding: 0px 16px;
}

.feed-component-title {
    display: -webkit-box;
    line-height: 1.24;
    margin-top: 0;
    text-transform: none !important;
    padding: 16px 16px 0px 0px;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.feed-component-container.home .feed-component-title {
    margin-bottom: 24px;
}

.feed-component-container.home .feed-component-events .feed-component-title {
    margin-bottom: 0;
}

.feed-component-container.home .feed-component-events {
    padding-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    height: 118px;
}

.feed-component-container.home.council .feed-component-events:nth-child(1),
.feed-component-container.home.council .feed-component-events:nth-child(2),
.feed-component-container.home.council .feed-component-events:nth-child(3){
    border-right: solid 1px var(--color-dnv-advisory-border-light-grey);
    break-inside: avoid-column;
}

.feed-component-container.home.council .feed-component-panel-clear:hover .feed-component-events:nth-child(1),
.feed-component-container.home.council .feed-component-panel-clear:hover .feed-component-events:nth-child(2),
.feed-component-container.home.council .feed-component-panel-clear:hover .feed-component-events:nth-child(3){
    border-right: solid 1px var(--color-dnv-top-bar-border-light-blue);

}

.feed-component-container.home .feed-component-events .feed-component-date {
    background-color: var(--color-dnv-brand-navy);
    color: var(--color-white);
    width: 65px;
    height: 78px;
    text-align: center;
    padding-top: 12px;
    margin: 16px 12px 0px 16px;
}

.feed-component-container.home .feed-component-events .feed-component-date h2,
.feed-component-container.home .feed-component-events .feed-component-date h3 {
    color: var(--color-white);
    width: 65px;
}

.feed-component-container.home .feed-component-events .feed-component-date h2 {
    margin-top: -2px;
    margin-bottom: 0px;
}

.feed-component-container.home .feed-component-events .feed-component-date h3 {
    margin-bottom: 0;
    text-transform: uppercase !important;
}

.feed-component-container.home .feed-component-events .feed-component-details {
    width: calc(100% - 65px);
    position: relative;
}

.feed-component-container.home .feed-component-events .feed-component-details .feed-component-time {
    position: absolute;
    bottom: 0;
}

.feed-component-location {
    padding-right: 16px;
}

h2.feed-component-title-link {
    color: #000000;
    font-family: var(--font-family-heading);
    font-weight: 500;
    line-height: 1.24;
    margin-bottom: 0px;
}
.feed-component-title-underline {
    display: block;
    margin-top: 8px;
    width: 100px;
    height: 8px;
    background-color: var(--color-dnv-brand-navy);
}
.feed-component-all-links {
    margin-top: 4px;
}
.feed-component-description {
    background-color: rgba(255, 255, 255, 0.5);
    text-transform: none !important;
    padding: 30px 16px;
    height: 180px;
}
.feed-component-footer {
    margin-top: 16px;
    vertical-align: baseline;
}
.feed-component-footer-item {
    padding-top: 6px;
    padding-bottom: 6px;
}
.feed-result-row {
    padding: 32px 0 32px 0;
}
.feed-result-description {
    font-size: 17px;
    line-height: 1.53rem;
}
.feed-result-description p {
    margin: 0 0 0 0;
}

.spinner-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

/* home page dnv stories / news feeds*/
.feed-home-container>.feed-home-row>.template-wrap {
    width: 100%;
}

.feed-home-container>.feed-home-row>.template-wrap .feed-component-header-clear>.feed-component-links-wrapper {
    gap: 34px;
}
.feed-home-container>.feed-home-row>.template-wrap .feed-component-header-clear .feed-pagination-wrapper {
    width: auto;
    padding: 0px 16px 0px 0px;
}
.feed-home-container>.feed-home-row .template-wrap .feed-component-header-clear .feed-pagination-wrapper .feed-pagination-container {
    display: flex;
    gap: 8px;
    width: auto;
    padding-top: 5px;
}
.feed-home-container>.feed-home-row .template-wrap .feed-component-header-clear .feed-pagination-wrapper .feed-pagination-container>.feed-pagination-item {
    padding: 0;
    height: auto;
    gap: 0;
    margin-right: 12px;
}
.feed-home-container>.feed-home-row .template-wrap .feed-component-header-clear .feed-pagination-wrapper .feed-pagination-container>.feed-pagination-item:last-child {
    margin-right: 0px;
    margin-left: 12px;
}

.feed-home-container>.feed-home-row>.template-wrap>.result-item-row {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.feed-home-container>.feed-home-row>.template-wrap>.result-item-row>.column {
    display: flex;
    flex-basis: 100%;
    gap: 32px;
}

.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item {
    width: 50%;
    overflow: hidden;
    display: flex;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item:hover {
    border-color: var(--color-dnv-top-bar-border-light-blue);
}


.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>*{
    width: 50%;
}

.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image,
.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .field-content.banner-image-style{
    height: 100%;
    overflow: hidden;
}

.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .simplicity-cover-image-thumb{
    margin: 0;
    height: 100% !important;
    min-height: 376px;
}

.feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-content{
    padding: 1.875rem 1.875rem 1.875rem 1.85rem;
}

/* news*/
.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column{
    gap: 32px;
    display: flex;
    flex-basis: 100%;
}

.feed-home-container>.feed-home-row.news .two-third{
    padding-right: 0;
}

.feed-home-container>.feed-home-row.news .two-third > .template-wrap,
.feed-home-container>.feed-home-row.news .two-third > .template-wrap > .result-item-row,
.feed-home-container>.feed-home-row.news .two-third > .template-wrap > .result-item-row > .column{
    max-width: 100%;
    width: 100%;
}

.feed-home-container>.feed-home-row.news .feed-component-all-links{
    width: 100%;
}

.feed-home-container>.feed-home-row.news .one-third .feed-component-all-links{
    width: auto;
}

.feed-home-container>.feed-home-row.news .feed-pagination-wrapper{
    width: 100%;
    padding: 0;
    white-space: nowrap;
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item{
    max-width: 274px;
    width: calc((100% - 64px)/3);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item:hover{
    border-color: var(--color-dnv-top-bar-border-light-blue);
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.title-date{
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    padding: 12px 16px;
    height: 104px;
    font-weight: normal;
    position: relative;
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.title-date>*{
    margin: 0;
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.title-date> h3{
    font-size: 17px;
    font-weight: 800;
    margin-bottom: 12px;
    color: var(--color-dnv-brand-blue);
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.title-date> .field-name-post-date {
    position: absolute;
    bottom: 16px;
}

.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.banner-image-style{
    margin: 0;
    height: 180px;
}



.feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item>.banner-image-style>.simplicity-cover-image-thumb{
    height: 180px !important;
    margin-top: 0px;
    margin-bottom: 0px;
}


@media only screen and (max-width: 700px) {
    /* .feed-home-container>.feed-home-row>:last-child, .feed-home-container>.feed-home-row>:nth-child(3) {
        margin-bottom: 0 !important;
        border-bottom: 0 !important;
        border-top: 0 !important;
        padding-top: 0 !important;
    } */

    .feed-home-container>.feed-home-row>* {
        /* border-bottom: 2px solid #D3D3C8; */
        border-right: 0;
        flex-basis: 100%;
        /* margin-bottom: 25px; */
        margin-right: 0 ;
        /* margin-top: 0 ; */
        max-width: 100%;
        padding-left: 0 ;
        padding-right: 0;
        padding-top: 0 ;
    }

    .feed-home-container>.feed-home-row>:first-child:after{
        display: none;
    }
}



@media only screen and (min-width: 1061px) and (max-width: 1200px){
    .feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item{
        max-width: unset;
        width: calc((100% - 32px) / 2);
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item{
        display: block;
    }
    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>*{
        width: 100%;
        height: 200px;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item .banner-image-style{
        width: 100%;
        height: 100%;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-content{
        min-height: 300px;
        height: auto;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .simplicity-cover-image-thumb {
        height: 200px !important;
        min-height: unset;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image, .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .field-content.banner-image-style,
    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .field-content.banner-image-style {
        height: auto;
    }
}

@media only screen and (max-width: 1060px) {
    .feed-home-container{
        max-width: 100%;
    }

    .feed-component-container.home.council-column{
        margin-top: 80px
    }
    .feed-home-container >.feed-home-row.stories{
        margin: 80px 0px;
    }

    .feed-home-container>.feed-home-row{
        display: inline-block;
        margin-bottom: 0px;
        max-width: 100%;
        width: 100%;
    }
    .feed-home-container>.feed-home-row.news{
        margin-bottom: 26px;
    }

    .feed-home-container>.feed-home-row>* {
        margin-right: 0;
        position: relative;
        border-right: 0;
        /* flex-basis: calc(50% - 1.25rem);
        max-width: calc(50% - 1.25rem); */
        max-width: 100%;
    }

    .feed-home-container>.feed-home-row>:nth-child(2) {
        border-right: 0;
        margin-right: 0;
    }

    .feed-home-container>.feed-home-row>:nth-child(3) {
        border-top: 2px solid #D3D3C8;
        margin-right: 1.25rem;
        padding-right: 1.25rem;
        padding-top: 1.25rem;
    }

    /* latest news */
    .feed-home-container>.feed-home-row>.template-wrap>.result-item-row{
        max-width: 100vw;
        overflow-x: scroll;
        padding-bottom: 16px;
    }

    .feed-component-container-notices>.feed-component-panel .feed-component-content{
        position: static;
    }

    .feed-component-container.home{
        display: inline-block;
        margin-top: 40px;
    }

    .feed-latest-news {
        display: inline-block;
        margin-top: 40px;
    }

    .feed-component-link-item{
        white-space: nowrap;
    }

    .feed-latest-news .result-item-row{
        max-width: 100vw;
        overflow-x: scroll;
        padding-bottom: 16px;
    }
    .feed-home-row.stories .result-item-row::-webkit-scrollbar,
    .feed-latest-news .result-item-row::-webkit-scrollbar {
        height: 8px;
    }
    .feed-home-row.stories .result-item-row::-webkit-scrollbar-track,
    .feed-latest-news .result-item-row::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
        border-radius: 10px;
        background-color: var(--color-dnv-bg-and-border-pale-tan);
    }
    .feed-home-row.stories .result-item-row::-webkit-scrollbar-thumb,
    .feed-latest-news .result-item-row::-webkit-scrollbar-thumb {
        border-radius: 10px;
        /* -webkit-box-shadow: inset 0 0 6px var(--color-dnv-brand-blue);  */
        background-color: var(--color-dnv-brand-blue);
    }
    .feed-home-container>.feed-home-row.news .feed-component-all-links{
        width: auto;
    }
    .feed-home-container>.feed-home-row.news .template-wrap>.result-item-row>.column>.feed-result-item{
        min-width: 320px;
    }

    .feed-home-container>.feed-home-row.stories .feed-pagination-wrapper,
    .feed-home-container>.feed-home-row.news .feed-pagination-wrapper{
        display: none;
    }

    .feed-component-links-wrapper{
        display: block;
    }
    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item{
        display: block;
        width: 320px;
    }
    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>*{
        width: 100%;
        height: 500px;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field.views-field-content{
        height: 376px;
    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field.views-field-content .views-field.views-field-body .field-content p{
        display: -webkit-box;
        -webkit-line-clamp: 10;
        line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image,
    .feed-home-container>.feed-home-row.stories>.template-wrap>.result-item-row>.column>.simplicity-story-item>.views-field-field-banner-image .simplicity-cover-image-thumb{
        height: 180px !important;
    }

    .feed-component-container-notices {
        margin-bottom: 40px;
    }

}


@media only screen and (max-width: 767px){
    .feed-component-header{
        margin-right: 0px;
    }
}

@media only screen and (max-width: 425px){
    .feed-component-link-item{
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }

    .council-column .feed-component-links-wrapper{
        width: calc(100% - 180px);
        max-width: 100%;
    }

    .feed-component-title{
        line-clamp: 2;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
