/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.two-column-no-nav {
    display: flex;
    flex-direction: row;
}
@media only screen and (min-width: 1200px) {
    .two-column-no-nav {
        padding-left: 25px;
        margin-left: auto;
        margin-right: auto;
        max-width: var(--desktop-width);
    }
}

.two-column-no-nav-left {
    padding-top: 50px;
    max-width: 400px;
    /*min-width: 300px;*/
    width: 345px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /*display: none;*/
}
@media only screen and (max-width: 1060px) {
    /*.two-column-no-nav {*/
    /*    flex-direction: column-reverse;*/
    /*}*/
    .two-column-no-nav-left {
        display: none;
    }
}
.two-column-no-nav .component {
    display: block;
    width: 100%;
}
@media only screen and (min-width: 1200px) {
    .two-column-no-nav-left {
        display: block;
    }
}
.two-column-no-nav-right {
    background-color: var(--color-white);
    flex:1;
    padding: 2em;
    max-width: 100%;
}
@media only screen and (min-width: 1200px) {
    .two-column-no-nav-right {
        display: block;
        padding: 54px 80px;
    }
}

.two-column-no-nav-right .main-content .summary{
    /*padding-right: 80px;*/
    margin-top: var(--spacing-default-component-margin-bottom);
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.two-column-no-nav-left .component {
    width: 100%;
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.two-column-no-nav-left .component > .simplicity-cta-content-image,
.two-column-no-nav-left .component > div {
    width: 100% !important;
 }
