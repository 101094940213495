/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.webpage-card-type-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 6px;
}
.webpage-card-type-container .type {
    cursor: pointer;
    flex: 0 0 60px;
    margin-right: 12px;
}
.webpage-card-type-container .arrow {
    color: #666666;
}
.webpage-card-type-container .arrow:before {
    border: 1px solid #666666;
}
.webpage-card-type-container .tooltip {
    background-color: #666;
    color: #FFFFFF;
}
.feature-webpage-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    flex: 0 0 100%;
}
.fixed_3 {
    justify-content: space-between;
}
/*.feature-webpage-container:has(.feature-webpage-container div:first-child:nth-last-child(3)) {*/
/*    justify-content: space-between;*/
/*    border: 1px solid red;*/
/*}*/
.feature-webpage-content {
    border: 1px solid #DFDFDF;
    /* border-radius: 4px; */
    /* padding: 12px; */
    flex: 0 0 auto;
    width: 30%;
    /*flex: 0 0 31.333%;*/
    /*width: 31.333%;*/
    /*margin-right: 2%;*/
    /*margin-bottom: 2%;*/
}
.feature-webpage-content:hover {
    border-color: var(--color-dnv-top-bar-border-light-blue);
}
@media only screen and (max-width: 1200px) {
    .feature-webpage-content {
        flex: 0 0 auto;
        width: 100%;
        /*flex: 0 0 31.333%;*/
        /*width: 31.333%;*/
        /*margin-right: 2%;*/
        /*margin-bottom: 2%;*/
    }
}
.feature-webpage-content.fill_full_width_1:last-child {
    flex: 0 0 100%;
    margin-right: 0;
}
.feature-webpage-content.fill_full_width_2:nth-last-child(2) {
    flex: 0 0 50%;
    /*margin-right: 2%;*/
    margin-right: 0;
}
.feature-webpage-content.fill_full_width_2:last-child {
    flex: 0 0 50%;
    /*margin-right: 2%;*/
    margin-right: 0;
}
.feature-webpage-content:nth-child(3n) {
    margin-right: 0;
}

@media screen and (max-width: 1024px) {
    .feature-webpage-content{
        flex: 0 0 100% !important;
        width: 100%;
        margin-right: 0;
    }
}
@media screen and (min-width: 760px) {
    .feature-webpage-content.fill_full_width_1 .model-image {
        height: 400px !important;
    }
}
@media screen and (min-width: 1200px) {
    .feature-webpage-content.fill_full_width_1 .model-image {
        /*flex: 0 0 100% !important;*/
        /*width: 100%;*/
        height: 500px !important;
        /*margin-right: 0;*/
    }
}
.feature-webpage-content .webpage-banner-image {
    width: 100%;
    max-width:100%;
    display:flex;
    overflow:hidden;
    position:relative;
    object-fit: cover;
}
/*.feature-webpage-content .webpage-banner-image img {}*/
/*.feature-webpage-content .webpage-banner-image:before {*/
/*    content: '';*/
/*    padding-top: 50%;*/
/*    display:block;*/
/*}*/
.feature-webpage-content .webpage-banner-image img {
    position: absolute;
    top:-100%;
    left: 0;
    right: 0;
    bottom: -100%;
    margin: auto;
    margin-left: 50%;
    transform: translateX(-50%);
    /* height: 100%; */
    width: 100%;
}

.feature-webpage-content .webpage-content-text {
    padding: 12px 16px;
}
.feature-webpage-content h4 {
    font-weight: 700;
    line-height: 1.5;
    color: var(--color-dnv-interactive-blue);
    margin: 12px 0;
}
