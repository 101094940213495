/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.preview-strip {
    height: 26px;
    font-family: "Open Sans", Arial, sans-serif;
    font-style: italic;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    background-color: #C0C0C0;
    color: #03243a;
    display: flex;
    justify-content: right;
    border-bottom: 1px solid #000000;
    display: none;
}

.preview-strip span {
    margin-right: 20px;
    padding: 5px 20px 5px 20px;
}
