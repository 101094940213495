/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.you-tube-container {
    margin-bottom: var(--spacing-default-component-margin-bottom);
    position: relative;
    /*overflow: hidden;*/
    width: 400px;
    /*padding-top: 56.25%;*/
}

.you-tube-container .responsive-iframe {
    aspect-ratio: 16 / 9;
    width: 80%;
}
