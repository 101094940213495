/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-image {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
.simplicity-cover-image-thumb {
    margin-top: 24px;
    margin-bottom: 40px;
}
.image.cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 340px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}
.simplicity-rich-text-figure,
.simplicity-image-caption {
    color: var(--color-dnv-text-black);
    font-family: var(--font-family-paragraph);
    font-size: 12px;
    font-style: italic;
    line-height: 18px;
    margin: 0.5em 0;
}

.simplicity-cover-image-thumb.cover + .simplicity-image-caption{
    margin-top: -34px;
    font-size: 12px;
    margin-bottom: 24px;
}

@media print {
    .image.cover {
        display: none;
    }
}

.image.cover.top-left {
    background-position: top left;
}

.image.cover.top {
    background-position: top center;
}

.image.cover.top-right {
    background-position: top right;
}

.image.cover.right {
    background-position: center right;
}

.image.cover.bottom-right {
    background-position: bottom right;
}

.image.cover.bottom {
    background-position: bottom center;
}

.image.cover.bottom-left {
    background-position: bottom left;
}

.image.cover.left {
    background-position: center left;
}

.yarl__container,
.yarl__thumbnails_container,
.yarl__thumbnails_thumbnail{
    background-color: #fff !important;
}
.yarl__thumbnails_vignette{
    background: none !important;
}
.yarl__icon {
    background-color: #00000099;
    border-radius: 24px;
    border: 1px solid white;
}
.simplicity-image-list-item {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    /*padding: 12px;*/
}
.react-photo-album--row img {
    /*border: 1px solid #787878;*/
    contain: strict;
    aspect-ratio: auto;
    object-fit: cover
}

/* ----------------------- */

.Percent100Aspect {
    aspect-ratio: 16/9;
    object-fit: contain;
    max-width: 100%;
}
.HeightPriority {
    object-fit: contain;
    max-width: 100%;
}
.WidthPriority {
    object-fit: contain;
    max-width: 100%;
}
.StandardAspect {
    aspect-ratio: 16/9;
}
