/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
@import "../_variables.css";

.side-nav {
    max-width: 400px;
    min-width: 200px;
    margin-bottom: 32px;
}
.side-nav a {
    color: var(--color-link-sidebar-grey);
}

.side-nav h2 {
    margin: 0px 24px 0px 0px;
    font-weight: 750;
}

.side-nav .title-underline {
    display: block;
    margin: 12px 0px 32px 0px;
    width: 100px;
    height: 8px;
    background-color: var(--color-dnv-brand-navy);
}
.side-nav button{
    border: none;
    background-color: inherit;
}
.side-nav button svg {
    border-radius: 50%;
    cursor: pointer;
    padding: 6px 8px;
    font-size: var(--font-size-medium);
}
.side-nav button i:hover {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-hover-bright-yellow);
}

/* Common styles for all menu levels */
.side-nav .menu-item ul li {
    display: flex;
    flex-direction: column;
}
.side-nav .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-medium);
    padding-top: 0;
    padding-bottom: 0;
}

.side-nav.event .menu-item:hover {
    background-color: var(--color-background-menu-level-1-selected);
}
.side-nav .menu-item .title {
    flex:1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0px;
}

/* .side-nav .menu-item .title.selected {
    margin-left: -6px;
} */

.side-nav .menu-item .title a {
    color: var(--color-black);
    border-bottom: 2px solid transparent;
}
.side-nav .menu-item .title a:hover {
    color: var(--color-dnv-interactive-dark-blue);
}
.side-nav .menu-item .selected-icon {
    /* width: 26px; */
    padding-right: 0;
    /* font-size: 22px; */
    color: var(--color-dnv-focus-green);
}

.side-nav .menu-item.selected .selected-icon{
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .side-nav .menu-level-2 .menu-item .selected-icon {
    padding-left: 38px;
} */

.side-nav .menu-level-3 .menu-item:not(.active) > .selected-icon{
    padding-left: 0px;
}

.side-nav .menu-item .selected-icon svg {
    width: 18px;
    height: 20px;
}

.side-nav.event .menu-item .selected-icon {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    margin-right: 8px;
    padding-left: 0px;
    padding-top: 2px;
}

.side-nav .menu-item .selected-icon.hidden {
    display: none;
}
.side-nav .menu-item .selected-icon:hover {
    cursor: pointer;
}
.side-nav .menu-level-2>li> .menu-item .title a {
    padding: 0px;
    font-family: var(--font-family-paragraph);
    width: calc(100% - 50px);
}
.side-nav .menu-level-3>li> .menu-item .title a:hover,
.side-nav .menu-level-2>li> .menu-item .title a:hover {
    color: var(--color-dnv-brand-blue);
}
.side-nav .menu-level-2>li> .menu-item.active .title a {
    font-weight: 800;
}
.side-nav .menu-item .title.selected {
    font-family: var(--font-family-heading);
}
.side-nav .menu-item .selected-icon + .title.selected{
    margin-left: 8px;
}
.side-nav .menu-item .expanded {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
}
.side-nav .menu-item .expanded button {
    width:30px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.side-nav .menu-item .expanded button:focus {
    border-color: var(--color-dnv-focus-dark-green);
    border-style: dashed;
    border-width: 2px;
    border-radius: 50%;
}
.side-nav .menu-item .title.selected button {
    color: var(--color-dnv-interactive-dark-blue);
}
.side-nav .menu-item .title:not(.selected) button {
    color: var(--color-dnv-interactive-blue);
}
.side-nav .menu-item button:hover,
.side-nav .menu-item .selected button:hover
/*.side-nav .menu-item .selected:hover button*/ {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-hover-green);
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out,border-color 0.2s ease-in-out,color 0.2s ease-in-out;
    color: var(--color-white);
}

.side-nav .menu-item .title:not(.selected) button:hover {
    color: var(--color-white);
}

.side-nav .menu-item button svg {
    font-size: 17.5px;
}

/* Level 1 menu styles */
/* .side-nav .menu-level-1 .menu-item{
    border-bottom: 1px solid var(--color-side-nav-border-bottom);
} */

.side-nav ul a{
    font-family: var(--font-family-paragraph) !important;
}
.side-nav .menu-level-1 {
    border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
    font-weight: 800;
}
.side-nav .menu-level-1>li>.menu-item {
    padding-left: 16px;
    border: solid 1px transparent;
    border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.side-nav .menu-level-1>li>.menu-item.active-parent {
    /* border: solid 1px var(--color-dnv-top-bar-border-light-blue); */
    background-color: var(--color-background-menu-level-1-selected);
}
.side-nav .menu-level-1>li>.menu-item:hover {
    border: solid 1px var(--color-dnv-top-bar-border-light-blue);
    /* background-color: var(--color-background-menu-level-1-selected); */
}
.side-nav .menu-level-1 .menu-item.selected-section:not(.active-parent) {
    font-weight: 800;
    border: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-left-color: transparent;
    border-right-color: transparent;
    background-color: var(--color-background-menu-level-1-selected);
}
.side-nav .menu-level-1 .menu-item.selected-section:not(.active-parent):hover {
    border: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.side-nav .menu-level-1>li > .menu-item.active {
    background-color: var(--color-background-menu-level-1-selected);
}

/* Level 2 menu styles */
.side-nav .menu-level-2 {
    max-height: 0;
    overflow: hidden;
    background-color: var(--color-background-menu-level-2);
    transition: all 0.3s ease-out;
    font-weight: 400;
}
.side-nav .menu-level-2.open {
    max-height: 2000px;
    border-bottom: 1px solid var(--color-side-nav-border-bottom);
    transition: all 0.3s ease-in;
}
.side-nav .menu-level-2.open li.menu-item {
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.side-nav .menu-level-2.open li>.menu-item:hover {
    border: solid 1px var(--color-dnv-interactive-blue);
}
.side-nav .menu-level-2 .menu-item {
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 38px;
    border: solid 1px transparent;
    border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.side-nav .menu-level-2 .menu-item.selected {
    padding-left: 16px;
}
.side-nav .menu-level-2 .menu-item.active-parent {
    font-weight: 800;
}
.side-nav .menu-level-2 .menu-item.selected {
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
}
/* .side-nav .menu-level-2 .menu-item .title{
    border-bottom: 1px solid var(--color-side-nav-border-bottom);
} */
.side-nav .menu-level-2 li:last-child .menu-item .title {
    border-bottom: none
}

/* Level 3 menu styles */
.side-nav .menu-level-3 {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
}
.side-nav .menu-level-3.open {
    max-height: 1000px;
    transition: all 0.3s ease-in;
}
.side-nav .menu-level-3.open >.menu-item.active {
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.side-nav .menu-level-3 .menu-item {
    padding-left: 64px;
}

.side-nav .menu-level-3 .menu-item.active{
    padding-left: 30px;
}

.side-nav .menu-level-3 .menu-item .selected-icon{
    padding-left: 0px;
    display: flex;
    justify-content: center;
}

.side-nav .menu-level-3 .menu-item.selected > .selected-icon {
    padding: 0px;
    width: 26px;
    display: flex;
    justify-content: center;
}

/* .side-nav .menu-level-3.open .menu-item.selected .selected-icon {
    margin-right: 10px;
} */

.side-nav .menu-level-3 .menu-item .title {
    border-bottom: none;
}
.side-nav .menu-level-3 .menu-item .expanded {
    border-bottom: none;
}

.side-nav .menu-level-3>li> .menu-item .title a {
    font-family: var(--font-family-paragraph);
}

.side-nav ul li div a.active:before {
    content: '\f061';
    font-family: "Font Awesome 5 Pro";
    font-size: 1.25rem;
    color: var(--color-dnv-active-dark-yellow);
    position: absolute;
    top: 50%;
    left: -1.5rem;
    -webkit-transform: translate(0rem, -50%);
    -ms-transform: translate(0rem, -50%);
    transform: translate(0rem, -50%);
}

.side-nav ul li div a.active {
    color: var(--color-dnv-text-black);
    font-family: var(--font-family-links-labels);
    font-weight: 800;
}
