/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.carousel-container {}

.carousel-slide {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    /*max-width: 1440px;*/
    min-height: 600px;
    float: none;
    justify-content: space-between;
    margin: 0 auto 24px auto;
}

.carousel-slide .carousel-image-wrapper {
    width: 100%;
    height: 500px;
}

.carousel-slide .carousel-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}

.carousel-slide .carousel-content {
    background-color: #DFDFDF;
    padding: 1.5rem 1.875rem;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out 1s;
    opacity: 1;
    min-height: 220px;
    overflow-y: hidden;
    position: relative;
}

.carousel-slide .carousel-content h1 {
    margin-bottom: 4px;
}

.carousel-slide .carousel-content p {
    margin-bottom: 12px;
}

.carousel-slide .carousel-content .controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    padding-left: 32px;
    padding-right: 32px;
    bottom: 32px;
    width: 100%;
}

.carousel-slide .carousel-content .controls a {
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium-large);
    color: var(--color-link-blue);
    display: block;
}

.carousel-slide .carousel-content .controls a:hover {
    color: var(--color-dnv-hover-green);
}

.carousel-slide .carousel-content .controls .forward-back {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.carousel-slide .carousel-content .controls .forward-back button {
    appearance: none;
    border: 2px dashed transparent;
    padding: 0;
    margin: 0;
    background-color: transparent;
    font-size: 1.1875rem;
    color: var(--color-dnv-brand-navy);
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.carou sel-slide  .carousel-content .controls .forward-back button:hover {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-brand-navy);
    text-decoration: none;
}

.carousel-slide .carousel-content .content {
    background-color:var(--color-white);
}

.carousel-slide-title {
    /* font-family: 'Public Sans', sans-serif; */
    font-size: 32px;
    font-weight: 700;
    line-height: 39.52px;
    letter-spacing: 0.25px;
    text-align: left;
}
.carousel-slide-description {
    /* font-family: 'Public Sans', sans-serif; */
    font-size: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    max-height: 78px;
    overflow-y: hidden;

}
.home-top-section .carousel-slide-description {
    padding-left: 0;
    max-height: unset;
}

.carousel-slide-link {
    /* font-family: 'Public Sans', sans-serif; */
    font-size: 18px;
    letter-spacing: 0.25px;
    text-align: left;
}

.carousel-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #CFCFCF;
    width: 100%;
    height: 100%;
}

.loader-container, .carousel-loading {
    opacity: 1;
    background: rgb(145, 145, 145);
    background: radial-gradient(circle, hsla(0, 0%, 57%, 1) 0%, hsla(0, 0%, 38%, 1) 100%);
}
.loader {
    margin: auto;
    width: 50px;
    height: 50px;
    /*background-color: var(--color-white);*/
    /*background-color: #F4F4EC;*/
    background-color: rgb(0, 62, 67);
    opacity: 0.7;
    animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}
@keyframes spin {
    0%, 100% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(0.5) rotate(180deg);
    }
}
