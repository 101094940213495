/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.simplicity-embed {
    margin: 0;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-radius: 8px;
}

.simplicity-embed.responsive {
    overflow: hidden;
    padding-top: 56.25%; /* 16:9*/
    position: relative;
}

.simplicity-embed iframe {
    width: 100%;
}

.simplicity-embed.responsive iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
}

.simplicity-embed.responsive:has([id="significant-applications-frame"]) {
    height: 530px;
    min-height: 530px;
}

.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
}

.simplicity-composer-embed textarea {
    max-height: 500px;
    overflow: auto;
}

@media screen and (max-width: 836px) {
    /*.simplicity-embed.responsive iframe {*/
    [id="significant-applications-frame"] {
        border: 0;
        height: 530px !important;
        position: static;
        width: 100%;
        padding: 0;
    }
}

/*-------------------------------------*/
.embedPlaceholder {
    color: #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 16px;
}
.defaultEmbedDiv {
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 24px 32px 24px 32px;
    background-color: #CFCFCF;
}
.defaultEmbedDiv .preview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    font-size: 0.75rem;
    color: #999999;
}
.embed-editor-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
}
.editor-wrapper {
    width: 100%;
    height: 95%;
    max-width: 760px;
    font-family: "Ubuntu Mono", Consolas, Monaco, "Andale Mono", monospace;
}
.embedEditor {
    width: 100%;
    height: 400px;
    border: 1px solid #343434;
    border-radius: 8px;
    background: #dedede;
}
.embedEditor textarea {
    min-height: 300px;
}

.simplicity-embed {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
