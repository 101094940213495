/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-quote {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.simplicity-quote svg {
    color: var(--color-link-sidebar-grey);
}

.simplicity-quote .author {
    display: flex;
    flex-direction: row-reverse;
    font-size: var(--font-size-medium-small);
    width: 100%;
}
