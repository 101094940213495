.bannerContainer {
    height: 60px;
    margin: 0 auto;
    flex-direction: row;
    padding-top: 0.2%;
    padding-left: 8%;
    padding-right: 8%;
    background-color: #2e2e2e;
    color: #AAAAAA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.leftContent {
    display: flex;
    align-items: center;
    gap: 7px;
}
.rightContent {
    display: flex;
    align-items: center;
    gap: 6px;
}
.rightContent button {
    color: #AAAAAA;
    text-decoration: underline;
    font-size: 17px;
    text-transform: none;
}
.editButton {
    color: #AAAAAA !important;
    font-size: 17px !important;
    text-transform: none !important;
    text-decoration: underline !important;
}
.bannerIcon {
    height: 20px;
    width: 20px;
    fill: #AAAAAA;
}
@media (max-width: 460px){
    .environment-label {
        display: none;
    }
}
@media (max-width: 768px){
    .bannerContainer {
        gap: 16px;
    }
}
@media (max-width: 1024px) {
    .bannerContainer {
        padding-left: 2%;
        padding-right: 4%;
    }
}
@media (max-width: 1440px) {
    .bannerContainer {
        padding-left: 2%;
        padding-right: 2%;
    }
}


