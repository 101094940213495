/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.layout.section-landing {
    /*padding-left: 1.625rem;*/
    padding-right: 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
}
.layout.section-landing .last-updated-rule {
    margin-bottom: 16px;
}
.region-above-content {
    height: 370px !important;
    width: 100%;
    /*max-width: 1920px;*/
}
.region-above-content .views-field-field-landing-page-banner {
    height: 370px !important;
    width: 100%;
    /*max-width: 1920px;*/
    margin: 0 auto;
    position: relative;
}
.sidebar {
    float: left;
    width: auto;
    max-width: 385px;
    padding-left: 24px;
    /* background-color: var(--color-dnv-bg-and-border-pale-grey); */
}
/* TODO: other layouts use sidebar and need this */
/*@media only screen and (max-width: 1124px) {*/
/*    .sidebar {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*#block-views-landing-page-banner-block-1 .views-field-field-landing-page-banner {*/
/*    height: 370px;*/
/*    width: 100%;*/
/*    max-width: 1920px;*/
/*    margin: 0 auto;*/
/*    position: relative;*/
/*}*/

.layout.section-landing.sidebar-first .main-content {
    /*padding: 0 2rem 4.375rem 2rem;*/
    position: relative;
    z-index: 10;
    /*padding: 3.125rem 5rem 3.75rem 5rem;*/
    float: none;
    width: calc(100% - 385px);
    max-width: calc(100% - 385px);
}

.layout.section-landing.sidebar-first .main-content:before {
    content: '';
    width: 100%;
    height: 5.5rem;
    background-color: #fff;
    position: absolute;
    top: -5.5rem;
    left: 0;
    z-index: -1;
}
.layout.section-landing.sidebar-first .main-content>* {
    margin-top: -2.5rem;
    /*margin-bottom: 2.5rem;*/
}

.layout.section-landing.sidebar-first .feed-small-container{
    max-width: 361px;
    width: 100%;
}

/* ---------------------------------- */

.section-landing {
    display: flex;
    justify-content: center;
}

.section-landing-page .summary {
    margin-bottom: 60px;
}

.page-content{
    background-color: white;
    margin-top: -80px;
    z-index: 100;
    padding: 16px;
}

@media only screen and (min-width: 600px) {
    .page-content{
        padding: 48px 80px;
    }
}


.sidebar-block {
    display: none;
    margin-top: 50px;
    flex:1;
}

.hero-image-container {
    height: 370px;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
}

.notices {
    width: 100%;
}

.notices .notices-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #51A0BD;
    padding: 6px 26px;
}
.notices .notices-header h2 {
    font-size: 20px;
}

.notices .notices-content {
    background-color: rgba(255,255,255,0.5);
    padding: 1em 2em;
    font-size: var(--font-size-medium);
}

.section-landing-page-content {
    display: flex;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1200px) {
    .sidebar-block {
        display: flex;
        flex: 1;
    }
    .page-content {
        flex:3;
    }
}

@media only screen and (max-width: 900px) {
    .sidebar,
    .layout.section-landing.sidebar-first .main-content {
        width: 100%;
        max-width: 100%;
    }
    .layout.section-landing {
        flex-direction: column;
    }

    .sidebar{
        padding: 0px 80px;
    }

}

@media only screen and (max-width: 767px) {
    .layout.section-landing.sidebar-first .main-content .page-content {
        flex: 1;
    }
    .sidebar{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .region-above-content{
        height: 340px !important;
    }
    .region-above-content .views-field-field-landing-page-banner{
        height: 340px !important
    }

    .layout.section-landing.sidebar-first .main-content:before{
        content: none;
    }
    .layout.section-landing.sidebar-first .main-content>.page-content{
        margin-top: 25px;
    }
}

@media only screen and (max-width: 480px) {
    .sidebar{
        padding: 1rem;
    }
}


@media only screen and (min-width: 600px) and (max-width: 767px){
    .sidebar{
        padding-left: 80px;
        padding-right: 80px;
    }
}
