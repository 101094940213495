/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.rich-text-container {
    /*margin-bottom: var(--spacing-default-component-margin-bottom);*/
    /*border: 1px solid red;*/
    /*color: red;*/
}

.rich-text-container {
    /*--spacing-y: 1.25rem;*/
    /*margin-bottom: calc(var(--spacing-y) * 1.5);*/
}
.rich-text-container p {
    --spacing-y: 1.25rem;
    margin-bottom: calc(var(--spacing-y) * 1.5);
    min-height: 1em;
}

.rich-text-container p.intro {
    font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
}

.rich-text-container > :not(h2, h3) {
    margin-bottom: var(--spacing-y);
}

.rich-text-container em {
    font-style: italic;
}

/* headings */
.rich-text-container h2,
.rich-text-container h3 {
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    font-weight: 400;
    line-height: 1.1;
}

.rich-text-container h2 {
    font-size: 1.5rem;
}

.rich-text-container h3 {
    font-size: 1.25rem;
}

/* block elements */
.rich-text-container blockquote {
    display: flex;
    flex-direction: column;
}
.rich-text-container blockquote::before {
    display: block;
    width: 1.0em;
    height: 1.0em;
    margin: 0 auto;
    content: url("../style-assets/quote-left-solid.svg"); /* fallback, without colour, when mask not supported */
    -webkit-mask: url("../style-assets/quote-left-solid.svg") no-repeat 50% 50%;
    mask: url("../style-assets/quote-left-solid.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #000000;
}
.rich-text-container blockquote.focus::before {
    background-color: #3D7737;
}
.rich-text-container blockquote.caution::before {
    background-color: #A68608;
}

.rich-text-container blockquote p {
    font-weight: 900;
}


/* -------------------------------------------- */
/* lists */
.rich-text-container ul,
.rich-text-container ol {
    padding-left: calc(2ch + 10px);
}

.rich-text-container ul {
    list-style: disc;
}

.rich-text-container ol {
    list-style: decimal;
}

.rich-text-container li {
    line-height: 1.4em;
    padding-bottom: 0.15em;
}

.rich-text-container li p {
    margin: 0;
}
.rich-text-container li p {
    margin: 0 0 0 0 !important;
    /* FIXME: affected by ui-styles.css ".layout .content p, .layout .content ol, .layout .content ul" */
    /*border: 1px solid red;*/
}

/* -------------------------------------------- */
/* anchor */
.rich-text-container a {
    color: var(--color-dnv-interactive-blue);
    text-decoration: underline;
}

.rich-text-container a:hover {
    color: var(--color-dnv-hover-green);
}

.rich-text-container a[link_type*="external"] {
    color: var(--color-dnv-interactive-blue) !important;
}
.rich-text-container a[link_type*="external"]:hover {
    color: var(--color-dnv-hover-green) !important;
}
.rich-text-container a[link_type*="external"]:active {
    color: var(--color-dnv-active-black) !important;
}
.rich-text-container a[link_type*="external"]:focus {
    color: var(--color-dnv-focus-green) !important;
}
.rich-text-container a[link_type*="external"]::after {
    /*content: url('data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url%28%23clip0_143_16146%29%22%3E%3Cpath%20d%3D%22M15.3726%202.57031C14.6812%202.57031%2014.1226%203.12891%2014.1226%203.82031C14.1226%204.51172%2014.6812%205.07031%2015.3726%205.07031H18.603L10.7397%2012.9375C10.2515%2013.4258%2010.2515%2014.2188%2010.7397%2014.707C11.228%2015.1953%2012.021%2015.1953%2012.5093%2014.707L20.3726%206.83984V10.0703C20.3726%2010.7617%2020.9312%2011.3203%2021.6226%2011.3203C22.314%2011.3203%2022.8726%2010.7617%2022.8726%2010.0703V3.82031C22.8726%203.12891%2022.314%202.57031%2021.6226%202.57031H15.3726ZM5.99756%203.82031C4.271%203.82031%202.87256%205.21875%202.87256%206.94531V19.4453C2.87256%2021.1719%204.271%2022.5703%205.99756%2022.5703H18.4976C20.2241%2022.5703%2021.6226%2021.1719%2021.6226%2019.4453V15.0703C21.6226%2014.3789%2021.064%2013.8203%2020.3726%2013.8203C19.6812%2013.8203%2019.1226%2014.3789%2019.1226%2015.0703V19.4453C19.1226%2019.7891%2018.8413%2020.0703%2018.4976%2020.0703H5.99756C5.65381%2020.0703%205.37256%2019.7891%205.37256%2019.4453V6.94531C5.37256%206.60156%205.65381%206.32031%205.99756%206.32031H10.3726C11.064%206.32031%2011.6226%205.76172%2011.6226%205.07031C11.6226%204.37891%2011.064%203.82031%2010.3726%203.82031H5.99756Z%22%20fill%3D%22black%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_143_16146%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate%282.87256%202.57031%29%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E');*/
    /*content: url('');*/
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    -webkit-mask: url('data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url%28%23clip0_143_16146%29%22%3E%3Cpath%20d%3D%22M15.3726%202.57031C14.6812%202.57031%2014.1226%203.12891%2014.1226%203.82031C14.1226%204.51172%2014.6812%205.07031%2015.3726%205.07031H18.603L10.7397%2012.9375C10.2515%2013.4258%2010.2515%2014.2188%2010.7397%2014.707C11.228%2015.1953%2012.021%2015.1953%2012.5093%2014.707L20.3726%206.83984V10.0703C20.3726%2010.7617%2020.9312%2011.3203%2021.6226%2011.3203C22.314%2011.3203%2022.8726%2010.7617%2022.8726%2010.0703V3.82031C22.8726%203.12891%2022.314%202.57031%2021.6226%202.57031H15.3726ZM5.99756%203.82031C4.271%203.82031%202.87256%205.21875%202.87256%206.94531V19.4453C2.87256%2021.1719%204.271%2022.5703%205.99756%2022.5703H18.4976C20.2241%2022.5703%2021.6226%2021.1719%2021.6226%2019.4453V15.0703C21.6226%2014.3789%2021.064%2013.8203%2020.3726%2013.8203C19.6812%2013.8203%2019.1226%2014.3789%2019.1226%2015.0703V19.4453C19.1226%2019.7891%2018.8413%2020.0703%2018.4976%2020.0703H5.99756C5.65381%2020.0703%205.37256%2019.7891%205.37256%2019.4453V6.94531C5.37256%206.60156%205.65381%206.32031%205.99756%206.32031H10.3726C11.064%206.32031%2011.6226%205.76172%2011.6226%205.07031C11.6226%204.37891%2011.064%203.82031%2010.3726%203.82031H5.99756Z%22%20fill%3D%22black%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_143_16146%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate%282.87256%202.57031%29%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E') no-repeat 50% 50%;
    mask: url('data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2025%2025%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20clip-path%3D%22url%28%23clip0_143_16146%29%22%3E%3Cpath%20d%3D%22M15.3726%202.57031C14.6812%202.57031%2014.1226%203.12891%2014.1226%203.82031C14.1226%204.51172%2014.6812%205.07031%2015.3726%205.07031H18.603L10.7397%2012.9375C10.2515%2013.4258%2010.2515%2014.2188%2010.7397%2014.707C11.228%2015.1953%2012.021%2015.1953%2012.5093%2014.707L20.3726%206.83984V10.0703C20.3726%2010.7617%2020.9312%2011.3203%2021.6226%2011.3203C22.314%2011.3203%2022.8726%2010.7617%2022.8726%2010.0703V3.82031C22.8726%203.12891%2022.314%202.57031%2021.6226%202.57031H15.3726ZM5.99756%203.82031C4.271%203.82031%202.87256%205.21875%202.87256%206.94531V19.4453C2.87256%2021.1719%204.271%2022.5703%205.99756%2022.5703H18.4976C20.2241%2022.5703%2021.6226%2021.1719%2021.6226%2019.4453V15.0703C21.6226%2014.3789%2021.064%2013.8203%2020.3726%2013.8203C19.6812%2013.8203%2019.1226%2014.3789%2019.1226%2015.0703V19.4453C19.1226%2019.7891%2018.8413%2020.0703%2018.4976%2020.0703H5.99756C5.65381%2020.0703%205.37256%2019.7891%205.37256%2019.4453V6.94531C5.37256%206.60156%205.65381%206.32031%205.99756%206.32031H10.3726C11.064%206.32031%2011.6226%205.76172%2011.6226%205.07031C11.6226%204.37891%2011.064%203.82031%2010.3726%203.82031H5.99756Z%22%20fill%3D%22black%22%2F%3E%3C%2Fg%3E%3Cdefs%3E%3CclipPath%20id%3D%22clip0_143_16146%22%3E%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22%20transform%3D%22translate%282.87256%202.57031%29%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3C%2Fsvg%3E') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--color-dnv-interactive-blue);
}
.rich-text-container a[link_type*="external"]::after:hover {
    background-color: var(--color-dnv-hover-green);
}
.rich-text-container a[link_type*="external"]::after:active {
    background-color: var(--color-dnv-active-black);
}
.rich-text-container a[link_type*="external"]::after:focus {
    background-color: var(--color-dnv-focus-green);
}

/* -------------------------------------------- */
/* table */

.rich-text-container .table-wrapper {
    overflow-x: auto;
}

.rich-text-container .table-wrapper table {
    min-width: 100%;
    table-layout: auto;
    /* margin-bottom: 16px; */
    hyphens: none;
}

.rich-text-container .table-wrapper table p {
    margin: 0;
}

.rich-text-container .table-wrapper table th {
    font-weight: 800;
    border-bottom: 2px solid var(--color-black);
    padding: 15px 16px 11px 16px;
}

.rich-text-container .table-wrapper table td {
    border-bottom: 1px solid #919191;
    padding: 13px 16px 9px 13px;
}

.rich-text-container .table-wrapper table tr:nth-of-type(even) {
    background-color: #FAFAF9;
}

/* uses breakpoint: medium */
@media screen and (min-width: 768px) {

    /* desktop styles */
    .rich-text-container {
        --spacing-y: 2rem;
    }

    .rich-text-container .table-wrapper table {
        table-layout: fixed;
    }
}

/* Control how much margin at the bottom inside of a container (use less) */
.image-list-item p {
    margin-bottom: 12px;
}



/* -------------------------------------------- */

/*.rich-text-display ol ul {*/
/*    display: block;*/
/*}*/
/*.rich-text-display ul {*/
/*    list-style: disc;*/
/*    padding-left: 3em;*/
/*    padding-bottom: 2em;*/
/*}*/
/*.rich-text-display ol {*/
/*    list-style: decimal;*/
/*    padding-left: 3em;*/
/*    padding-bottom: 2em;*/
/*}*/
/*.rich-text-display .body {*/
/*    font-size: 1rem;*/
/*    line-height: 1.5;*/
/*    font-weight: 400;*/
/*    color: #18313a;*/
/*}*/
/*.rich-text-display h1 {*/
/*    font-size: 2.5rem;*/
/*    line-height: 1.5;*/
/*    font-weight: 700;*/
/*    color: #2d2d2d;*/
/*}*/
/*.rich-text-display > h2 {*/
/*    font-size: 1.5rem !important;*/
/*    line-height: 1.1;*/
/*    font-weight: 600;*/
/*    color: #2d2d2d;*/
/*    margin-top: 2em;*/
/*    margin-bottom: 0.4em;*/
/*}*/

/*.rich-text-display h3 {*/
/*    font-size: 1.25rem;*/
/*    line-height: 1.25;*/
/*    font-weight: 500;*/
/*    color: #2d2d2d;*/
/*}*/

/*.rich-text-display a {*/
/*    color: var(--color-dnv-interactive-blue);*/
/*}*/

/*.rich-text-display a:hover {*/
/*    color: var(--color-dnv-hover-green);*/
/*}*/

/*.rich-text-display h3 {*/
/*    font-size: var(--font-size-medium-large);*/
/*}*/

/* --------------- richtext image wrap ------------------ */

/* outer component container */
.simplicity-richtext-wrap {
    max-width: 100%;
}
.simplicity-richtext-wrap img {
    /*width: 100%;*/
    /*height: 100%;*/
    /*display: block;*/
    object-fit: contain;
}
.simplicity-richtext-wrap img {
    width: 100%;
    display: block;
}
.simplicity-richtext-wrap::after {
    content: "";
    clear: both;
    display: table;
}
/* image wrapper */
.simplicity-richtext-wrap-image {
    align-self: baseline;
    padding-bottom: 16px;
    width: 300px;
}
.simplicity-richtext-wrap-image.vertical {
    /*border: 1px solid red;*/
    /*width: unset;*/
}
.simplicity-richtext-wrap .left {
    float: left;
    margin-left: 32px;
}
.simplicity-richtext-wrap .right {
    float: right;
    margin-left: 32px;
}
.simplicity-richtext-wrap img.left {
    /*border: 1px solid red;*/
}
.simplicity-richtext-wrap img.right {
    /*border: 1px solid red;*/
}
@media screen and (min-width: 768px) {
    @media screen and (min-width: 768px) {
        .simplicity-richtext-wrap .left {
            float: left;
            padding-right: 32px;
        }
        .simplicity-richtext-wrap .right {
            float: right;
            padding-left: 32px;
        }
        .simplicity-richtext-wrap-image {
            align-self: baseline;
            padding-bottom: 16px;
            width: 50%;
        }
        .simplicity-richtext-wrap-image.vertical  {
            width: 40%;
        }
    }
}
/* actual image */
.simplicity-richtext-image {
    height: 284px;
    width: 100%
}
/* text wrapper */
.simplicity-richtext-wrap .rich-text-display {
    display: block;
}
.simplicity-richtext-wrap .rich-text-display > p {}

/* -------------------------------------------- */

.rich-text-container .column-block {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 24px;
    padding: 8px 0;
}

.rich-text-container .column-block .column {
    overflow: auto;
    /*border: 1px gray dashed;*/
    border-radius: 8px;
    padding: 8px;
    margin: -8px;
}

/* --------------------------------------------- */

.simplicity-rich-text-image {
    /*border-left: 2px solid lime;*/
    max-width: 100%;
}
.simplicity-rich-text-image.left {
    margin-right: auto
}
.simplicity-rich-text-image.center {
    margin: 0 auto
}
.simplicity-rich-text-image.right {
    /*margin-right: auto;*/
    display: block;
}
.simplicity-rich-text-image.cover {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    object-fit: cover;
    overflow: hidden;
}
/*.simplicity-rich-text-image.Aspect {}*/


