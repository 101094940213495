/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
@import "../_variables.css";

/* prevent webkit browsers from injecting their own resets */
[type=search] {
    -webkit-appearance: textfield;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}


/* for mobile (no breakpoints) */
.quick-search-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
}

.quick-search--visible.quick-search-container {
    width: 100%;
    /*background-color: #FFFFFF;*/
}

@media (max-width: 480px) {
    .quick-search-container.quick-search--visible {
        position: absolute;
        top: 98px;
        right: 16px;
        width: calc(100% - 32px);
    }
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .quick-search-container {
        position: relative;
    }
}

/* -------------------------------------- */
/* quick-search-input-module */

.quick-search-root {
    display: none;
    flex-basis: 100%;
}

.quick-search--visible .quick-search-root {
    display: initial;
}

.quick-search-submit[type="submit"] {
    display: none;
}

.quick-search-input {
    height: 48px;
    border-width: 2px;
    border-color: var(--color-white);
    border-style: solid;
    width: 100%;
    outline: none;
    transition: box-shadow 0.2s ease-in-out;
    border: solid 1px var(--color-dnv-bg-and-border-grey);
}

.quick-search-submit,
.quick-search-reset {
    outline: none;
}

.quick-search-input:focus,
.quick-search-input:focus-visible,
.quick-search-submit:focus,
.quick-search-submit:focus-visible,
.quick-search-reset:focus,
.quick-search-reset:focus-visible {
    box-shadow: inset 0 0 0 2px var(--color-dnv-focus-green);
}

.quick-search-submit:focus,
.quick-search-submit:focus-visible {
    box-shadow: inset 0 0 0 2px #83dd7b;
}

.quick-search-reset:focus {
    border: 2px solid white;
}

.quick-search-submit {
    height: 48px;
    width: 48px;
    flex-shrink: 0;
    color: var(--color-white);
    background-color: var(--color-dnv-brand-blue);
    transition-duration: 0.2s;
    transition-property: background-color, color, outline;
    transition-timing-function: ease-in-out;
}

/* for toggle button outside of search input */
.quick-search-submit[type="button"] {
    border: none;
    font-size: inherit;
    font-family: inherit;
}

.quick-search-submit:hover {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-hover-green);
}

.quick-search-submit:focus,
.quick-search-submit:focus-visible {
    color: var(--color-dnv-text-white);
    background-color: var(--color-dnv-focus-green);
    position: relative;
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 481px) {
    .quick-search-root {
        position: relative;
        display: flex;
    }

    .quick-search-submit[type="button"] {
        display: none;
    }

    .quick-search-submit[type="submit"] {
        display: initial;
    }

    /* .quick-search-input {
        width: 288px;
    } */

    .quick-search-input,
    .quick-search-reset {
        display: unset;
    }
}

/* -------------------------------------- */
/* search-input-module */


.quick-search-root {
    --padding-y: 13px;
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
}

.quick-search-root button,
.quick-search-root input {
    font-size: inherit;
    font-family: inherit;
}

.quick-search-input {
    padding: var(--padding-y) 16px;
    outline-color: var(--color-dnv-focus-green);
}

.quick-search-inputContainer {
    position: relative;
}

.quick-search-reset {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 8px 0 8px;
    position: absolute;
    right: 8px;
    /* vertically center */
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.quick-search-submit {
    border: none;
    cursor: pointer;
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 481px) {
    .quick-search-inputContainer {
        display: inline-block;
    }
}


/* -------------------------------------- */
/* compact-results-module */


.compact-results-container {
    position: absolute;
    z-index: 100000;
    right: 0;
    top: 100%;
    width: calc(100vw - 64px);
    background: var(--color-white);
    padding: 20px;
    box-shadow: 0 8px 16px 0 #23263B40;
}

.compact-results-container a {
    color: var(--color-dnv-interactive-blue);
    font-size: 17px;
    font-weight: 900;
    text-align: left;
    text-decoration: underline;
}

.compact-results-container a:hover {
    text-decoration: underline;
    color: var(--color-dnv-hover-green);
}

.compact-results-container a:active {
    text-decoration: underline;
    color: #000000;
}

.compact-results-header {
    display: flex;
    justify-content: space-between;
}

.compact-results-header .compact-results-title {
    font-size: var(--font-size-medium);
}

.compact-results-header a {
    display: block;
}

.compact-results-container .ais-Hits a {
    padding: 12px 16px 12px 0;
    margin-bottom: 8px;
    display: inline-block;
}

.compact-results-container .compact-static-links {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    flex-direction: column;
}

.compact-results-container .compact-static-links > p {
    margin-bottom: 16px;
}

.compact-results-container .compact-static-links > p > a {
    display: inline-block;
    margin-left: 16px;
}

.compact-results-footer {
    border-top: 1px solid var(--color-divider);
    padding-top: 16px;
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .compact-results-container {
        width: 612px;
    }

    .compact-results-container .compact-static-links {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .compact-results-container .compact-static-links > p {
        flex-basis: 50%;
        flex-shrink: 0;
    }
}

@media (max-width: 680px) {
    .compact-results-container{
        width: calc(100vw - 64px);
    }
}

@media (max-width: 599px) {
    .quick-search-container {
        position: relative;
    }
}

@media screen and (max-width: 480px) {
    .compact-results-container{
        width: calc(100vw - 33px);
    }
}



/* -------------------------------------- */
/* full-search-input-module */

.full-search-root {
    margin-bottom: 16px;
}

.full-search-input {
    width: 100%;
}

.full-search-submit {
    display: none;
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .full-search-submit {
        display: unset;
        /* match the border width on the submit button */
        padding: calc(var(--padding-y) + 2px) 20px;
        margin-left: 30px;
        background-color: var(--color-dnv-interactive-blue);
        color: var(--color-dnv-text-white);
    }
}

@media screen and (max-width: 360px) {
.compact-results-container .compact-static-links > p{
    display: flex;
    }
}

/* -------------------------------------- */
/* full-search-module */
.full-search-controls {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 16px;
    margin-bottom: 16px;
    font-size: 17px;
}

/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .full-search-controls {
        flex-direction: row;
        gap: 0;
    }
}


/* -------------------------------------- */
/* full-search-content */
.full-search-content-filters {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* dropdown */
.full-search-controls .sort-results div[class*="dropdown_control"],
.full-search-content-filters .filter-results div[class*="dropdown_control"]{
    border: 1px solid var(--color-dnv-bg-and-border-grey);
    border-radius: 3px;
}

.full-search-controls .sort-results div[class*="dropdown_control"] div[class$="-ValueContainer"],
.full-search-content-filters .filter-results div[class*="dropdown_control"] div[class$="-ValueContainer"]{
   padding: 11px 16px;
}

.full-search-content-filters .filter-results div[class*="dropdown_control"] div[class$="-ValueContainer"] div[class*="dropdown_placeholder"],
.full-search-content-filters .filter-results div[class*="dropdown_control"] div[class*="dropdown_placeholder"]{
    color: var(--color-black);
}

.full-search-controls .sort-results span[class$="-indicatorSeparator"],
.full-search-content-filters .filter-results span[class$="-indicatorSeparator"]{
    display: none;
}

.full-search-controls .sort-results div[class$="-indicatorContainer"],
.full-search-content-filters .filter-results div[class$="-indicatorContainer"]:last-child {
    height: 18px;
    background: url("../dnv-apps/ui-includes/images/select.svg") var(--color-white) no-repeat;
    padding-right: 20px;
}

.full-search-controls .sort-results  div[class$="-indicatorContainer"] svg,
.full-search-content-filters .filter-results div[class$="-indicatorContainer"] svg{
    display: none;
}



/* toggle for 'show documents' */
.full-search-content-filters .MuiTypography-root{
    font-size: 17px;
}

.full-search-content-filters .MuiSwitch-root{
    padding-right: 8px;
}

.full-search-content-filters .MuiSwitch-track{
    height: 24px;
    width: 48px;
    margin-top: -5px;
    margin-left: -4px;
    border-radius: 12px;
    background-color: #919191;
    opacity: 1;
}

.full-search-content-filters .Mui-checked + .MuiSwitch-track{
    opacity: 1 !important;
}

.full-search-content-filters .MuiSwitch-thumb{
    box-shadow: none;
}

.full-search-content-filters .Mui-checked .MuiSwitch-thumb{
    color: var(--color-white);
    background-color: var(--color-white);
}

.full-search-content-filters .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked,
.full-search-content-filters .MuiButtonBase-root.MuiSwitch-switchBase{
    background-color: transparent;
}

@media screen and (min-width: 600px) {
    .full-search-content-filters {
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }
}


/* --------------------------------------- */
/* additional added full-search */

.full-search-root {
    --padding-y: 13px;
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
}

.full-search-root button,
.full-search-root input {
    font-size: inherit;
    font-family: inherit;
}

.full-search-input {
    padding: var(--padding-y) 16px;
    outline-color: var(--color-dnv-focus-green);
}

.full-search-inputContainer {
    position: relative;
}
.full-search-inputContainer label {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium);
    margin-bottom: 4px;
    display: inline-block;
}
.search-select {
    margin-right: 2rem;
}
/* .full-search-inputContainer .full-search-input {
    margin-right: 2rem;
} */

.full-search-reset {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 8px 0 8px;
    position: absolute;
    right: 8px;
    /* vertically center */
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.full-search-submit {
    border: none;
    cursor: pointer;
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .full-search-inputContainer {
        display: inline-block;
    }
}

/* --------------------------------------- */
/* search-hit-module */

.search-hit {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--color-divider);
}
.search-hit-type {
    width: auto;
    font-size: 17px;
    font-weight: 900;
    text-align: left;
    color: #000000;
}
.search-hit-title {
    font-size: 24px;
    font-weight: 900;
    text-align: left;
    color: var(--color-dnv-interactive-blue);
}
.search-hit-description {
    font-size: 17px;
    text-align: left;
    color: #000000;
    margin-bottom: 1em;
}

/*.search-hit h3 {*/
/*    font-size: var(--font-size-medium);*/
/*}*/

/*.search-hit h4 {*/
/*    color: var(--color-dnv-interactive-bluee);*/
/*}*/

/*.search-hit h5 {*/
/*    font-size: var(--font-size-large);*/
/*    border: 2px dotted darkslateblue;*/
/*}*/

.search-hit p {
    margin-bottom: 1em;
}

.search-hit a {
    display: inline-block;
    overflow-wrap: anywhere;
    font-size: 17px;
    font-weight: 900;
    text-align: left;
    text-decoration: none;
    color: var(--color-dnv-interactive-blue);
}


/* --------------------------------------- */
/* search-pagination-module */


/*.search-results-pagination {*/
/*    padding: 32px 0;*/
/*}*/

/*.search-results-pagination ul {*/
/*    display: flex;*/
/*    gap: 0.5rem;*/
/*    justify-content: center;*/
/*}*/

/*.search-results-pagination .ais-Pagination-item--disabled {*/
/*    color: var(--color-disabled);*/
/*}*/

/*.search-results-pagination .ais-Pagination-link {*/
/*    text-decoration: none;*/
/*    display: block;*/
/*    padding: 0.3rem 0.8rem;*/
/*    font-weight: 800;*/
/*}*/

/*.search-results-pagination .ais-Pagination-item--page .ais-Pagination-link {*/
/*    color: var(--color-dnv-interactive-blue);*/
/*}*/

/*.search-results-pagination .ais-Pagination-item--selected .ais-Pagination-link {*/
/*    background-color: var(--color-dnv-interactive-blue);*/
/*    color: white;*/
/*}*/


/* --------------------------------------- */
/* search-pagination-control-module */


.search-results-pagination-label {
    display: none;
}

/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .search-results-pagination-label {
        display: unset;
    }
}

/* ---------------------------------------- */
.search-pagination-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 24px;
    /*border: 1px solid blue;*/
}
.search-pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    gap: 8px;
    flex-wrap: wrap;
    /*border: 1px solid lime*/
}
.search-pagination-item {
    /*border: 1px solid red;*/
    color: var(--color-dnv-interactive-blue);
    padding: 12px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.search-pagination-item-square {
    color: var(--color-dnv-interactive-blue);
    max-width: 45px;
    min-width: 36px;
    padding: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-pagination-item-square:hover {
    color: var(--color-dnv-hover-green);
}
.search-pagination-item-square:active {
    color: var(--color-dnv-active-black);
}
.search-pagination-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search-pagination-item.disabled,
.search-pagination-item.disabled a {
    color: var(--color-disabled);
    font-weight: 700;
}
.search-pagination-selected-item,
.search-pagination-selected-item a {
    background-color: var(--color-dnv-interactive-blue);
    color: white;
}
.search-pagination-selected-item:hover a,
.search-pagination-selected-item:hover {
    background-color: var(--color-dnv-hover-green);
    color: var(--color-dnv-text-white)
}
.search-pagination-selected-item:active a,
.search-pagination-selected-item:active {
    background-color: var(--color-dnv-active-black);
    color: var(--color-dnv-text-white)
}

