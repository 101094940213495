/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-linkbutton {
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-default-component-margin-bottom);
    width: fit-content;
    background-color: var(--color-dnv-interactive-blue);
    color: var(--color-dnv-text-white);
    padding: 0 0;
}

.simplicity-linkbutton:hover {
    background-color: var(--color-dnv-hover-green);
}

.simplicity-linkbutton a {
    display: flex;
    background-color: var(--color-dnv-interactive-blue);
    padding: 16px 20px;
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium);
    text-decoration: none;
}

.simplicity-linkbutton a:hover {
    background-color: var(--color-dnv-hover-green);
    text-decoration: underline;
}
.simplicity-linkbutton a svg {
    padding-left: 12px;
    font-size: 17px;
}

