/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.flex-row {
    width: 100%;
    display: flex;
    align-items: center;
}

.flex-row-space-between {
    width: 100%;
    display: flex;
    align-items: center;
}

.flex-row-end {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
