/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-menu-wrapper {
    --spacing-top: 23px;
    --menu-width: 390px;
}

/* necessary for child menus to anchor against */
.simplicity-menu-item.level1,
.simplicity-menu-item.level2 {
    position: relative;
}

/* second level menu */
.simplicity-menu-item.level1 > ul.simplicity-menu {
    z-index: 500;
    width: calc(var(--menu-width) + 2px); /* 2px for left & right borders */
    box-shadow: 0px 3px 12px 0px #B7B7A9;
}

/* third level menu */
.simplicity-menu-item.level2 > ul.simplicity-menu {
    /* this is relative to the stacking context above */
    z-index: -1;
}

/* .simplicity-menu-item.level2 {
    border-bottom: 1px solid #D3D3C8;
    background-color: #F4F4EC;
} */

.simplicity-menu-item.level2.last{
    border-bottom: none;
}

/* applies to all menus under level 1 */
.simplicity-menu-item.level1 ul > li {
    width: var(--menu-width);
    color: var(--color-black);
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}

.simplicity-menu-wrapper ul > li > a {
    font-size: 17px;
    line-height: 25px;
    font-style: normal;
    text-decoration-thickness: initial;
    text-decoration: none;
    display: block;
}

/* top level */
.simplicity-menu-wrapper > ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* gap: 2.7rem; */
    height: 76px;
    width: 100%;
}
.simplicity-menu-wrapper > ul > li{
    width: calc(100% /6);
}
.simplicity-menu.level-1 > li:last-child ul{
    right: 0;
}
.simplicity-menu-item.level1 {
    /* flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 120px; */
    height: 76px;
    width: 100%;
}
@media only screen and (max-width: 1200px) {
    .simplicity-menu-item.level1 {
        flex-basis: 110px;
    }
    /*.simplicity-menu-item.level1 > a {*/
    /*    font-size: 14px;*/
    /*}*/
    .simplicity-menu-wrapper > ul {
        /* gap: 1.6rem; */
    }
}
@media only screen and (max-width: 1124px) {
    .simplicity-menu-wrapper > ul {
        gap: 1.2rem;
    }
}
.simplicity-menu-item.level1 > a {
    /*font-family: "Avenir LT W01_85 Heavy1475544","Helvetica Neue",Arial,sans-serif;*/
    font-family: var(--font-family-links-labels);
    display: block;
    color: var(--color-black);
    -webkit-transition: border-color 0.2s ease-in-out,color 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out,color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out,color 0.2s ease-in-out;
    /* border-bottom: 3px solid transparent; */
    overflow: hidden;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 76px;
    width: 100%;
    text-decoration: none;
}
.simplicity-menu-item.level1.is-open > a,
.simplicity-menu-item.level1 > a:hover {
    color: var(--color-dnv-interactive-dark-blue);
    background-color: var(--color-dnv-interactive-pale-blue);
}

.menu-item-root-target-text{
    width: 120px;
    margin-right: 8px;
}

.simplicity-menu-item.level1 > a:hover .menu-item-root-target .chevron{
    background-color: var(--color-dnv-focus-green);
}

.simplicity-menu-item.level1 > a.active {
    color: var(--color-dnv-interactive-dark-blue);
    /* border-bottom: 3px solid var(--color-white); */
    background-color: var(--color-dnv-interactive-pale-blue);
}

.simplicity-menu-item.level1 > a > .menu-item-root-target{
    /* display: flex;
    justify-content: space-between;
    width: 145px; */
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 12.5px 0px;
    height: 100%;
}

.simplicity-menu-item.level1 > a > .menu-item-root-target .chevron{
    border-radius: 50%;
    background-color: var(--color-dnv-interactive-blue);
    color: var(--color-white);
    width: 20px;
    height: 20px;
    text-align: center;
}

.simplicity-menu-item.level1 > a > .menu-item-root-target .chevron .svg-inline--fa{
    vertical-align: 1px;
    width: 12px;
}

.simplicity-menu-item {
    cursor: pointer;
    height: 54px;
}

.simplicity-menu-item.level1 ul > li:hover > a {
    color: var(--color-dnv-interactive-blue);
    text-decoration: underline;
}

.simplicity-menu-item.level1 ul {
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    /* font-family: var(--font-family-heading); */
}

.menu-item-root-target {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: auto;
    height: 50px;
}

.menu-item-target {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: auto;
    width: 320px;
    /* height: 50px; */
    font-family: var(--font-family-paragraph);
}
.menu-fields-menu-link {
    display: block;
}
.simplicity-menu-item.level1 > ul li a {
    color: var(--color-black);
    text-decoration: none;
}
.simplicity-menu-item.level1 > ul li a:hover {
    color: var(--color-dnv-interactive-blue);
    text-decoration: underline;
}

.simplicity-menu-item.level1 > ul {
    /* border-bottom: 1px solid #D3D3C8; */
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.simplicity-menu-item.level1 > ul.level-1 {
    font-weight: 800;
}

.simplicity-menu-item.level3 {
    background-color: rgba(250,250,249,1.0);
}
.simplicity-menu-item.level2 > ul {
    background-color: var(--color-white);
    border: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.simplicity-menu-item:not(.level1){
    border: solid 1px var(--color-dnv-bg-and-border-pale-grey);
    border-bottom-color: var(--color-dnv-advisory-border-light-grey);
}
.simplicity-menu-item:not(.level1):hover {
    /* background-color: var(--color-white); */
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.simplicity-menu.level-2>li:first-child:hover {
    border-top: none;

}

.simplicity-menu-item .no-button-spacer {
    width:30px;
}

.simplicity-menu-item.level2 .submenu-trigger {
    border: none;
    background-color: inherit;
    padding-right: 14px;
    width: 36px;
}

.simplicity-menu-item.level2 .submenu-trigger.inactive{
    display: block;
    font-size: 1em;
    color: var(--color-dnv-interactive-blue);
}

.simplicity-menu-item.level2 .submenu-trigger.active{
    display: none;
    font-size: 1.2em;
    color: var(--color-dnv-focus-green);
}

.simplicity-menu-item.level2:hover .submenu-trigger.inactive {
    display: none;
}

.simplicity-menu-item.level2:hover .submenu-trigger.active {
    display: block;
}

ul.simplicity-menu li ul {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    left: 0;
}

/* align submenu of 'government & administration' to the right'  */
.simplicity-menu.level-1 .simplicity-menu-item.level1.last > .simplicity-menu.level-1{
    left:auto;
}

/* align submenu of 'parks, trails, recreation' to the middle'  */
.simplicity-menu.level-1 .simplicity-menu-item.level1:nth-last-child(2) > .simplicity-menu.level-1{
    left:auto;
    right: -150px;
}

/* .simplicity-menu-item.level1 > ul{
    -webkit-transition: visibility 100ms linear 700ms;
    transition: visibility 100ms linear 700ms;
    visibility: hidden;
}

.simplicity-menu-item.level1:hover > ul{
    -webkit-transition-delay: 700ms;
    transition-delay: 700ms;
    visibility: visible;
} */

/* .simplicity-menu-item.level1:hover > ul,
.simplicity-menu-item.level1 > ul.simplicity-menu:hover  */
.simplicity-menu-item.level1.is-open > ul,
.simplicity-menu-item.level1 > ul.simplicity-menu.is-open {
    display: block;
    visibility: visible;
    opacity: 100%;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    transform: scale(1, 1);
    transition: transform 0.5s ease-in-out;
    /* padding-top: var(--spacing-top); */
    /* padding-top: 8px; */
    margin-top: 8px;
}
.simplicity-menu-item.level1:hover > ul.left,
.simplicity-menu-item.level1 > ul.simplicity-menu.left:hover {
    right: 0;
}

.simplicity-menu-item.level2.left {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.simplicity-menu-item.level2.left a {
    flex:1;
}

.simplicity-menu-item.level2,
.simplicity-menu-item.level3 {
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 1.5em;
    padding-right: 1.5em; */
    padding: 8px 20px;

}

.simplicity-menu-item.level3 a{
    font-family: var(--font-family-paragraph);
}

.simplicity-menu-item.level3 .menu-item-target{
    padding: 3px 1.5em;
}

/* .simplicity-menu-item.level2:hover > ul */
.simplicity-menu-item.level2.is-open > ul {
    display: block;
    visibility: visible;
    opacity: 100%;
    background: transparent;
    cursor: initial;
    position: fixed;
    /* min-height: calc(100% - var(--spacing-top)); */
    min-height: calc(100% + 2px);
    left: var(--menu-width);
    top: -1px;
    margin-top: 0;
    background-color: rgba(250,250,249,1.0);
    /* common animation settings */
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

/* .simplicity-menu-item.level2.left:hover > ul */
.simplicity-menu-item.level2.left.is-open > ul{
    box-shadow: 0px 3px 12px 0px #B7B7A9;
    /* ,inset 0px -3px 6px 0px rgba(0,0,0,0.16); */
    /* border-right: solid 2px rgba(0,0,0,0.10); */
    width: var(--menu-width);
    padding-right: 3px;
    padding-left: 0px;
}

/* .simplicity-menu-item.level2.left:hover > ul > li */
 .simplicity-menu-item.level2.left.is-open > ul > li{
    width: 388px;
}

/* .simplicity-menu-item.level2.right:hover > ul */
.simplicity-menu-item.level2.right.is-open > ul{
    box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding-left: 3px;
    padding-right: 0px;
}
/* .simplicity-menu-item.level2.right:hover > ul > li */
.simplicity-menu-item.level2.right.is-open > ul > li{
    width: 384px; /* 390px - boxshadow blur */
    margin-right: 3px;
}
.simplicity-menu-item.level2 > ul{
    font-weight: 400;
}
/* .simplicity-menu-item.level2:hover > ul.right */
.simplicity-menu-item.level2.is-open > ul.right {
    animation-name: rightEnter;
    transform-origin: left top;
    width: var(--menu-width);
}
.simplicity-menu-item.level1 > ul > li:first-child > .simplicity-menu-item,
.simplicity-menu-item.level2 > ul > li:first-child > .simplicity-menu-item {
    border-top: none;
}

.simplicity-menu-item.level1 > ul > li > .simplicity-menu-item{
    font-weight: 800;
}
/* .simplicity-menu-item.level2:hover > ul.left,
.simplicity-menu-item.level2 > ul.left:hover */
.simplicity-menu-item.level2.is-open > ul.left,
.simplicity-menu-item.level2 > ul.left.is-open {
    animation-name: leftEnter;
    transform-origin: right top;
}

.simplicity-menu-item.level3:hover a {
    color: var(--color-dnv-interactive-blue);
    text-decoration: underline;
}

@keyframes leftEnter {
    0% {
        transform: translateX(-100%);
        clip-path: inset(0 100% 0 0);
    }

    100% {
        transform: translateX(-200%);
    }
}

@keyframes rightEnter {
    0% {
        transform: translateX(-100%);
        clip-path: inset(0 100% 0 0);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes growDown {
    0% { transform: scaleY(0) }
    80% { transform: scaleY(1.1) }
    100% { transform: scaleY(1) }
}

/* Chrome doesn't play well with stretching content beyond it's current size.
* We end up seeing the transparent background until the animation finishes.
* Does not affect firefox
*/
/* @keyframes growRight {
    0% { transform: scaleX(0) }
    80% { transform: scaleX(1.1) }
    100% { transform: scaleX(1) }
}
@keyframes growLeft {
    0% { transform: scaleX(0) }
    80% { transform: scaleX(-1.1) }
    100% { transform: scaleX(-1) }
} */
