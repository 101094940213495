/* do not wrap in .layout*/
.dnv-widget .council-decisions .search-wrapper {
  margin-top: 10px;
}
.dnv-widget .council-decisions .search {
  margin-bottom: 4px;
  display: flex !important;
}
.dnv-widget .council-decisions .dropdown-modal__filters-wrapper {
  flex-direction: row;
  margin-bottom: 4px;
}
.dnv-widget .council-decisions table {
  table-layout: fixed;
  width: auto;
  min-width: 2750px;
}
.dnv-widget .council-decisions table thead tr {
  background-color: var(--color-white);
}
.dnv-widget .council-decisions table thead tr:nth-child(odd) {
  border-bottom: 2px solid var(--color-black);
}
.dnv-widget .council-decisions table th {
  background-color: var(--color-white);
}
.dnv-widget .council-decisions table th.fixed-col-top {
  position: absolute;
  width: 370px;
  height: 50.5px;
  z-index: 1;
}
.dnv-widget .council-decisions table th.meeting-date {
  width: 120px;
}
.dnv-widget .council-decisions table th.decision {
  width: 4.5%;
}
.dnv-widget .council-decisions table th.item {
  width: 250px;
  margin-left: 120px;
  border-right: 2px solid var(--color-black);
}
.dnv-widget .council-decisions table th.description {
  width: 14.5%;
}
.dnv-widget .council-decisions table th.councillor, .dnv-widget .council-decisions table th.mayor {
  width: 5.8%;
}
.dnv-widget .council-decisions table th.meetingType, .dnv-widget .council-decisions table th.category, .dnv-widget .council-decisions table th.minute-link, .dnv-widget .council-decisions table th.video-link {
  width: 6.5%;
}
.dnv-widget .council-decisions table td {
  height: 124.25px;
}
.dnv-widget .council-decisions table td.item, .dnv-widget .council-decisions table td.description {
  display: flex;
  align-items: flex-start;
}
.dnv-widget .council-decisions table td.item {
  margin-left: 120px;
  width: 250px;
  border-right: 2px solid var(--color-black);
}
.dnv-widget .council-decisions table tr:nth-child(even) td {
  background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.dnv-widget .council-decisions table tr:nth-child(odd) td {
  background-color: var(--color-white);
}
.dnv-widget .council-decisions table td.councillor,
.dnv-widget .council-decisions table td.mayor {
  text-align: center;
}
.dnv-widget .council-decisions table td.meeting-date {
  white-space: nowrap;
  width: 120px;
}
.dnv-widget .council-decisions .dropdown-modal__filters-wrapper {
  flex-direction: row;
  margin-bottom: 4px;
}
.dnv-widget .council-decisions .form-item + .form-item {
  margin-top: 0px;
}
.dnv-widget .council-decisions ul.list-group {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  max-width: none;
}
.dnv-widget .pager li.disabled a {
  cursor: not-allowed;
  color: grey;
  display: inline-block;
  padding: 12.25px 17px;
}
.dnv-widget .pager li.disabled a:hover {
  background-color: unset;
  text-decoration: none;
}

.MuiTypography-root {
  font-family: var(--font-family-paragraph) !important;
}

.filter.form-item {
  margin: 0 !important;
}
.filter.form-item label {
  font-size: 1.0625rem;
  color: var(--color-black);
}
.filter > .MuiFormControl-root {
  background: url("./ui-includes/images/select.svg"), var(--color-white);
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  margin-left: 0px;
}
.filter > .MuiFormControl-root svg {
  display: none;
}
.filter .MuiInputBase-root {
  border-radius: 0;
  border: 1px solid var(--color-dnv-brand-grey);
  font-family: var(--font-family-paragraph);
}
.filter .MuiInputBase-root:focus {
  border-color: var(--color-dnv-brand-green);
  outline: solid 1px var(--color-dnv-brand-green);
}
.filter .MuiInputBase-root fieldset {
  border: none;
}
.filter .MuiSelect-select {
  padding: 12.5px 32px 12.5px 14px;
}
.filter label.Mui-focused,
.filter label.MuiFormLabel-filled {
  /* hide label sitting on top of multi-select */
  display: none;
}
.filter button.dropdown-modal__trigger {
  /* date  */
  margin-top: 8px;
  border: 1px solid var(--color-dnv-brand-grey);
  min-width: 300px;
}
.filter button.dropdown-modal__trigger:focus {
  border-color: var(--color-dnv-brand-green);
  outline: solid 1px var(--color-dnv-brand-green);
}
.filter fieldset {
  background-color: transparent;
}
.filter fieldset legend {
  border: none;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: var(--color-dnv-brand-green);
}

.filter-date {
  z-index: 3 !important;
  min-width: 283px;
}

.filter-date .radio {
  margin: 5px 0px;
}

.filter-date input[type=radio] + label {
  padding-left: 26px;
  position: relative;
  display: block;
  line-height: 1.53;
}

.filter-date input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 0;
}

.filter-date input[type=radio]:hover:focus,
.filter-date input[type=radio]:active:focus {
  outline: none;
}

.filter-date input[type=radio] + label {
  padding-left: 26px;
  position: relative;
  display: block;
  line-height: 1.53;
}

.filter-date input[type=radio] + label:before {
  content: "\f111";
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
}

.filter-date input[type=radio]:focus + label:before {
  outline: 2px dashed var(--color-dnv-brand-green);
  outline-offset: 2px;
}

.filter-date input[type=radio]:checked + label:before {
  content: "\f192";
  font-weight: 700;
  color: var(--color-dnv-brand-green);
}

.filter-date input[type=radio][disabled] + label:before {
  cursor: not-allowed;
  font-weight: 700;
  color: var(--color-radio-disabled);
}

.filter-date input[type=radio].error + label:before {
  color: var(--color-dnv-messaging-red);
}

button.link--icon {
  background-color: transparent;
  border: none;
  position: relative;
  padding-left: 28px;
  color: var(--color-dnv-brand-blue);
}

button.link {
  background-color: transparent;
  border: none;
  position: relative;
  color: var(--color-dnv-brand-blue);
}

button.link--icon *[class*=fa-] {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  font-size: 18px;
  left: 0;
}

button.link:hover,
button.link--icon:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
  cursor: pointer;
}

button.link:active button.link--icon:active {
  color: var(--color-black);
  text-decoration: underline;
}

button.dropdown-modal__trigger {
  border-radius: 3px;
  margin-bottom: 4px;
  font-weight: normal;
  min-width: 283px;
}

i.green,
.green svg {
  color: var(--color-dnv-brand-green);
  fill: var(--color-dnv-brand-green);
}

i.red,
.red svg {
  color: var(--color-dnv-messaging-red);
  fill: var(--color-dnv-messaging-red);
}

.display-wrapper.desktop {
  display: flex !important;
  justify-content: space-between;
}

.display-wrapper.desktop .record-count {
  padding-top: 17px;
}

ul.list-group .list-group-item {
  padding: 0rem 1rem 0rem 0rem;
}

.filters-row {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.expand-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.expand-btn-wrapper button {
  background-color: transparent;
  border: none;
  color: var(--color-dnv-brand-blue);
}

.table_wrapper_outer {
  display: flex;
  justify-content: space-between;
}
.table_wrapper_outer .drop-shadow {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  min-width: 10px;
}

.table_wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.table_wrapper table {
  width: 100%;
  table-layout: fixed;
}
.table_wrapper table td > .line-limit {
  display: flex;
}
.table_wrapper table td > .line-limit .cell_content {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
}
.table_wrapper table td > .line-limit .popover-wrapper {
  display: flex;
  align-items: flex-end;
}
.table_wrapper table td > .line-limit .popover-wrapper .popover {
  position: static;
}
.table_wrapper table td.fixed-col {
  height: 124.25px;
  position: absolute;
  top: auto;
}
.table_wrapper table th.fixed-col {
  position: absolute;
  top: auto;
  z-index: 1;
}

.description .cell_content_wrapper {
  text-align: left;
  display: flex;
}

.item .cell_content,
.description .cell_content {
  text-align: left;
}

.item button.popover,
.description button.popover {
  display: inline-block;
}

.tippy-content {
  z-index: 2;
}

.councillor,
.mayor {
  white-space: nowrap;
}

.minute-link,
.video-link {
  text-align: center;
}

.mobile-filters .button-wrapper {
  padding-top: 10px;
  display: flex;
  gap: 10px;
}
.mobile-filters .button-wrapper > * {
  width: 50%;
}

.date-popper {
  width: 258px;
  border: solid 1px #919191;
  padding: 20px;
  background-color: #ffffff;
}
.date-popper .datePickers .form-item .form-item__label {
  font-size: 1.0625rem;
  display: block;
}
.date-popper .datePickers .form-item.end-date {
  margin-top: 24px;
}
.date-popper .btn-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.date-popper .btn-container > * {
  width: 50%;
}
.date-popper .btn-container .button {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 14.25px 20px;
  display: inline-block;
  background-color: #0873a1;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.date-popper .btn-container .button:hover {
  background-color: #3d7737;
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.51);
}
.date-popper .btn-container .button:hover:focus {
  outline: none;
}
.date-popper .btn-container .button:focus {
  outline: 2px dashed #44883e;
  outline-offset: 3px;
}
.date-popper .btn-container .button:active {
  background-color: #000;
}
.date-popper .btn-container .button:active:focus {
  outline: none;
}
.date-popper .btn-container .button--secondary {
  border: 2px solid #0873a1;
  background-color: #fff;
  color: #0873a1;
  padding: 12.25px 20px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.date-popper .form-item__error {
  background-color: var(--color-dnv-messaging-pale-red);
  padding: 12px 16px 7px 44px;
  margin-top: 1px;
  line-height: 1.53;
  position: relative;
}
.date-popper .form-item__error:before {
  content: "\f071";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: var(--color-dnv-messaging-red);
  position: absolute;
  left: 16px;
  transform: translateY(-50%);
  top: 50%;
}

/* mobile council vote*/
@media only screen and (max-width: 768px) {
  .btn-container > * {
    width: 100%;
    margin: 5px 0px;
  }
  .btn-container.clearAll {
    margin-top: 15px;
  }
}
/* mobile council vote*/
@media only screen and (max-width: 767px) {
  .dnv-widget .council-decisions .search-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .dnv-widget .council-decisions .button-wrapper {
    padding-top: 30px;
  }
  .dnv-widget .council-decisions .filter-wrapper {
    margin: 10px 0px;
    justify-content: flex-end;
    display: flex;
  }
  .dnv-widget .council-decisions .header {
    background-color: transparent;
  }
  .dnv-widget .council-decisions .btn-apply {
    margin: 5px 0px;
  }
  .dnv-widget .council-decisions .btn-container > * {
    width: 100%;
    margin: 5px 0px;
  }
  .dnv-widget .council-decisions .btn-container.clearAll {
    margin-top: 15px;
  }
  .dnv-widget .council-decisions .mobile-filters .radio {
    margin: 10px 0px;
  }
  .dnv-widget .council-decisions .mobile-filters input[type=checkbox] {
    position: absolute;
  }
  .dnv-widget .council-decisions .MuiPaper-root.MuiAccordion-root {
    box-shadow: none;
  }
  .dnv-widget .council-decisions .MuiPaper-root.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .dnv-widget .council-decisions .MuiPaper-root.MuiAccordion-root.Mui-expanded:before {
    opacity: 1;
  }
  .dnv-widget .council-decisions .MuiPaper-root.MuiAccordion-root.accordion-wrapper .MuiCollapse-root {
    padding-left: 0.5rem;
  }
  .dnv-widget .council-decisions .MuiAccordionSummary-root.Mui-expanded {
    border-top: solid 1px rgba(var(--color-black), 0.14);
    min-height: 44px;
  }
  .dnv-widget .council-decisions .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0px;
  }
  .dnv-widget .council-decisions .MuiAccordionDetails-root {
    padding: 0;
  }
  .dnv-widget .council-decisions .MuiAccordionDetails-root .datePickers .form-item .form-item__label {
    font-size: 1rem;
  }
  .dnv-widget .council-decisions .MuiAccordionDetails-root .radio.allDates {
    visibility: hidden;
    margin: 0;
    height: 0;
  }
  .dnv-widget .council-decisions .MuiAccordionSummary-content {
    margin-right: 5px;
    color: var(--color-dnv-brand-blue);
    font-weight: 700;
  }
  .dnv-widget .council-decisions .MuiAccordionSummary-expandIconWrapper {
    color: var(--color-dnv-brand-blue);
  }
  .dnv-widget .council-decisions .MuiButtonBase-root.MuiAccordionSummary-root {
    padding-left: 0.5rem;
  }
  .dnv-widget .council-decisions ul {
    padding-left: 0px;
  }
  .dnv-widget .council-decisions .item-row {
    text-align: left;
    display: flex;
    padding: 0.5rem;
    border-bottom: solid 1px var(--color-border-grey);
  }
  .dnv-widget .council-decisions .item-row.top-border {
    border-top: solid 1px var(--color-border-grey);
  }
  .dnv-widget .council-decisions .item-row.border-none {
    border: none;
  }
  .dnv-widget .council-decisions .item-row .item-title,
  .dnv-widget .council-decisions .item-row .item-value {
    width: 50%;
  }
  .dnv-widget .council-decisions .item-row .item-value .btn-video {
    padding: 0;
  }
  .dnv-widget .council-decisions .item-row .item-value.full {
    width: 100%;
  }
  .dnv-widget .council-decisions .item-row .item-value a svg path {
    fill: var(--color-dnv-interactive-blue);
  }
  .dnv-widget .council-decisions .item-row:last-child {
    border-bottom: none;
  }
  .mobile-pagination {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  .mobile-pagination .button {
    margin: 0px 5px;
  }
  .date-pad {
    margin-bottom: 24px;
  }
  .checkbox {
    margin: 5px 0px;
  }
  ul.list-group {
    justify-content: flex-start;
    padding-left: 0px;
  }
  .page-search__input {
    width: 100%;
  }
  .button-wrapper {
    padding-top: 10px;
    display: flex;
    gap: 10px;
  }
  .button-wrapper > * {
    width: 50% !important;
  }
}
@media only screen and (max-width: 1120px) {
  .layout.integration:not(.w-side-nav) .table_wrapper_outer .drop-shadow {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    min-width: 10px;
  }
}
/* only for temporary use permits */
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .layout.integration.w-side-nav .tup-widget .table_wrapper_outer .drop-shadow {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    min-width: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .layout.integration.w-side-nav .table_wrapper_outer .drop-shadow {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    min-width: 10px;
  }
}/*# sourceMappingURL=dnv-decisions.css.map */