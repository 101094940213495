/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

@import "dnv-apps/ui-includes/base/normalize.css";
@import url("dnv-apps/ui-styles.css");
@import url("common/alert-banner.css");
@import url("common/base.css");
@import url("common/calendar.css");
@import url("common/util.css");
@import url("menus/primary/index.css");
@import url("menus/mobile/index.css");
@import url("common/simplicity-cover-image.css");
@import url("common/logo.css");
@import url("common/breadcrumb.css");
@import url("common/button.css");
@import url("common/embedded-app.css");
@import url('common/simplicity-menu.css');
@import url("common/footer.css");
@import url("common/env-banner.css");
@import url("common/images.css");
@import url("common/preview-strip.css");
@import url("common/share-button.css");
@import url("common/side-nav.css");
@import url("common/feedback.css");
@import url("common/last-modified.css");
@import url("cms-content/navigation-list.css");
@import url("cms-content/accordion.css");
@import url("cms-content/call-to-action.css");
@import url("cms-content/carousel.css");
@import url("cms-content/document-list.css");
@import url("cms-content/embed.css");
@import url("cms-content/feature-webpage.css");
@import url("cms-content/heading.css");
@import url("cms-content/image.css");
@import url("cms-content/image-card.css");
@import url("cms-content/image-list.css");
@import url("cms-content/link-button.css");
@import url("cms-content/link-list.css");
@import url("cms-content/info-box.css");
@import url("cms-content/divider.css");
@import url("cms-content/quote.css");
@import url("cms-content/rich-text-display.css");
/*@import url("cms-content/simplicity-table.css");*/
@import url("cms-content/tabs.css");
@import url("cms-content/text.css");
@import url("cms-content/table-of-contents.css");
@import url("cms-content/youtube.css");
@import url("cms-content/fields.css");
@import url("cms-content/document.css");
@import url("cms-content/link.css");
@import url("cms-content/forms/form-group.css");
@import url("cms-content/forms/forms.css");
@import url("cms-content/forms/grid.css");
@import url("cms-content/forms/status-banner.css");
@import url("layouts/common.css");
@import url("layouts/application.css");
@import url("layouts/custom_app.css");
@import url("layouts/integration.css");
@import url("layouts/event.css");
@import url("layouts/homepage/homepage.css");
@import url("layouts/homepage/highlights.css");
@import url("layouts/homepage/common-tasks.css");
@import url("layouts/full_page.css");
@import url("layouts/neighbourhood.css");
@import url("layouts/report.css");
@import url("layouts/story-feed.css");
@import url("layouts/section-landing-page.css");
@import url("layouts/two-column-side-nav.css");
@import url("layouts/two-column-no-nav.css");
@import url("layouts/nav-subsection.css");
@import url("layouts/news-notices.css");
@import url("common/top-bar.css");
@import url("search/index.css");
@import url("common/feeds.css");
@import url("search/feeds.css");

@import url("calendar/calendar-tables.css");
@import url("calendar/full-calendar.css");

/* custom apps */
@import url("dnv-apps/dnv-search-lib.css");
@import url("dnv-apps/dnv-councildecisions.css");
@import url("dnv-apps/dnv-councilpolicies.css");
@import url("dnv-apps/DNVRapPublic.css");
@import url("dnv-apps/dnv-corporate-plan.css");

#mti_wfs_colophon {
    display: none !important;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App .route {
    flex: 1;
}

.App .route-overlay.open {
    position: absolute;
    height:2000px;
    width: 100%;
    background-color: var(--color-black);
    opacity: 0.7;
}

@media only screen and (min-width: 1200px) {
    .App .route-overlay.open {
        display: none;
    }
}

#mti_wfs_colophon {
    display: none !important;
}
