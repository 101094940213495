.dnv-widget .council-policies h3 {
  color: var(--color-dnv-brand-blue);
}
.dnv-widget .council-policies .MuiAccordion-root {
  box-shadow: none;
}
.dnv-widget .council-policies .MuiAccordion-root.Mui-expanded {
  margin: 0px;
}
.dnv-widget .council-policies .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root {
  padding: 0px;
  min-height: 0;
  padding: 0px 15px;
  color: var(--color-dnv-brand-blue);
  font-weight: 700;
  text-decoration: none;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root:hover {
  color: var(--color-dnv-brand-green);
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root:active {
  color: var(--color-black);
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root:active .MuiAccordionSummary-content {
  text-decoration: underline;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root.lastChild {
  border-bottom: 1px solid var(--color-border-grey);
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  padding-right: 10px;
  color: var(--color-dnv-brand-blue);
  transform: none;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  padding-left: 10px;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
  font-weight: 700;
  font-size: 1.0625rem;
  padding: 10px 0px;
  min-height: 0;
  margin: 0px;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root.MuiCollapse-entered {
  border-top: none;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiCollapse-wrapper {
  padding: 0px 20px;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root {
  padding: 0;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root ul {
  padding: 0;
  margin: 0;
  -moz-columns: 2;
       columns: 2;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root ul li {
  list-style-type: none;
  font-size: 1.063rem; /* 17px */
  display: flex;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root ul li i {
  margin-top: 12px;
  margin-right: 5px;
  color: var(--color-dnv-brand-blue);
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root ul li a {
  text-decoration: underline;
}
.dnv-widget .council-policies .MuiAccordion-root .MuiAccordionSummary-root .MuiCollapse-root .MuiAccordionDetails-root ul div {
  font-size: 1em;
  margin-bottom: 0.85em;
}
.dnv-widget .council-policies .accordion-details ul li {
  display: flex;
}
.dnv-widget .council-policies .accordion-details ul li svg {
  margin: 12px 10px 0px 0px;
  color: var(--color-dnv-brand-blue);
}
.dnv-widget .council-policies .accordion-details a {
  display: block;
  padding: 10px 0px;
}/*# sourceMappingURL=dnv-councilpolicies.css.map */