/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.image-card {
    display: flex;
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

.image-card .simplicity-image-card-image {
    flex:1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}

.image-card .simplicity-image-card-content {
    width:50%;
    padding: 1.5rem 1.5rem;
}
