/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.container {
    cursor: default;
    display: flex;
    margin-bottom: 0.5em;
    padding: 8px 20px;
}

.container:first-of-type {
    padding-top: 16px;
}

.label {
    margin-left: 1em;
}

.label.center {
    display: inline-flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
}

.label p {
    margin: 0
}

.count {
    font-size: 10px;
}
