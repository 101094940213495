/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

.two-column-side-nav {
    display: flex;
    flex-direction: row-reverse;
}
.two-column-side-nav>.field.field-component{
    padding-left: 24px;
}
@media only screen and (min-width: 1200px) {
    .two-column-side-nav {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--desktop-width);
    }
}

.two-column-side-nav-left {
    padding-top: 50px;
    padding-left: 24px;
    max-width: 385px;
    width: 385px;
    display: block;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.two-column-side-nav-left .component.call-to-action{
    width: 100%;
    /* margin-left: 24px;  */
}

.two-column-side-nav-left .feed-component-title-link-all a:hover{
    color: var(--color-dnv-hover-green);
}
@media only screen and (max-width: 1124px) {
    .two-column-side-nav-left .side-nav {
        display: none;
    }
    .two-column-side-nav-left {
        width: 100%;
        max-width: 100%;
        background-color: var(--color-white);
        padding: 0px 32px;
    }
    .two-column-side-nav {
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px) {
    .two-column-side-nav-left {
        padding: 1rem;
    }
}

.two-column-no-nav .component {
    display: block;
    width: 100%;
}
.two-column-side-nav-right {
    background-color: var(--color-white);
    flex:1;
    padding: 2em;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
@media only screen and (min-width: 1200px) {
    .two-column-side-nav-right {
        display: block;
        padding: 54px 80px;
    }
}
@media only screen and (max-width: 480px) {
    .two-column-side-nav-right {
        padding: 1rem;
    }
}
.two-column-side-nav-right .main-content .summary{
    /*padding-right: 80px;*/
    margin-top: var(--spacing-default-component-margin-bottom);
    margin-bottom: var(--spacing-default-component-margin-bottom);
}

@media only screen and (max-width: 1200px) {
    .two-column-side-nav-right:has(.forms-table-wrapper) {
        margin: 0;
    }
}
