/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.alert-banner {
    background-color: #FFFFFF;
    /* border-left: 8px solid #F48024; */
    color: black;
    display: flex;
    min-height: 125px;
    /* padding: 20px 20px 32px 56px; */
    border-bottom: 1px solid var(--color-dnv-bg-and-border-grey);
    box-shadow: 0px 3px 6px 0px #00000066;

}

.alert-banner.hide{
    display: none;
}

.alert-banner .alert-banner-icon{
    width: 72px;
    background-color: var(--color-dnv-active-dark-yellow);
    color: var(--color-white);
    display: flex;
    align-items: center;
}

.alert-banner .alert-banner-icon svg{
    width: 40px;
    height: 35px;
    margin: auto;
}

.alert-banner .alert-banner-icon i {
    font-size: 25px;
    margin-right: 20px;
    color: #F48024;
}

.alert-banner .alert-banner-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 32px;
}

.alert-banner .alert-banner-content {
    flex: 1;
    /* padding-right: 20px; */
    max-width: auto;
}

@media (max-width: 768px) {
    .alert-banner .alert-banner-content {
        max-width: 982px;
    }
}

.alert-banner .alert-banner-content .alert-banner-header {
  display: flex;
  flex-shrink: 0;
  margin-bottom: 20px;
  /* max-width: 982px; */
  flex-wrap: nowrap;
  justify-content: space-between;
}

.alert-banner .alert-banner-content .alert-banner-header .alert-banner-header-text{
    display: flex;
    justify-content: flex-start;
}

.alert-banner .alert-banner-content .alert-banner-header .alert-banner-title {
    font-family: var(--font-family-heading);
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 1; */
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content;
}

.alert-banner .alert-banner-content .alert-banner-time-stamp {
    display: flex;
    flex-shrink: 0;
    color: #000000;
    font-family: var(--font-family-paragraph);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    margin: 0px 0px 16px 24px;
}

.alert-banner .alert-banner-content p {
    font-family: var(--font-family-paragraph);
    font-size: 17px;
    line-height: 26px;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    padding-right: 48px;
}

.alert-banner .alert-banner-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    gap: 48px;
    margin-top: 40px;
}

.alert-banner .alert-banner-actions .LearnMore {
    color: #0873A1;
    border: 2px solid #0873A1;
    border-radius: 0;
    padding: 10px 20px;
}

.alert-banner .alert-banner-actions i {
    font-size: 20px;
}

.btn-close{
    border: none;
    background-color: transparent;
    color: var(--color-dnv-interactive-blue);
}

.btn-close:hover{
    cursor: pointer;
}

/* @media (max-width: 1440px) {
    .alert-banner .alert-banner-content .alert-banner-header {
        flex-wrap: wrap;
     }
} */

@media (max-width: 768px) {

    .alert-banner .alert-banner-content .alert-banner-header .alert-banner-header-text{
        display: block;
    }

    .alert-banner .alert-banner-content p {
        /* -webkit-line-clamp: 3; */
        padding-right: 0;
    }

     .alert-banner .alert-banner-content .alert-banner-time-stamp{
        margin: 0px 24px 0px 0px; /* added 24px right margin to force span to next line */
        display: block;
     }

     .alert-banner .alert-banner-actions {
        margin-top: 20px;
    }

    .alert-banner .alert-banner-container {
        /* flex-direction: column; */
        align-items: top;
    }
}
