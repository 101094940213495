/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
body {
    overflow-x: hidden;
    width: 100%;
}
.layout {
    margin-bottom: 0;
    display: block;
    /* set to --desktop-width to match the width of the  */
    max-width: var(--desktop-width);
    margin-left: auto;
    margin-right: auto;
    zoom: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
}

@media only screen and (max-width: 1200px) {
    .layout {
        max-width: 100%;
    }
}
@media only screen {
    .layout:has(.simplicity-forms-field-group) {
        max-width: 100%;
    }
}

.layout.homepage {
    background-color: var(--color-white);
    padding: 64px 32px;
}

.last-updated-rule{
    height: 1px;
    border-bottom: solid 1px var(--color-dnv-brand-grey);
}

.last-updated-rule + .field.field-component.content{
    margin-top: 24px;
}
/*
@media only screen and (max-width: 1060px) {
    .layout.homepage {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
@media only screen and (max-width: 1366px) {
    .layout.homepage {
        padding-left: 1rem;
        padding-right: 1rem;
    }
} */


/*@media (max-width: 1150px) {  }*/

.main-content {
    min-height: 350px;
}

.image.cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: -99999px;
    text-align: left;
    overflow: hidden;
}
.image.cover.center { background-position: center center; }
.image.cover.top-left { background-position: top left; }
.image.cover.top-center { background-position: top center; }
.image.cover.top-right { background-position: top right; }
.image.cover.center-left { background-position: center left; }
.image.cover.center-center { background-position: center center; }
.image.cover.center-right { background-position: center right; }
.image.cover.bottom-left { background-position: bottom left; }
.image.cover.bottom-center { background-position: bottom center; }
.image.cover.bottom-right { background-position: bottom right; }

@media (max-width: 480px) {
    .layout{
        max-width: 100%;
    }

    .layout.homepage{
        padding-left: 16px;
        padding-right: 16px;
    }
}
