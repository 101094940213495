/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-heading {
    margin: 0;
}
.simplicity-heading h1,h2,h3, h4{
    margin-top: 0;
    font-weight: 900;
    color: #2d2d2d;
}
.simplicity-heading h1 {
    font-size: 32px;
    line-height: 40px;
}
.simplicity-heading h2 {
    font-size: 25px;
    line-height: 31px;
}
.simplicity-heading h3 {
    font-size: 20px;
    line-height: 25px;
}
.simplicity-heading h4 {
    font-size: 17px;
    line-height: 22px;
}
