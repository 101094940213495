/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.layout.custom-app {
    background-color: var(--color-white);
    padding-right:80px;
    padding-left: 80px;
    padding-top: 48px;
    padding-bottom: 48px;
}
.layout.custom-app .main-content .content  p {
    font-size: 1.0625rem;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 1em;
    max-width: none;
}
