/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

:root {
    /* dimensions */
    --desktop-width: 1440px;
    --tablet-width: 768px;

    /* container padding */
    /*--container-padding-x: 50px;*/

    /* fonts */
    --font-family-paragraph: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
    --font-family-heading: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;
    --font-family-links-labels: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;
    --font-family-forms-helper: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;
    /* forms was using a different font*/
    /*--font-family-forms-helper: "Avenir LT W01_35 Light1475496","Helvetica Neue", Arial, sans-serif;*/
    --font-font-aweseome: "Font Awesome 5 Pro";

    --font-size-small: 12px;
    --font-size-medium-small: 16px;
    --font-size-medium: 17px;
    --font-size-medium-large: 20px;
    --font-size-large: 24px;
    --font-size-extra-large: 26px;

    /*--font-size-small: 12px;*/
    /*--font-size-medium-small: 16px;*/
    /*--font-size-medium: 17px;*/
    /*--font-size-medium-large: 20px;*/
    /*--font-size-large: 24px;*/
    /*--font-size-extra-large: 26px;*/

    /* --------------- colors -------------- */
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-disabled: #757575;

    /* Text */
    --color-dnv-text-black: #000000;
    --color-dnv-text-white: #FFFFFF;
    /* Brand */
    --color-dnv-brand-black: #000000;
    --color-dnv-brand-dark-navy: #003E43;
    --color-dnv-brand-navy: #004F59;
    --color-dnv-brand-blue: #0873A1;
    --color-dnv-brand-green: #3D7737;
    --color-dnv-brand-grey: #919191;
    /* Interactive */
    --color-dnv-interactive-dark-blue: #06577A;
    --color-dnv-interactive-blue: #0873A1;
    --color-dnv-interactive-pale-blue: #F3F8FA;
    --color-dnv-interactive-white: #FFFFFF;
    --color-dnv-interactive-gold: #BBA600;
    /* Hover */
    --color-dnv-hover-dark-green: #325A2A;
    --color-dnv-hover-green: #3D7737;
    --color-dnv-hover-pale-green: #ECF1EB;
    --color-dnv-hover-bright-yellow: #E6D022;
    /* Active */
    --color-dnv-active-black: #000000;
    --color-dnv-active-dark-yellow: #A68608;
    --color-dnv-active-bright-yellow: #E6D022;
    --color-dnv-active-bright-blue: #88D1E7;
    /* Focus */
    --color-dnv-focus-dark-green: #44883E;
    --color-dnv-focus-green: #3D7737;
    --color-dnv-focus-white: #FFFFFF;
    /* Backgrounds and Borders */
    --color-dnv-bg-and-border-white: #FFFFFF;
    --color-dnv-bg-and-border-dark-navy: #003E43;
    --color-dnv-bg-and-border-navy: #004F59;
    --color-dnv-bg-and-border-grey: #919191;
    --color-dnv-bg-and-border-muted-blue: #ACCDD2;
    --color-dnv-bg-and-border-muted-orange: #EFBE7D;
    --color-dnv-bg-and-border-tan: #E8E8E0;
    --color-dnv-bg-and-border-beige: #F4F4EC;
    --color-dnv-bg-and-border-pale-tan: #EFEFEB;
    --color-dnv-bg-and-border-pale-grey: #FAFAF9;
    --color-dnv-bg-and-border-beige: #EEE2CC;
    --color-dnv-top-bar-border-light-blue: #6399AE;
    --color-dnv-advisory-border-light-grey: #E5E5E5;
    /* Messaging */
    --color-dnv-messaging-black: #000000;
    --color-dnv-messaging-green: #3D7737;
    --color-dnv-messaging-dark-yellow: #A68608;
    --color-dnv-messaging-red: #B50909;
    --color-dnv-messaging-pale-red: #F9E5E5;
    --color-dnv-messaging-yellow: #f3d54e;

    /* -- Existing older colours -- */
    --color-search-btn-bg-color: #efbe7d;
    --color-background-main: #f0f0eb;
    --color-background-menu-level-1-selected:#AACCD2;
    --color-background-menu-level-2: #EFEFEB;
    --color-footer-white: #e8e8e1;
    --color-hover-blue: #1b8898;
    --color-link-blue: #1b8898;
    --color-link-bright-blue: #0980B5;
    --color-link-sidebar-grey: #575757;
    --color-side-nav-border-bottom: #D3D3C8;
    --color-submenu-trigger:#575757;
    --color-border-grey: #D3D3C8;
    --color-mobile-menu-background: #edede7;

    --color-divider: #C5C5C1;
    --color-panel-background: #dbdbd2;
    --color-panel-border: #b8b8b0;
    --color-radio-disabled: #c4c4c4;
    --color-autosuggest-boxshadow: #b7b7a9;
    --color-tooltip-title: #333333;

    /*spacing*/
    --spacing-default-component-margin-bottom: 1.5rem;
    --spacing-extra-small-margin-bottom: 0.5rem;
    --spacing-small-margin-bottom: 1rem;

    --breakpoint-x-small: 480px;
    --breakpoint-small: 600px;
    --breakpoint-medium: 768px;
    --breakpoint-large: 1000px;
    --breakpoint-x-large: 1200px;

    --cal-event-text-size: 14px;
    --cal-event-text-weigth: 600;
    --cal-event-text-color: #222222E9;
    --cal-event-background: #e3e3e3;
}
