/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.container {

}

.container p {
    font-weight: bold;
    margin-bottom: 8px;
}

/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .container {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .container p {
        font-weight: normal;
        margin: 0;
    }
}
