/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.mobile-backdrop {
    position: absolute;
    height:100%;
    width: 100%;
    background-color: var(--color-black);
    z-index: 11;
    opacity: 0.75;
    top: 0px;
}

.mobile-menu {
    width: 100%;
    height: 0;
    overflow: hidden;
    display: block;
    z-index: 99999999999;
}

/** matches an active menu item */
.mobile-menu .menu-item a.active {
    font-weight: bold;
}

.mobile-menu .menu-item span.active-icon {
    margin: 3px 0px 0px 0px;
}

.mobile-menu .menu-item span.active-icon svg{
    color: var(--color-dnv-focus-green);
}

.mobile-menu .menu-item a{
    font-family: var(--font-family-paragraph);
}

.mobile-menu.open .menu-item a:hover {
    color: var(--color-dnv-interactive-dark-blue);
    text-decoration: underline;
}

.mobile-menu.open {
    height:auto;
    z-index: 12;
    position: absolute;
    background-color: var(--color-white);
}

/* if mobile menu open, 'search' needs to be behind overlay */
.mobile-menu.open + .top-bar .top-bar-content .quick-search-container{
    z-index: 0;
}

/*.mobile-menu.open:after{*/
/*    content: '';*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    background-color: rgba(0,0,0,0.75);*/
/*    z-index: 11; !* need this for landing pages *!*/
/*}*/

.mobile-menu-content {
    width: 100%;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out;
    position: relative;
    /* z-index: 2; */
    z-index: 12;
}

.mobile-menu.open .mobile-menu-content {
    max-height: 2000px;
    transition: max-height 1s ease-in;
}

.mobile-menu.open .mobile-menu-content a {
    color: var(--color-black);
    text-decoration: none;
}

.mobile-menu.open .mobile-menu-content svg {
    color: var(--color-dnv-interactive-blue);
    width: 18px;
    height: 18px;
}

.mobile-menu .menu-item {
    display: flex;
    justify-content: space-between;
    padding-right: 1em;
    padding-top: 11px;
    padding-bottom: 12px;
}

.mobile-menu .menu-item.active-parent {
    font-weight: 800;
}

.mobile-menu .menu-item.active {
    justify-content: flex-start;
}

.mobile-menu .menu-item .menu-item-text {
    line-height: 26px;
}

.mobile-menu .has-children .menu-item {
    padding-top: 10px;
    padding-bottom: 12px;
    padding-right: 16px;
}

.mobile-menu .menu-item button {
    background-color: inherit;
    border: none;
    border-radius: 50%;
    padding: 4px;
    height: 26px;
    width: 26px;
    margin-left: 4px;
}

.mobile-menu .menu-item button:hover {
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-dnv-focus-green);
}

.mobile-menu .menu-item button:hover svg {
    color: var(--color-white);
}

.mobile-menu .menu-items>li .menu-item {
    min-height: 48px;
}

.mobile-menu .menu-items.level-1>li>div.menu-item {
    padding-left: 16px;
    /* background-color: var(--color-dnv-bg-and-border-pale-grey); */
    border: solid 1px transparent;
    border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
    min-height: 48px;
}

.mobile-menu .menu-items.level-1>li>.menu-item:hover{
    border: solid 1px var(--color-dnv-top-bar-border-light-blue);
}
.mobile-menu .menu-items.level-1>li>.menu-item a {
    font-family: var(--font-family-paragraph);
    font-weight: 800;
}

.mobile-menu .menu-items.level-3.open>li>.menu-item {
    border: solid 1px transparent;
}

.mobile-menu .menu-items.level-3.open>li>.menu-item.open {
    font-weight: 800;
    border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
}

.mobile-menu .menu-items.level-3.open>li>.menu-item.open:hover {
    border-color: var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);

}

.mobile-menu .menu-items.level-3.open>li>.menu-item.open:hover a{
    color: var(--color-dnv-interactive-blue);
}

.mobile-menu .menu-items.level-3.open>li>.menu-item.active {
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
}

.mobile-menu .menu-items.level-3.open>li>.menu-item.active {
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);

}

.mobile-menu .menu-items.level-3>li>.menu-item.open.active a {
    font-weight: 800;
}

.mobile-menu .menu-items.level-3>li>.menu-item.active button {
    position: absolute;
    right: 16px;
}

/* hide icons when higher levels are closed*/
.mobile-menu .menu-items.level-3:not(.open)>li>.menu-item.active button{
    position: static;
}

.mobile-menu .menu-items.level-1>li>.menu-item.active {
    background-color: var(--color-dnv-bg-and-border-muted-blue);
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
    padding-left: 16px;
}

.mobile-menu .menu-items.level-1>li:first-child>.menu-item.active{
    border-top-color: transparent;
}

.mobile-menu .menu-items.level-1>li>.menu-item.active .active-icon{
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu .menu-items.level-2>li>.menu-item.active .menu-item-text,
.mobile-menu .menu-items.level-1>li>.menu-item.active .menu-item-text {
    margin-left: 8px;
}

.mobile-menu .menu-items.level-1>li>.menu-item.active button {
    position: absolute;
    right: 16px;
}
/*.mobile-menu .menu-items.level-1 div.menu-item.open.active button{*/
/*    background-color: var(--color-dnv-focus-green);*/
/*}*/

/*.mobile-menu .menu-items.level-1 div.menu-item.open.active button svg{*/
/*    color: var(--color-white);*/
/*}*/

.mobile-menu .menu-items.level-2 {
    /* margin-left: 16px; */
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.mobile-menu .menu-items.level-2>.menu-item>.menu-item-text>a {
    font-weight: 800;
}

.mobile-menu .menu-items.level-2.open {
    max-height: 1000px;
    transition: all 0.3s ease-in;
    /*border-top: solid 1px var(--color-dnv-advisory-border-light-grey);*/
    /*border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);*/
}

.mobile-menu .menu-items.level-2>li {
    border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}

.mobile-menu .menu-items.level-2>li>.menu-item {
    padding-left: 38px;
    border: solid 1px transparent;
}

.mobile-menu .menu-items.level-3>li>.menu-item:hover,
.mobile-menu .menu-items.level-2>li>.menu-item:hover {
    border: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.mobile-menu .menu-items.level-2>li>.menu-item:hover a{
    color: var(--color-dnv-interactive-dark-blue);
}
.mobile-menu .menu-items.level-2>li>.menu-item a{
    font-weight: 800;
}

.mobile-menu .menu-items.level-2.open>li>.menu-item{
    padding-left: 38px;
}

.mobile-menu .menu-items.level-2.open>li>.menu-item.active-parent,
.mobile-menu .menu-items.level-2.open>li>.menu-item.open {
    background-color: var(--color-dnv-bg-and-border-muted-blue);
    font-weight: 800;
}

.mobile-menu .menu-items.level-2.open>li>.menu-item.open.active {
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
    font-weight: 800;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    padding-left: 16px;
}

.mobile-menu .menu-items.level-2.open>li>.menu-item.open.active:hover {
    border-left:solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-right: solid 1px var(--color-dnv-top-bar-border-light-blue);
    cursor: pointer;
}

/* .mobile-menu .menu-items.level-2.open>li>.menu-item.active {
    padding-left: 0px;
} */

.mobile-menu .menu-items.level-2.open>li>.menu-item.active .active-icon {
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu .menu-items.level-2.open>li>.menu-item.active button {
    position: absolute;
    right: 16px;
}

/* .mobile-menu .menu-items.level-2 div.menu-item {
    background-color: white;
    margin-left: 30px;
    padding-left:0;
} */

.mobile-menu .menu-items.level-3 {
    /* margin-left: 16px; */
    /* border-bottom: 1px solid var(--color-side-nav-border-bottom); */
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.mobile-menu .menu-items.level-3.open>li {
     border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
     border-top: 1px solid transparent;
}

.mobile-menu .menu-items.level-3.open>li:last-child {
    border-bottom: none;
}

.mobile-menu .menu-items.level-3.open {
    max-height: 1000px;
    transition: all 0.3s ease-in;
    margin: 0;
    background-color: var(--color-background-menu-level-2);
}

.mobile-menu .menu-items.level-3>li>.menu-item>.menu-item-text {
    margin-left: 64px;
    border-bottom: none;
}

.mobile-menu .menu-items.level-3>li>.menu-item.active>.menu-item-text {
    margin-left: 12px;
}

.mobile-menu .menu-items.level-3>li>.menu-item.open.active>.menu-item-text>a {
    font-weight: 800;
}

.mobile-menu .menu-items.level-3>li>.menu-item>.menu-item-text>a:hover {
    color: var(--color-dnv-interactive-blue);
}

.mobile-menu .menu-items.level-4 {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
}

.mobile-menu .menu-items.level-4.open li {
    border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
}
.mobile-menu .menu-items.level-4.open .menu-item.open.active {
    border-top: solid 1px var(--color-dnv-top-bar-border-light-blue);
    border-bottom: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.mobile-menu .menu-items.level-4.open .menu-item.open.active .active-icon {
    margin-left: 0;
}

.mobile-menu .menu-items.level-4 div.menu-item {
    padding-left: 80px;
    border: solid 1px transparent;
}

.mobile-menu .menu-items.level-4 div.menu-item.active{
    padding-left: 46px;
}

.mobile-menu .menu-items.level-4 div.menu-item:hover {
    border-color: var(--color-dnv-top-bar-border-light-blue);
    border-left: solid 1px var(--color-dnv-top-bar-border-light-blue);
}

.mobile-menu .menu-items.level-4 div.menu-item a:hover,
.mobile-menu .menu-items.level-4 div.menu-item:hover a{
    color: var(--color-dnv-interactive-blue);
}

.mobile-menu .menu-items.level-4 div.menu-item .menu-item-text {
    margin-left: -5px;
}

.mobile-menu .menu-items.level-4 div.menu-item .active-icon + .menu-item-text {
    margin-left: 12px;
}

.mobile-menu .menu-items.level-4.open {
    max-height: 1000px;
    transition: all 0.3s ease-in;
}

@media only screen and (min-width: 1200px) {
    .mobile-menu {
        display: none;
    }
}
