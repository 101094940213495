/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
@import "../_variables.css";

.top-bar {
    background-color: var(--color-white);
    font-size: var(--font-size-medium);
    padding: 19px 32px;
    border-bottom: solid 2px var(--color-dnv-top-bar-border-light-blue);
    display: flex;
    justify-content: space-between;
}

.top-bar-content-wrapper{
    width: 100%;
    max-width: 1440px;
    justify-content: space-between;
    margin: 0px auto;
    display: flex;
}


.top-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 20px;
    /* margin: auto; */
}

.top-bar-content > ul {
    display: flex;
    gap: 24px;
    margin-left: 20px;
}


.top-bar-content > ul a {
    font-family: var(--font-family-links-labels);
    color: var(--color-dnv-interactive-blue);
    text-decoration: none;
    font-weight: 800;
    border: solid 2px var(--color-dnv-interactive-blue);
    padding: 12px 14px;
    white-space: nowrap;
}

.top-bar-content > ul a:hover {
    color: var(--color-white);
    background-color: var(--color-dnv-hover-green);
    cursor: pointer;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.51);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.51);
    border-color: var(--color-dnv-hover-green);
}

@media screen and (max-width: 1124px){
    .top-bar{
        padding: 0;
        width: 100%;
    }
    .top-bar-content{
        width: 100%;
        padding: 20px;
        height: 88px;
        /* position: relative;
        z-index: 0; */
    }

    .top-bar-content > ul{
        margin-left: 0;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1124px){
    .top-bar-content{
        padding: 0px 32px;
    }
}

@media screen and (min-width: 481px) {
    .top-bar-content {
        max-width: 100%;
        justify-content: space-between;
        gap: 24px;
    }

    .top-bar-content > ul {
        gap: 24px;
    }

     .top-bar-content > ul a {
        display: inline-block;
        padding: 9px 14px;
        border: solid 2px var(--color-dnv-interactive-blue);
        height: 48px;
    }
}

@media screen and (max-width: 480px) {
    .top-bar-content{
        padding: 0px 16px;
    }

    .top-bar-content > ul {
        margin-top: 1px
    }
}

@media only screen and (max-width: 360px){
    .top-bar-content > ul{
        gap: 12px;
        margin-right: 12px;
    }
}
