/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.document-list-container {
    /*margin-bottom: 16px;*/
    font-family: var(--font-family-paragraph);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    text-align: left;
}
.document-list-container .document-list-accordion-root {
    border-radius: 0 !important;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 0;
}
.document-list-container .document-list-accordion-summary {}
.document-list-container .document-list-accordion-summary .expanded {}
.document-list-container .document-list-accordion-summary-icon {
    font-size: 1.75rem;
    color: var(--color-dnv-interactive-blue)
}
.document-list-container .list-heading {
    font-family: var(--font-family-heading);
    font-size: 1.4rem;
    font-weight: 900 !important;
    line-height: 26px;
    text-align: left;
    padding-bottom: 12px;
    padding-top: 12px;
}
.document-list-container .list-heading.bullet-list {}
.document-list-container .list-heading.list {}
.document-list-container .list-heading.accordion {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-heading);
    font-size: 1.3rem;
    font-weight: 900;
    text-align: left;
    color: var(--color-dnv-interactive-blue);
    padding: 0;
}
.document-list-container .list-heading.accordion.expanded {
    border-bottom: 0;
}
.document-list-container .document-list-accordion-details {
    padding: 0;
    margin: 0;
}
.document-list-container .document-list-accordion-list {
    margin-bottom: 24px;
    padding: 0 24px 0 24px;
}
.document-list-container .document-list-accordion-list .list-item {
    margin-top: 8px;
    margin-bottom: 8px;
}
.document-list-container ul.bullet-list li {
    list-style: disc inside;
}
.document-list-container ul.list li {
    list-style: none;
}
.document-list-container li.list-item {
    display: table
}
.list-item {}
.document-list-accordion-row {}
.document-list-container li::before {
    content: "";
    display: inline-block;
    background: #000000;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    line-height: 1rem;
    margin: 12px 12px 0 8px;
    align-items: center;
}
.document-list-container ul.bullet-list li {
    list-style: disc inside;
}
.document-list-container ul.list li {
    list-style: none;
}
.document-list-container li.list-item {
    display: table
}
.document-list-container .list-item a {
    color: #0E7490;
}
.display-doc {
    margin: 0;
    display: inline-block;
    color: #0E7490;
    font-size: 1rem;
}
.display-as.text_link_icon {}
.display-as.text_link {}
.display-as.icon_only {}
.document-file-size {
    margin-left: 8px;
    font-size: 1rem;
    color: #000000;
}
.display-as-link {
    /*border: 1px solid red;*/
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration-line: underline;
    line-height: 1.4rem;
}
.display-as-link .icon {
    position: relative;
    top: 0.15em;
    margin-right: 8px;
    text-decoration-line: underline;
}
.list-item-frame {
    display: inline-block;
    vertical-align: middle;
}
.document-text {
    padding: 6px 0 6px 0;
    line-height: 1.3rem;
    vertical-align: middle;
    display: inline-block
}
.document-list-container li.list-item .document-file-size {
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 1rem;
    color: #666666;
    line-height: 1.3rem;
    vertical-align: middle;
    display: inline-block
}
.document-list-container div.list-item .document-file-size {
    margin-left: 8px;
    margin-bottom: 4px;
    padding: 8px 0 8px 0;
    font-size: 1rem;
    color: #666666;
    line-height: 1.3rem;
    vertical-align: middle;
    display: inline-block
}
.document-file-size-line {
    padding: 6px 0 6px 0;
    color: #666666;
}
