body {
  font-size: 1.0625rem;
}

.layout .visually-hidden,
.layout .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
}
.layout h1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 32px;
  font-weight: 700;
  line-height: 1.24;
}
.layout h2 {
  margin-top: 0;
  margin-bottom: 21px;
  font-weight: 700;
  line-height: 1.24;
}
.layout h2.sm {
  margin-bottom: 10px;
}
.layout h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.24;
  margin-top: 0;
  margin-bottom: 8px;
}
.layout .content p,
.layout .content ol,
.layout .content ul {
  margin: 0 0 24px 0;
}
.layout p {
  line-height: 1.53;
  margin-top: 0;
}
.layout p.big {
  font-size: 1.375rem;
}
.layout ul,
.layout ol {
  padding-left: 5px;
  margin-top: -10px;
}
.layout ul ul,
.layout ul ol,
.layout ol ul,
.layout ol ol {
  padding-left: 18px;
  margin-top: 8px;
}
.layout .navigation-list-body ul,
.layout .navigation-list-body ol {
  padding-left: 0px;
}
.layout .navigation-list-body ul li a,
.layout .navigation-list-body ol li a {
  text-decoration: underline;
}
.layout ul li,
.layout ol li {
  margin-bottom: 8px;
  line-height: 1.53;
}
.layout a:not(:where(.button, .btn)) {
  font-weight: 700;
  text-decoration: underline;
  color: var(--color-dnv-brand-blue);
  transition: color 0.2s ease-in-out;
  text-decoration-color: var(--color-dnv-brand-blue);
}
.layout a:not(:where(.button, .btn)):hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
}
.layout a:not(:where(.button, .btn)):hover:focus {
  outline: none;
}
.layout a:not(:where(.button, .btn)):focus {
  outline: 2px dashed var(--color-dnv-brand-green);
  outline-offset: 2px;
}
.layout a:not(:where(.button, .btn)):active {
  color: var(--color-black);
}
.layout a:not(:where(.button, .btn)):active:focus {
  outline: none;
}
.layout a:not(:where(.button, .btn)).link--inline {
  text-decoration: underline;
}
.layout a:not(:where(.button, .btn)).link--icon {
  position: relative;
  padding-left: 28px;
}
.layout a:not(:where(.button, .btn)).link--icon *[class*=fa-] {
  font-size: 18px;
  margin-left: 10px;
}
.layout a:not(:where(.button, .btn)).link--icon--left {
  padding-left: 0;
  padding-right: 28px;
}
.layout a:not(:where(.button, .btn)).link--icon--left *[class*=fa-] {
  left: auto;
  right: 0;
}
.layout a:not(:where(.button, .btn)).link--dark-blue {
  color: var(--color-white);
  text-decoration: underline;
}
.layout a:not(:where(.button, .btn)).link--dark-blue:hover {
  color: var(--color-dnv-hover-bright-yellow);
}
.layout a:not(:where(.button, .btn)).link--dark-blue:focus {
  outline-color: var(--color-white);
}
.layout a:not(:where(.button, .btn)).link--dark-blue:active {
  color: var(--color-dnv-active-bright-blue);
}
.layout a:not(:where(.button, .btn)).link--orange {
  color: var(--color-dnv-interactive-dark-blue);
  text-decoration: underline;
}
.layout a:not(:where(.button, .btn)).link--orange:hover {
  color: var(--color-dnv-hover-dark-green);
}
.layout a:not(:where(.button, .btn)).link--orange:focus {
  outline-color: var(--color-dnv-hover-dark-green);
}
.layout a:not(:where(.button, .btn)).link--orange:active {
  color: var(--color-black);
}
.layout a:not(:where(.button, .btn)).link--light-blue {
  color: var(--color-dnv-interactive-dark-blue);
  text-decoration: underline;
}
.layout a:not(:where(.button, .btn)).link--light-blue:hover {
  color: var(--color-dnv-hover-dark-green);
}
.layout a:not(:where(.button, .btn)).link--light-blue:focus {
  outline-color: var(--color-dnv-hover-dark-green);
}
.layout a:not(:where(.button, .btn)).link--light-blue:active {
  color: var(--color-black);
}
.layout a:not(:where(.button, .btn)).link--light-blue.link--quicklink {
  padding-left: 43px;
}
.layout a:not(:where(.button, .btn)).link--light-blue.link--quicklink *[class*=fa-] {
  font-size: 26px;
}
.layout .container {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1340px;
}

.layout .content {
  max-width: 1920px;
  margin: 0 auto;
}
.layout .content:has(> aside) {
  display: flex;
}
.layout .content:has(> aside) main {
  padding: 52px 4.2vw;
  width: 100%;
}
.layout .content main {
  padding: 52px 20px;
  margin: 0 auto;
  max-width: 1340px;
}
.layout .content aside {
  background-color: var(--color-dnv-bg-and-border-beige);
  padding: 43px 2.15vw 43px 9.25vw;
  flex-basis: 27.66%;
}
@media only screen and (max-width: 64rem) {
  .layout .content aside {
    display: none;
  }
}
.layout .content__row {
  margin-bottom: 48px;
}
.layout .content__row > *:last-child {
  margin: 0;
}
.layout .content__row:last-of-type {
  margin-bottom: 0;
}

.header {
  background-color: var(--color-white);
}
.header__top {
  background-color: var(--color-dnv-bg-and-border-dark-navy);
}
.header__top > .container {
  display: flex;
  justify-content: flex-end;
  gap: 2.6vw;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 64rem) {
  .header__top > .container {
    justify-content: space-between;
    padding-right: 0;
    min-height: 48px;
  }
  .header__top > .container .search__mobile-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.header__top-menu nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 2.6vw;
}
@media only screen and (max-width: 48rem) {
  .header__top-menu nav ul {
    gap: 24px;
  }
}
.header__top-menu nav ul > li {
  margin: 0;
  padding: 0;
}
.header__top-menu nav ul > li > a {
  color: var(--color-white);
  text-decoration: underline;
}
.header__top-menu nav ul > li > a:hover {
  color: var(--color-dnv-hover-bright-yellow);
}
.header__top-menu nav ul > li > a:focus {
  outline-color: var(--color-white);
}
.header__top-menu nav ul > li > a:active {
  color: var(--color-dnv-active-bright-blue);
}
.header__main > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}
@media only screen and (max-width: 64rem) {
  .header__main > .container {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.header__logo img {
  display: block;
  width: 180px;
}
@media only screen and (max-width: 64rem) {
  .header__logo img {
    width: 102px;
  }
}
.header__main-menu {
  padding: 28px 0 29px;
}
@media only screen and (max-width: 64rem) {
  .header__main-menu {
    display: none;
  }
}
.header__main-menu nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 3.65vw;
}
@media only screen and (max-width: 48rem) {
  .header__main-menu nav ul {
    gap: 24px;
  }
}
.header__main-menu nav ul > li {
  margin: 0;
  padding: 0;
  line-height: 1.353;
}
.header__main-menu nav ul > li > a {
  color: var(--color-white);
  position: relative;
}
.header__main-menu nav ul > li > a:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}
.header__main-menu nav ul > li > a.active:after {
  background-color: var(--color-dnv-hover-bright-yellow);
  height: 3px;
}
.header__main-menu nav ul > li > a:hover {
  color: var(--color-dnv-hover-bright-yellow);
  text-decoration: none;
}
.header__main-menu nav ul > li > a:hover:after {
  background-color: var(--color-dnv-hover-bright-yellow);
  height: 3px;
}
.header__main-menu nav ul > li > a:focus {
  outline-color: var(--color-white);
}
.header__main-menu nav ul > li > a:active {
  color: var(--color-dnv-active-bright-blue);
}
.header__main-menu nav ul > li > a:active:after {
  background-color: var(--color-dnv-active-bright-blue);
}
.header__menu-toggle {
  display: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: var(--color-white);
  padding: 13px 40px 13px 14px;
  position: relative;
  border: 2px solid var(--color-dnv-active-bright-blue);
}
.header__menu-toggle:after {
  font-family: "Font Awesome 6 Pro";
  content: "\f0c9";
  font-size: 18px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 14px;
}
@media only screen and (max-width: 64rem) {
  .header__menu-toggle {
    display: block;
  }
}

.layout input[type=text],
.layout input[type=email],
.layout input[type=password],
.layout input[type=decimal],
.layout input[type=tel],
.layout input[type=date],
.layout input[type=search],
.layout input[type=time],
.layout input[type=number],
.layout input[type=url],
.layout select,
.layout textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  max-width: 100%;
  background: var(--color-white);
  border: 1px solid var(--color-dnv-bg-and-border-grey);
  border-radius: 3px;
  padding: 13px 16px;
  line-height: 1.175;
  box-sizing: border-box;
  width: 100%;
}
.layout input[type=text].error,
.layout input[type=email].error,
.layout input[type=password].error,
.layout input[type=decimal].error,
.layout input[type=tel].error,
.layout input[type=date].error,
.layout input[type=search].error,
.layout input[type=time].error,
.layout input[type=number].error,
.layout input[type=url].error,
.layout select.error,
.layout textarea.error {
  border-color: var(--color-dnv-messaging-red);
  outline: 1px solid var(--color-dnv-messaging-red);
}
.layout input[type=text]:focus,
.layout input[type=email]:focus,
.layout input[type=password]:focus,
.layout input[type=decimal]:focus,
.layout input[type=tel]:focus,
.layout input[type=date]:focus,
.layout input[type=search]:focus,
.layout input[type=time]:focus,
.layout input[type=number]:focus,
.layout input[type=url]:focus,
.layout select:focus,
.layout textarea:focus {
  border-color: var(--color-dnv-brand-green);
  outline: 1px solid var(--color-dnv-messaging-green);
}
.layout .form-item {
  width: -moz-fit-content;
  width: fit-content;
}
.layout .form-item__label {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.3;
}
.layout .form-item__help {
  margin-top: 10px;
  line-height: 1.53;
  font-style: italic;
}
.layout .form-item__error {
  background-color: var(--color-dnv-messaging-pale-red);
  padding: 12px 16px 7px 44px;
  margin-top: 1px;
  line-height: 1.53;
  position: relative;
}
.layout .form-item__error-wrapper {
  display: flex;
  flex-direction: column;
}
.layout .form-item__error:before {
  content: "\f071";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: var(--color-dnv-messaging-red);
  position: absolute;
  left: 16px;
  transform: translateY(-50%);
  top: 50%;
}
.layout .form-item__fieldset {
  border: 0;
  padding: 0;
}
.layout .form-item__fieldset .form-item {
  width: auto;
}
.layout .form-item__fieldset > legend {
  font-size: 1.5625rem;
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.24;
}
.layout .form-item__fieldset + .form-item__fieldset {
  margin-top: 64px;
}
.layout .form-item + .form-item {
  margin-top: 24px;
}
.layout .form-item__submit {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 30px;
}
.layout .form-item--flex {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;
}
.layout .form-item--flex input {
  max-width: 190px;
}
.layout .form-item--flex > .form-item + .form-item {
  margin-top: 0;
}
@media only screen and (max-width: 48rem) {
  .layout .form-item--flex {
    flex-direction: column;
  }
  .layout .form-item--flex input {
    max-width: 100%;
  }
}
.layout form ul {
  padding: 0 0 0 21px;
  margin: 0;
}

.layout .form-item__radios {
  max-width: 635px;
}
.layout .form-item__radios fieldset {
  padding: 0;
  border: 0;
}
.layout .form-item__radios fieldset legend {
  margin-bottom: 8px;
  font-weight: 700;
}
.layout .form-item__radios .radio + .radio {
  margin-top: 10px;
}
.layout .form-item__radios .form-item__error {
  margin-bottom: 16px;
}
.layout .form-item__radios .form-item__help {
  margin: 0 0 16px 0;
}
.layout input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 0;
}
.layout input[type=radio]:hover:focus, .layout input[type=radio]:active:focus {
  outline: none;
}
.layout input[type=radio] + label {
  padding-left: 26px;
  position: relative;
  display: block;
  line-height: 1.53;
}
.layout input[type=radio] + label:before {
  content: "\f111";
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 18px;
}
.layout input[type=radio]:focus + label:before {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 2px;
}
.layout input[type=radio]:checked + label:before {
  content: "\f192";
  font-weight: 700;
  color: var(--color-dnv-brand-green);
}
.layout input[type=radio][disabled] + label:before {
  cursor: not-allowed;
  font-weight: 700;
  color: var(--color-radio-disabled);
}
.layout input[type=radio].error + label:before {
  color: var(--color-dnv-messaging-red);
}

.layout select {
  background: url("./ui-includes/images/select.svg"), var(--color-white);
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  padding: 13px 44px 7px 16px;
  line-height: 1.53;
  min-width: 283px;
}

.layout .dnv-widget .form-item__checkboxes fieldset {
  padding: 0;
  border: 0;
}
.layout .dnv-widget .form-item__checkboxes fieldset legend {
  margin-bottom: 8px;
  font-weight: 700;
}
.layout .dnv-widget .form-item__checkboxes .checkbox + .checkbox {
  margin-top: 10px;
}
.layout .dnv-widget .form-item__checkboxes .form-item__error {
  margin-bottom: 16px;
}
.layout .dnv-widget .form-item__checkboxes .form-item__help {
  margin: 0 0 16px 0;
}
.layout .dnv-widget input[type=checkbox] {
  width: 18px;
  height: 18px;
  accent-color: #3d7737;
}
.layout .dnv-widget input[type=checkbox]:hover:focus, .layout .dnv-widget input[type=checkbox]:active:focus {
  outline: none;
}
.layout .dnv-widget input[type=checkbox] + label {
  padding-left: 8px;
  line-height: 1.53;
  margin-top: -3px;
}

.layout .form-item__file {
  max-width: 635px;
}
.layout .form-item__file .form-item__description {
  margin-bottom: 24px;
}
.layout .form-item__file .form-item__description p {
  margin: 0 0 8px 0;
}
.layout .form-item__file .form-item__description p.form-item__help {
  margin: 0 0 16px 0;
  font-style: normal;
}
.layout .form-item__file .form-item__error {
  margin-bottom: 16px;
}
@media only screen and (max-width: 48rem) {
  .layout .form-item__file .form-item__error {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 48rem) {
  .layout .form-item__file-input {
    position: unset;
    width: auto;
    height: auto;
    padding: unset;
    margin: unset;
    overflow: auto;
    -webkit-clip-path: unset;
            clip-path: unset;
    white-space: unset;
    border: unset;
  }
}
@media only screen and (min-width: 48rem) {
  .layout .form-item__file-input:focus {
    all: unset;
    outline: 2px dashed var(--color-dnv-focus-green);
  }
}
.layout .form-item__file-input::file-selector-button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  padding: 14.25px 20px;
  display: inline-block;
  background-color: var(--color-dnv-brand-blue);
  color: var(--color-white);
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  margin-right: 12px;
}
@media only screen and (max-width: 48rem) {
  .layout .form-item__file-input::file-selector-button {
    padding: 14.25px 16px;
  }
}
.layout .form-item__file-input::file-selector-button:hover {
  background-color: var(--color-dnv-brand-green);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
  cursor: pointer;
}
.layout .form-item__file-input::file-selector-button:hover:focus {
  outline: none;
}
.layout .form-item__file-input::file-selector-button:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .form-item__file-input::file-selector-button:active {
  background-color: var(--color-black);
}
.layout .form-item__file .file-drop {
  border: 2px dashed var(--color-dnv-brand-blue);
  background-color: var(--color-dnv-interactive-pale-blue);
  padding: 15px 30px 15px 77px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .form-item__file .file-drop {
    display: none;
  }
}
.layout .form-item__file .file-drop:hover, .layout .form-item__file .file-drop.hover {
  background-color: var(--color-dnv-hover-pale-green);
  border-color: var(--color-dnv-brand-green);
}
.layout .form-item__file .file-drop:before {
  font-family: "Font Awesome 6 Pro";
  content: "\f0ee";
  font-weight: 700;
  position: absolute;
  left: 30px;
  transform: translateY(-50%);
  top: 50%;
  font-size: 25px;
}
.layout .form-item__file .file-drop__text {
  margin-right: 14px;
  font-weight: 700;
}
.layout .form-item__file .file-list {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.layout .form-item__file .file-list:empty {
  display: none;
}
.layout .form-item__file .file-list__item-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
.layout .form-item__file .file-list__item-remove, .layout .form-item__file .file-list__item-cancel {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  position: relative;
  padding-left: 22px;
  color: var(--color-dnv-brand-blue);
  font-weight: 700;
  transition: color 0.2s ease-in-out;
}
.layout .form-item__file .file-list__item-remove:before, .layout .form-item__file .file-list__item-cancel:before {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  content: "\f00d";
  font-family: "Font Awesome 6 Pro";
  font-size: 18px;
}
.layout .form-item__file .file-list__item-remove:hover, .layout .form-item__file .file-list__item-cancel:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
}
.layout .form-item__file .file-list__item-remove:hover:focus, .layout .form-item__file .file-list__item-cancel:hover:focus {
  outline: none;
}
.layout .form-item__file .file-list__item-remove:focus, .layout .form-item__file .file-list__item-cancel:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
}
.layout .form-item__file .file-list__item-remove:active, .layout .form-item__file .file-list__item-cancel:active {
  color: var(--color-black);
}
.layout .form-item__file .file-list__item-progressbar {
  height: 100%;
  background-color: var(--color-dnv-brand-green);
  border-radius: 100px;
  width: 0%;
  transition: 0.2s ease-in-out width;
}
.layout .form-item__file .file-list__item-progressbar-wrapper {
  background-color: var(--color-dnv-bg-and-border-tan);
  width: 100%;
  height: 8px;
  border-radius: 100px;
}
.layout .form-item__file .file-list__item-progressbar-cancel-wrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 64px;
}
.layout .form-item__file .file-list__item-progressbar-cancel-wrapper.done {
  display: none;
}

.layout input[type=date] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.layout .search {
  display: flex;
  max-width: 338px;
  position: relative;
}
@media only screen and (max-width: 64rem) {
  .layout .search {
    display: none;
  }
}
.layout .search input[type=search] {
  border-radius: 0;
  height: 50px;
}
.layout .search input[type=search]:focus {
  border-color: var(--color-dnv-brand-grey);
  outline-offset: -4px;
  outline-width: 2px;
}
.layout .search__submit {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  background-color: var(--color-dnv-bg-and-border-muted-orange);
  border: 2px solid var(--color-dnv-bg-and-border-muted-orange);
  color: var(--color-black);
  font-weight: 700;
  font-size: 18px;
  flex-shrink: 0;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.layout .search__submit:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  border-color: var(--color-white);
  cursor: pointer;
}
.layout .search__submit:hover:focus {
  outline: none;
}
.layout .search__submit:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: -4px;
}
.layout .search__submit:active {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-white);
}
.layout .search__submit:active:focus {
  outline: none;
}
.layout .search--mobile {
  display: none;
}
.layout .search--mobile.is-visible {
  display: flex;
}
.layout .search--mobile input[type=search] {
  height: 48px;
  min-width: calc(100vw - 130px);
}
.layout .search--mobile .search__submit {
  height: 48px;
  width: 48px;
  flex-shrink: 0;
}
.layout .search__mobile-wrapper {
  display: none;
  position: relative;
}
.layout .search__mobile-wrapper .autosuggest {
  max-width: unset;
  width: 100%;
}
@media only screen and (max-width: 64rem) {
  .layout .search__mobile-wrapper {
    display: flex;
  }
}
.layout .mobile-search-trigger {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  background-color: var(--color-dnv-bg-and-border-muted-orange);
  border: 2px solid var(--color-dnv-bg-and-border-muted-orange);
  color: var(--color-black);
  font-weight: 700;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: none;
  flex-shrink: 0;
}
@media only screen and (max-width: 64rem) {
  .layout .mobile-search-trigger {
    display: block;
  }
}
.layout .mobile-search-trigger[aria-expanded=true] {
  background-color: var(--color-dnv-brand-blue);
  border-color: var(--color-dnv-brand-blue);
  color: var(--color-white);
}
.layout .mobile-search-trigger[aria-expanded=true]:focus {
  outline-color: var(--color-white);
}
.layout .mobile-search-trigger:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  border-color: var(--color-white);
  cursor: pointer;
}
.layout .mobile-search-trigger:hover:focus {
  outline: none;
}
.layout .mobile-search-trigger:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: -4px;
}
.layout .mobile-search-trigger:active {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-white);
}
.layout .mobile-search-trigger:active:focus {
  outline: none;
}
.layout .page-search {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 30px;
  align-items: flex-end;
  position: relative;
}
@media only screen and (max-width: 48rem) {
  .layout .page-search {
    align-items: flex-start;
  }
  .layout .page-search > * {
    width: 100%;
  }
}
.layout .page-search:has(+ .dropdown-modal__filters-modal-wrapper) {
  margin-bottom: 24px;
}
@media only screen and (max-width: 48rem) {
  .layout .page-search:has(+ .dropdown-modal__filters-modal-wrapper) {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 48rem) {
  .layout .page-search {
    flex-direction: column;
  }
}
.layout .page-search__submit {
  align-self: flex-end;
}
@media only screen and (max-width: 48rem) {
  .layout .page-search__submit {
    align-self: flex-start;
    width: auto;
  }
}
.layout .page-search__results-count {
  margin-top: 16px;
  width: 100%;
}
.layout .page-search__active-filters {
  margin-top: 16px;
}
.layout .page-search__results {
  margin-top: 48px;
}
.layout .page-search .dropdown-modal__trigger {
  border-radius: 3px;
}
@media only screen and (max-width: 48rem) {
  .layout .page-search .dropdown-modal__trigger {
    width: 100%;
  }
}
.layout .search-results-item {
  margin-bottom: 32px;
  padding-bottom: 42px;
  border-bottom: 1px solid var(--color-dnv-bg-and-border-grey);
}
.layout .search-results-item__headings {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.layout .search-results-item__headings > * {
  margin: 0 !important;
}
.layout .search-results-item__subheading {
  font-weight: 700;
  margin-bottom: 8px;
}
.layout .search-results-item p.search-results-item__snippet {
  margin: 0 0 26px 0;
}

.layout .button {
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 14.25px 20px;
  display: inline-block;
  background-color: var(--color-dnv-brand-blue);
  color: var(--color-white);
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .button {
    padding: 14.25px 16px;
  }
}
.layout .button:hover {
  background-color: var(--color-dnv-brand-green);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
  cursor: pointer;
}
.layout .button:hover:focus {
  outline: none;
}
.layout .button:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .button:active {
  background-color: var(--color-black);
}
.layout .button:active:focus {
  outline: none;
}
.layout .button[disabled] {
  background-color: var(--color-radio-disabled);
  color: var(--color-black);
}
.layout .button--confirmation {
  background-color: var(--color-dnv-brand-green);
}
.layout .button--confirmation:hover {
  background-color: var(--color-dnv-brand-blue);
}
.layout .button--secondary {
  border: 2px solid var(--color-dnv-brand-blue);
  background-color: var(--color-white);
  color: var(--color-dnv-brand-blue);
  padding: 12.25px 20px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .button--secondary {
    padding: 12.25px 16px;
  }
}
.layout .button--secondary:hover {
  color: var(--color-white);
  border-color: var(--color-dnv-brand-green);
}
.layout .button--secondary:active {
  border-color: var(--color-black);
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .button--icon {
  position: relative;
  padding-right: 46px;
}
@media only screen and (max-width: 48rem) {
  .layout .button--icon {
    padding-right: 42px;
  }
}
.layout .button--icon span[class*=fa] {
  position: absolute;
  right: 20px;
  transform: translateY(-50%);
  top: 50%;
  font-size: 18px;
  font-family: "Font Awesome 6 Pro";
}
@media only screen and (max-width: 48rem) {
  .layout .button--icon span[class*=fa] {
    right: 16px;
  }
}
.layout .button--icon--left {
  padding-right: 20px;
  padding-left: 46px;
}
@media only screen and (max-width: 48rem) {
  .layout .button--icon--left {
    padding-left: 42px;
    padding-right: 16px;
  }
}
.layout .button--icon--left span[class*=fa] {
  right: auto;
  left: 20px;
}
@media only screen and (max-width: 48rem) {
  .layout .button--icon--left span[class*=fa] {
    left: 16px;
  }
}
.layout .button--tag {
  background-color: var(--color-dnv-bg-and-border-pale-tan);
  color: var(--color-dnv-brand-blue);
  padding-right: 42px;
  position: relative;
}
@media only screen and (max-width: 48rem) {
  .layout .button--tag {
    padding-right: 38px;
  }
}
.layout .button--tag svg {
  position: absolute;
  right: 20px;
}
.layout .button--tag:hover {
  background-color: var(--color-dnv-bg-and-border-pale-tan);
  box-shadow: none;
  color: var(--color-dnv-brand-green);
}
.layout .button--tag:active {
  color: var(--color-black);
}
.layout .button--ui {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-decoration: none;
  color: var(--color-dnv-brand-blue);
  background-color: transparent;
  transition: color 0.2s ease-in-out;
}
.layout .button--ui:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
}
.layout .button--ui:hover:focus {
  outline: none;
}
.layout .button--ui:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 2px;
}
.layout .button--ui:active {
  color: var(--color-black);
  background-color: transparent;
}
.layout .button--ui:active:focus {
  outline: none;
}

.layout table {
  width: 100%;
  margin-bottom: 32px;
  border-collapse: collapse;
}
.layout table:has(+ .pager) {
  margin-bottom: 24px;
}
.layout table .cell-popover {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.layout table .cell-popover .popover {
  margin-left: -3px;
}
.layout table.no-wrap th,
.layout table.no-wrap td {
  text-wrap: nowrap;
}
.layout table span[class*=fa-] {
  font-size: 18px;
}
.layout table span[class*=fa-].green {
  color: var(--color-dnv-brand-green);
}
.layout table span[class*=fa-].red {
  color: var(--color-dnv-messaging-red);
}
.layout table thead {
  border: 0;
  border-bottom: 2px solid var(--color-black);
}
.layout table tbody {
  border: 0;
}
.layout table tfoot {
  border: 0;
}
.layout table tr {
  border: 0;
  border-bottom: 1px solid var(--color-dnv-bg-and-border-grey);
}
.layout table tr:nth-child(even) {
  background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.layout table th {
  border: 0;
  padding: 15px 16px 11px 16px;
  text-align: left;
  font-weight: bold;
}
.layout table th[tabindex="0"] {
  color: var(--color-dnv-brand-blue);
  transition: color 0.2s ease-in-out;
  position: relative;
}
.layout table th[tabindex="0"]:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
  cursor: pointer;
}
.layout table th[tabindex="0"]:hover:after {
  color: var(--color-dnv-brand-green);
}
.layout table th[tabindex="0"]:hover:focus {
  outline: none;
}
.layout table th[tabindex="0"]:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: -3px;
}
.layout table th[tabindex="0"]:active {
  color: var(--color-black);
}
.layout table th[tabindex="0"]:active:after {
  color: var(--color-black);
}
.layout table th[aria-sort]:after {
  content: "\f2ec";
  font-family: "Font Awesome 6 Sharp";
  font-size: 12px;
  margin-left: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.layout table th[aria-sort=descending]:after {
  transform: rotate(180deg) translateY(50%);
}
.layout table td {
  border: 0;
  padding: 16px;
  line-height: 1.442;
}
.layout .scrollable {
  clear: both;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 32px;
}
.layout .scrollable:has(+ .pager) {
  margin-bottom: 24px;
}
.layout .scrollable table {
  margin: 0;
}
.layout .scrollable > div {
  overflow-x: auto;
}
.layout .scrollable > div::-webkit-scrollbar {
  height: 12px;
}
.layout .scrollable > div::-webkit-scrollbar-track {
  padding: 5px 0;
  background: var(--color-white);
}
.layout .scrollable > div::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-dnv-bg-and-border-grey);
}
.layout .scrollable > div:focus {
  outline: none;
}
.layout .scrollable.has-scroll > div:after {
  border-radius: 10px 0 0 10px/50% 0 0 50%;
  box-shadow: -5px 0 10px rgba(var(--color-black), 0.25);
  content: "";
  height: 100%;
  left: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 50px;
}
.layout .scrollable.has-scroll .no-wrap .cell-popover .popover {
  margin-left: 10px;
}
.layout .table-legend-results {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px 32px;
}
.layout .table-legend-results__legend {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 30px;
}
.layout .table-legend-results__legend-item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.layout .table-legend-results span[class*=fa-] {
  font-size: 18px;
}
.layout .table-legend-results span[class*=fa-].green {
  color: var(--color-dnv-brand-green);
}
.layout .table-legend-results span[class*=fa-].red {
  color: var(--color-dnv-messaging-red);
}

.layout .pager {
  margin-bottom: 32px;
}
.layout .pager__first, .layout .pager__prev, .layout .pager__last, .layout .pager__next {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: var(--color-dnv-brand-blue);
  position: relative;
}
.layout .pager__first:hover, .layout .pager__prev:hover, .layout .pager__last:hover, .layout .pager__next:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
}
.layout .pager__first:hover:focus, .layout .pager__prev:hover:focus, .layout .pager__last:hover:focus, .layout .pager__next:hover:focus {
  outline: none;
}
.layout .pager__first:focus, .layout .pager__prev:focus, .layout .pager__last:focus, .layout .pager__next:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .pager__first:active, .layout .pager__prev:active, .layout .pager__last:active, .layout .pager__next:active {
  color: var(--color-black);
}
.layout .pager__first[disabled], .layout .pager__prev[disabled], .layout .pager__last[disabled], .layout .pager__next[disabled] {
  color: var(--color-disabled);
  pointer-events: none;
}
.layout .pager__first > [class*=fa], .layout .pager__prev > [class*=fa], .layout .pager__last > [class*=fa], .layout .pager__next > [class*=fa] {
  font-size: 18px;
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 48rem) {
  .layout .pager__first > [class*=fa], .layout .pager__prev > [class*=fa], .layout .pager__last > [class*=fa], .layout .pager__next > [class*=fa] {
    position: unset;
  }
}
@media only screen and (max-width: 48rem) {
  .layout .pager__first--text, .layout .pager__prev--text, .layout .pager__last--text, .layout .pager__next--text {
    display: none;
  }
}
.layout .pager__first, .layout .pager__prev {
  padding-left: 24px;
  margin-right: 16px;
}
.layout .pager__first > [class*=fa], .layout .pager__prev > [class*=fa] {
  left: 0;
}
@media only screen and (max-width: 48rem) {
  .layout .pager__first, .layout .pager__prev {
    padding-left: 0;
    padding-bottom: 2px;
  }
  .layout .pager__first > [class*=fa], .layout .pager__prev > [class*=fa] {
    left: auto;
  }
}
.layout .pager__last, .layout .pager__next {
  padding-right: 24px;
  margin-left: 16px;
}
.layout .pager__last > [class*=fa], .layout .pager__next > [class*=fa] {
  right: 0;
}
@media only screen and (max-width: 48rem) {
  .layout .pager__last, .layout .pager__next {
    padding-right: 0;
    padding-bottom: 2px;
  }
  .layout .pager__last > [class*=fa], .layout .pager__next > [class*=fa] {
    right: auto;
  }
}
.layout .pager__ellipsis {
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  padding: 12.25px 0;
  width: 44px;
  font-weight: 700;
}
.layout .pager ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 16px 8px;
  flex-wrap: wrap;
}
.layout .pager ul li:not(.disabled) {
  display: flex;
  align-items: center;
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last) {
  padding: 12.25px 17px;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: var(--color-dnv-brand-blue);
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last):hover {
  background-color: var(--color-dnv-brand-green);
  text-decoration: underline;
  color: var(--color-white);
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last):hover:focus {
  outline: none;
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last):focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last):active {
  background-color: var(--color-black);
}
.layout .pager ul li:not(.disabled) a:not(.pager__first):not(.pager__prev):not(.pager__next):not(.pager__last)[aria-current] {
  background-color: var(--color-dnv-brand-blue);
  color: var(--color-white);
}

.layout .progress-bar__segments {
  list-style-type: none;
  padding-left: 0;
  counter-reset: segment-counter;
  display: flex;
  gap: 0 100px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 32px 0;
  flex-wrap: wrap;
  background-image: url("./ui-includes/images/progress-bar--desktop.svg");
  background-position-y: 0px;
  background-repeat: repeat;
}
@media only screen and (max-width: 48rem) {
  .layout .progress-bar__segments {
    margin-bottom: 26px;
    gap: 0px 50px;
    background-image: url("./ui-includes/images/progress-bar--mobile.svg");
  }
}
.layout .progress-bar__segment {
  counter-increment: segment-counter;
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-dnv-bg-and-border-grey);
  border-radius: 100%;
  position: relative;
  font-weight: 700;
  background-color: var(--color-white);
}
@media only screen and (max-width: 48rem) {
  .layout .progress-bar__segment {
    width: 26px;
    height: 26px;
  }
}
.layout .progress-bar__segment[aria-current=true] {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  border-color: var(--color-dnv-brand-green);
}
.layout .progress-bar__segment--complete {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
}
.layout .progress-bar__segment:before {
  content: counter(segment-counter);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.layout .progress-bar__header {
  font-weight: 700;
}
.layout .progress-bar__heading-counter {
  margin-bottom: 8px;
}

.breadcrumbs {
  margin-bottom: 12px;
}
.breadcrumbs > ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  gap: 10px 44px;
  flex-wrap: wrap;
  max-width: unset;
}
@media only screen and (max-width: 48rem) {
  .breadcrumbs > ul {
    gap: 10px 32px;
  }
}
.breadcrumbs > ul > li {
  margin: 0;
}
.breadcrumbs > ul > li:not(:last-child) {
  position: relative;
}
.breadcrumbs > ul > li:not(:last-child):after {
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  content: "\f054";
  left: calc(100% + 16.375px);
  color: var(--color-dnv-brand-grey);
  transform: translateY(-50%);
  top: 50%;
  font-size: 18px;
}
@media only screen and (max-width: 48rem) {
  .breadcrumbs > ul > li:not(:last-child):after {
    left: calc(100% + 10.375px);
  }
}

.layout .dialog__overlay {
  display: none;
  background: rgba(var(--color-black), 0.3);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  overflow: scroll;
  overflow-x: hidden;
  padding: 20px;
  position: fixed;
  z-index: 100;
  align-items: center;
  justify-content: center;
}
.layout .dialog__modal {
  box-shadow: 4px 4px 5px 0px rgba(var(--color-black), 0.1);
  background-color: var(--color-white);
  border-radius: 3px;
  padding: 45px 121px 45px 61px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 700px;
  position: relative;
  z-index: 101;
}
@media only screen and (max-width: 48rem) {
  .layout .dialog__modal {
    padding: 28px;
  }
}
.layout .dialog__close {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  color: var(--color-dnv-brand-blue);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.layout .dialog__close:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .dialog__close:hover:focus {
  outline: none;
}
.layout .dialog__close:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .dialog__close:active {
  background-color: var(--color-black);
  color: var(--color-white);
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .dialog__close:active:focus {
  outline: none;
}
.layout .dialog__title {
  margin-bottom: 10px;
  line-height: 1.25;
  font-weight: 700;
}

a.link--horizontal-menu {
  position: relative;
  padding-left: 30px;
  display: inline-block;
}
a.link--horizontal-menu:after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: var(--color-dnv-brand-grey);
  transition: background-color 0.2s ease-in-out;
}
a.link--horizontal-menu *[class*=fa-] {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
}
a.link--horizontal-menu--selected {
  color: var(--color-black);
}
a.link--horizontal-menu--selected:after {
  background-color: var(--color-dnv-messaging-dark-yellow);
  height: 3px;
}
a.link--horizontal-menu:hover {
  text-decoration: none;
}
a.link--horizontal-menu:hover:after {
  height: 3px;
  background-color: var(--color-dnv-brand-green);
}
a.link--horizontal-menu:focus {
  outline-offset: 4px;
}
a.link--horizontal-menu:active:after {
  background-color: var(--color-black);
}
a.link--horizontal-menu--dark {
  color: var(--color-white);
}
a.link--horizontal-menu--dark:after {
  background-color: transparent;
}
a.link--horizontal-menu--dark:hover {
  color: var(--color-dnv-hover-bright-yellow);
}
a.link--horizontal-menu--dark:hover:after {
  background-color: var(--color-dnv-hover-bright-yellow);
}
a.link--horizontal-menu--dark:focus {
  outline-color: var(--color-white);
}
a.link--horizontal-menu--dark:active {
  color: var(--color-dnv-active-bright-blue);
}
a.link--horizontal-menu--dark:active:after {
  background-color: var(--color-dnv-active-bright-blue);
}
a.link--horizontal-menu--dark.link--horizontal-menu--selected:after {
  background-color: var(--color-dnv-hover-bright-yellow);
}

.layout .tooltip__overlay {
  display: none;
  background: rgba(var(--color-black), 0.3);
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  overflow: scroll;
  overflow-x: hidden;
  padding: 20px;
  position: fixed;
  z-index: 100;
  align-items: center;
  justify-content: center;
}
.layout .tooltip__modal {
  box-shadow: 4px 4px 5px 0px rgba(var(--color-black), 0.1);
  background-color: var(--color-white);
  border-radius: 3px;
  padding: 28px 32px 26px 32px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 700px;
  position: relative;
  z-index: 101;
}
.layout .tooltip__close {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  color: var(--color-dnv-brand-blue);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.layout .tooltip__close:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .tooltip__close:hover:focus {
  outline: none;
}
.layout .tooltip__close:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .tooltip__close:active {
  background-color: var(--color-black);
  color: var(--color-white);
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .tooltip__close:active:focus {
  outline: none;
}
.layout .tooltip__title {
  color: var(--color-tooltip-title);
  margin-bottom: 8px;
  line-height: 1.25;
  font-size: 1.25rem;
  font-weight: 700;
}
.layout .tooltip__trigger {
  text-decoration: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: var(--color-dnv-brand-blue);
  position: relative;
  transition: color 0.2s ease-in-out;
  margin-left: 10px;
}
.layout .tooltip__trigger:after {
  width: 44px;
  height: 44px;
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.layout .tooltip__trigger[aria-expanded=true] {
  color: var(--color-black);
}
.layout .tooltip__trigger:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
  cursor: pointer;
}
.layout .tooltip__trigger:hover:focus {
  outline: none;
}
.layout .tooltip__trigger:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 2px;
}
.layout .tooltip__trigger:active {
  color: var(--color-black);
}
.layout .tooltip__trigger:active:focus {
  outline: none;
}

.layout .popover {
  text-decoration: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  color: var(--color-dnv-brand-blue);
  position: relative;
  transition: color 0.2s ease-in-out;
  margin-left: 10px;
}
.layout .popover:after {
  width: 44px;
  height: 44px;
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.layout .popover[aria-expanded=true] {
  color: var(--color-black);
}
.layout .popover:hover {
  color: var(--color-dnv-brand-green);
  text-decoration: underline;
  cursor: pointer;
}
.layout .popover:hover:focus {
  outline: none;
}
.layout .popover:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 2px;
}
.layout .popover:active {
  color: var(--color-black);
}
.layout .popover:active:focus {
  outline: none;
}
.layout .popover__title {
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 8px !important;
  font-size: 1.0625rem;
  padding-right: 52px;
}
.layout .popover__content {
  display: none;
}
.layout .popover__close {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 36px;
  height: 36px;
  color: var(--color-dnv-brand-blue);
  position: absolute;
  border-top-right-radius: 2px;
  top: 0;
  right: 0;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.layout .popover__close:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .popover__close:hover:focus {
  outline: none;
}
.layout .popover__close:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .popover__close:active {
  background-color: var(--color-black);
  color: var(--color-white);
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .popover__close:active:focus {
  outline: none;
}
.layout .popover__close:has(+ p) + p:first-of-type {
  padding-right: 52px;
}
.layout .tippy-box[data-theme~=dnv-popover] {
  position: relative;
  line-height: 1.53;
  background: var(--color-dnv-bg-and-border-beige);
  border-radius: 3px;
  border: 1px solid var(--color-dnv-bg-and-border-grey);
  filter: drop-shadow(4px 4px 5px rgba(var(--color-black), 0.1));
  color: var(--color-black);
  box-shadow: none;
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-content {
  padding: 20px 20px 17px 20px;
  font-size: 17px;
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-content > * {
  margin: 0 0 16px 0;
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-content > *:last-child {
  margin: 0;
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-arrow {
  color: var(--color-dnv-brand-grey);
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-arrow:before {
  top: -9px;
}
.layout .tippy-box[data-theme~=dnv-popover] .tippy-arrow:after {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
  top: -8px;
  color: var(--color-dnv-bg-and-border-beige);
}

.layout .dropdown-modal__trigger-modal-wrapper {
  position: relative;
}
.layout .dropdown-modal__trigger-modal-wrapper--end {
  margin-left: auto;
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__trigger-modal-wrapper--end {
    margin-left: 0;
  }
}
.layout .dropdown-modal__triggers-modals-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 30px;
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__triggers-modals-wrapper {
    flex-direction: column;
    display: none;
  }
  .layout .dropdown-modal__triggers-modals-wrapper .dropdown-modal__trigger {
    width: 100%;
  }
}
.layout .dropdown-modal__mobile-trigger {
  display: none;
  position: relative;
  padding-left: 28px;
}
.layout .dropdown-modal__mobile-trigger:before {
  content: "\f0b0";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  font-size: 18px;
}
.layout .dropdown-modal__mobile-trigger[aria-expanded=true] {
  margin-bottom: 8px;
}
.layout .dropdown-modal__mobile-trigger[aria-expanded=true]:before {
  content: "\f00d";
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__mobile-trigger {
    display: block;
  }
}
.layout .dropdown-modal__trigger {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 13px 46px 9px 20px;
  border: 1px solid var(--color-dnv-bg-and-border-grey);
  height: 48px;
  background: url("./ui-includes/images/select.svg"), var(--color-white);
  background-position: calc(100% - 16px) 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  font-weight: 700;
  text-align: left;
}
.layout .dropdown-modal__trigger:hover {
  cursor: pointer;
}
.layout .dropdown-modal__trigger:hover:focus {
  border-color: var(--color-dnv-brand-grey);
  outline: none;
}
.layout .dropdown-modal__trigger:focus {
  border-color: var(--color-dnv-brand-green);
  outline: 1px solid var(--color-dnv-focus-green);
}
.layout .dropdown-modal__modal {
  display: none;
  padding: 20px 66px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(var(--color-black), 0.15);
  border: 2px solid var(--color-dnv-bg-and-border-tan);
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: var(--color-white);
  z-index: 1;
  min-width: 191px;
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__modal {
    position: relative;
    margin-top: 10px;
    min-width: unset;
    width: auto;
  }
}
.layout .dropdown-modal__close {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  color: var(--color-dnv-brand-blue);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__close {
    display: none;
  }
}
.layout .dropdown-modal__close:hover {
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  text-decoration: underline;
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .dropdown-modal__close:hover:focus {
  outline: none;
}
.layout .dropdown-modal__close:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 3px;
}
.layout .dropdown-modal__close:active {
  background-color: var(--color-black);
  color: var(--color-white);
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.51);
}
.layout .dropdown-modal__close:active:focus {
  outline: none;
}
.layout .dropdown-modal__label {
  margin-bottom: 8px;
  line-height: 1.53;
  font-weight: 700;
}
@media only screen and (max-width: 48rem) {
  .layout .dropdown-modal__label {
    display: none;
  }
}
.layout .dropdown-modal__filters-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 48px;
}
.layout .dropdown-modal__filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.layout .dropdown-modal__filters {
  display: flex;
  gap: 16px 20px;
  flex-wrap: wrap;
}
.layout .dropdown-modal__filters-clear {
  align-self: flex-start;
}

.layout .notification {
  border: 1px solid var(--color-dnv-bg-and-border-grey);
  border-left: 8px solid var(--color-dnv-bg-and-border-grey);
  padding: 19px 16px 17px 65px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 48px;
}
@media only screen and (max-width: 64rem) {
  .layout .notification {
    padding-left: 57px;
  }
}
.layout .notification--success {
  border-left-color: var(--color-dnv-brand-green);
}
.layout .notification--success .notification__title:before {
  color: var(--color-dnv-brand-green);
  content: "\f058";
}
.layout .notification--error {
  border-left-color: var(--color-dnv-messaging-red);
}
.layout .notification--error .notification__title:before {
  color: var(--color-dnv-messaging-red);
  content: "\f057";
}
.layout .notification--caution {
  border-left-color: var(--color-dnv-active-dark-yellow);
}
.layout .notification--caution .notification__title:before {
  color: var(--color-dnv-active-dark-yellow);
  content: "\f06a";
}
.layout .notification__title {
  margin: 0;
  position: relative;
  font-size: 20px;
}
.layout .notification__title:has(+ *) {
  margin: 0 0 8px 0;
}
@media only screen and (max-width: 64rem) {
  .layout .notification__title {
    font-size: 17px;
  }
}
.layout .notification__title:before {
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  right: calc(100% + 20px);
  top: 0;
  font-size: 25px;
}
@media only screen and (max-width: 64rem) {
  .layout .notification__title:before {
    right: calc(100% + 24px);
  }
}
.layout .notification p.notification__message {
  margin: 0;
  font-size: 17px;
  max-width: 67ch;
  line-height: 1.53;
}

.layout .inside-feature-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: 900px;
  transition: box-shadow 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .inside-feature-card {
    display: block;
  }
}
.layout .inside-feature-card__image img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
@media only screen and (max-width: 48rem) {
  .layout .inside-feature-card__image img {
    max-height: 226px;
  }
}
.layout .inside-feature-card__content {
  background-color: var(--color-dnv-bg-and-border-pale-tan);
  padding: 45px 32px;
}
@media only screen and (max-width: 48rem) {
  .layout .inside-feature-card__content {
    padding: 32px;
  }
}
.layout .inside-feature-card__content > * {
  margin: 0 0 16px 0;
}
.layout .inside-feature-card__content > *:last-child {
  margin: 0;
}
.layout .inside-feature-card__content a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.layout .inside-feature-card__content a:hover:focus:before {
  outline: none;
}
.layout .inside-feature-card__content a:focus {
  outline: none;
}
.layout .inside-feature-card__content a:focus:before {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 4px;
}
.layout .inside-feature-card__content a:active:before {
  outline: none;
}
.layout .inside-feature-card__title, .layout .inside-feature-card__title * {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}
.layout .inside-feature-card:hover {
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.4);
}
.layout .feedback-contact-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: 660px;
  transition: box-shadow 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .feedback-contact-card {
    display: block;
  }
}
.layout .feedback-contact-card__title, .layout .feedback-contact-card__title * {
  font-size: 1.5625rem;
  margin-bottom: 16px;
}
.layout .feedback-contact-card__image img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
@media only screen and (max-width: 48rem) {
  .layout .feedback-contact-card__image img {
    max-height: 226px;
  }
}
.layout .feedback-contact-card__content {
  padding: 32px;
  background-color: var(--color-white);
}
.layout .feedback-contact-card__items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.layout .feedback-contact-card__item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.layout .feedback-contact-card__items-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 52px;
}
@media only screen and (max-width: 48rem) {
  .layout .feedback-contact-card__items-footer {
    gap: 32px;
  }
}
.layout .feedback-contact-card:hover {
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.4);
}
.layout .dnv-stories-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  max-width: 660px;
  transition: box-shadow 0.2s ease-in-out;
}
@media only screen and (max-width: 48rem) {
  .layout .dnv-stories-card {
    display: block;
  }
}
.layout .dnv-stories-card__title, .layout .dnv-stories-card__title * {
  font-size: 1.5625rem;
  margin-bottom: 16px;
}
.layout .dnv-stories-card__image img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
@media only screen and (max-width: 48rem) {
  .layout .dnv-stories-card__image img {
    max-height: 226px;
  }
}
.layout .dnv-stories-card__content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-dnv-bg-and-border-muted-orange);
  gap: 52px;
}
@media only screen and (max-width: 48rem) {
  .layout .dnv-stories-card__content {
    gap: 32px;
  }
}
.layout .dnv-stories-card__content-top p {
  margin: 0 0 16 0;
}
.layout .dnv-stories-card__content-top p:last-child {
  margin: 0;
}
.layout .dnv-stories-card__content-bottom a {
  color: var(--color-dnv-interactive-dark-blue);
  text-decoration: underline;
}
.layout .dnv-stories-card__content-bottom a:hover {
  color: var(--color-dnv-hover-dark-green);
}
.layout .dnv-stories-card__content-bottom a:focus {
  outline-color: var(--color-dnv-hover-dark-green);
}
.layout .dnv-stories-card__content-bottom a:active {
  color: var(--color-black);
}
.layout .dnv-stories-card .dnv-stories-card__cta {
  margin-bottom: 0;
}
.layout .dnv-stories-card .dnv-stories-card__cta a {
  color: var(--color-dnv-interactive-dark-blue);
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 8px;
  font-size: 1.25rem;
}
.layout .dnv-stories-card .dnv-stories-card__cta a:hover {
  color: var(--color-dnv-hover-dark-green);
}
.layout .dnv-stories-card .dnv-stories-card__cta a:focus {
  outline-color: var(--color-dnv-hover-dark-green);
}
.layout .dnv-stories-card .dnv-stories-card__cta a:active {
  color: var(--color-black);
}
.layout .dnv-stories-card:hover {
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.4);
}
.layout .notices-card {
  width: -moz-fit-content;
  width: fit-content;
  transition: box-shadow 0.2s ease-in-out;
}
.layout .notices-card__top {
  background-color: var(--color-dnv-bg-and-border-muted-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 12px 24px 7px;
}
.layout .notices-card__top-heading {
  margin-bottom: 0;
}
.layout .notices-card a.notices-card__top-link {
  color: var(--color-dnv-interactive-dark-blue);
  text-decoration: underline;
}
.layout .notices-card a.notices-card__top-link:hover {
  color: var(--color-dnv-hover-dark-green);
}
.layout .notices-card a.notices-card__top-link:focus {
  outline-color: var(--color-dnv-hover-dark-green);
}
.layout .notices-card a.notices-card__top-link:active {
  color: var(--color-black);
}
.layout .notices-card__bottom {
  padding: 20px 24px 60px;
  background-color: var(--color-white);
}
@media only screen and (max-width: 48rem) {
  .layout .notices-card__bottom {
    padding-bottom: 20px;
  }
}
.layout .notices-card__bottom-heading {
  margin-bottom: 0;
}
.layout .notices-card__bottom-heading a {
  font-size: 1.25rem;
  margin-bottom: 8px;
  display: inline-block;
}
.layout .notices-card__bottom-date {
  margin-bottom: 8px;
}
.layout .notices-card__bottom-snippet {
  margin: 0;
}
.layout .notices-card:hover {
  box-shadow: 0px 3px 6px 0px rgba(var(--color-black), 0.4);
}

.layout .reference-number {
  padding: 21.5px 24px 17.5px;
  background-color: var(--color-dnv-brand-green);
  color: var(--color-white);
  text-align: center;
  max-width: 683px;
}
.layout .reference-number p {
  margin-bottom: 8px;
  max-width: unset;
}
.layout .reference-number h2 {
  margin-bottom: 16px;
}
.layout .reference-number__feature {
  font-size: 1.25rem;
  font-weight: 700;
}

.layout .form-item--slider {
  width: unset;
}
.layout .form-item--slider .form-item__help {
  margin-top: 0;
  margin-bottom: 16px;
}
.layout .form-item--slider .form-item__error {
  margin-bottom: 16px;
}
.layout .form-item--slider + .form-item--slider {
  margin-top: 32px;
}
.layout .form-item--slider--single .slider__range-input {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.layout .slider {
  position: relative;
  height: 20px;
  margin-bottom: 16px;
}
.layout .slider__min {
  height: 0 !important;
  z-index: 1 !important;
}
.layout .slider__ranges {
  display: flex;
  gap: 56px;
  margin-top: -16px;
}
.layout .slider__range {
  margin-top: -16px;
}
.layout .slider__range--min {
  position: relative;
}
.layout .slider__range--min:after {
  content: "-";
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  transform: translateY(-50%);
  top: calc(50% + 8.5px);
  left: calc(100% + 24px);
}
.layout .slider__range-label {
  font-size: 14px;
  font-weight: 700;
  transform: translate(16px, 24px);
  display: block;
  width: calc(100% - 16px);
  padding-right: 32px;
}
.layout .slider__range-input {
  padding-bottom: 8px !important;
  padding-top: 31px !important;
}
.layout input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  height: 8px;
  background: var(--color-radio-disabled);
  border-radius: 40px;
  border: none;
  background-image: linear-gradient(var(--color-dnv-brand-blue), var(--color-dnv-brand-blue));
  background-repeat: no-repeat;
  width: 100%;
  max-width: 410px;
}
.layout input[type=range]:hover:focus, .layout input[type=range]:active:focus {
  outline: none;
}
.layout input[type=range]:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 10px;
}
.layout input[type=range]::-webkit-slider-thumb {
  -webkit-transition: background-color 0.2s ease-in-out, border-color;
  transition: background-color 0.2s ease-in-out, border-color;
  -webkit-appearance: none;
  pointer-events: all;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 2px solid var(--color-dnv-brand-blue);
  cursor: pointer;
}
.layout input[type=range]::-webkit-slider-thumb:hover {
  background-color: var(--color-dnv-brand-green);
  border-color: var(--color-dnv-brand-green);
}
.layout input[type=range]::-webkit-slider-thumb:active {
  background-color: var(--color-dnv-brand-green);
  border-color: var(--color-dnv-brand-green);
}
.layout input[type=range].error::-webkit-slider-thumb {
  border-color: var(--color-dnv-messaging-red);
}
.layout input[type=range]::-moz-range-thumb {
  -moz-transition: background-color 0.2s ease-in-out, border-color;
  transition: background-color 0.2s ease-in-out, border-color;
  -webkit-appearance: none;
  pointer-events: all;
  width: 20px;
  height: 20px;
  background-color: var(--color-white);
  border-radius: 50%;
  border: 2px solid var(--color-dnv-brand-blue);
  cursor: pointer;
}
.layout input[type=range]::-moz-range-thumb:hover {
  background-color: var(--color-dnv-brand-green);
  border-color: var(--color-dnv-brand-green);
}
.layout input[type=range]::-moz-range-thumb:active {
  background-color: var(--color-dnv-brand-green);
  border-color: var(--color-dnv-brand-green);
}
.layout input[type=range].error::-moz-range-thumb {
  border-color: var(--color-dnv-messaging-red);
}
.layout .form-item--slider--multiple input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  height: 8px;
  position: absolute;
  background-color: var(--color-radio-disabled);
  pointer-events: none;
  border-radius: 40px;
  transform: translateY(-50%);
  top: 50%;
}
.layout .form-item--slider--multiple input[type=range]:hover:focus, .layout .form-item--slider--multiple input[type=range]:active:focus {
  outline: none;
}
.layout .form-item--slider--multiple input[type=range]:focus {
  outline: 2px dashed var(--color-dnv-focus-green);
  outline-offset: 10px;
}

.layout .form-item--stepper .form-item__help {
  margin-bottom: 20px;
}
.layout .stepper {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
.layout .stepper input[type=number] {
  border: 0;
  padding: 0 10px;
  text-align: center;
}
.layout .stepper .button {
  padding: 0;
  width: 34px;
  height: 34px;
  flex-shrink: 0;
}
.layout .stepper .button:hover {
  cursor: pointer;
  box-shadow: none;
}
.layout .stepper .button[disabled] {
  cursor: not-allowed;
}

.layout .autosuggest {
  position: absolute;
  top: calc(100% + 7px);
  left: 0;
  background-color: var(--color-white);
  box-shadow: 0px 3px 10px 0px var(--color-autosuggest-boxshadow);
  width: 100%;
  z-index: 99;
  max-width: 288px;
  display: none;
}
.layout .autosuggest > ul {
  padding: 2px 0;
  list-style: none;
  margin: 0;
}
.layout .autosuggest > ul > li {
  margin: 0;
  line-height: 1.285;
}
.layout .autosuggest > ul > li > a {
  display: block;
  padding: 12px 20px;
}
.layout .autosuggest > ul > li > a:focus {
  outline-offset: -4px;
}

.layout .information-block {
  border: 2px solid var(--color-black);
  padding: 20px 20px 18px 70px;
  max-width: 800px;
}
.layout .information-block p.information-block__message {
  max-width: 67ch;
  font-weight: 700;
  position: relative;
  margin: 0;
  line-height: 1.3;
}
.layout .information-block p.information-block__message:before {
  content: "\f27a";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  right: calc(20px + 100%);
  top: -2px;
  font-size: 30px;
}

.layout blockquote {
  margin: 0;
  padding: 61px 0 18px 20px;
  font-weight: 700;
  border-left: 2px solid var(--color-dnv-bg-and-border-grey);
  position: relative;
}
@media only screen and (max-width: 48rem) {
  .layout blockquote {
    padding: 53px 0 16px 16px;
  }
}
.layout blockquote:before {
  content: "\f10d";
  font-family: "Font Awesome 6 Pro";
  font-size: 30px;
  position: absolute;
  left: 20px;
  top: 17px;
}
@media only screen and (max-width: 48rem) {
  .layout blockquote:before {
    top: 16px;
    left: 16px;
  }
}
.layout blockquote p {
  margin: 0;
  max-width: 67ch;
}
.layout blockquote p + p {
  margin-top: 16px;
}
.layout blockquote.blockquote--green:before {
  color: var(--color-dnv-brand-green);
}
.layout blockquote.blockquote--yellow:before {
  color: var(--color-dnv-messaging-dark-yellow);
}/*# sourceMappingURL=styles.css.map */