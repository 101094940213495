/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

/* table */
.forms-table-wrapper {
    overflow-x: auto;
}

.forms-table-wrapper table {
    min-width: 100%;
    table-layout: auto;
    hyphens: none;
}

.forms-table-wrapper table p {
    margin: 0;
}

.forms-table-wrapper table th {
    font-weight: 800;
    border-bottom: 2px solid var(--color-black);
    padding: 15px 16px 11px 16px;
}

.forms-table-wrapper table td {
    border-bottom: 1px solid #919191;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 16px;
    padding-left: 13px;

    /* hardcoded height to prevent jiggling while expanding */
    height: 71.5px;
}

.forms-table-wrapper table tr:nth-of-type(even) {
    background-color: #FAFAF9;
}

.forms-table-wrapper col {
    width: 30px;
}

.forms-table-wrapper col.row-title {
    min-width: 100px;
}

.forms-table-wrapper col.numerical-value {
    min-width: 100px;
}

.forms-table-wrapper col.short-answer{
    min-width: 130px;
}

.forms-table-wrapper col.dropdown {
    min-width: 140px;
}

/* uses breakpoint: medium */
@media screen and (min-width: 768px) {
    .forms-table-wrapper table {
        overflow-x: unset;
        table-layout: fixed;
    }
}
