.corporate-plan .progressVisualFull {
  display: flex;
  /* change the value if you want different thickness */
  height: 22px;
  margin: 0px;
}
.corporate-plan .progressVisualFull-vertical {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: end;
}
.corporate-plan .progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: width 2s;
}
.corporate-plan .progressVisualFull-vertical .progressVisualPart {
  /* Number of the seconds for complete animation */
  transition: height 2s;
  width: 100%;
}
.corporate-plan .title-status {
  display: flex;
  justify-content: space-between;
}
.corporate-plan .title-status .percentage {
  width: auto;
  font-weight: 800;
}
.corporate-plan .prog,
.corporate-plan .at-a-glance {
  border: solid 1px var(--color-dnv-advisory-border-light-grey);
  padding: 24px 0px 0px 0px;
  height: 100%;
}
.corporate-plan .indicator .gauge .top {
  padding: 0px 24px 24px 24px;
  border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.corporate-plan .indicator2 {
  padding: 24px;
}
.corporate-plan .indicator2 .loader2 {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  max-width: 100%;
}
.corporate-plan .indicator2 .loader2 .status-block {
  width: calc((100% - 72px) / 4);
}
.corporate-plan .icon-label {
  display: flex;
  justify-content: center;
  margin: 10px 0px 15px 0px;
}
.corporate-plan .icon-label .icon {
  margin-right: 10px;
}
.corporate-plan .icon.on-track svg {
  color: var(--color-dnv-hover-green);
}
.corporate-plan .icon.complete svg {
  color: var(--color-dnv-brand-blue);
}
.corporate-plan .icon.upcoming svg {
  color: var(--color-dnv-brand-grey);
}
.corporate-plan .icon.focus-needed svg {
  color: var(--color-dnv-active-dark-yellow);
}
.corporate-plan .icon.some-disruption svg {
  color: var(--color-dnv-messaging-red);
}
.corporate-plan .icon-label .status {
  width: auto;
  white-space: nowrap;
}
.corporate-plan td.icon {
  white-space: nowrap;
  width: 190px;
}
.corporate-plan .stats {
  display: flex;
  justify-content: center;
}
.corporate-plan .goalCards {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  flex-wrap: wrap;
}
.corporate-plan .goalCards .goalCard-inner {
  width: calc((100% - 36px) / 2); /* 32px for gap 4px for left & right borders on 2 elements*/
  position: relative;
  height: 100%;
}
.corporate-plan .goalCards .goalCard {
  border: solid 1px var(--color-dnv-advisory-border-light-grey);
  height: 100%;
  min-height: 172px;
}
.corporate-plan .goalCards .goalCard .goal-header {
  display: flex;
  padding: 20px 22px 10px 22px;
  background-color: var(--color-dnv-bg-and-border-pale-grey);
  border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.corporate-plan .goalCards .goalCard:hover,
.corporate-plan .goalCards .goalCard:hover .goal-header {
  border-color: var(--color-dnv-hover-green);
}
.corporate-plan .goalCards .goalCard .goal-header .frame-icon,
.corporate-plan .frame-icon {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  color: var(--color-dnv-brand-navy);
  flex-grow: 0;
  flex-shrink: 0;
}
.corporate-plan .prog .frame-icon {
  border: solid 1px var(--color-dnv-bg-and-border-navy);
  color: var(--color-dnv-bg-and-border-navy);
}
.corporate-plan .prog.objectives .frame-icon {
  color: #6399ae;
  border-color: #6399ae;
}
.corporate-plan .frame-icon svg {
  margin: auto;
}
.corporate-plan .goalCards .goalCard .goal-header .goal-statement {
  display: flex;
  align-items: center;
  margin-left: 9px;
  text-decoration: underline;
}
.corporate-plan .goalCards .goalCard .indicator {
  padding: 24px;
  border-top: none;
  max-height: 95px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.corporate-plan .goalCards .goalCard:hover .indicator {
  border-color: var(--color-dnv-hover-green);
}
.corporate-plan .goalCards .goalCard .indicator .gauge .top {
  border: none;
  padding: 0;
}
.corporate-plan .section-container {
  margin-top: 64px;
}
.corporate-plan .section-container h2 {
  margin-bottom: 24px;
}
.corporate-plan .section-container .section-header {
  margin-bottom: 40px;
}
.corporate-plan .back-to-top {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-bottom: 10px;
  border-bottom: solid 1px #919191;
}
.corporate-plan .back-to-goals:before,
.corporate-plan .back-to-top:before {
  content: "[";
  margin-right: 16px;
  color: #0873a1;
  font-weight: 800;
}
.corporate-plan .back-to-goals:after,
.corporate-plan .back-to-top:after {
  content: "]";
  margin-left: 16px;
  color: #0873a1;
  font-weight: 800;
}
.corporate-plan .goal-section .overview {
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-dnv-bg-and-border-navy);
  color: var(--color-white);
  padding: 16px;
}
.corporate-plan .goal-section .overview .frame-icon {
  color: #e6d022;
  width: auto;
  height: auto;
  margin-right: 10px;
}
.corporate-plan .goal-section .overview h3 {
  margin: 0;
  color: var(--color-white);
}
.corporate-plan .goal-section .goal-background {
  min-height: 340px;
  background-size: cover;
  background-position: center center;
  -o-object-fit: contain;
     object-fit: contain;
}
.corporate-plan .section-content {
  margin-top: 16px;
}
.corporate-plan .section-content .description {
  margin-bottom: 16px;
}
.corporate-plan .accordion-section {
  margin-top: 32px;
}
.corporate-plan .back-to-goals {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 64px;
  padding: 24px 0px 10px 0px;
  border-bottom: solid 1px var(--color-dnv-brand-grey);
}
.corporate-plan .prog .indicator {
  display: flex;
  justify-content: flex-start;
  padding: 0px 24px;
  border-bottom: solid 1px var(--color-dnv-advisory-border-light-grey);
}
.corporate-plan .prog .indicator .gauge .top {
  border: none;
  padding-right: 0px;
}
.corporate-plan .prog .indicator .gauge {
  flex-grow: 1;
}
.corporate-plan .totalprogress {
  font-weight: 800;
  white-space: nowrap;
}
.corporate-plan .objectives-wrapper {
  padding: 24px;
  overflow: hidden;
}
.corporate-plan .corporate-plan .objectives-wrapper .objective {
  overflow-x: auto;
}
.corporate-plan .objectives-wrapper table {
  min-width: 700px;
}
.corporate-plan td.icon svg {
  margin-right: 12px;
}
.corporate-plan .expand-btn-wrapper button {
  font-weight: 800;
}
.corporate-plan .MuiPaper-root:last-child {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0px;
}
.corporate-plan .MuiAccordionSummary-root,
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper,
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-content h3 {
  color: #0873a1;
  transform: none;
  padding-right: 10px;
  padding-left: 25px;
  text-indent: -25px;
}
.corporate-plan .MuiAccordionSummary-root:hover,
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper:hover,
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-content h3:hover {
  color: var(--color-dnv-hover-green);
}
.corporate-plan .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.corporate-plan .MuiAccordion-root {
  box-shadow: none;
}
.corporate-plan .MuiAccordion-root.Mui-expanded {
  margin: 0px;
}
.corporate-plan .MuiAccordion-root.Mui-expanded:before {
  opacity: 1;
}
.corporate-plan .MuiButtonBase-root.MuiAccordionSummary-root {
  min-height: 57px;
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (max-width: 1200px) {
  .corporate-plan .indicator2 .loader2 {
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 16px;
  }
  .corporate-plan .indicator2 .loader2 .status-block {
    min-width: 150px;
  }
  .corporate-plan .objective::-webkit-scrollbar,
  .corporate-plan .indicator2 .loader2::-webkit-scrollbar {
    height: 8px;
  }
  .corporate-plan .objective::-webkit-scrollbar-track,
  .corporate-plan .indicator2 .loader2::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--color-dnv-bg-and-border-pale-tan);
  }
  .corporate-plan .objective::-webkit-scrollbar-thumb,
  .corporate-plan .indicator2 .loader2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--color-dnv-brand-blue);
  }
  .corporate-plan .goalCard {
    align-items: stretch;
  }
  .corporate-plan .goalCard .goal-header .goal-statement a {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
@media (max-width: 481px) {
  .corporate-plan .goalCards .goalCard-inner {
    width: 100%;
  }
}/*# sourceMappingURL=dnv-corporate-plan.css.map */