/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
@media only screen and (max-width: 720px) {
    .calendar-wrapper .fc-header-toolbar {
        flex-direction: column;
        justify-content: space-between;
    }
    .calendar-wrapper .fc .fc-header-toolbar .fc-toolbar-chunk:has(.fc-dayGridMonth-button) {
        margin-left: unset;
    }
}
@media only screen and (max-width: 800px) {
    .fc-button-group .fc-dayGridMonth-button {
        display: none;
    }
}
.two-column-side-nav-right .layout:has(.feed-calendar),
.events-page .layout {
    margin: 0;
}
.calendar-wrapper {
    width: 100%;
}
.calendar .fc table td div.fc-daygrid-day-events {
    min-height: 80px;
 }
.calendar .feed-pagination-container a.feed-pagination-selected-item {
    color: var(--color-dnv-text-white);
}
.calendar .fc table {
    border: none;
}
.fc thead .fc-col-header-cell {
    text-align: center;
}
.fc thead .fc-col-header-cell a {
    color: var(--color-dnv-brand-black);
}
.fc table thead {
    border-bottom: 2px solid var(--color-dnv-brand-grey);
}
.fc-daygrid-event-harness .fc-event {
    color: var(--cal-event-text-color);
    background-color: inherit;
    padding: 4px;
    border: 0;
    border-radius: 0;
}
.fc-daygrid-event-harness .fc-event:hover {
    background-color: var(--color-dnv-bg-and-border-tan);
}
.fc-daygrid-event-harness-abs .fc-event {
    background-color: var(--color-white);
    border: 2px solid var(--color-dnv-interactive-dark-blue);
    color: var(--cal-event-text-color);
    padding: 4px;
}
.fc-daygrid-event-harness-abs .fc-event:hover {
    background-color: var(--color-dnv-interactive-pale-blue);
}
.fc-event-main .fc-event-main-frame {
    flex-direction: column;
}
.fc-event-time {
    display: flex;
    align-items: center;
    font-weight: var(--cal-event-text-weigth);
    font-size: var(--cal-event-text-size);
    min-width: 40px;
    padding: 0;
    overflow: hidden;
    color: #343434;
    flex-shrink: 0;
}
.fc-daygrid-event-harness .fc-event .fc-event-title {
    color: var(--color-dnv-interactive-blue);
    display: -webkit-box;
    font-weight: var(--cal-event-text-weigth);
    font-size: var(--cal-event-text-size);
    line-clamp: 2;
    line-height: 16px;
    overflow: hidden;
    text-decoration: underline;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.fc-daygrid-event-harness .fc-event .fc-event-title:hover {
    color: var(--color-dnv-brand-green);
}
.fc-daygrid-event-harness-abs .fc-event .fc-event-title {
    color: var(--color-dnv-interactive-blue);
    font-weight: var(--cal-event-text-weigth);
    font-size: var(--cal-event-text-size);
    white-space: normal;
}
.fc-daygrid-event-harness .fc-event-today .fc-event-title,
.fc-daygrid-event-harness-abs .fc-event-today .fc-event-title {
    color: var(--color-dnv-interactive-dark-blue);
}
.fc-daygrid-event-harness-abs .fc-event .fc-event-title:hover {
    color: var(--color-dnv-brand-green);
}
.fc-daygrid-day.fc-day-sat:not(.fc-day-disabled),
.fc-daygrid-day.fc-day-sun:not(.fc-day-disabled) {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: #B0CBD6;
}
.fc .fc-daygrid-day:not(.fc-day-today):hover {
    background-color: var(--color-dnv-interactive-pale-blue);
    cursor: pointer;
}
/* ----- Tabs ----- */
.calendar .website-tabs-container,
.feed-calendar .website-tabs-container {
    border: 2px solid var(--color-dnv-brand-grey);
    padding: 32px 24px;
    /*padding: 32px 16px;*/
    /*border-right: 2px solid var(--color-dnv-brand-grey);*/
    /*border-left: 2px solid var(--color-dnv-brand-grey);*/
    /*border-bottom: 2px solid var(--color-dnv-brand-grey);*/
}
.calendar .website-tabs-bar {
    border-bottom: 2px solid var(--color-dnv-brand-grey);
}
.calendar .panel-group .tab-nav-wrapper .tab-nav,
.feed-calendar .panel-group .tab-nav-wrapper .tab-nav {
    flex-wrap: nowrap;
    gap: 6px;
    max-width: 60%;
}
/* TODO: seems to cause an alignment issue with tab baseline*/
/*.calendar .panel-group .tab-nav-wrapper .tab-nav {*/
/*    margin-bottom: -3px;*/
/*}*/
.panel-group .tab-nav-wrapper .tab-nav li:hover {
    color: var(--color-dnv-brand-black);
    text-decoration: none;
}
.panel-group .tab-nav-wrapper .tab-nav li {
    border: none;
    border-bottom: 2px solid var(--color-dnv-brand-grey);
    flex-shrink: 1;
    margin: 0px 8px 0px 0px
}
.panel-group .tab-nav-wrapper .tab-nav li.active {
    border-bottom: none;
    color: var(--color-dnv-brand-black);
    text-decoration: none;
    padding-top: 12px;
}
.panel-group .tab-nav-wrapper .tab-nav li:focus:not(:focus-visible) {
    outline: none;
}
.fc .fc-button-primary {
    color: var(--color-dnv-interactive-blue);
    background-color: unset;
    border: 0;
    font-weight: 700;
}
.fc .fc-button .fc-icon,
.fc .fc-button .fc-icon:hover,
.fc .fc-button .fc-icon:active {
    color: black;
    background-color: unset;
    border-width: 0;
    border-style: unset;
    border-color: unset;
    box-shadow: none;
}
.fc .fc-button .fc-icon:hover {
    background-color: unset;
}
.fc .fc-button-primary:active {
    border-width: 0;
    border-style: unset;
    border-color: unset;
    box-shadow: none;
}
.fc .fc-button-primary {
    border-width: 0;
    border-style: unset;
    border-color: unset;
    box-shadow: none;
}
.fc .fc-button-primary:hover {
    color: black;
    background-color: #f2f2f2;
}
.fc-next-button,
.fc .fc-button-primary:active,
.fc .fc-button-primary:focus:not(:focus-visible),
.fc .fc-button-primary:hover {
    border: 0!important;
    box-shadow: none;
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus:not(:focus-visible),
.fc .fc-button-primary:not(:disabled):active:focus:not(:focus-visible),
.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    color: #000000;
    background-color: unset;
    border: 0;
    box-shadow: none;
}
.fc .fc-button-primary:disabled,
.fc .fc-today-button {
    color: var(--color-dnv-interactive-blue);
    background-color: unset;
    border: 0;
    font-weight: 700;
    opacity: 1.0;
    box-shadow: none;
}
.fc .fc-today-button {
    padding-left: 0;
    padding-right: 0;
}
.fc .fc-button-primary .fc-icon {
    color: var(--color-dnv-interactive-blue);
}
.fc .fc-button.fc-button-primary:hover {
    background-color: unset;
}
.fc-button-group .fc-dayGridMonth-button::after,
.fc-button-group .fc-multiMonthYear-button::after {
    content: "";
    background: var(--color-dnv-bg-and-border-grey);
    position: absolute;
    bottom: 22%;
    left: 96%;
    height: 50%;
    width: 1px;
}
.fc .fc-header-toolbar {
    justify-content: left;
}
.fc .fc-header-toolbar .fc-toolbar-chunk:has(.fc-dayGridMonth-button) {
    margin-left: auto;
}

/* Customized Events */
.calendar .calendar-event-wrapper {
    font-size: 12px;
    background-color: #E0E0E0;
    max-width: 100%;
    overflow: hidden;
    font-weight: 500;
    color: black;
    padding: 4px 8px;
    border-radius: 4px;
    text-decoration: none;
}
.calendar .calendar-allday-event-wrapper {
    background-color: #0873A1;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    max-width: 100%;
    overflow: hidden;
    font-weight: 500;
    text-decoration: none;
}
.event-panel {
    background-color: #efefef;
    border-radius: 8px;
    border: 1px solid #999999;
    color: black;
    pointer-events: none;
    overflow: hidden;
    white-space: wrap;
    font-size: 13px;
    z-index: 9999;
    padding: 12px;
    width: 190px;
    box-shadow: 0 3px 10px 0 #33333388;
}
.all-day-event-panel {
    background-color: #efefef;
    border: 1px solid #222222;
    color: black;
    pointer-events: none;
    font-size: 13px;
    z-index: 9999;
    padding: 16px;
    overflow: hidden;
    white-space: wrap;
    font-weight: 500;
    text-decoration: none;
    width: 190px;
    box-shadow: 0 3px 10px 0 #33333388;
}
.event-title {
    font-weight: 900;
}
.event-icon {
    color: #0873A1;
}
.event-all-day-icon {
    color: #FFFFFF;
}
.allday-event-title {
    color: white;
    font-weight: 900;
}

/* Year view */
.fc-multimonth-daygrid-table .fc-daygrid-day-bottom {
    text-align: center;
}
.fc .fc-multimonth-daygrid-table a.fc-more-link {
    border: 1px solid var(--color-dnv-interactive-dark-blue);
    border-radius: 0;
    display: inline-block;
    height: 24px;
    width: 32px;
}
.fc-multimonth-daygrid-table .fc-more-link .more-link-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.fc-multimonth-daygrid-table .fc-more-link svg {
    height: 0.8rem;
    margin-bottom: 2px;
    width: 0.8rem;
}
.calendar-wrapper .fc .fc-multimonth {
    border: none;
    overflow: visible;
}
/* Week View */
.calendar-wrapper .fc-listWeek-view:has(table) {
    border-top: none;
}
.fc-listWeek-view .fc-event .fc-list-event-graphic {
    display: none;
}
.fc-listWeek-view .fc-list-day a {
    color: var(--color-dnv-brand-black);
}
.calendar-wrapper .fc-listWeek-view .fc-list-event-time {
    font-weight: 700;
    padding-bottom: 0;
}
.calendar-wrapper .fc-listWeek-view .fc-list-event-title {
    padding-top: 0;
}
.calendar-wrapper .fc-listWeek-view .fc-list-event-title a {
    color: var(--color-dnv-interactive-blue);
    display: -webkit-box;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    line-clamp: 2;
    overflow: hidden;
    text-decoration: underline;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.fc-listWeek-view .fc-event {
    display: flex;
    flex-direction: column;
}
.fc-listWeek-view .fc-event td {
    border: 0;
}
.fc-listWeek-view table .fc-list-event {
    border: none;
}
.fc-listWeek-view table .fc-list-event td {
    line-height: 1.5;
}
.fc-listWeek-view table .fc-list-event:hover td {
    background-color: unset;
}
.fc-listWeek-view table .fc-list-event:hover a {
    color: var(--color-dnv-brand-green);
}
.fc-listWeek-view table .fc-list-day {
    border-top: 1px solid var(--color-dnv-advisory-border-light-grey);
}
.calendar-wrapper .fc-listWeek-view tr.fc-event-today {
    background-color: var(--color-dnv-interactive-pale-blue);
}
.calendar-wrapper .fc-listWeek-view tr.fc-day-today > th {
    background-color: var(--color-dnv-bg-and-border-muted-blue);
}


/* More Events Popover */
.calendar-wrapper .fc-more-popover {
    max-width: 100%;
}
.calendar-wrapper .fc-more-popover .fc-popover-header {
    background: var(--color-dnv-interactive-pale-blue);
    padding: 8px 16px;
}
.calendar-wrapper .fc-more-popover .fc-popover-header .fc-popover-title {
    font-weight: 700;
}
.fc-more-popover .fc-popover-body .fc-event-title {
    color: var(--color-dnv-interactive-blue);
    display: block;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fc-more-popover .fc-popover-body .fc-event-time {
    justify-content: left;
    min-width: 70px;
}
.fc-more-popover .fc-popover-body .fc-event-title:hover {
    color: var(--color-dnv-brand-green);
    text-decoration: underline;
}
.calendar-wrapper .fc-more-popover .fc-popover-body .fc-event:hover,
.calendar-wrapper .fc-more-popover .fc-popover-body .fc-event:focus:not(:focus-visible) {
    background-color: unset;
    box-shadow: none;
    outline: none;
}
.calendar-wrapper .fc-more-popover .fc-popover-body .fc-event:focus::after {
    background-color: unset;
}
.calendar-wrapper .fc-more-popover .fc-event-main-frame {
    flex-direction: row;
}
.calendar-wrapper .fc-daygrid-day-events .fc-daygrid-more-link {
    text-decoration: underline;
}

/* Calendar Feed Component */
.feed-calendar .calendar-wrapper .fc-list-table {
    margin-bottom: 0;
}
.feed-calendar .calendar-wrapper table tr {
    background-color: unset;
}
.feed-calendar .fc-list-table .fc-list-day a {
    text-decoration: none;
}
.feed-calendar .fc-list-table .fc-list-day {
    border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
}
.feed-calendar .calendar-wrapper .fc-daygrid table thead th,
.feed-calendar .calendar-wrapper .fc-daygrid table tbody > tr > td {
    padding: 0;
}
.feed-calendar .calendar-wrapper .fc-daygrid table.fc-col-header {
    margin-bottom: 0;
}
.feed-calendar .calendar-wrapper .fc-daygrid table a {
    line-height: 1;
}
.feed-calendar .calendar-wrapper .fc-daygrid table {
    border: 0;
    margin-bottom: 0;
}
.feed-calendar .calendar-wrapper .fc-daygrid table td {
    border: 1px solid var(--color-dnv-advisory-border-light-grey);
}
.feed-calendar .calendar-wrapper .fc-daygrid .fc-daygrid-day-events {
    min-height: 80px;
}
.feed-calendar .calendar-wrapper .fc-popover-body a {
    text-decoration: none;
}
.feed-calendar .fc-multimonth-header-table .fc-col-header-cell {
    padding: 0;
}
.feed-calendar .fc-multimonth-header-table .fc-col-header-cell a {
    text-decoration: none;
}
.feed-calendar .calendar-wrapper a {
    text-decoration: none;
}

/* List View */
.calendar .feed-result-row,
.feed-calendar .feed-result-row {
    border-bottom: 1px solid var(--color-dnv-bg-and-border-grey);
}
.calendar .feed-result-row h3 a {
    text-decoration: underline;
}
.calendar .feed-result-row .field-name-post-date,
.feed-calendar .feed-result-row .field-name-post-date {
    font-weight: 800;
}
.calendar .loading-indicator p,
.feed-calendar .loading-indicator p {
    margin-bottom: 0;
}

/* Below code was causing issues with tab borders in mobile view */
/* @media only screen and (max-width: 960px){
    .panel-group .tab-nav-wrapper .tab-nav li:first-child{
        border-top: 2px solid var(--color-dnv-brand-grey);
    }
    .panel-group .tab-nav-wrapper .tab-nav li:first-child.active{
        border-bottom: 2px solid var(--color-dnv-brand-grey);
    }
    .panel-group .tab-nav-wrapper .tab-nav li{
        border-left: 2px solid var(--color-dnv-brand-grey);
        border-right: 2px solid var(--color-dnv-brand-grey);
    }
    .panel-group .tab-nav-wrapper .tab-nav li.active:not(:first-child) {
        border-bottom: 2px solid var(--color-dnv-brand-grey);
        border-top: none;
    }

    .calendar .panel-group .tab-nav-wrapper .tab-nav li:not(.active){
        border: none;
        border-bottom: 2px solid var(--color-dnv-brand-grey);
    }
} */
