/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.simplicity-forms {
    width: 100%;
    position: relative;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
}
.simplicity-forms h3 {
    font-size: 1.25rem;
    line-height: 3.25;
    font-family: var(--font-family-heading);
    font-weight: 400;
    margin-top: 2em;
    margin-bottom: 0.4em;
}
.simplicity-forms .form-heading-desc {
    font-size: 1.0625rem;
    font-weight: 400;
    font-family: var(--font-family-paragraph);
}
.simplicity-forms form{
    margin: 64px 0 64px 0;
}
.simplicity-forms .missing {
    display: block;
    /*width: 500px;*/
    line-height: 2rem;
    margin-left: 20px;
    color: red;
    font-weight: 600;
}
/* Info Box */
.component.form>.forms-notification,
.simplicity-forms .simplicity-forms-info-box {
    font-family: var(--font-family-heading);
    font-weight: 700;
    border: 2px solid #000000;
    display: flex;
    padding: 20px 95px 18px 20px;
    margin-bottom: 16px;
    flex-wrap: nowrap;
    font-size: var(--font-size-medium);
}
.component.form>.forms-notification .iconBox{
    margin-right: 10px;
    padding-top: 3px;
}
.simplicity-forms .simplicity-forms-info-box .iconBox {
    margin-right: 20px;
}
.component.form>.forms-notification .iconBox svg,
.simplicity-forms .simplicity-forms-info-box svg {
    width: 30px;
    height: 25px;
    padding: 0;
    margin: 0;
}
.component.form>.forms-notification .iconBox svg path{
    fill: var(--color-dnv-messaging-dark-yellow);
}

.simplicity-forms .simplicity-forms-info-box svg path {
    fill: #000000;
}
.component.form>.forms-notification,
.simplicity-forms .simplicity-forms-info-box p {
    color: #2D2D2D;
    margin: 0;
    font-size: 17px;
    line-height: 22px;
}
.component.form>.forms-notification div,
.simplicity-forms .simplicity-forms-info-box div {
    margin-bottom: 0
}

/* Info Box - Mobile */
@media only screen and (max-width: 600px) {
    .simplicity-forms .simplicity-forms-info-box {
        padding: 20px 16px 18px 16px;
    }

    .simplicity-forms .simplicity-forms-info-box svg {
        width: 25px;
        height: 21px;
        padding: 0;
        margin: 0;
    }

    .simplicity-forms .simplicity-forms-info-box p {
        line-height: 22px;
        vertical-align: top;
    }
}

/* Info Box */
.simplicity-forms .simplicity-forms-success-box {
    border: 1px solid #919191;
    background: #fff;
    color: var(--color-dnv-focus-green);
    display: flex;
    margin: 16px 0;
    flex-wrap: nowrap;
}
.simplicity-forms .simplicity-forms-success-box .iconBox {
    border-left: 8px solid var(--color-dnv-messaging-green);
    flex: 0 0 30px;
    padding: 20px 24px 16px 24px;
}
.simplicity-forms .simplicity-forms-success-box .msgBox {
    padding: 16px 24px 16px 0;
}
.simplicity-forms .simplicity-forms-success-box .msgBox h4 {
    font-size: 20px;
    line-height: 1.25;
}
.simplicity-forms .simplicity-forms-success-box .msgBox p {
    font-family: var(--font-family-paragraph);
    color: var(--color-black);
    font-size: var(--font-size-medium);
    font-weight: 400;
    line-height: 1.529;
    margin: 0;
}
.simplicity-forms .simplicity-forms-success-box svg {
    width: 20px;
    height: 20px;
}
.simplicity-forms .simplicity-forms-success-box svg path {
    fill: var(--color-dnv-messaging-green);
}
.simplicity-forms .simplicity-forms-success-box div {
    margin-bottom: 0
}

/* Rating */
.simplicity-forms .simplicity-forms-rating.heart {
    color: #fe636b;
}
.simplicity-forms .simplicity-forms-rating.star {
    color: #f9a62a;
}
.simplicity-forms .simplicity-forms-rating.thumbUp {
    color: #04b6d4;
}

/* Opinion Scale */
.simplicity-forms .simplicity-forms-opinion-scale.MuiSlider-root {
    margin-left: 8px;
    color: #0E7490;
}
.simplicity-forms .simplicity-forms-opinion-scale .MuiSlider-thumbColorPrimary {
    color: #0E7490;
}

/* Location */
.simplicity-forms .simplicity-forms-location {
    margin-bottom: 24px;
    z-index: 0;
}
.simplicity-forms .simplicity-forms-location input {
    border: none !important;
}

/* File Upload */
.simplicity-forms .simplicity-forms-file-upload {
    width: 100%;
    border: 2px dashed var(--color-dnv-interactive-blue);
    background: #f3f8fa;
    padding: 28px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    height: 140px;
}
.simplicity-forms .simplicity-forms-file-upload:hover {
    background-color: #ecf1eb;
    border-color: #3d7737;
}

.simplicity-forms .simplicity-forms-file-upload.drag-active {
    border: 2px dashed var(--color-dnv-focus-green);
    background: #ECF1EB;
}

.simplicity-forms .simplicity-forms-file-upload.drag-problem {
    border: 2px dashed #B50909;
    background-color: #F9E5E5;
}

.simplicity-forms .drag-problem {
    border: #B50909;
}

.simplicity-forms .simplicity-forms-file-upload p {
    width: 100%;
    margin: 0;
}

.simplicity-forms .files-container {
    padding-top: 12px;
    padding-right: 12px;
    padding-left: 12px;
}

/* --------------------- preview-image-content --------------------- */
.simplicity-forms .preview-image-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    width: 100%;
}

.simplicity-forms .preview-image-content p {
    margin-bottom: 4px;
}

.simplicity-forms .preview-image-content .MuiLinearProgress-root {
    flex: 0 0 86%;
    height: 8px;
    border-radius: 8px;
    margin-top: 8px;
    background-color: #E8E8E0;
}

.simplicity-forms .preview-image-content .MuiLinearProgress-bar {
    background-color: var(--color-dnv-messaging-green);
}

.simplicity-forms .preview-image-content span {
    text-transform: uppercase;
}

.simplicity-forms .deleteIcon {
    display: flex;
    flex-wrap: nowrap;
    color: var(--color-dnv-interactive-blue);
    font-size: 17px;
    align-items: center;
    margin-left: 12px;
    cursor: pointer;
}

.simplicity-forms .deleteIcon svg {
    color: var(--color-dnv-interactive-blue);
    cursor: pointer;
    width: 15px;
    height: auto;
    margin-right: 8px;
}

.simplicity-forms .deleteIcon span {
    color: var(--color-dnv-interactive-blue);
    font-size: 17px;
    font-weight: 800;
    text-transform: capitalize;
}

.simplicity-forms-file-upload-settings {
    margin-top: 14px;
    margin-bottom: 22px;
}

.simplicity-forms-file-upload-settings p {
    margin-bottom: 8px;
    font-weight: 800;
}

.simplicity-forms-file-upload-settings span {
    font-weight: normal;
}

.simplicity-forms-file-upload-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.simplicity-forms-file-upload-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 48px;
    align-self: flex-start;
}

.simplicity-forms-file-upload-icon svg path {
    fill: var(--color-dnv-text-black)
}

.simplicity-forms-file-upload-text {
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    font-size: 17px;
    font-family: var(--font-family-forms-helper);
    font-weight: 400;
    text-align: left;
    white-space: break-spaces;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.simplicity-forms-file-upload-button {
    margin-left: 14px;
}

.form-btn-file-upload {
    border: 0;
    /*border: 1px solid red;*/
    width: 220px;
    word-wrap: normal;
    margin-top: 0 !important;
    background-color: var(--color-dnv-interactive-blue) !important;
    color: #FFFFFF !important;
    padding: 16px 20px !important;
    font-size: 17px !important;
    border-radius: 0 !important;
    font-weight: 800 !important;
    font-family: var(--font-family-links-labels);
    text-transform: capitalize !important;
    transition: background-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
}
.form-btn-file-upload:hover {
    background-color: var(--color-dnv-hover-green) !important;  /*#3d7737;*/
    text-decoration: underline;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,.51);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.51);
}

.simplicity-forms .simplicity-forms-file-upload-reject-files-title {
    color: #C10000;
    font-weight: 700;
    margin-bottom: 0;
}

.simplicity-forms .simplicity-forms-file-upload-reject-files {
    color: #C10000;
    font-size: 0.875rem;
    margin-top: 8px;
    margin-bottom: 24px;
    font-weight: normal;
    /*font-family: "Avenir LT W01_85 Heavy1475544","Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-links-labels);
}

.simplicity-forms .simplicity-forms-field-group {
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.simplicity-forms .simplicity-forms-field-group .Mui-checked {
    color: var(--color-dnv-focus-green);
}

.simplicity-forms-field-group.date-time .react-datepicker-wrapper {
    max-width: 196px;
}

.simplicity-forms-field-group.date-time .react-datepicker__calendar-icon {
    margin: 8px 0 8px 145px;
}

.simplicity-forms-field-group.date-time.error input {
    border: 2px solid #B50909;
}

.simplicity-forms .simplicity-forms-field-group input,
.simplicity-forms .simplicity-forms-field-group textarea {
    border: 2px solid #D3D3C8;
    border-radius: 3px;
    padding: 0.75rem 1rem;
    background-color: #fff;
    -webkit-transition: border-color 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
    font-size: 1.0625rem;
    color: #000;
    width: 100%;
    font-family: var(--font-family-paragraph);
    font-weight: 400;
  outline: none;
}

.simplicity-forms .simplicity-forms-field-group input:hover,
.simplicity-forms .simplicity-forms-field-group textarea:hover,
.simplicity-forms .simplicity-forms-field-group input:focus,
.simplicity-forms .simplicity-forms-field-group textarea:focus {
    border: 2px solid var(--color-dnv-focus-green);
}

.simplicity-forms-field-dropdown {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    font-family: var(--font-family-forms-helper);
    border: 2px solid #D3D3C8;
    background-color: #fff;
    border-radius: 3px;
}
.simplicity-forms-field-dropdown > div {
    padding: 0.25rem;
    border: none !important;
}
.simplicity-forms-field-dropdown select {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    outline: 0;
    cursor: pointer;
    border: 1px solid #c5c5c1;
}
/*.simplicity-forms-field-dropdown-label {*/
/*    width: 100%;*/
/*    position: relative;*/
/*    display: block;*/
/*    !*padding-right: 2rem;*!*/
/*    border: 2px solid #D3D3C8;*/
/*    border-radius: 3px;*/
/*    padding: 0.75rem 1rem;*/
/*    background-color: #fff;*/
/*    -webkit-transition: border-color 0.2s ease-in-out;*/
/*    -o-transition: border-color 0.2s ease-in-out;*/
/*    transition: border-color 0.2s ease-in-out;*/
/*    font-size: 1.0625rem;*/
/*    color: #575757;*/
/*    line-height: 19px;*/
/*    cursor: pointer;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*}*/
/*.simplicity-forms-field-dropdown-label:after {*/
/*    font-family: "dnv-icons";*/
/*    content: "\e600";*/
/*    display: block;*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 3px;*/
/*    z-index: 0;*/
/*    width: 36px;*/
/*    text-align: left;*/
/*    text-indent: 10px;*/
/*    height: 41px;*/
/*    line-height: 34px;*/
/*    font-weight: bold;*/
/*    font-size: 17px;*/
/*    color: #000;*/
/*    background: transparent;*/
/*}*/
.simplicity-forms .simplicity-forms-field-group input.inline-block {
    width: 34%;
    margin-right: 2%;
}

.simplicity-forms .simplicity-forms-field-label {
    display: block;
    font-family: var(--font-family-links-labels);
    font-size: 17px;
    font-weight: normal;
    line-height: 2rem;
    margin-bottom: 5px;
    margin-left: 2px;
    margin-right: 20px;
}

.simplicity-forms .simplicity-forms-field-label span {
    color: #000;
    margin-left: 4px;
}
.simplicity-forms .simplicity-forms-description {
    font-family: var(--font-family-paragraph);
    display: block;
    font-style: italic;
    margin: 8px 0;
}
.simplicity-forms .simplicity-forms-helper-text {
    font-family: var(--font-family-forms-helper);
    display: block;
    font-style: italic;
    margin: 24px 0;
}
.simplicity-forms .simplicity-forms-field-description {
    display: block;
    padding: 0;
    font-style: italic;
    font-size: 12px;
    margin-left: 20px;
    color: #8a8a8a;
}

.simplicity-forms .simplicity-forms-field-radios {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000
}

.simplicity-forms .simplicity-forms-field-radios .Mui-checked {
    color: var(--color-dnv-focus-green);
}
.simplicity-forms .simplicity-forms-field-radios.error svg {
    color: #B50909
}
.simplicity-forms .simplicity-forms-field-radios.inline-block {
  display: inline-block;
}

/* Hide the browser's default radio button */
.simplicity-forms .simplicity-forms-field-radios > input[type='radio'] {
    width: 1px;
    height: 1px;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='radio']:after {
    top: -12px;
    left: -1px;
    position: relative;
    width: 14px;
    height: 14px;
    background: #fff;
    border: solid 1px #c5c5c1;
    border-radius: 50%;
    content: '';
    display: inline-block;
    visibility: visible;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='radio']:checked:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 50%;top: -12px;
    left: -1px;
    background: #ffffff;
    border: 2px solid #B50909 !important;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='radio']:checked:after {
    position: relative;
    top: -34px;
    left: 1px;
    background-color: #000000;
    width: 10px;
    height: 10px;
    border: 1px solid #ffffff;
}
/* Checkbox */
.simplicity-forms .simplicity-forms-field-radios > input[type='checkbox'] {
    width: 1px;
    height: 1px;
    border: 1px solid red !important;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='checkbox'] .error {
     border: 2px solid #B50909 !important;
    outline: none;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='checkbox']:after {
    top: -12px;
    left: -1px;
    position: relative;
    width: 14px;
    height: 14px;
    background: #ffffff;
    border: solid 1px #c5c5c1;
    content: '';
    display: inline-block;
    visibility: visible;
}
.simplicity-forms .simplicity-forms-field-radios > input[type='checkbox']:checked:before {
    display: inline-block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 6px;
    left: 3px;
    z-index: 999;
    font-family: "dnv-icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e60d";
    font-size: 16px;
    line-height: 0.75em;
    text-align: right;
    color: #000;
}

.simplicity-forms .simplicity-forms-field-selection-text {
    /* used for radio button text*/
    margin-left: 12px;
    padding: 6px;
    font-family: var(--font-family-forms-helper);
    font-weight: 400;
}

.simplicity-forms .simplicity-forms-paragraph {
    /* used for radio button text*/
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    font-weight: normal;
}

.simplicity-forms .simplicity-forms-field-group .MuiFormControlLabel-root {
    display: flex;
    align-items: self-start;
}

.simplicity-forms .simplicity-forms-field-radios .MuiFormControlLabel-label {
    width: 100%;
    margin: 8px 0;
}

.simplicity-forms .simplicity-forms-field-radios .MuiFormControlLabel-label p {
    margin-bottom: 10px;
}

.component.form .forms-notification{
    border: 1px solid #919191;
    background: #fff;
    display: flex;
    margin: 16px 0;
    flex-wrap: nowrap;
    padding: 16px;
}

.simplicity-forms .forms-notification{
    border: 1px solid #919191;
    background: #fff;
    display: flex;
    margin: 16px 0;
    flex-wrap: nowrap;
}

.simplicity-forms .forms-notification .iconBox{
    flex: 0 0 30px;
    padding: 20px 24px 16px 24px;
}

.component.form .forms-notification .iconBox svg,
.simplicity-forms .forms-notification .iconBox svg {
    width: 20px;
    height: 20px;
}
.component.form .forms-notification.forms-notification--success,
.simplicity-forms .forms-notification.forms-notification--success{
    color: var(--color-dnv-messaging-green);
}

.component.form  .forms-notification.forms-notification--success .iconBox,
.simplicity-forms .forms-notification.forms-notification--success .iconBox{
    border-left: 8px solid var(--color-dnv-messaging-green);
    color: var(--color-dnv-messaging-green);
}
.component.form .forms-notification.forms-notification--error,
.simplicity-forms .forms-notification.forms-notification--error{
    color: var(--color-dnv-messaging-red);
}

.component.form .forms-notification.forms-notification--error .iconBox,
.simplicity-forms .forms-notification.forms-notification--error .iconBox{
    border-left: 8px solid var(--color-dnv-messaging-red);
    color: var(--color-dnv-messaging-red);
}

.component.form .forms-notification.forms-notification--caution{
    color: var(--color-dnv-messaging-dark-yellow);
    border-left: 8px solid var(--color-dnv-messaging-dark-yellow);
}

.simplicity-forms .forms-notification.forms-notification--caution{
    color: var(--color-dnv-messaging-dark-yellow);
}

.simplicity-forms .forms-notification.forms-notification--caution .iconBox{
    border-left: 8px solid var(--color-dnv-messaging-dark-yellow);
    color: var(--color-dnv-messaging-dark-yellow);
}

.simplicity-forms .forms-notification .msgBox{
    padding: 16px 24px 16px 0px;
    color: var(--color-black);
}
.component.form .forms-notification .msgBox h3,
.simplicity-forms .forms-notification .msgBox h3{
    font-size: 20px;
    line-height: 25px;
    margin-top: 0px;
}
.component.form .forms-notification .msgBox p,
.simplicity-forms .forms-notification .msgBox p{
    margin-bottom: 0px;
}

.form-item label, .views-exposed-widget label {
    font-size: 1.0625rem;
}

.form-text, .form-textarea, .css-select .css-select-label {
    border: 2px solid #D3D3C8;
    border-radius: 3px;
    padding: 0.75rem 1rem;
    background-color: #fff;
    -webkit-transition: border-color 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
    font-size: 1.0625rem;
    color: #575757;
}

.form-text:focus, .form-textarea:focus, .css-select .css-select-label:focus {
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #44883E;
}

fieldset legend {
    font-size: 1.25rem;
}

.form-type-radio label.option, .form-type-checkbox label.option {
    font-size: 1rem;
}

.css-select .css-select-label {
    padding-right: 2rem;
}

.form-item + .description, .form-item .description {
    font-size: 1rem;
}

fieldset:not(.collapsed) {
    margin-bottom: 16px;
}

/* fieldset:not(.collapsed) legend {
    border-color: #c5c5c1;
} */

/* fieldset legend {
    float: left;
    background: #f0f0eb;
    padding: 12px 24px;
    font-size: 2rem;
    margin-top: -54px;
    margin-left: -18px;
    border: 2px solid #c5c5c1;
    border-bottom: 0;
} */

fieldset .fieldset-wrapper {
    margin-top: 16px;
}

fieldset .webform-container-inline .form-text, fieldset .webform-container-inline .form-textarea-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 66.669%;
    max-width: 100%;
}

@media (max-width: 960px) {
    fieldset .webform-container-inline .form-text, fieldset .webform-container-inline .form-textarea-wrapper {
        width: 100%;
    }
}

fieldset .webform-container-inline label:not(.option) {
    min-width: 150px;
    display: inline-block;
    vertical-align: middle;
    width: calc(33.332% - 25px);
}

@media (max-width: 960px) {
    fieldset .webform-container-inline label:not(.option) {
        width: 100%;
    }
}

fieldset .webform-container-inline .form-textarea-wrapper {
    vertical-align: middle;
}

fieldset .webform-container-inline .form-textarea {
    width: 100%;
}

fieldset .webform-container-inline .form-radios {
    display: inline-block;
    vertical-align: middle;
    width: calc(66.669% - 25px);
}

@media (max-width: 960px) {
    fieldset .webform-container-inline .form-radios {
        width: 100%;
    }
}

fieldset .form-text, fieldset .form-textarea {
    width: 100%;
    border: 0;
    max-width: 100%;
}

fieldset.collapsed {
    border: 0;
    padding: 0;
}

fieldset.collapsed legend {
    margin-left: 0;
    border-color: #f0f0eb;
}

.webform-container-inline label:not(.option) {
    display: inline-block;
    vertical-align: middle;
    width: 200px;
}

@media (max-width: 960px) {
    .webform-container-inline label:not(.option) {
        width: 100%;
    }
}

.webform-container-inline .form-textarea-wrapper {
    vertical-align: middle;
}

.webform-container-inline .form-textarea {
    width: 100%;
}

.webform-container-inline .form-radios {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 230px);
}

@media (max-width: 960px) {
    .webform-container-inline .form-radios {
        width: 100%;
    }
}

.form-text, .form-textarea, .css-select .css-select-label {
    background: #fff;
    border: solid 1px #c5c5c1;
    max-width: 100%;
    padding: 10px;
    font-family: var(--font-family-forms-helper);
    font-size: 1.6rem;
}

.form-text:focus, .form-textarea:focus, .css-select .css-select-label:focus {
    background: #f8f8f4;
    outline: none;
    -webkit-box-shadow: 0 0 6px #CCC;
    box-shadow: 0 0 6px #CCC;
}

@media (max-width: 480px) {
    .form-text, .form-textarea {
        width: 100%;
    }
}

.form-item label, .views-exposed-widget label {
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    margin-bottom: 5px;
    margin-left: 2px;
    font-weight: normal;
    /*font-size: 16px;*/
    /* font-size: 1.6rem; */
}

.form-item {
    margin: 0 0 20px;
}

.form-item + .description, .form-item .description {
    /*font-size: 14px;*/
    font-size: 1.4rem;
    font-family: var(--font-family-forms-helper);
    font-style: italic;
    margin: 8px 0 0;
}

.form-item + .description {
    margin-top: -12.8px;
    margin-bottom: 20px;
}

.form-item label + .description {
    margin: 0 0 8px;
}

.form-item .form-required {
    color: #c07a26;
}

.form-item--inline {
    display: inline-block;
    margin-right: 5px;
    white-space: nowrap;
}

.form-item--nested {
    margin-bottom: 0;
}

.form-submit {
    -webkit-appearance: none;
}

.form-type-radio label.option, .form-type-checkbox label.option {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    /*padding-left: 20px;*/
    padding: 3px 3px 3px 22px;
    background-repeat: no-repeat;
    background-position: 0 0;
    /*font-size: 14px;*/
    font-size: 1.4rem;
    line-height: 142.85714%;
    vertical-align: middle;
    cursor: pointer;
    font-family: var(--font-family-forms-helper);
    text-transform: none;
}

.form-type-radio input, .form-type-checkbox input {
    position: absolute;
}

.csschecked .form-type-radio input,
.csschecked .form-type-checkbox input {
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.csschecked .form-type-radio input + label,
.csschecked .form-type-checkbox input + label {
    position: relative;
}

.csschecked .form-type-radio input + label:before,
.csschecked .form-type-checkbox input + label:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    border: solid 1px #c5c5c1;
}

.csschecked .form-type-checkbox input + label:before {
    width: 14px;
    height: 14px;
    top: 6px;
    background: #fff;
}

.csschecked .form-type-checkbox input:checked + label:before {
    display: block;
    font-family: 'dnv-icons';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /*line-height: 1;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 14px;
    line-height: .75em;
    text-align: right;
    color: #000;
}

.csschecked .form-type-checkbox input:focus + label:before {
    border-color: orange;
}

.csschecked .form-type-radio input + label:before {
    width: 14px;
    height: 14px;
    top: 7px;
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
}

.csschecked .form-type-radio input:checked + label:before {
    background: #162129;
    border-color: #c5c5c1;
}

select[multiple] {
    padding: 5px;
    font-size: 16px;
    color: #000;
    min-width: 290px;
}

select[multiple]:focus {
    -webkit-box-shadow: 0 0 6px #CCC;
    box-shadow: 0 0 6px #CCC;
    border: 1px solid #c5c5c1;
    background: #f8f8f4;
    outline: none;
}

.css-select {
    display: block;
    position: relative;
    width: 290px;
    max-width: 100%;
}

form.workbench-moderation-moderate-form .css-select {
    display: block;
    margin: 10px 0;
}

.webform-container-inline .css-select {
    display: inline-block;
    width: auto;
}

@media (max-width: 960px) {
    .css-select {
        width: 215px;
    }
}

@media (max-width: 480px) {
    .css-select {
        width: 100%;
    }
}

.css-select select {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    outline: 0;
    cursor: pointer;
    border: 1px solid #c5c5c1;
}

.css-select select:focus ~ .css-select-label {
    -webkit-box-shadow: 0 0 6px #CCC;
    box-shadow: 0 0 6px #CCC;
    border-color: #c5c5c1;
    background: #f8f8f4;
    outline: none;
}

.css-select .css-select-label {
    width: 100%;
    position: relative;
    display: block;
    padding-right: 35px;
    line-height: 19px;
    cursor: pointer;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    speak: none;
}

.css-select .css-select-label:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 3px;
    z-index: 0;
    width: 36px;
    text-align: left;
    text-indent: 10px;
    height: 41px;
    line-height: 34px;
    font-weight: bold;
    font-size: 17px;
    color: #000;
    background: transparent;
}

.node .webform-client-form {
    margin: 64px 0;
}

/* fieldset {
    border: 2px solid #c5c5c1;
    background: #f0f0eb;
    margin-left: 0;
    padding: 8px 16px;
    margin-top: 54px;
    margin-bottom: 48px;
} */

.form-steps-numbers-header {
    margin-bottom: 32px;
}

.form-steps-numbers-header .step {
    border-radius: 24px;
    width: 44px;
    height: 44px;
    background-color: #fff;
    border: 2px solid #919191;
    color: #000;
    text-align: center;
    font-size: 17px;
    line-height: 42px;
}

.form-steps-numbers-header .step.active {
    border: 2px solid var(--color-dnv-focus-green);
    background-color: var(--color-dnv-focus-green);
    color: #fff;
}

.form-steps-numbers-header .step.passed {
    border: 2px solid #000;
    background-color: #000;
    color: #fff;
}

.form-steps-numbers-header .step-line {
    width: 100px;
    height: 2px;
    background-color: #919191;
}
.form-step-heading-container {
    margin-bottom: 8px;
}

.form-steps-numbers-title {
    flex: 0 0 100%;
    font-size: 17px;
    font-weight: 800;
    line-height: 28px;
}
.form-step-heading-container h2.empty-step {
    margin: 0;
}

.form-btn-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 30px;
}

.form-btn-back,
.form-btn-next,
.form-btn-submit {
    max-height: 48px;
    padding: 13px 20px !important;
    border-radius: 0 !important;
    font-size: var(--font-size-medium) !important;
    font-weight: 800 !important;
    box-shadow: 0 0 0 0 var(--color-black);
    font-family: var(--font-family-links-labels) !important;
    text-transform: capitalize !important;
    transition: background-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out;
    border: 2px solid;
    text-align: Left;
    vertical-align: center;
    cursor: pointer;
    margin: 0 !important;
}

.form-btn-next {
    background-color: var(--color-dnv-brand-blue) !important;
    color: var(--color-dnv-text-white) !important;
    border: 2px solid var(--color-dnv-brand-blue) !important;
}

.form-btn-back {
    background-color: transparent !important;
    color: var(--color-dnv-brand-blue) !important;
    border: 2px solid var(--color-dnv-brand-blue) !important;
}

.form-btn-submit {
    background-color: var(--color-dnv-interactive-blue) !important;
    color: var(--color-dnv-text-white) !important;
    border: 2px solid var(--color-dnv-brand-blue) !important;
}

.form-btn-back:hover,
.form-btn-next:hover,
.form-btn-submit:hover {
    background-color: var(--color-dnv-hover-green) !important;
    color: var(--color-dnv-text-white) !important;
    border: 2px solid var(--color-dnv-hover-green) !important;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.51);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.51);
    outline: none;
    text-decoration: underline;
}

.form-btn-back svg, .form-btn-next svg {
    width: 17px;
    margin-left: 4px;
}


.react-datepicker-wrapper {
    width: 100%;
}

.form-field-error-container {
    font-size: 0.86em;
    padding: 6px 0 0 0;
}

.form-character-limit-error {
    display: flex;
    justify-content: space-between;
}

.form-field-error-input {
    border: 2px solid #B50909 !important;
    outline: none;
}

.form-field-error-label {
    width: 100%;
}

.form-field-error {
    font-size: 17px;
    line-height: 1.5;
    color: #000000 !important;
    background-color: #F9E5E5;
    border-radius: 4px;
    margin-bottom: 10px !important;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
}
.form-field-error .error-text {
    width: 100%;
}
.form-field-error .error-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-field-error .error-icon svg {
    display: block;
    margin: auto;
    width: 24px;
    height: 24px;
}
.form-field-error .form-field-error-heading {
    margin-bottom: 0;
}

/* multiple errors */
.form-field-error ul{
    padding-inline-start: 1.55em;
}
.form-field-error ul li {
    list-style: disc;
    line-height: 26px;
}
.form-file-upload-file-listing {
    margin-bottom: 8px;
}
.file-upload-control-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.file-title {
    flex-grow: 1;
    width: 100%;
}
.progress-bar-container {
    width: 100%;
}
.form-field-error .MuiSvgIcon-root {
    fill: #B50909;
    width: 16px;
    margin-right: 8px;
}

.form-field-error-date {
    border: 1px solid red
}

.address-question {
    height: 100%;
    width: 100%;
}
.address-question .simplicity-forms-description {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000;
    margin-bottom: 12px;
}

.address-question-grid {
    gap: 10px 24px;
    grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(5, 1fr);
	height: 100%;
	width: 100%;
    display: grid;
    margin-top: 24px;
}

@media screen and (max-width: 768px) {
    .address-question-grid {
        display: flex;
        flex-direction: column;
    }
}

.simplicity-forms-field-group .address-label{
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium-large);
    font-weight: 900;
    line-height: 25px;
    text-align: left;
    color: #2D2D2D;
    margin-top: 0;
    margin-bottom: 8px;
    margin-left: 0;
    margin-right: 0;
}



.simplicity-forms-field-group .address-question-grid p {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    font-weight: 800;
    line-height: 22px;
    margin-bottom: 8px;
}

.street-address {
    margin: 0;
	grid-area: 1 / 1 / 2 / 7;
}
.unit {
    margin: 0;
	grid-area: 2 / 1 / 3 / 4;
}
.city {
    margin: 0;
	grid-area: 3 / 1 / 4 / 4;
}
.province {
    margin: 0;
	grid-area: 3 / 4 / 4 / 7;
}
.country {
    margin: 0;
	grid-area: 4 / 1 / 5 / 7;
}
.postcode {
    margin: 0;
	grid-area: 5 / 1 / 6 / 4;
}

.question-info {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    font-style: italic;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    margin: 0;
    margin-top: 24px;
}

.review-step hr {
    margin-bottom: 48px;
}

.review-step .title {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium);
    font-weight: 900;
    line-height: 22px;
    text-align: left;
    color: #000000;
    margin-bottom: 16px;
}
.review-step .paragraph {
    font-family: var(--font-family-paragraph);
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #000000;
}

/* Result Form */
.result-form {
    margin-bottom: 24px;
}
/* .result-form .title {
    margin: 0 0 24px 0;
} */

.result-form .content {
    margin-left: 32px;
}

/* dynamic-response-group */
.dynamic-response-group {
    margin-bottom: 32px;
}
.dynamic-response-group .group-title {
    margin: 0 0 24px 0;
}
.dynamic-response-group .content {
    margin-left:32px;
}
.dynamic-response-group .responses {
    margin-left: 32px;
}

.response-step-container:last-child hr{
    border: 2px solid red;
    display: none;
}

.response-step {
    display: flex;
    gap: 0 16px;
    align-items: start;
}

.response-step span {
    background-color: #fff;
    border-radius: 24px;
    border: 2px solid #919191;
    color: #000;
    flex-shrink: 0;
    font-size: 17px;
    height: 44px;
    line-height: 42px;
    text-align: center;
    width: 44px;
}

.response-step h2.step-title {
    font-family: var(--font-family-heading);
    font-size: 25px;
    font-weight: 900;
    line-height: 31px    ;
    margin-top: 10px;
}
.response-step h2.empty-step {
    margin: 0;
}

.response-step h3 {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium);
    font-weight: 900;
    line-height: 25px;
}
.response-step h4 {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium);
    font-weight: 900;
    line-height: 22px;
    margin-bottom: 8px;
}

.response-step p {
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
    font-weight: 400;
    line-height: 26px;
    margin: 0;
}

.response-step .snapshot-grid-row {
    margin-left: 32px;
    margin-bottom: 24px;
}

.response-step .snapshot-grid-row .snapshot-grid-column {
    margin-left: 32px
}
.text-snapshot {
    white-space: pre-wrap;
}

/* captcha */
.grecaptcha-badge {
    visibility: hidden;
}
.form-captcha-notice {
    font-size: 14px;
}
