/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.document-display-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
}
.document-display-container a {
    font-weight: 700;
    text-decoration: none;
}
.document-display-container a .document-display-size {
    font-weight: 400;
    text-decoration: none;
}

.document-display-container a div{
    text-decoration: none !important;
}
.document-display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.0rem;
    gap: 12px;
    border: none;
}
.document-display-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
    font-size: 1.0rem;
    gap: 12px;
    padding: 8px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
}
.document-display-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    font-size: 1.0rem;
    gap: 12px;
    /*padding: 8px;*/
    /*border: 1px solid #eaeaea;*/
    /*border-radius: 4px;*/
}
.as-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    padding-inline-start: 20px;
}
.as-list {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
}
.as-list::before {
    content: "";
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: 1rem;
    background-color: #2b2b2b;
    line-height: 36px;
    height: 0.5rem;
}

.document-display-text {
    /*color: #2b2b2b;*/
    text-decoration: none;
    vertical-align: center;
    height: 36px;
    line-height: 36px;
    min-height: 36px;
}
.document-display-icon {
    width: 24px;
    height: 24px;
    margin: 4px;
    min-height: 24px;
}
.document-display-icon svg path{
    fill: #0E7490
}
.document-display-card:hover {
    background-color: #00000005;
}
.document-display-link {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
}
.document-display-link > div {
    color: #0E7490;
    text-decoration: none !important;
}
.document-display-link.undecorated {
    color: #2b2b2b;
    text-decoration: none;
}
.document-display-size {
    text-decoration: none;
    vertical-align: center;
    height: 36px;
    line-height: 36px;
    min-height: 36px;
    font-size: 0.75rem;
    color: #666 !important;
    /*text-decoration: none !important;*/
}
.document-display-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    height: 36px;
}
