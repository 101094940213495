/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.simplicity-text-link-list-item {
    margin-top: 8px;
    margin-bottom: 8px;
}

/*.link_standard {*/
/*    !*color: #1b8898;*!*/
/*    colour: var(--color-dnv-interactive-blue);*/
/*}*/

.link_report_header {
    color: rgb(228, 150, 66)
}

.simplicity-text-link-item {
    width: 100%;
    margin: 10px 0;
}
.simplicity-text-link-item:has(.h1) {
    margin: 14px 0;
}
.simplicity-text-link-item:has(.h2) {
    margin: 12px 0;
}
.simplicity-text-link-item:has(.h3) {
    margin: 10px 0;
}
.simplicity-text-link-item:has(.h4) {
    margin: 10px 0;
}
.simplicity-text-link-item:has(.h5) {
    margin: 10px 0;
}
.simplicity-text-link-item:has(.h6) {
    margin: 10px 0;
}
.simplicity-text-link-item:has(.body) {
    margin: 10px 0;
}
.simplicity-link-icon {
    color: var(--color-dnv-interactive-blue)
}
.simplicity-text-link a,
.simplicity-text-link a i:before,
.simplicity-link-icon svg path {
    color: var(--color-dnv-interactive-blue);
    fill: var(--color-dnv-interactive-blue);
}
.simplicity-text-link a:hover,
.simplicity-text-link a:hover i:before,
.simplicity-link-icon svg:hover path {
    color: var(--color-dnv-hover-green);
    fill: var(--color-dnv-hover-green);
}
.simplicity-text-link a:active,
.simplicity-text-link a:active i:before,
.simplicity-link-icon svg:active path {
    color: var(--color-dnv-active-black);
    fill: var(--color-dnv-active-black);
}

.simplicity-text-link {
    font-size: 17px;
    line-height: 22px;
    font-family: var(--font-family-links-labels);
    font-weight: 400;
    /*color: red !important;*/
}
.simplicity-text-link.h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
}
.simplicity-text-link.h2 {
    font-size: 25px;
    line-height: 31px;
    font-weight: 900;
}
.simplicity-text-link.h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
}
.simplicity-text-link.h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 900;
}
.simplicity-text-link.h5 {
    font-size: 22px;
    line-height: 28px;
}
.simplicity-text-link.h6 {
    font-size: 15px;
    line-height: 18px;
}
.simplicity-text-link.body {
    font-size: 17px;
    line-height: 22px;
}
/*-------------------------------------------*/

.simplicity-bullet-point-link {
    /*text-decoration: underline;*/
    text-decoration: underline !important;
    font-size: 17px;
    line-height: 22px;
    font-family: var(--font-family-links-labels);
    font-weight: 400;
    /*color: red !important;*/
}
.simplicity-bullet-point-link.h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
}
.simplicity-bullet-point-link.h2 {
    font-size: 25px;
    line-height: 31px;
    font-weight: 900;
}
.simplicity-bullet-point-link.h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
}
.simplicity-bullet-point-link.h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 900;
}
.simplicity-bullet-point-link.h5 {
    font-size: 22px;
    line-height: 28px;
}
.simplicity-bullet-point-link.h6 {
    font-size: 15px;
    line-height: 18px;
}
.simplicity-bullet-point-link.body {
    font-size: 17px;
    line-height: 22px;
}
/*--------------------------------------------*/

.simplicity-cta-link {
    font-size: 17px;
    line-height: 22px;
    font-family: var(--font-family-links-labels);
    font-weight: 400;
    /*color: red !important;*/
}
.simplicity-cta-link.h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
}
.simplicity-cta-link.h2 {
    font-size: 25px;
    line-height: 31px;
    font-weight: 900;
}
.simplicity-cta-link.h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
}
.simplicity-cta-link.h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 900;
}
.simplicity-cta-link.h5 {
    font-size: 22px;
    line-height: 28px;
}
.simplicity-cta-link.h6 {
    font-size: 15px;
    line-height: 18px;
}
.simplicity-cta-link.body {
    font-size: 17px;
    line-height: 22px;
}

/*--------------------------------------------*/

.simplicity-underline-link {
    text-decoration: underline !important;
    font-size: 17px;
    line-height: 22px;
    font-family: var(--font-family-links-labels);
    font-weight: 400;
    /*color: red !important;*/
}
.simplicity-underline-link.h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 900;
}
.simplicity-underline-link.h2 {
    font-size: 25px;
    line-height: 31px;
    font-weight: 900;
}
.simplicity-underline-link.h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
}
.simplicity-underline-link.h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 900;
}
.simplicity-underline-link.h5 {
    font-size: 22px;
    line-height: 28px;
}
.simplicity-underline-link.h6 {
    font-size: 15px;
    line-height: 18px;
}
.simplicity-underline-link.body {
    font-size: 17px;
    line-height: 22px;
}


/*--------------------------------------------*/

/*.link-list {*/
/*margin-bottom: var(--spacing-default-component-margin-bottom);*/
/*}*/

/*.link-list ul {*/
/*list-style: disc;*/
/*padding-left: 0;*/
/*}*/

/*.link-list li {*/
/*    padding-top: 3px;*/
/*    padding-bottom: 3px;*/
/*    padding-left: 0;*/
/*    margin: 0 0 8px 0;*/
/*}*/
/*.link-list ul li a {*/
/*    !*color: var(--color-dnv-interactive-blue);*!*/
/*    !* text-decoration: underline; *!*/
/*    !*font-size: var(--font-size-medium);*!*/
/*    !*display: flex;*!*/
/*    !*flex-direction: row;*!*/
/*    !*align-items: center;*!*/
/*    !*gap: 6px*!*/
/*}*/
/*.link-list ul li a:hover {*/
/*    color: var(--color-dnv-hover-green);*/
/*}*/
/*.link-list ul li a:active {*/
/*    color: var(--color-dnv-active-black);*/
/*}*/
/*.link-list ul li a:focus {*/
/*    border: 2px dashed var(--color-dnv-focus-green);*/
/*}*/

/*.link-list ul li a.underline {*/
/*    text-decoration: underline;*/
/*}*/

/*.link-list ul li a.text-link {*/
/*    margin: 0;*/
/*    text-decoration: none;*/
/*    display: block;*/
/*}*/
/*.link-list ul li a.text-link:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*.link-list ul li a.cta-link {*/
/*    margin: 0;*/
/*    text-decoration: none;*/
/*    display: block;*/
/*}*/


/*.link-list link-icon svg path {*/
/*    color: var(--color-dnv-interactive-blue);*/
/*}*/
/*.link-list link-icon:hover svg path {*/
/*    color: var(--color-dnv-hover-green);*/
/*}*/
/*.link-list link-icon:active svg path {*/
/*    color: var(--color-dnv-active-black);*/
/*}*/
/*.link-list link-icon:focus svg path {*/
/*    border: 2px solid var(--color-dnv-focus-green);*/
/*}*/

/*.link-list a.body {*/
/*    font-size: 1rem;*/
/*    line-height: 1.75;*/
/*    font-weight: 700;*/
/*    color: var(--color-dnv-interactive-blue);*/
/*    !*color: #0980b5;*!*/
/*}*/

/*.link-list a.body:hover,*/
/*.link-list a.body:hover i {*/
/*    color: var(--color-dnv-hover-green);*/
/*}*/
/*.link-list a.body:active,*/
/*.link-list a.body:active i {*/
/*    !*color: #000000;*!*/
/*    color: var(--color-dnv-active-black);*/
/*}*/

/*.link-list a.body svg {*/
/*    margin-left: 8px;*/
/*}*/
/*.link-list a.h1{*/
/*    font-size: 2.5rem;*/
/*    line-height: 1.5;*/
/*    font-weight: 700;*/
/*    !*color: #0980b5;*!*/
/*    color: var(--color-dnv-interactive-blue);*/
/*}*/
/*.link-list a.h2{*/
/*    font-size: 1.75rem;*/
/*    line-height: 1.25;*/
/*    font-weight: 600;*/
/*    color: var(--color-dnv-interactive-blue)*/
/*    !*color: #0980b5;*!*/
/*}*/
/*.link-list a.h3{*/
/*    font-size: 1.25rem;*/
/*    line-height: 1.25;*/
/*    font-weight: 500;*/
/*    !*color: #0980b5;*!*/
/*    color: var(--color-dnv-interactive-blue);*/
/*}*/
/*.link-list a.body svg, a.h1 svg , a.h2 svg , a.h3 svg {*/
/*    margin-left: 8px;*/
/*}*/
/*.link-list ul li  a.h1 svg.fa-arrow-up-right-from-square, */
/*.link-list ul li  a.h1 svg.fa-arrow-right {*/
/*    width: 24px;*/
/*    height: 24px;*/
/*}*/
/*.link-list ul li  a.h2 svg.fa-arrow-up-right-from-square,*/
/*.link-list ul li  a.h1 svg.fa-arrow-right {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*}*/
/*.link-list ul li  a.h3 svg.fa-arrow-up-right-from-square,*/
/*.link-list ul li  a.h3 svg.fa-arrow-right {*/
/*    width: 16px;*/
/*    height: 16px;*/
/*}*/

/*.link-list svg {*/
/*    font-size: var(--font-size-small);*/
/*}*/

/*.link-list li.link-list-inline svg{*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    margin-right: 8px;*/
/*    margin-bottom: 6px;*/
/*}*/

/*.link-list ul li a svg path {*/
/*    !*fill: #0980b5;*!*/
/*    fill: var(--color-dnv-interactive-blue);*/
/*}*/
/*.link-list ul li a svg {*/
/*    margin-left: 10px;*/
/*}*/
/*svg.bullet-point{*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    margin-right: 8px;*/
/*    margin-top: 3px;*/
/*    margin-bottom: 6px;*/
/*}*/
/*svg.bullet-point path {*/
/*    fill: #333 !important;*/
/*}*/
/*.link-list ul li  svg.h1.bullet-point{*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    margin-bottom: 20px;*/
/*}*/
/*.link-list ul li svg.h2.bullet-point{*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    margin-bottom: 14px;*/
/*}*/
