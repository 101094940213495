/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

/* links */
.calendar a:not(:where(.button, .btn)) {
    font-weight: 700;
    text-decoration: none;
    color: var(--color-dnv-brand-blue);
    transition: color 0.2s ease-in-out;
    text-decoration-color: var(--color-dnv-brand-blue);
}

.calendar a:not(:where(.button, .btn)):hover {
    color: var(--color-dnv-brand-green);
    text-decoration: none;
}

.calendar a:not(:where(.button, .btn)):hover:focus {
    outline: none;
}

.calendar a:not(:where(.button, .btn)):focus {
    outline: 2px dashed var(--color-dnv-brand-green);
    outline-offset: 2px;
}

.calendar a:not(:where(.button, .btn)):active {
    color: var(--color-black);
}

.calendar a:not(:where(.button, .btn)):active:focus {
    outline: none;
}

.calendar a:not(:where(.button, .btn)).link--inline {
    text-decoration: none;
}

.calendar a:not(:where(.button, .btn)).link--icon {
    position: relative;
    padding-left: 28px;
}

.calendar a:not(:where(.button, .btn)).link--icon *[class*=fa-] {
    font-size: 18px;
    margin-left: 10px;
}

.calendar a:not(:where(.button, .btn)).link--icon--left {
    padding-left: 0;
    padding-right: 28px;
}

.calendar a:not(:where(.button, .btn)).link--icon--left *[class*=fa-] {
    left: auto;
    right: 0;
}

.calendar a:not(:where(.button, .btn)).link--dark-blue {
    color: var(--color-white);
    text-decoration: none;
}

.calendar a:not(:where(.button, .btn)).link--dark-blue:hover {
    color: var(--color-dnv-hover-bright-yellow);
}

.calendar a:not(:where(.button, .btn)).link--dark-blue:focus {
    outline-color: var(--color-white);
}

.calendar a:not(:where(.button, .btn)).link--dark-blue:active {
    color: var(--color-dnv-active-bright-blue);
}

.calendar a:not(:where(.button, .btn)).link--orange {
    color: var(--color-dnv-interactive-dark-blue);
    text-decoration: none;
}

.calendar a:not(:where(.button, .btn)).link--orange:hover {
    color: var(--color-dnv-hover-dark-green);
}

.calendar a:not(:where(.button, .btn)).link--orange:focus {
    outline-color: var(--color-dnv-hover-dark-green);
}

.calendar a:not(:where(.button, .btn)).link--orange:active {
    color: var(--color-black);
}

.calendar a:not(:where(.button, .btn)).link--light-blue {
    color: var(--color-dnv-interactive-dark-blue);
    text-decoration: none;
}

.calendar a:not(:where(.button, .btn)).link--light-blue:hover {
    color: var(--color-dnv-hover-dark-green);
}

.calendar a:not(:where(.button, .btn)).link--light-blue:focus {
    outline-color: var(--color-dnv-hover-dark-green);
}

.calendar a:not(:where(.button, .btn)).link--light-blue:active {
    color: var(--color-black);
}

.calendar a:not(:where(.button, .btn)).link--light-blue.link--quicklink {
    padding-left: 43px;
}

.calendar a:not(:where(.button, .btn)).link--light-blue.link--quicklink *[class*=fa-] {
    font-size: 26px;
}


/* tables */
.calendar table {
    width: 100%;
    /*margin-bottom: 32px;*/
    margin-bottom: 0;
    border-collapse: collapse;
}

.calendar table:has(+ .pager) {
    /*margin-bottom: 24px;*/
    margin-bottom: 0;
}

.calendar table .cell-popover {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.calendar table .cell-popover .popover {
    margin-left: -3px;
}

.calendar table.no-wrap th,
.calendar table.no-wrap td {
    text-wrap: nowrap;
}

.calendar table span[class*=fa-] {
    font-size: 18px;
}

.calendar table span[class*=fa-].green {
    color: var(--color-dnv-brand-green);
}

.calendar table span[class*=fa-].red {
    color: var(--color-dnv-messaging-red);
}

.calendar table thead {
    border: 0;
    border-bottom: 2px solid var(--color-black);
}

.calendar table tbody {
    border: 0;
}

.calendar table tfoot {
    border: 0;
}

.calendar table tr {
    border: 0;
    /*border-bottom: 1px solid var(--color-dnv-bg-and-border-grey);*/
    border-bottom: 1px solid var(--color-dnv-advisory-border-light-grey);
}

.calendar table tr:nth-child(even) {
    /*background-color: var(--color-dnv-bg-and-border-pale-grey);*/
    background-color: unset;
}

.calendar table th {
    border: 0;
    /*padding: 15px 16px 11px 16px;*/
    padding: 0;
    text-align: left;
    font-weight: bold;
}

.calendar table th[tabindex="0"] {
    color: var(--color-dnv-brand-blue);
    transition: color 0.2s ease-in-out;
    position: relative;
}

.calendar table th[tabindex="0"]:hover {
    color: var(--color-dnv-brand-green);
    text-decoration: none;
    cursor: pointer;
}

.calendar table th[tabindex="0"]:hover:after {
    color: var(--color-dnv-brand-green);
}

.calendar table th[tabindex="0"]:hover:focus {
    outline: none;
}

.calendar table th[tabindex="0"]:focus {
    outline: 2px dashed var(--color-dnv-focus-green);
    outline-offset: -3px;
}

.calendar table th[tabindex="0"]:active {
    color: var(--color-black);
}

.calendar table th[tabindex="0"]:active:after {
    color: var(--color-black);
}

.calendar table th[aria-sort]:after {
    content: "\f2ec";
    font-family: "Font Awesome 6 Sharp";
    font-size: 12px;
    margin-left: 14px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}

.calendar table th[aria-sort=descending]:after {
    /*transform: rotate(180deg) translateY(50%);*/
    transform: unset;
}

.calendar table td {
    padding: 0;
    /*padding: 16px;*/
    line-height: 1;
    /*line-height: 1.442;*/
    border: 1px solid var(--color-dnv-advisory-border-light-grey);
}

.calendar .scrollable {
    clear: both;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-bottom: 32px;
}

.calendar .scrollable:has(+ .pager) {
    margin-bottom: 24px;
}

.calendar .scrollable table {
    margin: 0;
}

.calendar .scrollable > div {
    overflow-x: auto;
}

.calendar .scrollable > div::-webkit-scrollbar {
    height: 12px;
}

.calendar .scrollable > div::-webkit-scrollbar-track {
    padding: 5px 0;
    background: unset;
}

.calendar .scrollable > div::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: unset;
}

.calendar .scrollable > div:focus {
    outline: none;
}

.calendar .scrollable.has-scroll > div:after {
    border-radius: 10px 0 0 10px/50% 0 0 50%;
    box-shadow: -5px 0 10px rgba(var(--color-black), 0.25);
    content: "";
    height: 100%;
    left: 100%;
    overflow: hidden;
    position: absolute;
    /*position: unset;*/
    top: 0;
    width: 50px;
}

.calendar .scrollable.has-scroll .no-wrap .cell-popover .popover {
    margin-left: 10px;
}

.calendar .table-legend-results {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px 32px;
}

.calendar .table-legend-results__legend {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 30px;
}

.calendar .table-legend-results__legend-item {
    display: flex;
    align-items: center;
    gap: 15px;
}

.calendar .table-legend-results span[class*=fa-] {
    font-size: 18px;
}

.calendar .table-legend-results span[class*=fa-].green {
    color: var(--color-dnv-brand-green);
}

.calendar .table-legend-results span[class*=fa-].red {
    color: var(--color-dnv-messaging-red);
}
