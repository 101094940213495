/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.image-list {
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
.image-list .image-list-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
@media only screen and (min-width: 600px) {
    .image-list .image-list-item {
        flex-direction: row;
    }
}
.image-list .image-list-item img {
    object-fit: cover;
}
.image-list .image-list-item .rich-text-display {
    margin-bottom: 0;
}
.image-list .image-list-item .rich-text-display p {
    margin-bottom: var(--spacing-extra-small-margin-bottom);
}
.image-list .image-list-item .image-list-item-link {
    display: flex;
    align-items: center;
}
.image-list .image-list-item .image-list-item-link svg path,
.image-list .image-list-item .image-list-item-link a {
    color: var(--color-dnv-interactive-blue);
    fill: var(--color-dnv-interactive-blue);
    font-size: var(--font-size-medium);
    font-family: var(--font-family-links-labels);
    text-decoration: underline;
}
.image-list .image-list-item .image-list-item-link:hover svg path,
.image-list .image-list-item .image-list-item-link:hover a {
    color: var(--color-dnv-hover-green);
    fill: var(--color-dnv-hover-green);
}
.image-list .image-list-item .image-list-item-link:active svg path,
.image-list .image-list-item .image-list-item-link:active a:active {
    color: var(--color-dnv-active-black);
    fill: var(--color-dnv-active-black);
}
/*.image-list .image-list-item .image-list-item-link a:focus {*/
/*    border: 3px dashed var(--color-dnv-focus-green);*/
/*    padding: 4px;*/
/*}*/
