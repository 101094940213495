/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.layout-fullwidth {
    background-color: var(--color-white);
    padding-right: 80px;
    padding-left: 80px;
    padding-top: 48px;
    padding-bottom: 48px;
}

.layout-fullwidth .content p {
    max-width: 100%;
}

.layout-fullwidth .content p {
    max-width: 100%;
}

@media (max-width: 1200px) {
    .layout-fullwidth{
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (max-width: 480px) {
    .layout-fullwidth{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
