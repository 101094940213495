/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.event .layout {}

.event .event-heading {
    font-size: 2rem;
    line-height: 1.1;
    font-family: var(--font-family-heading);
    margin-top: 16px;
    margin-bottom: 2rem;
    text-transform: none !important;
}
.event .layout .event-date {
    font-family: var(--font-family-links-labels);
    font-weight: 900;
    font-size: 17px;
    color: var(--color-dnv-text-black);
}

.event-time,
.event-location-name,
.event-location-address,
.event-online-event,
.event-detail,
.event-additional,
.event-add-to-calendar {
    display: block;
    width: 100%;
    min-height: 24px;
}
.event-detail {
    margin-top: 20px;
}
.event-detail p {
    font-size: 1.0625rem;
}
