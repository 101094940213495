/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */


/*.e-schedule .e-month-view .e-appointment {*/
/*    background: var(--color-dnv-interactive-blue);*/
/*    color: white*/
/*}*/
/*.e-appointment {*/
/*    background: var(--color-dnv-interactive-blue);*/
/*    color: white*/
/*}*/
/*.e-appointment:hover {*/
/*    background: var(--color-dnv-interactive-dark-blue);*/
/*    color: var(--color-dnv-text-white)*/
/*}*/
/*.e-schedule .e-agenda-view .e-appointment:hover {*/
/*    background: var(--color-dnv-interactive-dark-blue);*/
/*    color: var(--color-dnv-text-white);*/
/*}*/
/*.e-schedule .e-month-agenda-view .e-appointment:hover {*/
/*    background: var(--color-dnv-hover-green);*/
/*    color: var(--color-dnv-text-white);*/
/*}*/
/*.e-schedule .e-month-view .e-current-date .e-date-header,*/
/*.e-schedule .e-month-agenda-view .e-current-date .e-date-header {*/
/*    background: var(--color-dnv-hover-green);*/
/*    border-radius: 50%;*/
/*    color: var(--color-dnv-text-white);*/
/*    min-width: 20px;*/
/*    padding: 0 4px;*/
/*    width: -moz-fit-content;*/
/*    width: fit-content;*/
/*}*/
/*.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day, .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {*/
/*    color: var(--color-dnv-hover-green);*/
/*    font-weight: normal;*/
/*}*/

.website-tabs-bar {
    margin-bottom: -2px;
}
.website-tabs-container {
    padding: 32px;
}

.calendar-template-wrap {
    padding: 0 6px 0 6px;
    overflow-y: hidden;
    /*background-color: red;*/
    /*line-height: 20px;*/
    /*height: 80px;*/
    height: 100%;
    overflow: hidden;
}
.calendar-subject {
    white-space: normal;
    overflow-y: hidden;
    line-height: 16px;
    height: 32px;
    overflow: hidden;
}
.calendar-time {

}

