/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.feed-search-bar {
    margin-top: 24px;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    --padding-y: 13px;
    font-family: var(--font-family-paragraph);
    font-size: var(--font-size-medium);
}

.feed-search-bar button,
.feed-search-bar input {
    font-size: inherit;
    font-family: inherit;
}
.feed-search-inputContainer .full-search-input {
    margin-right: 2rem;
}
.feed-search-inputContainer label {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-medium);
    margin-bottom: 4px;
    /*display: block;*/
    display: inline-block;
}
.search-select {
    margin-right: 2rem;
}
.feed-search-inputContainer {
    margin-top: 12px;
}
.feed-search-inputContainer .feed-search-input {
    margin-right: 2rem;
}
.feed-search-inputContainer button {
    margin-right: 2rem;
}
.feed-search-button {
    height: 48px;
    margin-top: 4px;
    padding: calc(var(--padding-y) + 2px) 20px;
    background-color: var(--color-dnv-interactive-blue);
    color: var(--color-dnv-text-white);
    border: none;
}
.feed-search-button:hover {
    background-color: var(--color-dnv-hover-green);
    color: var(--color-dnv-text-white);
}
.feed-search-button:active {
    background-color: var(--color-dnv-active-black);
    color: var(--color-dnv-text-white);
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 820px) {
    .feed-search-bar {
        display: flex;
        flex-direction: row;
    }
    .feed-search-inputContainer {
        margin-top: 0;
    }
    .feed-search-button {
        height: 48px;
        margin-top: 4px;
        /* match the border width on the submit button */
        padding: calc(var(--padding-y) + 2px) 20px;
        margin-left: 30px;
        background-color: var(--color-dnv-interactive-blue);
        color: var(--color-dnv-text-white);
    }
}

/* -------Feed pagination (may be combined with search page later) ------- */
.feed-pagination-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 24px;
}
.feed-pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 600px;
    gap: 8px;
}
.feed-pagination-ellipsis {
    align-self: center;
    color: var(--color-dnv-active-black)
}
.feed-pagination-item {
    color: var(--color-dnv-interactive-blue);
    padding: 12px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
a.feed-pagination-item,
a.feed-pagination-item-square {
    text-decoration: none;
}
.feed-pagination-item .feed-pagination-text {
    display: none;
}
.feed-pagination-item,
.feed-pagination-item-square {
    cursor: pointer;
    font-weight: 700;
}
.feed-pagination-item-square {
    color: var(--color-dnv-interactive-blue);
    max-width: 45px;
    min-width: 36px;
    padding: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feed-pagination-item-square:hover {
    color: var(--color-dnv-hover-green);
}
.feed-pagination-item-square:active {
    color: var(--color-dnv-active-black);
}
.feed-pagination-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.feed-pagination-item.disabled {
    color: var(--color-disabled);
    cursor: default;
}
a.feed-pagination-item.disabled:hover {
    text-decoration: none;
    color: var(--color-disabled);
}

a.feed-pagination-selected-item {
    background-color: var(--color-dnv-interactive-blue);
    color: white;
}
a.feed-pagination-selected-item:hover {
    background-color: var(--color-dnv-hover-green);
    color: var(--color-dnv-text-white)
}
a.feed-pagination-selected-item:active {
    background-color: var(--color-dnv-active-black);
    color: var(--color-dnv-text-white)
}

@media screen and (max-width: 768px){
    .feed-search-inputContainer .feed-search-input{
        margin-right: 0px;
    }
}

/* responsive styles for breakpoints greater than mobile */
/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .feed-pagination-item {
        gap: 6px;
    }

    .feed-pagination-item .feed-pagination-text {
        display: block;
    }


}
