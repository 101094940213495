/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.navigation-list-item {
    border-bottom: 2px solid var(--color-border-grey);
}
.navigation-list-item .section-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}
.navigation-list-item .section-summary .section-summary-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    /*border: 1px solid red;*/
}
.navigation-list-item .section-summary .section-summary-content .section-summary-icon {
    /*border: 1px solid red;*/
    margin-right: 2rem;
    margin-left: 1rem;
    font-size: 32px;
    color: #575757;
    width: 40px;
    min-width: 40px;
}
.navigation-list-item .section-summary .section-summary-content .section-summary-icon svg{
    vertical-align: top;
}
.navigation-list-item .section-summary .section-summary-content .section-summary-content-detail {
    max-width: 610px;
}
.navigation-list-item .section-summary .section-summary-content .navigation-list-item-title {
    font-family: var(--font-family-heading);
    color: var(--color-dnv-brand-blue);
    font-size: 1.25rem;
}
.navigation-list-item .section-summary.expanded .section-summary-content .navigation-list-item-title {
    font-family: var(--font-family-heading);
    color: var(--color-dnv-hover-green);
}
.navigation-list-item .section-summary .section-summary-content .section-summary-expand {
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navigation-list-item .section-summary-expand svg {
    width: 24px;
    height: 24px;
}
.navigation-list-item .section-summary-expand .fa-minus{
    display: none;
}
.navigation-list-item .section-summary-expand .fa-plus{
    display: block;
}
.navigation-list-item .section-summary.expanded .section-summary-expand  {
    color: var(--color-dnv-hover-green);
}
.navigation-list-item .section-summary.expanded .section-summary-expand .fa-plus {
    display: none;
}
.navigation-list-item .section-summary.expanded .section-summary-expand .fa-minus{
    display: block;
}
.navigation-list-item .navigation-list-item-children {
    padding-bottom: 60px;
    padding-left: 63px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item .icon {
    width: 32px;
    height: 32px;
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item .icon + .text{
    width: calc(100% - 32px);
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item .icon svg {
    margin: 0 0.5em;
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item .icon svg path {
    color: var(--color-dnv-interactive-blue)
}

.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item {
    width: 100%;
    border-bottom: 1px solid var(--color-border-grey);
    padding-bottom: 1rem;
}
@media only screen and (min-width: 600px) {
    .navigation-list-item .navigation-list-item-children .navigation-list-item-child-item {
        width: calc(50% - 1rem);
    }
}
.navigation-list-item .navigation-list-item-children .navigation-list-item-child-item a {
    font-size: var(--font-size-medium);
    color: var(--color-dnv-interactive-blue);
}
.navigation-list-item .MuiAccordionSummary-content.Mui-expanded{
    margin: 12px 0px;
}
.navigation-list-body li {
    list-style-type: disc;
    list-style-position: outside;
    font-size: 17px;
    margin-bottom: 6px;
    margin-left: 20px;
}
.navigation-list-description {
    font-size: 18px;
}
.navigation-list-description p {
    margin-top: 4px;
    margin-bottom: 4px;
}
