/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.nav-subsection {
    display: flex;
    flex-direction: row-reverse;
}
@media only screen and (min-width: 1200px) {
    .nav-subsection {
        padding-left: 25px;
        margin-left: auto;
        margin-right: auto;
        max-width: var(--desktop-width);
    }
}
.nav-subsection-left {
    padding-top: 50px;
    max-width: 360px;
    min-width: 360px;
    display: block;
}
@media only screen and (max-width: 1124px) {
    .nav-subsection-left .side-nav {
        display: none;
    }
    .nav-subsection-left {
        width: 100%;
        max-width: 100%;
    }
    .nav-subsection {
        flex-direction: column;
    }
}
.nav-subsection .component {
    display: block;
    width: 100%;
}
.nav-subsection-right {
    background-color: var(--color-white);
    flex:1;
    padding: 2em;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
@media only screen and (min-width: 1200px) {
    .nav-subsection-right {
        display: block;
        padding: 54px 80px;
    }
}
.nav-subsection-right .main-content .summary{
    /*padding-right: 80px;*/
    margin-top: var(--spacing-default-component-margin-bottom);
    margin-bottom: var(--spacing-default-component-margin-bottom);
}
