/** @format */
.layout.integration:has(.mobile-rap-public) {
  padding-left: 1rem;
  padding-right: 1rem;
}

.rap-public-modal {
  line-height: 1.5;
  position: relative;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-size: 20px;
}
.rap-public-modal .MuiDialog-container .MuiPaper-root {
  max-width: 750px;
}
.rap-public-modal #icon-button {
  display: none;
}
.rap-public-modal .modal-heading {
  padding: 1rem;
  font-size: 24px;
  background-color: #0980b5;
  color: white;
}
.rap-public-modal .textEntry {
  margin-bottom: 1rem;
}
.rap-public-modal .progressToRestore .dataRestorable {
  margin-top: 1rem;
  padding: 1rem;
  border: solid 1px #d3d3c8;
}
.rap-public-modal .progressToRestore .dataRestorable .modal-item {
  margin-bottom: 0.5rem;
}
.rap-public-modal .progressToRestore .dataRestorable .modal-item:last-child {
  margin: auto;
}
.rap-public-modal .modal-btns {
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
}
.rap-public-modal .modal-btns button {
  height: 3rem;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 0.375rem;
}

.dnv-widget .grecaptcha-badge {
  visibility: hidden;
}
.dnv-widget .rap-public {
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 20px;
  margin: -0.25rem;
}
.dnv-widget .rap-public p {
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  margin-top: 1rem;
}
.dnv-widget .rap-public .asteriskColoring {
  color: red;
  font-weight: bold;
}
.dnv-widget .rap-public h2 {
  font-size: 1.5rem;
  margin-bottom: 0rem;
  margin-top: revert;
  line-height: normal;
  font-weight: bold;
}
.dnv-widget .rap-public h3 {
  margin-bottom: 1rem;
  font-size: 1.375rem;
  margin-top: revert;
  font-weight: revert;
  line-height: normal;
}
.dnv-widget .rap-public select {
  font-size: 20px;
}
.dnv-widget .rap-public .preNextErrorInput {
  border: 2px solid red !important;
}
.dnv-widget .rap-public .preNextErrorLabel {
  color: red;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: normal !important;
  font-size: 18px !important;
}
.dnv-widget .rap-public .preNextErrorMsgText {
  margin-bottom: 0;
}
.dnv-widget .rap-public a {
  text-decoration: none;
}
.dnv-widget .rap-public a.lnk {
  color: #0980b5;
  font-weight: bold;
}
.dnv-widget .rap-public a.lnk:hover {
  text-decoration: underline;
}
.dnv-widget .rap-public a:hover {
  text-decoration: underline;
}
.dnv-widget .rap-public textarea {
  font-size: 20px;
}
.dnv-widget .rap-public .microcopy {
  font-size: 15px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}
.dnv-widget .rap-public .devPageSelectBox {
  height: 2rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;
  margin-top: 1rem;
}
.dnv-widget .rap-public .navButton {
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
}
.dnv-widget .rap-public .navButton.problemSelectionStep {
  justify-content: end;
}
.dnv-widget .rap-public .navButton .reviewBtn {
  display: block;
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
}
.dnv-widget .rap-public .navButton .reviewBtn button {
  margin-right: 0;
  float: right;
  background-color: white;
  color: #0980b5;
  border: none;
  text-decoration: underline;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0;
  padding-left: 0;
  width: 102px;
  height: 3rem;
}
.dnv-widget .rap-public .navButton .reviewBtn button:hover {
  color: rgb(0, 136, 14);
}
.dnv-widget .rap-public .navButton .backBtn {
  height: 3rem;
  font-size: 16px;
  background-color: white;
  border: 2px solid rgb(9, 128, 181);
  color: #1b8898;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.dnv-widget .rap-public .navButton .backBtn:hover {
  border: 2px solid rgb(0, 136, 14);
  color: rgb(0, 136, 14);
  cursor: pointer;
}
.dnv-widget .rap-public .navButton .NextBtn {
  height: 3rem;
  background-color: #0980b5;
  border: 1px solid #0980b5;
  color: white;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  margin-left: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.dnv-widget .rap-public .navButton .NextBtn:hover {
  background-color: rgb(0, 136, 14);
  border: 1px solid rgb(0, 136, 14);
  cursor: pointer;
}
.dnv-widget .rap-public .navButton .NextBtn.startOverBtn {
  margin-left: 0;
}
.dnv-widget .rap-public .required-field::after {
  content: " *";
  color: red;
  display: inline;
}
.dnv-widget .rap-public .searchContainer {
  position: absolute;
  top: 0px;
  width: 100%;
}
.dnv-widget .rap-public .searchContainer .searchField {
  position: absolute;
  z-index: 1;
  margin: 1rem;
  width: calc(100% - 2rem);
}
.dnv-widget .rap-public .searchContainer .searchField input {
  height: 56px;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  padding-left: 50px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 50px;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  border: #575757 2px solid;
}
.dnv-widget .rap-public .searchContainer .searchField .searchIcon {
  position: absolute;
  top: 15px;
  left: 17px;
}
.dnv-widget .rap-public .searchContainer .searchField .searchIcon svg {
  height: 25px;
  width: 25px;
}
@media screen and (max-width: 479px) {
  .dnv-widget .rap-public .bigDisplayOnly {
    display: none !important;
  }
  .dnv-widget .rap-public .smallDisplayOnly {
    display: block !important;
  }
  .dnv-widget .rap-public #map:not(.entryMap) {
    height: 18rem;
    margin-left: calc(50% - 50vw);
    width: calc(100vw - 2px);
  }
  .dnv-widget .rap-public #map.entryMap {
    height: 20rem !important;
  }
  .dnv-widget .rap-public .submissionElement span {
    display: block;
  }
  .dnv-widget .rap-public .removePhone {
    display: block;
    margin-bottom: 1rem;
    margin-left: 0 !important;
  }
  .dnv-widget .rap-public .phoneDetails {
    display: flex;
  }
  .dnv-widget .rap-public .phoneDetails input {
    max-width: none !important;
  }
  .dnv-widget .rap-public .addedPhoneDetails {
    display: inline-flex !important;
  }
  .dnv-widget .rap-public .answerTextboxEntry:not(.ageInput),
  .dnv-widget .rap-public .answerSelectBox {
    width: 100% !important;
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }
  .dnv-widget .rap-public .vehicleFirstRow {
    width: 100%;
  }
  .dnv-widget .rap-public .textEntryInfo {
    line-height: 1.5rem;
  }
  .dnv-widget .rap-public .questionTooltip .tooltipText {
    width: 50vw !important;
  }
  .dnv-widget .rap-public .nameInfo {
    display: block !important;
  }
  .dnv-widget .rap-public .nameInfo .firstName,
  .dnv-widget .rap-public .nameInfo .lastName {
    width: 100% !important;
  }
  .dnv-widget .rap-public .nameInfo input {
    margin-right: 0% !important;
  }
  .dnv-widget .rap-public .moreInfoContainer {
    width: auto !important;
  }
  .dnv-widget .rap-public h2.what-is {
    white-space: nowrap;
  }
}
@media screen and (max-width: 719px) {
  .dnv-widget .rap-public .containers {
    display: block !important;
  }
  .dnv-widget .rap-public .containers .infoContainer {
    width: 100% !important;
  }
  .dnv-widget .rap-public .containers .infoContainer.left {
    margin-bottom: 2rem !important;
  }
  .dnv-widget .rap-public .nameInfo .firstName,
  .dnv-widget .rap-public .nameInfo .lastName {
    width: 100% !important;
  }
}
@media screen and (min-width: 720px) {
  .dnv-widget .rap-public .emailInfo input {
    width: 796px !important;
  }
  .dnv-widget .rap-public .firstName input,
  .dnv-widget .rap-public .lastName input {
    width: 375px !important;
  }
  .dnv-widget .rap-public .addressInfo input {
    width: 796px !important;
  }
}
.dnv-widget .rap-public .landingPageComponent .linksContainer .links-title {
  font-size: 22px;
  font-weight: bold;
  padding-top: 2rem;
}
.dnv-widget .rap-public .landingPageComponent .linksContainer .links {
  padding-top: 1rem;
}
.dnv-widget .rap-public .landingPageComponent .linksContainer .links a {
  color: #0980b5;
}
.dnv-widget .rap-public .landingPageComponent .googleRecaptchaInfo {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.dnv-widget .rap-public .landingPageComponent .reviewBtn {
  display: block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 2rem;
}
.dnv-widget .rap-public .landingPageComponent .reviewBtn button {
  margin-right: 0;
  float: right;
  background-color: white;
  color: #0980b5;
  border: none;
  text-decoration: underline;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0;
  padding-left: 0;
  width: 102px;
  height: 2.5rem;
}
.dnv-widget .rap-public .landingPageComponent .reviewBtn button:hover {
  color: rgb(0, 136, 14);
}
.dnv-widget .rap-public .progressBarComponent #title:hover {
  cursor: pointer;
}
@media (min-width: 480px) {
  .dnv-widget .rap-public .progressBarComponent .stepName {
    display: contents;
  }
}
@media (max-width: 479px) {
  .dnv-widget .rap-public .progressBarComponent .stepName {
    display: none !important;
  }
  .dnv-widget .rap-public .progressBarComponent .stepper-wrapper {
    max-width: 95% !important;
  }
}
.dnv-widget .rap-public .progressBarComponent .stepper-wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 75%;
}
.dnv-widget .rap-public .progressBarComponent .stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.dnv-widget .rap-public .progressBarComponent .stepper-item .step-name {
  text-align: center;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  display: block;
  height: calc(100% - 46px);
  width: 100%;
}
@media (max-width: 768px) {
  .dnv-widget .rap-public .progressBarComponent .stepper-item {
    font-size: 12px;
  }
}
.dnv-widget .rap-public .progressBarComponent .stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 5px solid #ccc;
  width: 100%;
  top: 17px;
  left: 50%;
  z-index: 2;
}
.dnv-widget .rap-public .progressBarComponent .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}
.dnv-widget .rap-public .progressBarComponent .step-counter.selectable {
  cursor: pointer;
}
.dnv-widget .rap-public .progressBarComponent .step-counter.active {
  font-weight: bold;
  color: white;
  background-color: rgb(9, 128, 181) !important;
}
.dnv-widget .rap-public .progressBarComponent .step-counter.completed {
  background-color: rgb(9, 128, 181) !important;
  color: white;
  font-size: 16px;
}
.dnv-widget .rap-public .progressBarComponent .parentComplete::after {
  position: absolute;
  content: "";
  border-bottom: 5px solid rgb(9, 128, 181);
  width: 100%;
  top: 17px;
  left: 50%;
  z-index: 3;
}
.dnv-widget .rap-public .progressBarComponent .stepper-item:first-child::before {
  content: none;
}
.dnv-widget .rap-public .progressBarComponent .stepper-item:last-child::after {
  content: none;
}
.dnv-widget .rap-public .problemTypeMenuComponent .problemSearchInfo {
  line-height: 28px;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer {
  width: 100%;
  display: inline-flex;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection {
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch {
  position: relative;
  height: 105px;
  top: -10px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch p {
  text-align: center;
  font-size: 17px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch p.showCounter {
  display: block;
  position: absolute;
  top: 4rem;
  left: 25%;
  right: 25%;
  z-index: 1;
  margin-top: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch .noSearchResults {
  display: flex;
  width: 100%;
  position: absolute;
  top: 5rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch .noSearchResults span {
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-weight: 100;
  margin: auto;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .problemSearch #problemSearchField:focus {
  border: 2px solid #00880e;
  outline: none;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection {
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .selected {
  background-color: lightgrey;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root {
  box-shadow: none !important;
  border-bottom: solid #d3d3c8 2px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root {
  min-height: 50px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root:hover:not(.Mui-expanded) .categoryText {
  color: #00880e !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root .Mui-expanded .categoryText {
  color: #bba600 !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root .categoryMenuInfo {
  width: 90%;
  display: flex;
  margin-left: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root .categoryMenuInfo .categoryIcon {
  font-size: 1rem;
  width: 40px;
  margin-right: 0.5rem;
  fill: #575757;
  padding-top: 6px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root .categoryMenuInfo .categoryText {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  color: rgb(9, 128, 181);
  width: calc(100% - 40px);
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root .MuiAccordionSummary-root .categoryMenuInfo .levelOneMenuInfo {
  font-size: 20px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordion-root.Mui-expanded #removeIcon {
  color: #bba600;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel1 .MuiAccordionDetails-root {
  padding: 0px 0px 0px 80px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel2 .MuiAccordion-root {
  border-bottom: none !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel2 .levelTwoMenuInfo {
  font-size: 17px;
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel2 .MuiAccordionSummary-root:hover {
  text-decoration: underline;
  color: #00880e;
  cursor: pointer;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel2 .MuiAccordionDetails-root {
  padding: 0px 0px 0px 44px !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel3 {
  min-height: 50px;
  line-height: 25px;
  display: flex;
  padding: 0px 0px 0px 16px !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel3:hover {
  color: #00880e;
  cursor: pointer;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .problemSelection .categorySelection .menuLevel3 .levelThreeMenuInfo {
  font-size: 17px;
  height: 100%;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .commonSearches {
  width: 20%;
  margin-left: 60px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .commonSearches .commonSearchesTitle {
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .commonSearches .commonSearchesItem {
  color: #1b8898;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .selectionContainer .commonSearches .commonSearchesItem:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
  transition: all 0.5s;
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: scroll;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow.close {
  top: 100%;
  height: 0;
  overflow: hidden;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer {
  display: flex;
  position: relative;
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .exitFilterIcon {
  margin: auto;
  width: 2rem;
  color: #1b8898;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .exitFilterIcon svg {
  margin-left: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .mobileSearchField {
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .mobileSearchField input {
  width: 100%;
  width: -webkit-fill-available;
  height: 2.5rem;
  padding-right: 2rem;
  border: none;
  border-bottom: 1px solid lightgray;
  font-size: 16px;
  font-family: "Avenir LT Std 95 Black", "Avenir LT Std 55 Roman", "Avenir LT Std", sans-serif;
  /* FF 4-18 */
  /* FF 19-51 */
  /* IE 10+ */
  /* Modern Browsers */
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .mobileSearchField input:focus {
  outline: none;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .mobileSearchField input::-moz-placeholder {
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif, "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .mobileSearchField input::placeholder {
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif, "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .clearTextIcon {
  margin: auto;
  width: 2rem;
  color: #1b8898;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputContainer .clearTextIcon svg {
  margin-right: 1rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .commonSearchContainer .title {
  padding: 0.75rem;
  color: white;
  background-color: #166079;
  font-size: 14px;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .commonSearchContainer .commonSearchItem {
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  color: #1b8898;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection {
  margin-top: 0;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .noSearchResults {
  display: flex;
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .noSearchResults span {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  font-weight: 100;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordion-root {
  border-bottom: none !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root {
  display: flex;
  padding: 0;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root:hover {
  text-decoration: none !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-content {
  max-width: 90%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-content .categoryMenuInfo {
  width: 100% !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-content .categoryMenuInfo .categoryIcon {
  width: 10% !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-content .categoryMenuInfo .categoryText {
  width: 90% !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  width: 10%;
  display: flex;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .menuLevel1 .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg {
  margin: auto;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root {
  padding: 0 !important;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel2 {
  display: flex;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel2 .MuiAccordion-root {
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel2 .MuiAccordion-root .MuiAccordionSummary-root {
  width: 100%;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel2 .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin-left: calc(10% + 1.5rem);
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel2 .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content .levelTwoGapSpace {
  margin-right: 2rem;
}
.dnv-widget .rap-public .problemTypeMenuComponent .slideWindow .mobileInputFilterResults .categorySelection .MuiAccordionDetails-root .menuLevel3 {
  padding: 0px 2rem 0px 3.5rem !important;
}
.dnv-widget .rap-public .problemErrorMessageComponent div {
  font-weight: normal;
}
.dnv-widget .rap-public .problemErrorMessageComponent .heading {
  background-color: rgba(236, 128, 141, 0.7450980392);
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemErrorMessageComponent .heading.isUrgent {
  background-color: #eac99c;
}
.dnv-widget .rap-public .problemErrorMessageComponent .heading svg {
  font-size: 1.5rem;
  vertical-align: middle;
}
.dnv-widget .rap-public .problemErrorMessageComponent .heading span {
  line-height: 4rem;
  margin-left: 0.5rem;
  vertical-align: middle;
  font-size: 22px;
}
.dnv-widget .rap-public .problemErrorMessageComponent .safetyNotification {
  border: solid 1px rgb(0, 79, 89);
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .problemErrorMessageComponent .safetyNotification .safetyNotificationText {
  color: #004f59;
  margin: 1rem;
}
.dnv-widget .rap-public .problemErrorMessageComponent .safetyNotification .safetyNotificationText span {
  font-weight: bold;
}
.dnv-widget .rap-public .problemErrorMessageComponent .isUrgentInfoTag {
  margin-top: 1rem;
  line-height: 2rem;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemErrorMessageComponent .generalMsg {
  line-height: 2rem;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemErrorMessageComponent .addressAnchor {
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 2rem;
  font-style: italic;
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer {
  margin-top: 1rem;
  width: 25%;
  min-width: -moz-max-content;
  min-width: max-content;
  background-color: #f0f0eb;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container {
  padding: 1rem;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container .messageTitle {
  font-weight: bold;
  display: inline;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container .messageTitleInfo {
  margin-left: 0.5rem;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container .message {
  margin-top: 0.5rem;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container .message a {
  color: #0980b5;
}
.dnv-widget .rap-public .problemErrorMessageComponent .moreInfoContainer .container:not(:first-of-type) {
  padding-top: 0;
}
.dnv-widget .rap-public .locationSelectionComponent .selectedMenuItem {
  border: 2px solid rgb(211, 211, 200);
  text-align: center;
  margin-top: 1.5rem;
}
.dnv-widget .rap-public .locationSelectionComponent .selectedMenuItem p {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.dnv-widget .rap-public .locationSelectionComponent .selectedMenuItem p strong {
  font-family: "Avenir LT W01_95 Black1475556", "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
}
.dnv-widget .rap-public .locationSelectionComponent .locationSearchInfo {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .locationSelectionComponent .locationSearchError {
  color: red;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .locationSelectionComponent .locationSearchError a {
  color: #1b8898;
}
.dnv-widget .rap-public .locationSelectionComponent .locationSearchError a:hover {
  cursor: pointer;
}
.dnv-widget .rap-public .locationSelectionComponent .locationContainer {
  position: relative;
}
.dnv-widget .rap-public .locationSelectionComponent .locationSearchInputTitle,
.dnv-widget .rap-public .locationSelectionComponent .mapSelectionTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.75rem;
}
.dnv-widget .rap-public .locationSelectionComponent .mapView {
  position: relative;
}
.dnv-widget .rap-public .locationSelectionComponent .mapView .map {
  width: auto;
  height: 40rem;
  border: black 1px solid;
}
.dnv-widget .rap-public .locationSelectionComponent .leaflet-container {
  width: auto;
  height: 40rem;
  border: 1px solid black;
}
.dnv-widget .rap-public .locationSelectionComponent .leaflet-container:after {
  z-index: 1001 !important;
  background: rgba(0, 0, 0, 0.6) !important;
}
@media screen and (max-width: 479px) {
  .dnv-widget .rap-public .locationSelectionComponent .leaflet-container:not(.entryMap) {
    height: 18rem;
    margin-left: calc(50% - 50vw);
    width: calc(100vw - 2px);
  }
  .dnv-widget .rap-public .locationSelectionComponent .leaflet-container.entryMap {
    height: 20rem !important;
  }
  .dnv-widget .rap-public .locationSelectionComponent .search__input {
    font-size: 16px !important;
  }
}
.dnv-widget .rap-public .locationSelectionComponent .leaflet-bottom.leaflet-right {
  z-index: 999;
}
.dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete {
  position: absolute;
  z-index: 1000;
  border: 1px inset #767676;
  left: 15px;
  max-width: calc(100% - 4rem);
  width: -moz-fit-content;
  width: fit-content;
}
.dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete .item {
  font-size: 14px;
  padding: 0.25rem 1.5rem;
  background-color: white;
  color: black;
}
.dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete .item:hover, .dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete .item.hover {
  background-color: #007bff;
  color: white;
}
.dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete .emptyItemTop {
  padding-bottom: 0.25rem;
  background-color: white;
}
.dnv-widget .rap-public .locationSelectionComponent .inputAutocomplete .emptyItemBottom {
  padding-top: 0.25rem;
  background-color: white;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField {
  position: absolute;
  z-index: 1000;
  left: 15px;
  max-width: calc(100% - 4rem);
  width: 100%;
  margin: 1rem;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .search {
  max-width: none !important;
  display: block !important;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .search .search__input {
  height: 56px;
  width: 100%;
  border: 2px solid transparent !important;
  box-shadow: 3px 3px 5px #6d6d6d;
  border-radius: 2em;
  padding-left: 50px;
  padding-right: 10px;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .search .search__input:focus {
  border: 2px solid rgb(0, 136, 14) !important;
  outline: none;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .search__submit {
  display: none;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .search__mobile-wrapper {
  display: none;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest {
  position: absolute;
  z-index: 1000;
  border: 1px inset #767676;
  left: 15px;
  max-width: calc(100% - 4rem);
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 0px;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest ul {
  margin: 0;
  padding: 0;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item {
  list-style-type: none;
  font-size: 14px;
  padding: 0.25rem 1.5rem;
  background-color: white;
  color: black;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item a {
  text-decoration: none;
  color: black;
  width: 100%;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item:hover, .dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item.hover {
  background-color: #007bff;
}
.dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item:hover a, .dnv-widget .rap-public .locationSelectionComponent .searchField .autosuggest .autosuggest-item.hover a {
  color: white;
}
.dnv-widget .rap-public .detailsInputComponent .asteriskColoring p {
  width: -moz-fit-content;
  width: fit-content;
}
.dnv-widget .rap-public .detailsInputComponent label {
  font-weight: normal;
}
.dnv-widget .rap-public .detailsInputComponent .textEntryContainer .textEntryInfo {
  line-height: 1.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .textEntryTextbox {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 10rem;
  resize: none;
  padding: 0.5rem;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .detailsInputComponent .optionalTag {
  font-weight: normal;
  color: grey;
  font-weight: 100;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer {
  margin-bottom: 2.5rem;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer {
  font-weight: normal;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer p {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 20px;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer p span.questionRequired {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerTextboxEntry,
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerSelectBox {
  resize: none;
  line-height: 1.5rem;
  height: 3rem;
  width: 25%;
  padding: 0.5rem;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  overflow: hidden;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerTextboxEntry.ageInput,
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerSelectBox.ageInput {
  width: 3rem;
  text-align: center;
  font-size: 16px;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerTextboxEntry {
  max-width: calc(500px - 1rem);
  width: calc(100% - 1rem);
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .radioOtherEntryInput {
  margin-left: 32px;
  width: calc(500px - 1rem - 32px);
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer input::-webkit-outer-spin-button,
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer .answerSelectBox {
  height: 2.5rem;
  width: 500px;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .problemQuestionAnswer.hidden {
  display: none;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .vehicleFirstRow {
  display: inline-flex;
  width: 500px;
  margin-right: 1rem;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .vehicleFirstRow .problemQuestionAnswer {
  width: 100%;
}
.dnv-widget .rap-public .detailsInputComponent .problemQuestionsContainer .vehicleFirstRow .problemQuestionAnswer textarea {
  width: 100%;
  width: -webkit-fill-available;
}
.dnv-widget .rap-public .detailsInputComponent .addedPhoto {
  background: url("./ui-includes/images/photo-blank-background.png");
  background-size: 100%;
}
.dnv-widget .rap-public .detailsInputComponent #attachPhotoErrorText {
  color: red;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  min-height: 20rem;
  display: flex;
  border: black dashed 1px;
  background-color: white;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer.attachPhotoError,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile.attachPhotoError {
  border-color: #ff3860;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  margin: auto;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected i::before,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected i::before {
  font-size: 40px;
  color: rgb(9, 128, 181);
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected svg,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected svg {
  width: 2.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected p,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected p {
  width: auto;
  margin-top: 1rem;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected .browseFilesBtn,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected .browseFilesBtn {
  height: 3rem;
  background-color: #0980b5;
  border: 1px solid #0980b5;
  color: white;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  margin-left: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer .dragAndDropNonSelected .browseFilesBtn:hover,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .dragAndDropNonSelected .browseFilesBtn:hover {
  background-color: rgb(0, 136, 14);
  border: 1px solid rgb(0, 136, 14);
  cursor: pointer;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainer.dragging,
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile.dragging {
  background-color: #f5f5f5;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer {
  width: 100%;
  display: block;
  margin-top: 2rem;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoRow1,
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoRow2 {
  display: flex;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoRow1 .photoRowItemWidth,
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoRow2 .photoRowItemWidth {
  width: 33.3%;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoRow2 {
  margin-top: 2rem;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer {
  display: flex;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem {
  width: 125px;
  height: 125px;
  display: flex;
  border: 1px solid black;
  vertical-align: text-top;
  position: relative;
  text-align: center;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem button {
  background-color: rgb(9, 128, 181);
  border: 1px solid rgb(9, 128, 181);
  color: white;
  position: absolute;
  top: -10px;
  right: -12.5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem button:hover {
  cursor: pointer;
  background-color: #166079;
  border: 1px solid #166079;
  color: white;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem.addPhoto {
  border: 1px dashed black;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem.addPhoto .icon {
  margin-top: 25%;
  margin-bottom: 25%;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItem.addPhoto .icon i::before {
  font-size: 2.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItemDetails {
  display: grid;
  width: calc(100% - 167px);
  height: -moz-min-content;
  height: min-content;
  margin: auto 1rem;
  font-weight: normal;
  font-size: 16px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItemDetails .photoName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItemDetails .photoSize {
  margin-top: 0.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItemDetails .rejectImageUpload {
  color: red;
  font-weight: bold;
  margin-top: 0.25rem;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .photoItemDetails.hideDetails {
  display: none;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .progress-bar {
  overflow: hidden;
  width: 100%;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .progress-bar span {
  display: block;
  background: rgba(0, 0, 0, 0.075);
  height: 20px;
  margin-top: 0.25rem;
  position: relative;
}
.dnv-widget .rap-public .detailsInputComponent .desktopSelectedPhotosContainer .photoItemContainer .progress-bar span .progress {
  background: #75b800;
  color: #fff;
  padding: 5px;
  width: 0%;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .cameraControl {
  width: 100%;
  text-align: center;
  margin: auto;
  min-height: 10rem;
  position: relative;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .cameraControl i {
  margin-top: 12.5%;
  margin-bottom: 12.5%;
  font-size: 2.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .cameraControl svg {
  width: 55px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer {
  width: 100%;
  text-align: center;
  display: block;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem {
  width: 100px;
  height: 100px;
  display: inline-flex;
  vertical-align: text-top;
  position: relative;
  margin: 0.75rem;
  text-align: center;
  border: 1px solid black;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem.mobilePhotoErr {
  border: 2px solid red;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem button {
  background-color: rgb(9, 128, 181);
  border: 1px solid rgb(9, 128, 181);
  color: white;
  position: absolute;
  top: -10px;
  right: -12.5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem button:hover {
  cursor: pointer;
  background-color: #166079;
  border: 1px solid #166079;
  color: white;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem.addPhoto {
  border: 1px dashed black;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem.addPhoto .icon {
  margin: auto;
}
.dnv-widget .rap-public .detailsInputComponent .attachPhotoContainerMobile .selectedPhotosContainer .photoItem.addPhoto .icon svg {
  font-size: 2.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .uploadErrorMsg {
  color: red;
  line-height: 1.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .openCameraBtn {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .detailsInputComponent .openCameraBtn,
.dnv-widget .rap-public .detailsInputComponent .openPhotosBtn {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #6399ae;
  border-radius: 50px;
  color: white;
  border: none;
}
.dnv-widget .rap-public .detailsInputComponent .openCameraBtn i::before,
.dnv-widget .rap-public .detailsInputComponent .openPhotosBtn i::before {
  font-size: large;
  margin-right: 0.5rem;
}
.dnv-widget .rap-public .detailsInputComponent .checkBoxContainer label {
  display: block;
  line-height: 2rem;
  width: -moz-fit-content;
  width: fit-content;
}
.dnv-widget .rap-public .detailsInputComponent .checkBoxContainer .checkSelector {
  accent-color: #0980b5;
  transform: scale(1.375);
  margin-right: 0.5rem;
  -webkit-appearance: auto !important;
     -moz-appearance: auto !important;
          appearance: auto !important;
  width: initial !important;
  height: initial !important;
  position: relative !important;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer {
  line-height: 2.25rem;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer input {
  margin: revert;
  margin-right: 0.5rem;
  transform: scale(1.375);
  accent-color: #0980b5;
  -webkit-appearance: auto !important;
     -moz-appearance: auto !important;
          appearance: auto !important;
  width: initial !important;
  height: initial !important;
  position: relative !important;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer .conditionalResponse {
  color: rgb(0, 136, 14);
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer .conditionalResponse .redResponseText {
  color: red;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer.horizontalRadioOptions {
  display: flex;
  flex-wrap: wrap;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer.horizontalRadioOptions .radioSelectBox {
  display: flex;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer.horizontalRadioOptions .radioSelectBox label {
  margin-right: 1rem;
}
.dnv-widget .rap-public .detailsInputComponent .radioContainer.horizontalRadioOptions p {
  width: 100%;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip > svg {
  color: #1b8898;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip {
  position: relative;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip .tooltipText {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: left;
  padding: 5px 5px;
  border-radius: 6px;
  border: solid 1px black;
  font-weight: normal;
  position: absolute;
  z-index: 1;
  width: 10vw;
  min-width: 125px;
  bottom: 108%;
  left: 50%;
  margin-left: -70px;
  margin-bottom: 2.5px;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip .tooltipArrow {
  visibility: hidden;
  content: " ";
  position: absolute;
  top: -20%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip:hover {
  cursor: pointer;
}
.dnv-widget .rap-public .detailsInputComponent .questionTooltip:hover .tooltipText,
.dnv-widget .rap-public .detailsInputComponent .questionTooltip:hover .tooltipArrow {
  visibility: visible;
}
.dnv-widget .rap-public .contactIntoEntryComponent p {
  font-size: 20px;
  line-height: 2rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer {
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer.newContact {
  margin-top: 2.5rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
  font-size: 20px;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer label span:not(#notificationLabelText) {
  font-weight: normal;
  color: grey;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer input:not(#notificationCheckBox) {
  margin-right: 1rem;
  line-height: 40px;
  padding: 0rem 10px;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer input:not(#notificationCheckBox):focus {
  border: 2px solid rgb(0, 136, 14);
  outline: none;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .nameInfo {
  display: flex;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .nameInfo .firstName input,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .nameInfo .lastName input {
  margin-bottom: 0.25rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .emailInfo {
  display: block;
  width: 100%;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .emailInfo input {
  margin-bottom: 0.25rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addressInfo {
  display: block;
  width: 100%;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addressInfo input {
  margin-bottom: 0.25rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo {
  width: 100%;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneOne {
  display: grid;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneOne input {
  margin-right: 1.75rem !important;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneOne, .dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo.phoneTwo, .dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo.phoneThree {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails {
  display: inline-flex;
  margin-bottom: 0.25rem;
  align-items: center;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails.addedPhoneDetails {
  display: block;
  width: 100%;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails.addedPhoneDetails .removePhoneIcon {
  margin-left: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails.addedPhoneDetails .removePhoneIcon:hover {
  cursor: pointer;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails.addedPhoneDetails .removePhoneIcon svg {
  width: 1.25rem;
  fill: #1b8898;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails select {
  margin-right: 1rem;
  height: 45px;
  padding: 0rem 10px;
  width: 10%;
  min-width: 125px;
  font-size: 20px;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  border: 1px inset #767676;
  color: black;
  background-color: white;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .phoneInfo .phoneDetails input {
  display: inline-flex;
  padding-right: 0;
  width: 90% !important;
  max-width: 175px;
  padding-bottom: 0 !important;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addRemoveNumbersCtl {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .firstName,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .lastName,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .emailInfo,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addressInfo {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addPhone,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .removePhone {
  color: #1b8898;
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addPhone:hover,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .removePhone:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .addPhone:focus,
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .removePhone:focus {
  outline: 4px dashed #00880e;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck {
  margin-top: 1.5rem;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationLabel {
  display: flex;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationLabel #notificationLabelText {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationLabel #bellIcon {
  display: inline-block;
  margin-left: 0.5rem;
  transform: rotate(-15deg);
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationLabel #bellIcon svg {
  width: 30px;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationControl {
  display: block;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationControl input {
  transform: scale(1.5);
  accent-color: #0980b5;
  margin-left: 6px;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formContainer .notificationCheck .notificationControl .notificationText {
  width: calc(100% - 60px);
  margin-left: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.5rem;
  display: inline-flex;
}
.dnv-widget .rap-public .contactIntoEntryComponent .formErrorMsg {
  color: red;
  display: block;
  font-size: 18px !important;
  font-weight: normal;
}
.dnv-widget .rap-public .contactIntoEntryComponent .inputError {
  border-color: red;
  border-top-style: double;
  border-left-style: double;
  border-width: thin;
}
.dnv-widget .rap-public .contactIntoEntryComponent .noMarginRight {
  margin-right: 0 !important;
}
.dnv-widget .rap-public .submissionReviewComponent p {
  margin-bottom: 1.5rem;
}
.dnv-widget .rap-public .submissionReviewComponent .entryTitle {
  margin-top: 0;
  margin-bottom: auto;
}
.dnv-widget .rap-public .submissionReviewComponent .entryInput {
  font-size: 20px;
  font-weight: normal;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  line-height: 2rem;
  word-wrap: break-word;
}
.dnv-widget .rap-public .submissionReviewComponent .entryEdit {
  font-size: 20px;
  color: rgb(9, 128, 181);
  font-weight: bold;
  height: -moz-min-content;
  height: min-content;
}
.dnv-widget .rap-public .submissionReviewComponent .addedPhoto {
  background-size: 100%;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry {
  display: flex;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border: 3px solid #d3d3c8;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryTitle {
  font-weight: bold;
  width: 25%;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput {
  width: 65%;
  z-index: 1;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput #mapLocation {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .map {
  width: 75%;
  height: 20rem;
  border: black 1px solid;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos {
  width: 100%;
  display: block;
  margin-top: 1rem;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem {
  width: 125px;
  height: 125px;
  display: inline-flex;
  border: 1px solid black;
  vertical-align: text-top;
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem:first-child {
  margin-left: 0;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem:last-child {
  margin-right: 0;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryEdit {
  max-width: 10%;
  width: 100%;
  text-align: right;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryEdit span:hover {
  cursor: pointer;
  color: rgb(0, 136, 14);
  text-decoration: underline;
}
.dnv-widget .rap-public .submissionReviewComponent .desktopDisplayOnly .problemSubmissionEntry .entryEdit span:focus {
  outline: 4px dashed #00880e;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry {
  display: block;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border: 3px solid #d3d3c8;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryTitle {
  font-weight: bold;
  width: 75%;
  margin-top: 0.5rem;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryEdit {
  max-width: 25%;
  height: -moz-min-content;
  height: min-content;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryEdit:hover {
  cursor: pointer;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryEdit span:focus {
  outline: 4px dashed #00880e;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput {
  width: 100%;
  display: inline-block;
  margin-top: 0.5rem;
  line-height: 1.75rem;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput #mapLocation {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput .map {
  width: 100%;
  height: 20rem;
  border: black 1px solid;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos {
  width: 100%;
  display: block;
  margin-top: 1rem;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem {
  width: 100px;
  height: 100px;
  display: inline-flex;
  vertical-align: text-top;
  position: relative;
  margin: 0.5rem;
  text-align: center;
  border: 1px solid black;
}
.dnv-widget .rap-public .submissionReviewComponent .mobileDisplayOnly .problemSubmissionEntry .entryInput .submissionPhotos .photoItem img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.dnv-widget .rap-public .submissionCompleteComponent {
  /*
  FIXME: Imports must be at the top of a style sheet before any other rule.
         They are likely to be ignored when not. https://developer.mozilla.org/en-US/docs/Web/CSS/@import
  */
  @import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
}
.dnv-widget .rap-public .submissionCompleteComponent .checkMarkIcon {
  text-align: center;
  font-size: 4rem;
  color: #6dc36b;
}
.dnv-widget .rap-public .submissionCompleteComponent .checkMarkHeading {
  text-align: center;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionCompleteComponent p {
  line-height: 1.5rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepsTitle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepText {
  font-weight: normal;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepsContainer {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepsContainer .step {
  display: flex;
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepsContainer .step .circleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgb(9, 128, 181);
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.dnv-widget .rap-public .submissionCompleteComponent .stepsContainer .step .stepText {
  margin: auto 1rem auto 1rem;
  width: calc(100% - 3.25rem);
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile {
  border-bottom: solid #d3d3c8 2px;
  border-top: solid #d3d3c8 2px;
  background-color: white;
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsTitle {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 20px;
  color: rgb(9, 128, 181);
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsTitle.expandedListIcon {
  color: #bba600;
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsTitle span {
  color: #575757;
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsContainer {
  margin-left: 0;
  margin-right: 0;
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsContainer .step {
  margin-left: 1rem;
  margin-right: 1rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .nextStepsMobile .stepsContainer .step .stepText {
  margin-right: 0;
  line-height: 1.5rem;
  font-size: 20px;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer {
  margin-top: 2rem;
  line-height: 1.5rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer .ratingTitle {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer .ratingInformation {
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 0.5rem;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer .ratingLink {
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer .ratingLink a span {
  vertical-align: middle;
}
.dnv-widget .rap-public .submissionCompleteComponent .rateRAPContainer .ratingLink a span svg {
  width: 20px;
}
.dnv-widget .rap-public .submissionCompleteComponent a {
  color: #0980b5;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings {
  overflow: hidden;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating {
  padding: 0;
  margin: 0;
  border: none;
  float: left;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating input {
  display: none;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating label {
  color: #ddd;
  float: right;
  height: 4rem;
  width: 55px;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating label:before {
  margin: 10px 5px;
  font-size: 2.25em;
  font-family: FontAwesome;
  content: "\f005";
  display: flex;
  height: 100%;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating > input:checked ~ label, .dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating:not(:checked) > label:hover, .dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating:not(:checked) > label:hover ~ label {
  color: #0980b5;
}
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating > input:checked + label:hover,
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating > input:checked ~ label:hover,
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating > label:hover ~ input:checked ~ label,
.dnv-widget .rap-public .submissionCompleteComponent .starRatings .rating > input:checked ~ label:hover ~ label {
  color: #48c7ff;
}
.dnv-widget .rap-public .submissionCompleteComponent .mobileStarRatings .rating {
  float: none;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
}
.dnv-widget .rap-public .systemErrorComponent .heading {
  background-color: rgba(236, 128, 141, 0.7450980392);
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
  border: none !important;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  display: inline-flex;
  justify-content: center;
}
.dnv-widget .rap-public .systemErrorComponent .heading svg {
  margin-top: auto;
  margin-bottom: auto;
}
.dnv-widget .rap-public .systemErrorComponent .heading.isUrgent {
  background-color: #eac99c;
}
.dnv-widget .rap-public .systemErrorComponent .heading i::before {
  font-size: 1.5rem;
  vertical-align: middle;
}
.dnv-widget .rap-public .systemErrorComponent .heading span {
  margin: 0.85rem 0.5rem;
  font-size: 20px;
  font-weight: normal;
}
.dnv-widget .rap-public .systemErrorComponent #title:hover {
  cursor: pointer;
}
.dnv-widget .rap-public .systemErrorComponent .issueDesc {
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: normal;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
  line-height: 1.5rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers {
  margin-bottom: 2rem;
  display: flex;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer {
  border: 3px solid #d3d3c8;
  width: 48%;
  display: inline-table;
  height: inherit;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer.right {
  margin-left: auto;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin {
  margin: 1rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerIcon {
  text-align: center;
  font-size: 5rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerIcon svg {
  width: 100px;
  height: 100px;
  fill: #575757;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInfo {
  margin-bottom: 1rem;
  text-align: center;
  margin-top: auto;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInfo a {
  color: #0980b5;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions {
  font-size: 20px;
  margin-bottom: 2rem;
  font-weight: normal;
  font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions .inclusionList {
  margin-left: 2rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions .inclusionList ul {
  line-height: 1.5rem;
  margin-block-start: 0.5rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions .contactDetail {
  margin-bottom: 1rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions .contactDetail .dateTime {
  font-weight: 900;
  line-height: 2rem;
}
.dnv-widget .rap-public .systemErrorComponent .containers .infoContainer .containerMargin .containerInstructions .contactDetail a {
  color: #0980b5;
}/*# sourceMappingURL=DNVRapPublic.css.map */
