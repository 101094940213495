/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.footer-land{
    background-color: var(--color-dnv-bg-and-border-muted-blue);

}
.footer-land p{
    max-width: var(--desktop-width);
    width: 100%;
    padding: 16px 50px;
    margin: auto;
    line-height: 26px;
}
.footer-land a{
    color: var(--color-dnv-interactive-dark-blue);
    font-family: var(--font-family-links-labels);
}
.footer-land a:hover{
    color: var(--color-dnv-hover-green);
}
.page-footer {
    display: flex;
    flex-direction: column;
    background-color: var(--color-dnv-brand-navy);
    padding-top: 2.5em;
}

.page-footer-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1440px;
}

.page-footer-content > div {
    width: calc(100% / 3);
    max-width: 436px;
}

.page-copyright {
    min-height: 50px;
    background-color: var(--color-white);
    font-size: 17px;
}

.page-copyright-content {
    align-items: center;
    color: var(--color-black);
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 15px 20px 16px 20px;
    min-height: 50px;
    max-width: var(--desktop-width);
    width: 100%;
    padding: 0px 50px;
}

.page-copyright-content p {
    margin-bottom: 0;
}

.page-copyright-content a {
    color: var(--color-dnv-interactive-blue);
    font-weight: 800;
}

.page-copyright-content ul {
    display: flex;
    align-items: center;
    gap: 81px;
}
.page-copyright-content li:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 50px;
}

@media only screen and (min-width: 1366px) {
    .page-copyright-content ul {
        gap: 60px;
    }
}

.page-copyright-content ul div {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}


.page-footer a {
    color: var(--color-footer-white);
    font-size: 17px;
}

.page-footer .neighbourhoods a {
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium);
    line-height: 2.2em;
    font-weight: 800;
}

.page-footer address {
    font-size: var(--font-size-medium);
    line-height: 26px;
    color: var(--color-footer-white);
}

.page-footer p {
    font-size: var(--font-size-medium);
    max-width: 270px;
    color: var(--color-footer-white);
}

.page-footer .footer-logo p {
    margin-top: 2.2em;
}

.page-footer a:hover {
    color: var(--color-dnv-active-bright-blue);
}

.page-footer h2 {
    font-family: var(--font-family-heading);
    color: var(--color-footer-white);
    font-size: var(--font-size-large);
    margin: 0 0 .8em 0;
}

.page-footer .footer-button-link {
    margin-top: 1.5em;
    border-radius: 0;
    border: 2px solid var(--color-white);
    width: 224px;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    font-family: var(--font-family-links-labels);
    font-size: var(--font-size-medium-small);
}

.page-footer .footer-button-link:hover {
    border-color: var(--color-dnv-active-bright-blue);
    color: var(--color-white);
}
.page-footer .footer-button-link:hover i {
    color: var(--color-dnv-hover-bright-yellow);
}

.page-footer .screen-reader {
    display: none;
}

.page-footer .social-links {
    /* display: flex;
    gap: 8px;
    flex-wrap: wrap; */
    font-size: 17px;
    font-weight: 800;
}

.page-footer .social-links a{
    margin: 0px 24px 24px 0px;
    display: inline-block;
    text-decoration: none;
}

.page-footer .social-links a .link-text{
    text-decoration: underline;
    text-underline-offset: 2px;
}

.page-footer .social-links i:hover {
    color: var(--color-dnv-hover-bright-yellow);
}

.page-footer .footer-contact p{
    margin-bottom: 12px;
}

.footer-social{
    position: relative;
}

.footer-social a.more-link.footer-button-link{
    position: absolute;
    bottom: 0;
}

@media only screen and (max-width: 900px) {


    .page-footer-content > div{
        width: 48%;
        margin-right: 10px;
    }
}


@media only screen and (max-width: 854px) {
    .page-footer-content{
        justify-content: flex-start;
    }

    .page-footer-content > div{
        width: 48%;
        margin-right: 10px;
    }

    .page-footer .footer-contact{
        margin-top: 32px;
    }
}

@media only screen and (max-width: var(--tablet-width)) {
    .page-copyright {
        padding-top: 3em !important;
        padding-bottom: 3em !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 768px) {
    .page-copyright {
        height: 100%;
        padding: 15px 20px 16px 20px
    }

    .page-copyright-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .page-copyright-content p {
        margin-bottom: 16px;
    }

    .page-copyright-content ul div {
        display: flex;
        gap: 50px;
        justify-content: space-between;
    }

    .page-copyright-content ul {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

}

@media only screen and (max-width: 600px) {
    .page-footer{
        .page-footer-content{
            > div{
                width: 100%;
            }
        }
        p{
            max-width: unset;
        }
        /* .footer-social{
            margin-top: 32px;
        } */
    }

}

@media only screen and (max-width: 585px) {
    .page-footer-content{
        flex-direction: column;
    }
    /* .footer-social{
        margin-top: 32px;
    } */
}

@media only screen and (max-width: 1060px){

    .footer-land p{
        padding: 16px 32px;
    }
    .page-footer{
        padding: 32px 20px;
    }
    .page-footer-content{
        padding: 0px;
        margin: 0px;
        max-width: 100%;
    }

    .page-footer .page-footer-content > div{
        margin-bottom: 60px;
    }
    .page-footer .page-footer-content > .footer-social{
        margin-bottom: 0px;
    }
    .footer-social a.more-link.footer-button-link{
        position: static;
    }
}
