/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
:root {
    --highlight-size: 345px;
    --highlight-size-height: 345px;
    --highlight-card-width: 690px;
    --highlight-row-width: 1410px;
    --highlight-card-half-width: 50%;
}
.homepage-highlights {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    gap: 24px;
    /*width: 100%;*/
    /* width: var(--highlight-card-width) */
}
.homepage-highlights > div {
    flex: 1;
    width: 100%;
}
.homepage-highlights .simplicity-cta-content {
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.homepage-highlights .cta-link-container a,
.homepage-highlights .cta-link-container a i:before,
.homepage-highlights .cta-link-container svg path {
    color: var(--color-dnv-interactive-dark-blue);
    fill: var(--color-dnv-interactive-dark-blue);
}
.homepage-highlights .cta-link-container a:hover,
.homepage-highlights .cta-link-container a:hover i:before,
.homepage-highlights .cta-link-container svg path {
    color: var(--color-dnv-hover-dark-green);
    fill: var(--color-dnv-hover-dark-green);
}
.homepage-highlights .cta-link-container a:active,
.homepage-highlights .cta-link-container a:active i:before,
.homepage-highlights .cta-link-container svg path {
    color: var(--color-dnv-active-black);
    fill: var(--color-dnv-active-black);
}
.homepage-highlights + div .simplicity-cta-content {
    background-color: var(--color-dnv-bg-and-border-white);
}
.homepage-highlights > div,
.homepage-highlights > div > .simplicity-cta-container {
    height: 100%;
    width: 100%;
}
.homepage-highlights > div > .simplicity-cta-container > .simplicity-cta-content {
    height: 100%;
    width: 50%;
}
/* .homepage-highlights > div:hover {
    -webkit-box-shadow: 0 3px 12px 0 #B7B7A9;
    box-shadow: 0 3px 12px 0 #B7B7A9;
} */
.homepage-highlights .simplicity-cta-container {
    background-color: transparent;
    height: var(--highlight-size-height);
    width: var(--highlight-size);
}
.homepage-highlights .simplicity-cta-container.horizontal {
    overflow: hidden;
    /* max-width: var(--highlight-card-width); */
    flex: 1;
    /* width: var(--highlight-card-width) !important;
    height: var(--highlight-card-width) !important; */
    width: 100%;
    background-color: var(--color-dnv-bg-and-border-pale-grey);
}
.homepage-highlights .simplicity-cta-container.vertical {
    overflow: hidden;
    max-width: var(--highlight-card-width);
}
.homepage-highlights .simplicity-cta-container p {
    margin-bottom: 24px;
    max-height: 175px;
}
.homepage-highlights .component.call-to-action:nth-child(2) .simplicity-cta-container p:last-child{
    margin-bottom: 0px;
}

.homepage-highlights .rich-text-container{
    --spacing-y: 0px;
}

.homepage-highlights .simplicity-cta-content.horizontal{
    min-width: unset;
}

/* .homepage-highlights .component.call-to-action{
    width: calc(50% - 12px);
} */

.homepage-highlights .component.call-to-action .simplicity-cta-content-image.horizontal{
    min-width: 0;
}

.homepage-highlights .component.call-to-action .feed-component-header{
    padding: 0;
    margin-bottom: 16px;
}

@media (min-width: 1360px) {
    .homepage-highlights {
        /* width: var(--highlight-row-width); */
        flex-direction: row;
        margin-left: 0;
        margin-right: 0;
    }
    .homepage-highlights > div {
        /* width: var(--highlight-row-width); */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* gap: 24px; */
    }
    .homepage-highlights > div > div {
        height: 100%;
        width: var(--highlight-card-width);
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 0;
    }
    .homepage-highlights .simplicity-cta-container {
        width: var(--highlight-card-width);
        /* max-height: var(--highlight-size-height); */
    }
}

@media only screen and (max-width: 1060px) {
    .homepage-highlights > div {
        flex: unset;
    }
}


@media (max-width: 1359px){
    .homepage-highlights{
        flex-direction: row;
    }

    /* .homepage-highlights .simplicity-cta-content-image.horizontal{
        height: 405px;
    } */
}

@media (max-width: 1161px){
    .homepage-highlights{
        flex-direction: row;
    }
    /* .homepage-highlights .component.call-to-action{
        height: 450px;
    } */

    .homepage-highlights .simplicity-cta-container.horizontal{
        display: flex;
        flex-direction: column;
        max-height: unset;
        width: 100%;
    }
    .homepage-highlights .simplicity-cta-content-image.horizontal{
        height: 180px;
        width: 100%;
        /* min-height: 180px; */
    }

    .homepage-highlights > div > .simplicity-cta-container > .simplicity-cta-content {
        width: 100%;
    }

}

@media (max-width: 481px) {
    .homepage-highlights {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex-direction: column;
    }
    .homepage-highlights > div {
        width: 100%;
    }
    .homepage-highlights > div > div {
        width: 100%;
    }
    .homepage-highlights .component.call-to-action{
        width: 100%;
        height: auto;
    }
    .homepage-highlights .simplicity-cta-container {
        width: 100%;
        max-width: 100%;
    }
    .homepage-highlights .simplicity-cta-container,
    .homepage-highlights .simplicity-cta-container.horizontal,
    .homepage-highlights .simplicity-cta-container.vertical {
        width: 100%;
        max-width: 100%;
    }

    .homepage-highlights .simplicity-cta-container.horizontal{
        min-height: unset;
    }
}

@media (max-width: 775px){
    .homepage-highlights  .simplicity-cta-container.horizontal{
        min-height: unset;
    }
}

@media (max-width: 375px) {
    .homepage-highlights .simplicity-cta-container .cta-link-item{
        max-height: unset;
        padding: 0px 24px;
    }
}
