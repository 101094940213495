/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/* Base Styles */
.primary-menu {
    /* max-width: var(--desktop-width); */
    width: 100%;
    /* padding-left: 3.125rem;
    padding-right: 3.125rem; */
    /* margin: 0 auto; */
    border-bottom: solid 2px var(--color-dnv-top-bar-border-light-blue);
    background-color: var(--color-white);
}

@media only screen and (max-width: 1060px) {
    .primary-menu {
        width: auto;
        padding: 0;
        position: relative;
        z-index: 12;
        background-color: var(--color-white);
    }
    .primary-menu-content{
        justify-content: space-between !important;
        padding: 20px;
    }
}

@media only screen and (max-width: 700px) {
    /* .primary-menu {
        padding: 0;
    } */
}

.primary-menu-content {
    margin:auto;
    display: flex;
    justify-content: center;
    height: 76px;
    max-width: var(--desktop-width);
    width: 100%;
    padding: 0px 32px;
}
.primary-menu-content-logo {
    display: none;
    /* margin-top: 7px; */
    /* margin-left: 30px; */
}
.primary-menu-content-menu {
    /* margin-left: 160px;
    margin-right: 30px; */
    /* width:886px; 6 items in menu */
    /* width:1080px; 7 items in menu */
    width: 100%;
}

/* Mobile Styles */
.primary-menu-content-menu-mobile {
     display: none;
   /* height: 100px; */
}

.primary-menu-content-menu-mobile button {
    color: var(--color-dnv-brand-blue);
    border-radius: 0;
    border: var(--color-dnv-brand-blue) 2px solid;
    padding: 12px 16px;
    background-color: inherit;
    /* text-transform: uppercase; */
    font-size: 17px;
    display: flex;
    align-items: center;
    gap:12px;
    width: 115px;
    height: 48px;
    margin-top: 6px;
    font-family: var(--font-family-links-labels);
    font-weight: 800;
}

.primary-menu-content-menu-mobile.open button {
    color: var(--color-white);
    border: var(--color-dnv-hover-green) 2px soli d;
    background-color: var(--color-dnv-hover-green);
    box-shadow: 0px 3px 6px 0px #00000066;
}

.primary-menu-content-menu-mobile button svg {
    color: var(--color-dnv-brand-blue);
    font-size: 20px;
}

.primary-menu-content-menu-mobile.open button svg {
    color: var(--color-white);
}

.primary-menu-content-menu-mobile button:hover {
    /* color: var(--color-dnv-hover-bright-yellow);
    border-color: var(--color-dnv-active-bright-yellow); */
    cursor: pointer;
}

@media only screen and (max-width: 1124px) {
    /* .primary-menu{
        position: relative;
        z-index: 2;
    } */
    .primary-menu-content {
        justify-content: space-between;
        padding: 20px;
    }
    .primary-menu-content-menu {
        display: none !important;
    }
    .primary-menu-content-menu-mobile {
        display: flex;
        align-items: center;
    }
    .primary-menu-content-logo {
        display: flex;
        align-items: center;
    }
}

/* Menu Block Wrapper Styles */
.menu-block-wrapper > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.menu-block-wrapper > ul > li > a {
    display: block;
    color: #E8E8E1;
    /*font-family: "Avenir LT W01_85 Heavy1475544", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-links-labels);
    padding: 0;
    border-bottom: 3px solid transparent;
    -webkit-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menu-block-wrapper > ul > li > a:hover,
.menu-block-wrapper > ul > li > a:focus,
.menu-block-wrapper > ul > li > a:active {
    outline: none;
    color: var(--color-dnv-hover-bright-yellow);
    text-decoration: none;
    border-bottom-color: var(--color-dnv-active-dark-yellow);
}

.menu-block-wrapper > ul > li.active-trail > a {
    color: #E8E8E1;
    border-bottom-color: #E8E8E1;
}

.menu-block-wrapper > ul > li.active-trail > a:hover,
.menu-block-wrapper > ul > li.active-trail > a:focus,
.menu-block-wrapper > ul > li.active-trail > a:active {
    outline: none;
    color: var(--color-dnv-hover-bright-yellow);
    text-decoration: none;
    border-bottom-color: var(--color-dnv-active-dark-yellow);
}

.menu-block-wrapper > ul > li > ul {
    position: absolute;
    left: 1.5rem;
    top: 100%;
    width: 340px;
    -webkit-box-shadow: 0 3px 12px 0 #B7B7A9;
    box-shadow: 0 3px 12px 0 #B7B7A9;
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.menu-block-wrapper > ul > li > ul.visible {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.menu-block-wrapper > ul > li > ul > li {
    display: block;
    clear: both;
}

.menu-block-wrapper > ul > li > ul > li.expanded > a {
    padding-right: 2.625rem;
}

.menu-block-wrapper > ul > li > ul > li.expanded > a:hover:after,
.menu-block-wrapper > ul > li > ul > li.expanded > a:focus:after,
.menu-block-wrapper > ul > li > ul > li.expanded > a:active:after {
    content: '\f061';
    color: var(--color-dnv-active-dark-yellow);
    -webkit-transform: translate(0.25rem, -50%);
    -ms-transform: translate(0.25rem, -50%);
    transform: translate(0.25rem, -50%);
}

.menu-block-wrapper > ul > li > ul > li.expanded > a:after {
    content: '\f054';
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 1.375rem;
    top: 50%;
    -webkit-transform: translate(0rem, -50%);
    -ms-transform: translate(0rem, -50%);
    transform: translate(0rem, -50%);
    -webkit-transition: color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.menu-block-wrapper > ul > li > ul > li > a {
    position: relative;
    z-index: 1;
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    display: block;
    padding: 0.9375rem 1.25rem;
    color: #575757;
    border-bottom: 1px solid #D3D3C8;
    background-color: #F4F4EC;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menu-block-wrapper > ul > li > ul > li > a:hover,
.menu-block-wrapper > ul > li > ul > li > a:focus,
.menu-block-wrapper > ul > li > ul > li > a:active {
    background-color: #fff;
    color: #44883E;
    text-decoration: underline;
}

.menu-block-wrapper > ul > li > ul > li > a.active-trail {
    background-color: #fff;
}

.menu-block-wrapper > ul > li > ul > li.expanded:hover > a,
.menu-block-wrapper > ul > li > ul > li.expanded:focus > a,
.menu-block-wrapper > ul > li > ul > li.expanded:active > a {
    background-color: var(--color-white);
    color: #44883E;
    text-decoration: underline;
}

.menu-block-wrapper > ul > li > ul > li.expanded:hover > a:after,
.menu-block-wrapper > ul > li > ul > li.expanded:focus > a:after,
.menu-block-wrapper > ul > li > ul > li.expanded:active > a:after {
    content: '\f061';
    color: var(--color-dnv-active-dark-yellow);
    -webkit-transform: translate(0.25rem, -50%);
    -ms-transform: translate(0.25rem, -50%);
    transform: translate(0.25rem, -50%);
}

.menu-block-wrapper > ul > li > ul > li > ul {
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    min-height: 100%;
    background-color: #FAFAF9;
    -webkit-box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    padding-left: 3px;
}

.menu-block-wrapper > ul > li > ul > li > ul.visible {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.menu-block-wrapper > ul > li > ul > li > ul > li {
    display: block;
    clear: both;
}

.menu-block-wrapper > ul > li > ul > li > ul > li > a {
    /*font-family: "Avenir LT W01_55 Roman1475520", "Helvetica Neue", Arial, sans-serif;*/
    font-family: var(--font-family-paragraph);
    font-weight: 400;
    display: block;
    padding: 0.9375rem 1.25rem;
    color: #575757;
    background-color: transparent;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.menu-block-wrapper > ul > li > ul > li > ul > li > a:hover,
.menu-block-wrapper > ul > li > ul > li > ul > li > a:focus,
.menu-block-wrapper > ul > li > ul > li > ul > li > a:active {
    background-color: var(--color-white);
    color: #44883E;
    text-decoration: underline;
}

.menu-block-wrapper > ul > li > ul > li > ul > li > a.active-trail {
    background-color: var(--color-white);
}

.menu-block-wrapper > ul > li > ul > li > ul > li ul {
    display: none;
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul,
.menu-block-wrapper > ul > li:last-child > ul {
    left: auto;
    right: -4rem;
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded > a,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded > a {
    padding-right: 0.9375rem;
    padding-left: 2.625rem;
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded > a:hover:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded > a:hover:after,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded > a:focus:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded > a:focus:after,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded > a:active:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded > a:active:after {
    content: '\f060';
    -webkit-transform: translate(-0.25rem, -50%);
    -ms-transform: translate(-0.25rem, -50%);
    transform: translate(-0.25rem, -50%);
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded > a:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded > a:after {
    content: '\f053';
    left: 0.9375rem;
    right: auto;
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:hover > a,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:hover > a,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:focus > a,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:focus > a,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:active > a,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:active > a {
    background-color: var(--color-white);
    color: #44883E;
    text-decoration: underline;
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:hover > a:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:hover > a:after,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:focus > a:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:focus > a:after,
.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li.expanded:active > a:after,
.menu-block-wrapper > ul > li:last-child > ul > li.expanded:active > a:after {
    content: '\f060';
    color: var(--color-dnv-active-dark-yellow);
    -webkit-transform: translate(-0.25rem, -50%);
    -ms-transform: translate(-0.25rem, -50%);
    transform: translate(-0.25rem, -50%);
}

.menu-block-wrapper > ul > li:nth-last-child(2) > ul > li > ul,
.menu-block-wrapper > ul > li:last-child > ul > li > ul {
    -webkit-transform-origin: right center;
    -ms-transform-origin: right center;
    transform-origin: right center;
    left: auto;
    right: 100%;
    padding-left: 0;
    padding-right: 3px;
    -webkit-box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
}

.menu-block-wrapper > ul > li:nth-last-child(-n+3) > ul > li > a {
    padding-left: 2.625rem;
}

@media only screen and (max-width: 1880px) {
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul {
        left: auto;
        right: -4rem;
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded > a {
        padding-right: 0.9375rem;
        padding-left: 2.625rem;
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded > a:hover:after,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded > a:focus:after,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded > a:active:after {
        content: '\f060';
        -webkit-transform: translate(-0.25rem, -50%);
        -ms-transform: translate(-0.25rem, -50%);
        transform: translate(-0.25rem, -50%);
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded > a:after {
        content: '\f053';
        left: 0.9375rem;
        right: auto;
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:hover > a,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:focus > a,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:active > a {
        background-color: var(--color-white);
        color: #44883E;
        text-decoration: underline;
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:hover > a:after,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:focus > a:after,
    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li.expanded:active > a:after {
        content: '\f060';
        color: var(--color-dnv-active-dark-yellow);
        -webkit-transform: translate(-0.25rem, -50%);
        -ms-transform: translate(-0.25rem, -50%);
        transform: translate(-0.25rem, -50%);
    }

    .menu-block-wrapper > ul > li:nth-last-child(3) > ul > li > ul {
        -webkit-transform-origin: right center;
        -ms-transform-origin: right center;
        transform-origin: right center;
        left: auto;
        right: 100%;
        padding-left: 0;
        padding-right: 3px;
        -webkit-box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
    }
}

@media only screen and (max-width: 1550px) {
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded > a {
        padding-right: 0.9375rem;
        padding-left: 2.625rem;
    }

    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded > a:hover:after,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded > a:focus:after,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded > a:active:after {
        content: '\f060';
        -webkit-transform: translate(-0.25rem, -50%);
        -ms-transform: translate(-0.25rem, -50%);
        transform: translate(-0.25rem, -50%);
    }

    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded > a:after {
        content: '\f053';
        left: 0.9375rem;
        right: auto;
    }

    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:hover > a,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:focus > a,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:active > a {
        background-color: var(--color-white);
        color: #44883E;
        text-decoration: underline;
    }

    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:hover > a:after,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:focus > a:after,
    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li.expanded:active > a:after {
        content: '\f060';
        color: var(--color-dnv-active-dark-yellow);
        -webkit-transform: translate(-0.25rem, -50%);
        -ms-transform: translate(-0.25rem, -50%);
        transform: translate(-0.25rem, -50%);
    }

    .menu-block-wrapper > ul > li:nth-last-child(4) > ul > li > ul {
        -webkit-transform-origin: right center;
        -ms-transform-origin: right center;
        transform-origin: right center;
        left: auto;
        right: 100%;
        padding-left: 0;
        padding-right: 3px;
        -webkit-box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 12px 0px #B7B7A9, inset 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
    }

    .menu-block-wrapper > ul > li:nth-last-child(-n+4) > ul > li > a {
        padding-left: 2.625rem;
    }
}

@media only screen and (max-width: 1500px) {
    .menu-block-wrapper > ul > li:last-child > ul {
        right: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .menu-block-wrapper > ul > li {
        padding: 1.625rem 1.75vw;
    }
}

@media only screen and (max-width: 1150px) {
    .menu-block-wrapper > ul > li:nth-last-child(5) > ul {
        left: 0.5rem;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1124px){
    .primary-menu-content{
        padding: 0px 32px;
    }
}

@media only screen and (max-width: 480px){
    .primary-menu-content{
        padding: 0px 16px;
    }
}
