/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
.logo {
    text-indent: 9999px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 100%;
    max-width: 260px;
    height: 114px;
    background-image: url(../../resources/dnvlogo-blue-full.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    /*border: 1px solid greenyellow;*/
}

.no-svg .logo {
    background-image: url(../../resources/logo-white-full.png);
}

.logo.footer-logo {
    max-width: 200px;
    text-indent: 9999px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 79px;
    background-image: url(../../resources/logo-white-full-b.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    margin-bottom: 12px;
}

.logo {
    max-width: 123px;
    height: 56px;
}
