/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

.tag {
    display: inline-flex;
    padding: 8px 6px 8px 16px;
    align-items: baseline;
    background-color: #EFEFEB;
    color: var(--color-dnv-interactive-blue);
    font-weight: 800;
}

.labelPrefix {
    display: none;
}

.tag button {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 6px 10px;
}

/* uses breakpoint: small */
@media screen and (min-width: 600px) {
    .labelPrefix {
        display: initial;
    }
}
